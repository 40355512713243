import React, {useState} from 'react'
import {InfoTooltip, LightContainer, Loader, MaxBtn, MiniBtn} from "../../core/components/components"
import {MyModal} from "../../core/components/modal"
import {CheckboxInput, InputContainer, TextareaInput, TextfieldInput} from "../../core/input/basic"
import {DateTimeInput} from "../../core/input/date"
import {SelectfieldInput} from "../../core/input/select"
import {TagsInput} from "../../core/input/tags"
import "../course_form.css"
import Status from "../../core/status"
import {apiGet, apiPost} from "../../core/api"

import {FormContext, FormContextWrapper} from "../../core/form_context"
import UserHistory from "../../user/user_history"

import {FaCopy, FaEye, FaSave, FaTrashAlt, FaUserEdit} from "react-icons/fa"
import {maxiPost} from "../../core/maxios"
import UserPermissions from "../../club/permissions"
import {checkPermissions} from "../../club/permissionsHelpers"
import {UserContext} from "../../user/UserContext"
import {dateFormatTime} from "../../core/dateFuncs"
import Consts from "../../core/consts"
import {encodeGetParams, objGetEntries} from "../../core/helpers"
import {StatusVar} from "../../club/club_inputs"
import {CoursePaymentModelSelect} from "../paymentPlan/course_payment_model_select"
import {CourseInput} from "../../core/input/courseInput";

const fields = {
    name: "Name",
    minparts: "Minimale Teilnehmer",
    maxparts: "Maximale Teilnehmer",
    membersOnly: "Mitgliedschaft erforderlich?",
    paymentDueDate: <>Fällig ab <InfoTooltip><h2>Erklärung zu: Fällig ab</h2> Das Datum, ab dem die Zahlung des Kursbeitrages fällig ist. Wenn es gesetzt wird, dann werden Buchungen dieses Kurses in der individuellen Abrechnung erst dann
        angezeigt,
        wenn das Fälligkeitsdatum überschritten wurde.</InfoTooltip></>,
    priceInput: <>Preis in € <InfoTooltip>Bitte nur eine Zahl einfügen und keine Währungszeichen etc. einfügen.</InfoTooltip></>,
    pricenmInput: <>Preis kein Mitglied <InfoTooltip>Preis der jenen verrechnet wird, die kein Mitglied sind, also keine Mitgliedsrolle zugewiesen haben. </InfoTooltip></>,
    bookingStart: "Buchbar ab",
    bookingEnd: "Buchbar bis",
    allBookingStart: <>Für neue Mitglieder buchbar ab <InfoTooltip><h2>Erklärung zu: Für neue Mitglieder buchbar ab</h2> Dieses Datum überschreibt das Datum des Feldes "Buchung von" für neue Mitglieder. Als "neue Mitglieder" zählen jene
        Benutzer, die keinen Kurs besucht haben, der innerhalb der letzten 365 Tage geendet hat (also letzter Kurstermin innerhalb der letzten 365 Tage).</InfoTooltip></>,
    ageQualifyingDate: <>Alters-Stichtag <InfoTooltip>Der Tag, an dem das Alter für die Kurseinschränkungen (Mindestalter / Maximalalter) berechnet wird. Wenn dieses Attribut nicht gesetzt wird, wird der erste Kurstermin für die Berechnung verwendet.</InfoTooltip></>,
    showStart: "Anzeigen ab",
    showEnd: "Anzeigen bis",
    olderthan: "Mindestalter (Jahre)",
    youngerthan: <>Maximalalter <InfoTooltip>
        <p>Wenn als Alter eine Ganzzahl eingegeben wird (z.B. 16), dann wird 16 inklusive gemeint, das heißt Personen sind bis zum Ende ihres 17. Lebensjahres erlaubt.</p>
        <p>Es ist auch möglich, eine Dezimalzahl einzugeben (z.B. 16,5), dann werden alle Personen, die maximal 16 Jahre und 6 Monate alt sind zugelassen.</p>
    </InfoTooltip></>,
    yearAllowedLeft: "Jahrgänge ab inklusive (JJJJ)",
    yearAllowedRight: "Jahrgänge bis inklusive (JJJJ)",
    gender: "Geschlecht",
    audience: "Zielgruppe",
    trainersAllowed: "erweiterter Trainerzugriff",
    enableOnlineConferences: "Online Treffen",
    enableMedia: "Medienabschnitt",
    downloadCertificate: "Zertifikat herunterladen",
    showShiftPlanner: <>Schicht-Einteilung anzeigen <InfoTooltip>Dieses Attribut dient dazu, die Schichten-Einteilung zu diesem Kurs (z.B. für Veranstaltungen) anzuzeigen.</InfoTooltip></>,
    //rolesAllowed: "Definierende Rollen",
    tags: "Tags / Stichworte",
    coursePaymentModel_ID: "Zahlungsweise festlegen",
    permittingCourses: "Eingeschlossene Kurse",
    description: "Beschreibung",
    information: "Information",
}

const fieldsNames = {
    paymentDueDate: "Fällig ab",
    priceInput: "Preis in €",
    pricenmInput: "Preis kein Mitglied",
    allBookingStart: "Für neue Mitglieder buchbar ab",
    showShiftPlanner: "Schicht-Einteilung anzeigen"
}

// Overrule JSx-Elements from fields.
const fieldsName = (x: keyof typeof fields) => {
    let value = fields[x]
    if ((!value || !(value instanceof String)) && (x in fieldsNames)) {
        value = fieldsNames[x as keyof typeof fieldsNames]
    }
    return value
}

const comp = [
    ["minparts", "maxparts"],
    ["priceInput", "pricenmInput"],
    ["olderthan", "youngerthan"],
    ["bookingStart", "bookingEnd"],
    ["showStart", "showEnd"],
    ["yearAllowedLeft", "yearAllowedRight"],
] as const

interface CourseEditState {
    error?: string
    loading?: boolean
}

interface CourseEditProps {
    id: string
    onSubmit?: Function
    multiple?: boolean
    courses?: any[]
}

class CourseEdit extends React.Component<CourseEditProps, CourseEditState> {
    static contextType = FormContext
    id: string
    apiGet: typeof apiGet
    apiPost: typeof apiPost
    state: CourseEditState = {}
    context!: React.ContextType<typeof FormContext>;

    constructor(props: CourseEditProps, context: React.ContextType<typeof FormContext> & { id: string }) {
        super(props)
        this.id = context.id || props.id
        this.apiGet = apiGet.bind(this)
        this.apiPost = apiPost.bind(this)
        this.setState = this.setState.bind(this)
    }

    handleChange = (e: any) => {
        const s = e.target.name.split("_")
        // Prevent subeditor update
        if (s[0].substring(0, 5) === "data." || s.length === 1 && s[0] === "") {
            return
        }

        this.context.setState({
            [s[0]]: Object.assign(
                {},
                this.context.state[s[0]],
                {[s[1]]: e.target.type === 'checkbox' ? e.target.checked : e.target.value}
            )
        })
    }

    handleSubmit = (event?: any) => {
        event?.preventDefault()

        //checks
        const course = this.context?.state?.course || {}
        //console.log(course, this.state)
        if ((course.yearAllowedLeft || course.yearAllowedRight) && (course.olderthan || course.youngerthan)) {
            this.setState({error: "Jahrgangs- und Alters-Limitierung darf nicht gemischt werden!"})
            return
        }

        const compError = comp.reduce((prev, curr) => {
            const left = course[curr[0]]
            const right = course[curr[1]]
            if (!prev && left !== null && right !== null && parseInt(left) > parseInt(right) && (curr[0] !== "priceInput" || !course.membersOnly)) {
                this.setState({error: `Die Felder "${fieldsName(curr[0] as keyof typeof fields)}" und "${fieldsName(curr[1] as keyof typeof fields)}" erfüllen nicht die Ordnungsbedingung!`})
                return true
            }
            return prev
        }, false)
        if (compError) {
            return
        }


        this.setState({loading: true})
        this.apiPost("/course/attributes/" + this.id, course, () => this.props.onSubmit && this.props.onSubmit())
    }


    render() {

        const FormWrapperElement = InputContainer//this.props.multiple ? (props) => <div {...props}></div> : InputContainer
        return <>
            <form id={"course_form"} onChange={this.handleChange} onSubmit={this.handleSubmit}>
                {
                    objGetEntries(fields).filter(x => !this.props.multiple || x.key !== "name").map(({key: f}, i) => {
                        let name = fields[f as unknown as keyof typeof fields]
                        if (this.props.multiple) {
                            let renderer = ["showStart", "showEnd", "bookingStart", "bookingEnd", "allBookingStart", "ageQualifyingDate"].includes(f) ? dateFormatTime : (x: any) => x
                            if (["priceInput", "pricenmInput"].includes(f)) {
                                renderer = (x: number) => Consts.moneyMax(x / 100)
                            }
                            name = <span style={(this.context?.state?.course || {})[f] !== undefined ? {color: "red"} : {}}>
                            {name} {
                                !["tags",].includes(f) &&
                                <FieldInformation accessor={f} courses={this.props.courses || []} name={name} renderer={renderer}/>
                            }
                        </span>
                        }
                        switch (f) {
                            case "gender":
                                return <FormWrapperElement key={"course_" + f}>
                                    <SelectfieldInput class={"form-control "} name={name} tag={"course_" + f} selectives={[
                                        ["male", "männlich"],
                                        ["fem", "weiblich"],
                                        ["all", "beide"],
                                    ]}/>
                                    {i % 2 === 0 && <br/>}
                                </FormWrapperElement>
                            case "coursePaymentModel_ID":
                                return <CoursePaymentModelSelect name={name} tag={"course_" + f} onSubmit={this.handleSubmit}/>
                            case "description":
                            case "information":
                                return <TextareaInput name={name} key={"course_" + f} tag={"course_" + f} style={{width: "100%"}}/>
                            //return <EditorInput name={name} key={"course_" + f} tag={"course_" + f} style={{width: "100%"}}/>;
                            case "tags":
                                return <TagsInput multiple name={name} entity={"course"} ID={this.id} tag={"course_tags"} key={"course_" + f}/>
                            /*case "rolesAllowed":
                                return <TagsInput multiple name={<>{name} <InfoTooltip>Wenn <b>Mitgliedschaft erforderlich?</b> aktiv ist, dürfen nur Personen mit folgenden Mitgliedsstatusen sich für diesen Kurs anmelden.</InfoTooltip></>}
                                                  entity={"roles"} ID={this.id} tag={"course_rolesAllowed"}/>;*/
                            case "permittingCourses":
                                return <>
                                    <CourseInput name={<>{name} <InfoTooltip>Wähle die Kurse aus, die der Teilnehmer ebenfalls besuchen darf, wenn er diesen bucht.</InfoTooltip></>} width={"100%"} tag={"course_permittingCourses"} multiple={true}/>
                                </>
                            case "membersOnly":
                            case "enableOnlineConferences":
                            case "enableMedia":
                            case "downloadCertificate":
                            case "showShiftPlanner":
                                return <FormWrapperElement key={"course_" + f}>
                                    <CheckboxInput name={name} tag={"course_" + f}/>
                                    {i % 2 === 0 && <br/>}
                                </FormWrapperElement>
                            case "trainersAllowed":
                                return <FormWrapperElement key={"course_" + f}>
                                    <CheckboxInput name={<>
                                        {name} <InfoTooltip>
                                        <li>Erlaube Trainern Neuigkeiten zu verschicken!</li>
                                        <li>Erlaube Trainern Videos im Medienteil (falls aktiviert) hochzuladen und diese werden sofort veröffentlicht.</li>
                                    </InfoTooltip>
                                    </>} tag={"course_" + f}/>
                                    {i % 2 === 0 && <br/>}
                                </FormWrapperElement>
                            case "name":
                                return <>
                                    <TextfieldInput class={"form-control "} key={"course_" + f} style={f === "name" ? {width: Math.min(window.innerWidth - 100, 400)} : {}} name={name} tag={"course_" + f}/>
                                    <br/>
                                </>
                            default:
                                if (f.startsWith("skip")) {
                                    return <FormWrapperElement key={"course_" + f}></FormWrapperElement>
                                }
                                if (f.startsWith("show") || f.startsWith("booking") || f.startsWith("allBooking") || f === "paymentDueDate" || f === "ageQualifyingDate") {
                                    return <FormWrapperElement key={"course_" + f}>
                                        <DateTimeInput name={name} midNight tag={"course_" + f}/>
                                        {i % 2 === 0 && <br/>}
                                    </FormWrapperElement>
                                }
                                return <FormWrapperElement key={"course_" + f}>
                                    <TextfieldInput class={"form-control "} name={name} tag={"course_" + f}/>
                                    {i % 2 === 0 && <br/>}
                                </FormWrapperElement>
                        }

                    })
                }
                <Status type="error" text={this.state?.error}/>
                <MaxBtn><FaSave/> Speichern</MaxBtn><Loader loading={this.state?.loading}/>
            </form>

            <h3>Anzeigedatum leer lassen</h3>
            <p>Angebote mit leer gelassenem Anzeige Datum ODER Buchungsdatum werden nicht unter Einheiten / Kurse angezeigt, sondern nur für Administratoren im jeweiligen Benutzerkonto. Angebote, wo nur das Buchungsdatum nicht vorhanden
                ist, werden
                nur den
                teilnehmende Personen in meine Buchungen angezeigt, nicht aber im Angebot.</p>
            <h3>Automatische Erhebung von Warteliste</h3>
            <p>
                Vor der Kursanmeldung wird geprüft, ob der Platz nicht jemand anderes gehören sollte und sonst wird derjenige auf die Warteliste gesetzt. Wenn man den Kurs bearbeitet (die Teilnehmeranzahl verändert), werden die Personen nur
                alle 11 Minuten von der Warteliste erhoben. Wenn man jemand abmeldet, wird auch sofort überprüft, ob den Platz nicht jemand anderes einnehmen könnte.
            </p>
            <h3>Trainer hinzufügen</h3>
            <p>Trainer werden als besondere Teilnehmer des Kurses angesehen. Um Trainer / Kurs-Leitende hinzuzufügen bitte diese Person zuerst zum Teilnehmer machen (oben über Teilnehmer &gt; Person hinzufügen) und dann zum Trainer machen
                über das
                Klicken auf <i>Teilnehmer <FaUserEdit/></i>&nbsp;
                und <MiniBtn>Mache zum Trainer</MiniBtn>.</p>
            <br/>
            {
                !this.props.multiple &&
                <UserContext.Consumer>
                    {
                        userContext => <>
                            {
                                checkPermissions(userContext, "club") &&
                                <MyModal trigger={<MaxBtn>Berechtigungen verwalten</MaxBtn>}>
                                    <UserPermissions entity_type={"course"} entity_ID={this.id}/>
                                </MyModal>
                            }
                            {
                                checkPermissions(userContext, "course") &&
                                <CourseDuplicate course_ID={this.id}/>
                            }
                            {
                                checkPermissions(userContext, "course/data_read", this.id) &&
                                <LightContainer name={"Kurs Veränderungen"}>
                                    <UserHistory userID={this.id} personType={"course"}/>
                                </LightContainer>
                            }
                        </>
                    }
                </UserContext.Consumer>
            }
        </>


    }
}

function encodeJustTrue(values: Record<string, boolean>) {
    let str = ""
    objGetEntries(values).forEach(({key, value}) => {
        if (value) {
            str += key + "=true&"
        }
    })
    return str
}

function CourseDuplicate({course_ID}: { course_ID: string }) {
    const [statusVar, setStatusVar] = useState<StatusVar>({})
    const [form, setForm] = useState({withTrainers: true, withParticipants: true, withEvents: true, moveYears: true})

    const duplicate = () => {
        maxiPost("/course/duplicate?" + encodeJustTrue(form), {course_ID}, {setStatusVar}).then(resp => window.location.href = "/kurse/" + resp.course_ID)
    }

    return <>
        <Status type={"success"} text={statusVar.success}/>
        <Status type={"error"} text={statusVar.error}/>
        <MyModal trigger={<MaxBtn><FaCopy/> Duplizieren</MaxBtn>}>
            <div>
                <Status type={"success"} text={statusVar.success}/>
                <Status type={"error"} text={statusVar.error}/>
                <FormContextWrapper value={{state: form, setState: setForm}}>
                    <InputContainer>
                        <CheckboxInput tag={"withTrainers"} name={"Trainer inkludieren"}/>
                    </InputContainer>
                    <InputContainer>
                    <CheckboxInput tag={"withParticipants"} name={"Teilnehmer inkludieren"}/>
                    </InputContainer>
                    <InputContainer>
                    <CheckboxInput tag={"withEvents"} name={"Kurstermine inkludieren"}/>
                    </InputContainer>
                    <InputContainer>
                    <CheckboxInput tag={"moveYears"} name={<>Kurstermine und Kursdaten um ein Jahr verschieben <InfoTooltip>
                        <h2>Von der Terminverschiebung betroffen sind:</h2>
                        <li><b>Kursdatumsangaben:</b> Also Datumswerte wie zum Beispiel "Buchbar ab / bis", "Anzeigen ab / bis" werden um genau 1 Jahr nach hinten verschoben.</li>
                        <li><b>Einzeltermine:</b> Wenn "Termine inkludieren" ausgewählt wurde, dann werden dabei auch die Start- und Endzeitpunkte um ein Jahr angepasst. Die Anpassung erfolgt aber auf Wochentage genau. Ein Termin am Dienstag bleibt also ein Termin am Dienstag, wird aber auf ein anderes Datum (das vom Schuljahr abhängt) verschoben. Unten folgt ein kurzes Beispiel:</li>

                        <p></p>
                        <h2>Erklärungen zur Terminverschiebung:</h2>
                        <p>Ein Schuljahr in Wien, Niederösterreich und im Burgenland fängt immer am Montag im Zeitraum 01.09. bis 07.09. des jeweiligen Jahres an. Wir verschieben jetzt einen wöchentlichen Kurs, der am Mittwoch, 14.09.2022 anfängt, einige Male:</p>
                        <ul>
                            <li><b>2023:</b> Schulbeginn ist am 04.09.2023, im Vorjahr war er am 05.09.2022. Damit verschiebt sich der erste Kurstag auch um einen Tag nach vorne, es ist der 13.09.2023.</li>
                            <li><b>2024:</b> Schulbeginn ist am 02.09.2024, weil 2024 ein Schaltjahr ist. Damit verschiebt sich der erste Kurstag um 2 Tage nach vorne, es ist der 11.09.2024.</li>
                            <li><b>2025:</b> Schulbeginn ist am 01.09.2025. Damit verschiebt sich der erste Kurstag um einen Tag nach vorne, es ist der 10.09.2025.</li>
                            <li><b>2026:</b> Schulbeginn ist am 07.09.2025. Damit verschiebt sich der erste Kurstag <b>um sechs Tage</b> nach hinten, es ist der 16.09.2026.</li>
                        </ul>
                        <p>Analoge Regeln gelten natürlich auch in den anderen Bundesländern, weil der Schulbeginn um eine Woche nach hinten verschoben ist.</p>
                    </InfoTooltip></>}/>
                    </InputContainer>
                    <InputContainer>
                    <CheckboxInput tag={"withMedia"} name={"Medieninhalte inkludieren"}/>
                    </InputContainer>
                    <MaxBtn onClick={duplicate}>Duplizieren <FaCopy/></MaxBtn> &nbsp; <Loader loading={statusVar.loading} />
                </FormContextWrapper>
            </div>
        </MyModal>
        <MaxBtn onClick={() => window.confirm("Wirklich löschen?") && maxiPost("/course/delete", {course_ID}, {setStatusVar}).then(() => window.location.href = "/kurs/buchung")}>Löschen <FaTrashAlt/></MaxBtn>
    </>
}

interface FieldInformationProps {
    accessor: string
    courses: any[]
    name: string | JSX.Element | null
    renderer: Function
}

function FieldInformation({accessor, courses, name, renderer}: FieldInformationProps) {
    const accessorReal = {priceInput: "price", pricenmInput: "pricenm"}[accessor as unknown as "priceInput" | "pricenmInput"] || accessor

    return <MyModal trigger={<FaEye/>}>
        <h1>Bisherige Werte des Felds "{name}" bei verschiedenen Kursen</h1>
        <ul>
            {
                courses.map(x => <li><b>Kurs {x.name}</b>: {x[accessorReal] ? (renderer(x[accessorReal]) || "kein Wert") : "kein Wert"}</li>)
            }
        </ul>
    </MyModal>
}

export default CourseEdit
