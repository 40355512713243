import React, {useContext, useMemo, useState} from 'react';
import {FaArrowCircleLeft, FaCheck, FaCheckCircle, FaDivide, FaInfoCircle, FaPlusCircle, FaUser} from "react-icons/fa";
import "../dashboard.sass"
import Consts from "../core/consts";
import {Container, LightContainer, Loader, MaxBtn, MiniBtn} from "../core/components/components";
import {MyModal} from "../core/components/modal";
import {MyReactTable} from "../core/components/table";
import {CheckboxInput, InputContainer, TextareaInput, TextfieldInput} from "../core/input/basic";
import {SelectfieldInput} from "../core/input/select";
import Status from "../core/status";
import DatePicker from "react-datepicker";
import {apiGet, apiPost} from "../core/api";

import "react-datepicker/dist/react-datepicker.css";
import {UserContext} from "./UserContext";
import {FormContext, FormContextWrapper} from "../core/form_context";
import {dateFormat, dateFormatTime, dateFormatUnix, dateFormatUnixSmall} from "../core/dateFuncs";
import {Link} from "react-router-dom";
import UserSearchSelect from "./user_search_select";
import ChangeAddressExt from "./address_input";
import AddNewPerson from "./user_add";
import NotificationSettings from "./notificationSettings";
import {AdditionalFields} from "./user_additionalFields";
import {checkPermissions} from "../club/permissionsHelpers";
import {maxiPost} from "../core/maxios";
import {usePaymentPlan} from "../core/hooks/usePaymentPlan";
import {CoursePaymentPlanSelect} from "../course/paymentPlan/CoursePaymentPlanSelect";


class UserEdit extends React.Component {
    static contextType = UserContext;

    checkPhone = (e) => {
        const s = e.target.name.split("_");
        const val = this.state[s[0]][s[1]];
        if (s[1].startsWith("phone")) {

            const newVal = Consts.phoneNormalise(val)
            if (newVal !== val) {
                this.setState({[s[0]]: {...this.state[s[0]], [s[1]]: newVal}})
                //console.log(val, newVal)
            }
        }
        if (s[1] === "email") {
            this.apiPost("/user/address/email", {email: val}, resp => {
                if (resp.email === (this.state[s[0]] || {}).email) {
                    this.setState({emailValid: resp.valid})
                }
            })
        }
    };

    handleChange = (e) => {

        const s = e.target.name.split("_");
        let val = e.target.value;

        this.setState({
            [s[0]]: Object.assign(
                {},
                this.state[s[0]],
                {[s[1]]: e.target.type === 'checkbox' ? e.target.checked : val}
            )
        });

    };
    handleSubmit = () => {
        this.setState({loading: true});
        //console.log(this.state, this.props, this.state[this.props.id])
        this.apiPost("/user/subusers", {...this.state[this.props.id], data: this.state.data}, resp => {
            this.setState({
                [this.props.id]: {...this.state[this.props.id], place_ID: resp.place_ID}
            })
        })
    };
    open = id => {
        this.setState({
            open: this.state.open.indexOf(id) < 0 ? this.state.open.concat([id]) : this.state.open.filter(i => {
                return i !== id
            })
        })
    };

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            changeAddressOpen: false,
            user_ids: [],
            emailValid: null,
        };

        this.setState = this.setState.bind(this);
        this.apiGet = apiGet.bind(this);
        this.apiPost = apiPost.bind(this);


    }

    componentDidMount = () => {
        this.loadData()
    }

    loadData = () => {
        this.apiGet("/user/subusers?edit=" + this.props.id, (resp) => {
            //.map(u=>({...u, erzberID: u.erzberID.toString()}))
            Object.keys(resp.users).forEach(id => {
                resp.users[id].phone = Consts.phoneNormalise(resp.users[id].phone.toString())
                resp.users[id].phoneAdd = Consts.phoneNormalise(resp.users[id].phoneAdd.toString())
            })
            if (!!resp.users[this.props.id] && !!this.props.setChild) {
                this.props.setChild(resp.users[this.props.id])
            }
            this.setState({
                loading: false,
                ...resp.users,
                user_ids: Object.keys(resp.users),
                open: Object.keys(resp.users).map(id => id * 1),
                data: resp.users[this.props.id]?.data,
            });
        });
    };

    isSelectMode = () => false;

    changeToUserView = () => {
        this.apiPost("/user/change/" + this.props.id, {}, (resp) => {
            window.location.href = "/"
        }, (resp) => {
            this.setState({error: resp.message})
        })
    }

    render() {
        const {user, club} = this.context;
        const editLimit = 5;
        const childArray = this.state.user_ids
            .filter(id => id === this.props.id)
        if (childArray.length !== 1) {
            return null
        }
        const id = childArray[0];
        const child = this.state[id] || {};
        return <>

            {
                user !== undefined && user.role < 80 ?
                    <>
                        <FaArrowCircleLeft style={{marginBottom: "-2px"}}/>
                        <em onClick={() => window.history.back()}>zurück</em>
                        {user.verwalter &&
                            <>
                                <FaArrowCircleLeft style={{marginBottom: "-2px"}}/>
                                <em onClick={() => window.location.href = "/benutzer/liste"}>zur Übersicht</em> <br/><br/>
                            </>
                        }
                    </> :
                    null
            }
            {user?.role >= 80 && !user.originalUser &&
                <>
                    <Status type={"error"} text={this.state.error}/>
                    <FaUser style={{marginBottom: "-2px"}}/>
                    <em onClick={this.changeToUserView}>die Perspektive von diesem Benutzer übernehmen</em> <br/><br/>
                </>
            }
            {this.state[id]?.deleteDate > 0 &&
                <h2 style={{color: "red"}}>GELÖSCHTER BENUTZER (seit {dateFormatTime(this.state[id]?.deleteDate)})</h2>
            }

            <FormContext.Provider value={{state: this.state, setState: this.setState}}>
                <form onChange={this.handleChange} onSubmit={(e) => {
                    e.preventDefault();
                    this.handleSubmit();
                }}>


                    {
                        ["sex", "titlesAnte", "titlesPost", "prename", "lastname", "email", "phone", "birthdate", "Gesetzlicher Vertreter", "Notfallnummer", "address", "note", "internalnote"]
                            .map((k, i) => {

                                if (k === "internalnote" && !checkPermissions(this.context, "user_read")) {
                                    return null
                                }
                                if (k === "Gesetzlicher Vertreter" || k === "Notfallnummer") {
                                    if (child["age"] >= 18) {
                                        return null
                                    } else {
                                        return k === "Gesetzlicher Vertreter" ?
                                            <InputContainer>
                                                <SelectfieldInput name={"Gesetzlicher Vertreter"} tag={id + "_erzberID"}
                                                                  demandSelect
                                                                  selectives={
                                                                      this.state.user_ids
                                                                          .filter(id => this.state[id].age >= 18)
                                                                          .map(a => [parseInt(a), this.state[a].prename + " " + this.state[a].lastname])
                                                                  }/>
                                                {(i % 2 === 1) && <br/>}
                                            </InputContainer> :
                                            <InputContainer>
                                                <TextfieldInput name={"Notfallnummer"} onBlur={this.checkPhone} tag={id + "_phoneAdd"}/>
                                                {(i % 2 === 1) && <br/>}
                                            </InputContainer>

                                    }
                                }

                                if (k === "birthdate") {
                                    let d;
                                    if (child.birthdate > 0) {
                                        let date = `${child[k]}`;
                                        let date_string = `${date.substr(0, 4)}-${date.substr(4, 2)}-${date.substr(6, 2)}`;
                                        d = new Date(date_string);
                                    } else {
                                        //d = new Date()
                                    }

                                    return <InputContainer>
                                        <label key={"gbrtst" + id}><span>Geburtsdatum</span>
                                            <DatePicker selected={d}
                                                        locale={"de"}
                                                        dateFormat={"dd.MM.yyyy"}
                                                        placeholderText={"00.00.0000"}
                                                        showYearDropdown
                                                        peekNextMonth
                                                        showMonthDropdown
                                                        maxDate={new Date()}
                                                        dropdownMode="select"
                                                        onChange={(date) => {
                                                            if (date) {
                                                                let datemy = date.getFullYear() * 10000 + (date.getMonth() + 1) * 100 + date.getDate()
                                                                this.setState({[id]: Object.assign({}, this.state[id], {birthdate: datemy})})
                                                            }
                                                        }
                                                        }/></label>
                                    </InputContainer>;
                                }

                                if (k === "sex") {
                                    return <InputContainer>
                                        <SelectfieldInput name={"Geschlecht"} tag={id + "_sex"} selectives={[
                                            ["male", "Männlich"], ["fem", "Weiblich"],
                                            ...([17].includes(user.verein_ID) ? [["fam", "Familie"], ["inst", "Institution"]] : [])
                                        ]}/>
                                        {(i % 2 === 1) && <br/>}
                                    </InputContainer>
                                }

                                if (k === "address") {
                                    return <div>
                                        <ChangeAddressExt child={child} changeAddress={a => this.setState({[id]: {...child, ...a}})}/>
                                        {i % 2 === 1 && <br/>}

                                        {
                                            user.role < 20 && <>
                                                {child.phone < 1000 && <span style={{color: "#f0594f"}}>Bitte gib die Telefon-Nummer ein!<br/></span>}
                                                {child.email.length < 5 && <span style={{color: "#f0594f"}}>Bitte gib die E-Mail-Adresse ein!<br/></span>}
                                                {child.birthdate < 100 && <span style={{color: "#f0594f"}}>Bitte gib das Geburtsdatum ein!<br/></span>}
                                                {!child.place_ID && child.country === "AT" && <span style={{color: "#f0594f"}}>Bitte korrigiere die Adresse!<br/></span>}
                                            </>
                                        }
                                    </div>
                                }

                                if (k === "note" || k === "internalnote") {
                                    return <InputContainer className={"textarea"}>
                                        <TextareaInput name={k === "note" ?
                                            (<>Gesundheitliche Einschränkungen und Hinweise (optional)
                                                &nbsp;
                                                <MyModal trigger={<FaInfoCircle/>} on={"hover"}>
                                                    <h2>Gesundheitliche Einschränkungen und Hinweise (optional)</h2>
                                                    Um noch besser auf die Bedürfnisse der Kinder eingehen zu können bzw. im Notfall richtig gehandelt wird, ist es von großem Vorteil, wenn der Trainer über Gesundheitliche Einschränkungen Bescheid
                                                    weiß bzw. Hinweise dazu angegeben werden. Weiters werden zu besonderen Anlässen (Weihnachten, Fasching, letzte Einheit usw.) oftmals eine Kleinigkeit zu essen ausgegeben, deshalb bitte Allergien
                                                    und Unverträglichkeiten angeben.<br/>
                                                    Mit der Bekanntgabe erklärt sich der gesetzliche Vertreter mit der Verarbeitung und Weitergabe an die Trainer einverstanden. Art. 6 Abs. 1 lit. D DSVGO: „Schutz lebenswichtiger Interessen“
                                                </MyModal>
                                            </>) :
                                            "Interne Notizen"} tag={id + "_" + k}/>
                                    </InputContainer>
                                }

                                if (child[k] !== undefined && typeof child[k] === "boolean") {
                                    return <InputContainer>
                                        <CheckboxInput name={k[0].toUpperCase() + k.substr(1)} tag={id + "_" + k}/>
                                        {i % 2 === 1 && <br/>}
                                    </InputContainer>
                                }
                                if (k === "email") {
                                    return <InputContainer>
                                        <TextfieldInput key={"textfieldinput" + id}
                                                        onBlur={this.checkPhone}
                                                        inputBorderColor={this.state.emailValid === false ? "red" : null}
                                                        name={<>{Consts.translate(k)} {this.state.emailValid === true && <FaCheckCircle/>}{this.state.emailValid === false && "(falsch)"}</>}
                                                        tag={id + "_" + k}
                                        />
                                        {(i % 2 === 1) && <br/>}
                                    </InputContainer>
                                }
                                return <InputContainer>
                                    <TextfieldInput key={"textfieldinput" + id} onBlur={this.checkPhone} name={Consts.translate(k)} tag={id + "_" + k}/>
                                    {(i % 2 === 1) && <br/>}
                                </InputContainer>
                            })

                    }
                    <br/>
                    <AdditionalFields/>
                    <Status type="error" text={this.state.error}/>
                    <Status type={"success"} text={this.state.status}/>

                    {
                        this.state.user_ids !== undefined && (this.state.user_ids.length < editLimit || this.props.id !== undefined) &&
                        <button className={"maxbtn"}>Änderungen speichern</button>
                    }


                </form>
                <ConfirmData {...{child}}/>
                {
                    user.role >= 80 && <div>
                        {
                            child.country === "AT" && (
                                !(parseInt(child.place_ID) > 100000) ?
                                    <span style={{color: "#f0594f"}}>Diese Adresse ist nicht bestätigt / ungültig!<br/></span> :
                                    <MaxBtn onClick={(e) => {
                                        e.preventDefault();
                                        this.apiPost("/user/address/markinvalid", {child_ID: child.ID}, resp => {
                                            this.setState({[id]: {...child, place_ID: ""}})
                                        })
                                    }}>Adresse ist ungültig?</MaxBtn>
                            )
                        }
                        <MyModal trigger={<MaxBtn>Sendungs-Einstellungen</MaxBtn>}>
                            <NotificationSettings child_ID={child.ID}/>
                        </MyModal>
                    </div>
                }
                {
                    /*!!child.data && user.role > 20 &&
                    <table>
                        {Object.entries(child.data).map(([key, val]) => <tr>
                            <td>{key}</td>
                            <td>{val}</td>
                        </tr>)}
                    </table>*/
                }

                {
                    child.svn !== undefined && child.svn.length !== 32 && child.deathdate === 0 && club?.svnRequired &&
                    <MyModal
                        modal
                        defaultOpen={child.verwalter_ID === user.ID}
                        trigger={<MaxBtn>Sozialversicherungsnummer von {child.fullname} ergänzen</MaxBtn>}
                    >
                        {close => <AddSVN {...{child, setStateParent: this.setState, close, reload: this.loadData}}/>}
                    </MyModal>
                }
                {
                    child.deathdate > 0 &&
                    <h2>Diese Person ist am {dateFormatUnixSmall(child.deathdate)} gestorben!</h2>
                }
            </FormContext.Provider>
            <Loader loading={this.state.loading}/>

            {
                (user.role >= 80 || user.verwalter) && <>
                    <h2>Login</h2>
                    zuletzt {child.lastLogin > 0 ? dateFormatTime(child.lastLogin) : "nie"},
                    {
                        child.lastLogin > 0 && <>
                            &nbsp;zuletzt aktiv&nbsp;
                            {child.lastActive ? (dateFormatUnix(child.lastActive) === dateFormatUnix(new Date().getTime() / 1000) ? "heute" : dateFormatUnix(child.lastActive)) : (<>nicht seit {dateFormatUnix(Math.max(new Date("2024-11-15").getTime() / 1000, new Date().getTime() / 1000 - 365 * 86400))}</>)}
                        </>
                    }

                    <em onClick={() => {
                        if (window.confirm("Passwort zurücksetzten!")) {

                            this.setState({loading: true})
                            this.apiPost("/user/pwreset", {child_ID: child.ID})
                        }
                    }}> {user.ID === child.ID ? "Passwort-Änderungs-Link anfragen" : "Passwort-Zurücksetz-Link senden"}</em>

                </>
            }


            {
                this.props.id !== undefined && user?.role > 80 &&
                <>
                    {
                        this.state.user_ids.length > 1 &&
                        <>
                            <br/><br/>
                            <h2>Personen in dieser Admingruppe</h2>
                            {
                                //von {this.state[child.verwalter_ID] !== undefined && this.state[child.verwalter_ID].fullname} verwaltete
                                this.state.user_ids
                                    //.filter(id => id !== this.props.id)
                                    .map(id => {
                                        const childSub = this.state[id];
                                        return <li style={child.ID === childSub.ID ? {fontWeight: 700} : {}}>
                                            <Link to={"/benutzer/profil/" + childSub.ID}>{childSub.lastname}, {childSub.prename}</Link>
                                            {childSub.birthdate > 0 && <>, {dateFormat(childSub.birthdate)}</>}
                                            {childSub.verwalter_ID === childSub.ID ? ", (Verwalter)" : ""}
                                            {child.ID === childSub.ID && ", (diese Person)"}
                                        </li>
                                    })
                            }
                        </>
                    }
                    <br/>
                    {
                        child.verwalter_ID === child.ID && child.deathdate === 0 &&
                        <MyModal trigger={<h2 style={{marginTop: "20px", fontStyle: "normal", display: "inline-block"}}>Person neu registrieren <FaPlusCircle/></h2>}>
                            <br/>
                            <AddNewPerson
                                verwalter_ID={child.verwalter_ID}
                                child_ID={this.props.id}
                                updateParent={this.loadData}
                                name={"Person neu registrieren"}
                                childs={this.state.user_ids.map(id => this.state[id])}
                                defaultOpen
                            />
                        </MyModal>

                    }
                    {
                        child.deathdate === 0 &&
                        <MyModal
                            trigger={<h2 style={{marginTop: "20px", marginLeft: child.verwalter_ID === child.ID ? "20px" : "0", fontStyle: "normal", display: "inline-block"}}>zu Verwaltenden hinzufügen <FaPlusCircle/></h2>}
                        >
                            <UserSearchSelect
                                style={{marginLeft: "-20px"}}
                                heading={"Zu verwaltende Person hinzufügen"}
                                preclaimer={child.verwalter_ID !== child.ID ? <><b>Achtung:</b> Hinzufügen eines zu Verwaltenden macht diese Person zu einem Verwalter, obwohl sie derzeit keine ist. Ein möglicher Offenstand bleibt bei dem Verwalter,
                                    der der Verwalter zu dem Zeitpunkt des Entstehens des Offenstands war.</> : ""}
                                /*usersHide={this.state.user_ids.map(id => parseInt(id))}*/
                                selectButton={(id) => <em onClick={() => {
                                    this.apiPost("/user/move_allocation", {child_ID: id, verwalter_ID: child.ID}, () => {
                                        this.setState({errors: "", loading: false})
                                        this.loadData()
                                    })
                                }}>hinzufügen</em>}
                            />
                        </MyModal>
                    }
                    {
                        child.ID !== child.verwalter_ID &&
                        <h2 style={{marginTop: "20px", cursor: "pointer", fontStyle: "normal", marginLeft: "20px", display: "inline-block"}} onClick={() => {
                            if (window.confirm("Willst du diese Person wirklich von dem derzeitigen Verwalter loslösen und einen eigenständigen Verwalter daraus machen?")) {
                                this.apiPost("/user/leave_admingroup", {child_ID: child.ID}, resp => {
                                    this.loadData()
                                })
                            }
                        }
                        }>zu eigenständigem Verwalter machen <FaDivide/></h2>
                    }

                    {
                        child.deathdate === 0 &&
                        <AddToCourse child={child}>
                            <h2 style={{marginTop: "20px", fontStyle: "normal", marginLeft: "20px", display: "inline-block"}}>zu Kurs hinzufügen <FaPlusCircle/></h2>
                        </AddToCourse>

                    }

                </>
            }
        </>

    }
}

function ConfirmData({child}) {
    const [confirmed, setConfirmed] = useState(false)
    const [unconfirmedUsers, setUnconfirmedUsers] = useState([])
    const {user} = useContext(UserContext);
    const overdue = (new Date() / 1000 - child.timeDataLastConfirmed) > 86400 * 280

    const confirmAndSwitchToNext = () => {
        if (!confirmed && window.confirm("Daten bestätigen?")) {
            maxiPost("/user/confirmData", {child_ID: child.ID}).then((resp) => {
                setConfirmed(true)
                setUnconfirmedUsers(resp.unconfirmedPersons)
            })
        }
    }

    useMemo(() => {
        setConfirmed(false)
        setUnconfirmedUsers([])
    }, [child.ID])

    const unconfirmedLength = unconfirmedUsers?.length || 0
    return <>
        {
            user.ID === child.verwalter_ID && overdue && !confirmed && <span style={{color: "#f0594f"}}>Bitte überprüfe deine Daten, diese wurden zuletzt am {dateFormatUnix(child.timeDataLastConfirmed)} bestätigt!<br/></span>
        }
        {
            (user.ID !== child.verwalter_ID || overdue) && <>
                <MaxBtn onClick={() => confirmAndSwitchToNext()}>
                    Korrektheit der Daten bestätigen {confirmed ? <>- Danke! <FaCheck/></> : (user.ID !== child.verwalter_ID && <>(zuletzt {dateFormatUnixSmall(child.timeDataLastConfirmed)})</>)}
                </MaxBtn>

                {
                    confirmed && unconfirmedLength > 0 && <p>
                        Es gibt noch {unconfirmedLength} {unconfirmedLength > 1 ? "Personen" : "Person"} in deiner Verwaltergruppe, für die die Korrektheit der Daten bestätigt werden müssen.<br/>
                        <Link className={"no-underline"} to={"/benutzer/profil/" + unconfirmedUsers[0]?.ID}><MaxBtn>Korrektheit der Daten für {unconfirmedUsers[0]?.fullname} bestätigen</MaxBtn></Link>
                    </p>
                }
            </>
        }
    </>
}

function AddSVN({child, setStateParent, close, reload}) {
    const context = useContext(UserContext)
    const [state, setState] = useState({})
    const [{loading, success, error}, setStatusVar] = useState({});
    const errorKnown = error?.includes("Bitte die richtige SVN eingeben!");
    return <Container name={"Sozialversicherungsnummer von " + child.fullname + " ergänzen"}>
        <FormContextWrapper value={{state, setState}}>
            Die Angabe der Sozialversicherungsnummer dient lediglich zur eindeutigen Identifikation von Personen und wird in unserem System verschlüsselt abgespeichert, somit hat darauf niemand Zugriff.<br/><br/>
            <Status type={"error"} text={error}/>
            <Status type={"success"} text={success}/>
            <TextfieldInput name={"SVN"} tag={"svnAdd"} ph={"1234010199"}/>

            {
                <MaxBtn onClick={() => maxiPost("/user/addsvn", {svn: state.svnAdd, child_ID: child.ID, forceOtherBirthdate: errorKnown}, {setStatusVar}).then(() => {
                    setStateParent({loading: false, status: "SVN Erfolgreich ergänzt!"})
                    reload()
                    close()
                })}>{errorKnown && checkPermissions(context, "user") ? "Trotzdem Speichern" : "Speichern"}</MaxBtn>
            }
            <br/>
            <br/>

        </FormContextWrapper>
        {
            checkPermissions(context, "user") && <MaxBtn onClick={() => window.confirm("Dadurch muss die Person ihre SVN nicht ergänzen?") && maxiPost("/user/nosvn", {child_ID: child.ID}, {setStatusVar}).then(() => {
                close()
                reload()
            })}>Keine SVN erforderlich?</MaxBtn>
        }
    </Container>
}

class AddToCourse extends React.Component {
    /*const
    loadCourses = () => {
        apiGet()
    }*/

    state = {
        courses: [],
        show: {
            all: 0,
        },
        loading: true,
    };
    apiGet = apiGet.bind(this)
    apiPost = apiPost.bind(this)
    setState = this.setState.bind(this);
    loadCourses = () => {
        const {child} = this.props;
        this.apiGet(`/user/${child.ID}/courses?restricted`, resp => {
            this.setState({
                ...resp,
                loading: false,
                courses: resp.courses
                    .map(c => ({...c, startdateDate: new Date(c.startdate * 1000)}))
                    .sort((a, b) => a.name < b.name ? -1 : 1)
                //.sort((a, b) => a.name.toLowerCase().replace(/[0-9]{4} /, "") < b.name.toLowerCase().replace(/[0-9]{4} /, "") ? -1 : 1)
            })
        });
    };

    render() {
        const {child} = this.props;
        if (!child.ID) {
            return null
        }
        return <MyModal
            trigger={this.props.children}
            onOpen={() => {
                this.loadCourses()
            }}
        >{
            close =>
                <LightContainer name={child.fullname + " zu Kurs hinzufügen"}>
                    <Status type={"error"} text={this.state.error}/>
                    <FormContextWrapper value={{state: this.state, setState: this.setState}}>
                        <CheckboxInput tag={"show_all"} name={"Nicht erlaubte Kurse anzeigen"}/>
                    </FormContextWrapper>
                    <MyReactTable
                        data={this.state.courses.filter(c => c.allowed || this.state.show?.all)}
                        loading={this.state.loading}
                        columns={[
                            {
                                Header: "Name",
                                accessor: "name",
                                sortable: true,
                                filterable: true,
                                Cell: ({value, original: c}) => value + (c.parts_count >= c.maxparts ? " - Warteliste" : ""),
                            },
                            {
                                Header: "Trainer",
                                filterable: true,
                                accessor: "trainers",
                                Cell: ({value}) => value?.split(", ").map(a => <Link to={"/benutzer/profil/" + a.split("|")[0]}>{a.split("|")[1]}</Link>).joinComp(", ") || null
                            },
                            {
                                Header: "Erlaubt",
                                accessor: "allowed",
                                show: this.state.show?.all,
                                filterable: true,
                                maxWidth: 70,
                                Cell: ({value}) => value ? "ja" : "nein",
                            },
                            {
                                Header: "anmelden",
                                Cell: ({original: course}) => <RegisterButton course_ID={course.ID} child_ID={child.ID} close={close} membersOnly={course?.membersOnly}/>,
                                maxWidth: 120,
                            }
                        ]}
                    />
                </LightContainer>
        }
        </MyModal>
    }
}


function RegisterButton({course_ID, child_ID, close, membersOnly}) {
    const paymentPlan = usePaymentPlan(course_ID)
    const [paymentPlanGroup, setPaymentPlanGroup] = useState()
    const [{loading, error}, setStatusVar] = useState({})
    const registerUser = () => {
        !loading && maxiPost("/course/register", {
            course_ID,
            child_ID,
            paymentPlan_ID: paymentPlanGroup?.ID || undefined
        }, {setStatusVar}).then(resp => {
            close()
        })
    }
    if (!!paymentPlan?.length) {
        return <MyModal trigger={<em>anmelden</em>}>
            <Status type={"error"} text={error}/>
            <Container name={"Zahlungsweise auswählen"}>
                <CoursePaymentPlanSelect onChange={planGroup => setPaymentPlanGroup(planGroup)} membersOnly={membersOnly}
                                         paymentGroups={paymentPlan || []}/>
                <MiniBtn
                    onClick={() => registerUser()}>{paymentPlanGroup ? `Zahlungsmethode ${paymentPlanGroup.name} auswählen` : "keine Zahlungsmethode auswählen"}</MiniBtn>
            </Container>
        </MyModal>
    }

    return <>
        <Status type={"error"} text={error}/>
        <MaxBtn onClick={registerUser}>anmelden</MaxBtn>
    </>

}

export default UserEdit;
