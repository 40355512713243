import React, {useContext, useMemo, useState} from 'react';
import {Container, LightContainer, Loader, MaxBtn} from "../core/components/components";
import {MyTabsExtended} from "../core/components/tabs";
import {SelectfieldInput} from "../core/input/select";
import AsyncComponent from "../core/components/AsyncComponent";
import "./course_form.css"
import Status from "../core/status";
import {apiGet, apiPost} from "../core/api";
import CourseEdit from "./edit/course_edit"
import CourseDesription from "./course_description";
import {FormContext, FormContextWrapper} from "../core/form_context";
import CourseEvents from "./course_events";
import {UserContext} from "../user/UserContext";
import CourseParts from "./course_parts";
import CourseParticipate from "./course_participate";
import CourseNews from "./course_news";
import CourseMedia from "./course_media";
import {checkPermissions} from "../club/permissionsHelpers";
import {maxiGet, maxiPost} from "../core/maxios";
import AsyncComponentWrapper from "../core/components/asyncComponentWrapper";
import CourseParticipationCertificate from "./courseparts_certificate";
import {CourseShiftPlannerComponent} from "./shiftPlanner/CourseShiftPlanner";


class CourseOverview extends React.Component {
    state = {
        error: "",
        status: "",
        loading: true,
        participant: {},
        course: {},
        showNews: true,
    };

    constructor(props) {
        super(props);

        this.pn = props.location.pathname || "";
        this.id = parseInt(props.match.params.courseID);
        if (isNaN(this.id)) {
            this.id = null;
            return
        }
        this.apiGet = apiGet.bind(this);
        this.apiPost = apiPost.bind(this);
        this.setState = this.setState.bind(this);
    }

    loadCourseAndParticipation = () => this.apiGet("/course/attributes/" + this.id, resp => {
        this.setState({
            ...resp,
            course: {
                ...resp.course,
                rolesAllowed: (resp.course.rolesAllowed || "").split(",").filter(a => a !== "").map(a => parseInt(a)).map(a => ({label: this.context.club.memberStates[a], value: a})),
            },
            loading: false
        })
    });
    componentDidMount = () => this.loadCourseAndParticipation()


    t = new Date();
    static contextType = UserContext;

    render() {
        if (this.id == null) {
            return <span>fehlende id</span>
        }
        const user = this.context?.user || {};

        const ConferenceLocal = AsyncComponent(() => import("./conference"));
        const trainerIsAndAllowed = this.state.course.trainersAllowed && this.state.participant.role === 1;
        return <Container visible>
            <FormContext.Provider value={{state: this.state, setState: this.setState, id: this.id, updateState: (a, b, c) => this.setState({[a]: {...this.state[a], [b]: c}})}}>
                <Status type="error" text={this.state.error}/>
                <Loader loading={this.state.loading}/>
                {!this.state.loading && !this.state.error && this.state.participant_count !== undefined && user !== undefined &&
                    <MyTabsExtended
                        tabs={[
                            {
                                name: "Beschreibung",
                                content: <CourseDesription/>,
                                // hideHeading: true,
                            },
                            ((this.state.participant.role === 1 && user.role < 20) || (user.role >= 20 && user.role <= 50) || (user.role < 20 && checkPermissions(this.context, "course/participants_read", this.id))) ? {
                                name: "Teilnehmer",
                                content: <CourseParts id={this.id} name={this.state.course?.name || ""} role={this.state.participant.role} reload={this.loadCourseAndParticipation}/>
                            } : null,
                            this.state.showNews && this.id > 0 && user.role < 80 && !trainerIsAndAllowed && !checkPermissions(this.context, "course/news", this.id) && this.state.participant.ID ? {
                                name: "Neuigkeiten",
                                collapsable: (localStorage.getItem("courseNewsCollapsed") ?? "false") === "true",
                                onCollapseChange: (value) => localStorage.setItem("courseNewsCollapsed", value),
                                content: <CourseNews
                                    id={this.id}
                                    hideExistingNews={!this.state.participant.ID}
                                    name={this.state.course?.name}
                                    partsCount={this.state.participant_count}
                                    waitlistCount={this.state.waitlist_count}
                                    role={this.state.participant.role}
                                    hide={(b) => this.setState({showNews: b})}
                                />
                            } : null,
                            this.id > 0 && (user.role < 80) ? {
                                name: this.state.participant.role >= 0 ? "Teilnahme" : "Kosten",
                                content: <CourseParticipate
                                    id={this.id} role={this.state.participant.role}
                                    reload={this.loadCourseAndParticipation}
                                    preferredChild={!!this.props.location.state && this.props.location.state.preferredChild}
                                />
                            } : null,
                            user.role > 80 ? {
                                name: "Teilnehmer",
                                content: <CourseParts id={this.id} course={this.state.course} name={this.state.course?.name || ""} prole={this.state.participant.role} reload={this.loadCourseAndParticipation}/>
                            } : null,
                            this.id > 0 && (user.role >= 80 || trainerIsAndAllowed || checkPermissions(this.context, "course/news", this.id)) ? {
                                name: "Neuigkeiten",
                                collapsable: (localStorage.getItem("courseNewsCollapsed") ?? "true") === "true",
                                onCollapseChange: (value) => localStorage.setItem("courseNewsCollapsed", value),
                                content: <CourseNews
                                    id={this.id}
                                    name={this.state.course?.name}
                                    partsCount={this.state.participant_count}
                                    waitlistCount={this.state.waitlist_count}
                                    trainerIsAndAllowed={trainerIsAndAllowed}
                                    role={this.state.participant.role}
                                    hide={(b) => !trainerIsAndAllowed && this.setState({showNews: b})}
                                />
                            } : null,
                            this.state.course.enableMedia && ([0, 1].includes(this.state.participant.role) && this.state.participant.timeLeave === 0 || user?.role > 20) ? {
                                name: "Medieninhalte",
                                content: <CourseMedia id={this.id}
                                                      user={user}
                                                      allowedTrainerOrAdmin={(this.state.participant.role === 1 && this.state.participant.timeLeave === 0) || checkPermissions(user, "course/media", this.id)}
                                />,
                            } : null,
                            this.state.course?.showShiftPlanner && ((!!this.state.participant?.ID && this.state.participant.child_ID === this.context.user.ID) || this.context.user.role > 80) ? {
                                name: "Schicht-Einteilung",
                                content: <CourseShiftPlannerComponent course={this.state.course}/>
                            } : null,
                            this.id > 0 ? {
                                name: "Termine",
                                content: <>
                                    <CourseEvents id={this.id} showAddSickNote={[0, 1].includes(this.state.participant.role) && this.state.participant.timeLeave === 0}/>
                                    {this.state.course.enableOnlineConferences && <ConferenceLocal id={this.id} userRole={this.state.participant.role} userID={user?.ID}/>}
                                </>
                            } : null,
                            this.state.course?.formSlots?.length > 0 || checkPermissions(user, "page_blocks") || user.role >= 80 ? {
                                name: "Umfragen",
                                content: <FormSlotViewer slots={this.state.course?.formSlots} courseID={this.id}/>
                            } : null,
                            this.state.course?.downloadCertificate && !!this.state.participant?.ID ? {
                                name: "Zertifikat",
                                content: <CourseParticipationCertificate participant={this.state.participant} course={this.state.course}/>
                            } : null,

                            user.role > 30 || user.role === 11 || checkPermissions(this.context, "course/data_read", this.id) ? {
                                name: "Daten",
                                content: <CourseEdit/>
                            } : null,
                        ]}>
                        <em style={{float: "right", marginTop: "20px"}} onClick={() => window.history.back()}> zurück zu allen Angeboten</em>
                        <h1>{this.state.course.name !== undefined ? this.state.course.name : "Kursansicht"}</h1>

                        <Loader loading={this.state.loading}/>
                    </MyTabsExtended>
                }


            </FormContext.Provider>
        </Container>

    }
}

function FormSlotViewer({courseID, slots}) {
    const [state, setState] = useState({selected: {values: slots.map(x => ({label: x, value: x}))}})
    const [forms, setForms] = useState([])
    const {user} = useContext(UserContext)
    const [{loading, error, success}, setStatusVar] = useState({})

    const loadForms = () => {
        maxiGet("/page_blocks/form/slots/get", {}).then(resp => {
            setForms(resp.map(x => x))
        })
    }
    useMemo(loadForms, [])
    const updateCourses = () => {
        maxiPost("/page_blocks/form/courses/assign", {course_id: courseID, slots: (state?.selected?.values || []).map(x => x.value)}, {setStatusVar}).then(({message}) => setStatusVar({success: message}))
    }

    return <LightContainer>
        <Loader loading={loading}/>
        <Status type={"error"} text={error}/>
        <Status type={"success"} text={success}/>
        {(user.role > 80 || checkPermissions(user, "forms", null)) &&
            <FormContextWrapper value={{state, setState}}>
                <SelectfieldInput type={"reactselect"} selectives={forms.map(x => ({label: x, value: x}))} tag={"selected_values"} name={"Diesem Kurs zugeordnete Formulare / Umfragen"} multiple={true} width={"100%"} labelWidth={600}/>
                <MaxBtn onClick={updateCourses}>Speichern</MaxBtn>
                <br/><br/>
            </FormContextWrapper>
        }
        {(state.selected?.values || []).map((x, index) =>
            <>
                {(state.selected?.values || []).length > 1 && <h3 style={{borderBottom: "solid 4px #bbb"}}>Umfrage {index + 1} / {(state.selected?.values || []).length}</h3>}
                <AsyncComponentWrapper slot={x.value} defaultEdit={false} myImport={() => import("../pages/page_view")}/>
            </>
        )}
    </LightContainer>
}


export default CourseOverview;
