import React from 'react';
import Consts from "../core/consts"
import axios from "axios"
import {UserContext} from "./UserContext";
import {clubMap, domainMap, placeholderClub} from "../core/custom";
import {apiGet} from "../core/api";
import {encodeGetParams} from "../core/helpers";


class CheckLogin extends React.Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);
        this.apiGet = apiGet.bind(this)
    }

    setState = (state) => {
        if (!!state?.error){
            throw new Error(state.error)
        }
    }

    componentDidMount() {
        const pn = this.props.location.pathname || "";
        if (pn.substr(0, 15) !== "/benutzer/login" && pn.substr(0, 19) !== "/benutzer/changepw/" && pn.substr(0, 13) !== "/registrieren" && pn.substr(0, 11) !== "/aktivieren") {
            this.getStatus();
            if (!!localStorage.getItem("status_cache")) {
                this.props.addUserFromStatus({user: JSON.parse(localStorage.getItem("status_cache"))})
            }
        } else {
            this.loadClubData()
        }

    }

    getStatus = () => {
        if (Consts.f(true, false)) {
            //return
        }
        const pn = this.props?.location?.pathname || "";
        const publicPage = pn.startsWith("/profil/") || pn.startsWith("/ams/kiosk") || pn.startsWith("/faq")
        if (pn.includes("/logout")){
            return
        }
        this.apiGet("/user/status", response => {
            localStorage.setItem("status_cache", JSON.stringify(response))
            this.props.addUserFromStatus({user: JSON.parse(localStorage.getItem("status_cache"))})
            if (response.status === "error") {
                this.loadClubData(response.verein_ID)
                if (!publicPage) {
                    localStorage.setItem("redir", pn);
                    window.location.href = "/benutzer/login"
                }
            } else {
                if (response.status !== "logged_in") {
                    this.loadClubData(response.verein_ID)
                    if (!publicPage) {
                        localStorage.setItem("redir", pn);
                        window.location.href = "/benutzer/login"
                    }
                } else {
                    this.loadClubData(response.verein_ID)
                }
            }
        })
    };

    loadClubData = (verein_ID = null) => {
        const time = new Date().getTime()
        let localClub = JSON.parse(localStorage.getItem("verein_cache")) || null
        if (!localClub || localClub.timestamp < time - 300 * 1000 || Consts.f(true,false)) {
            this.props.addUserFromStatus({club: localClub || placeholderClub})
            this.apiGet("/club?" + encodeGetParams({verein_ID}), r => {
                console.log("CLUB", r.club)
                const newClub = r.club
                if (!!newClub) {
                    newClub["timestamp"] = time
                    localStorage.setItem("verein_cache", JSON.stringify(newClub))
                }
                this.props.addUserFromStatus({club: {...(newClub || placeholderClub)}})
            })
        } else {
            console.log(localClub)
            this.props.addUserFromStatus({club: {...localClub}})
        }
    }

    render() {
        return null
    }

}

export default CheckLogin;
