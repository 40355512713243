import React, {SetStateAction, useContext} from "react"
import {Club as ClubCustom} from "../core/custom"

export interface User {
    ID: number
    email: string
    prename: string
    lastname: string
    fullname: string
    phone: number
    role: number
    verein_ID: number
    verwalter: boolean
    country?: 'AT' | 'DE' | 'CH' | string
    data?: Record<string, string>
}

export interface Club extends ClubCustom {
    ID: number
    paymentMethods: string[]
    additionalFields: Partial<AdditionalField>[]
    memberStates: Record<string, string>
    memberStatesShort: Record<string, string>
    verein_ID: number
    noMembership: boolean
    fullNameDeclined: string
    color: string
    svnRequired: boolean,
    domain: string
    defaultregisterAusserordentlichesMitgliedText?: string,
    defaultregisterOrdentlichesMitgliedText?: string,
    defaultregisterUnterstuetzendesMitgliedText?: string,
    defaultPricingPartText?: string,
    defaultPaymentReminderText?: string,
    defaultCourseConfirmationText?: string,
    PricingPartText?: string
    defaultWaitlistConfirmationText?: string
    defaultRegistrationText?: string
    defaultCourseRegistrationText?: string
    ChildRoleChangedEmailText?: string
    defaultChildRoleChangedEmailText?: string
    dataprotectionLink?: string
    sendInvoicePDFs?: boolean
    agbsLink?: string
}

export interface AdditionalField {
    type: "text" | "select" | "file" | "textarea" | "checkbox" | "date"
    label: string
    info: string | JSX.Element
    required: boolean
    showIfNonExistent: number | boolean
    showInAMS: number | boolean
    tag: string
    touched?: boolean
    editable?: "read" | "write" | "registerWrite" | "internal",
    props?: any
}

interface UserContext {
    user: User
    club: Club
    setState: SetStateAction<{ user: User, club: Club }>
}

const UserContext = React.createContext<UserContext>({
    setState: (a) => a,
    user: {
        ID: -1,
        email: "",
        prename: "",
        lastname: "",
        fullname: "",
        phone: 0,
        role: 0,
        verein_ID: -100,
        verwalter: false,
    },
    club: {
        ID: -1,
        domain: "",
        paymentMethods: [],
        additionalFields: [],
        memberStates: {},
        memberStatesShort: {},
        verein_ID: -100,
        noMembership: false,
        fullNameDeclined: "",
        color: "",
        svnRequired: true,
        arn: "",
        address: "",
        arnName: "",
        PricingPartText: "",
        bank: {bankname: "", bic: ""},
        bankname: "",
        bic: "",
        creditorID: "",
        defaultCourseConfirmationText: "",
        defaultCourseRegistrationText: "",
        defaultPaymentReminderText: "",
        defaultPricingPartText: "",
        defaultRegistrationText: "",
        defaultWaitlistConfirmationText: "",
        defaultregisterAusserordentlichesMitgliedText: "",
        defaultregisterOrdentlichesMitgliedText: "",
        defaultregisterUnterstuetzendesMitgliedText: "",
        email: "",
        facebook: "",
        fontColor: "",
        fontColorInverse: "",
        fullName: "",
        iban: "",
        links: {},
        logo: undefined,
        msFees: {addText: "", junior: 0, normal: 0, senior: 0, supporter: 0},
        sendReminders: false,
        shortName: "",
        title: "",
        showLogo: false,
        roomDependencies: [],
        website: "",
    }
})

export function useAuth() {
    return useContext(UserContext)
}

export {UserContext}
