import React, {useState} from "react";
import {maxiPost} from "../maxios";
import {Loader, MaxBtn} from "../components/primitives";
import {MyModal} from "../components/modal";
import Status from "../status";
import {FormContext, FormContextWrapper} from "../form_context";
import {FaEdit, FaTrashAlt} from "react-icons/fa";
import {components} from "react-select";
import {apiGet, apiPost} from "../api";
import {UserContext} from "../../user/UserContext";
import CreatableSelect from "react-select/creatable/dist/react-select.esm";
import {TextfieldInput} from "./basic";

export function TagsEditModal({data, onClose, entity, trigger = undefined}) {
    const [state, setState] = useState({label: data.label})
    const [{loading, error, success}, setStatusVar] = useState({});

    const submit = (close) => {
        maxiPost(`/tags/update/${entity}/${data.value}`, state.label, {setStatusVar}).then(resp => {
            onClose()
            close()
        })
    }

    return <MyModal trigger={trigger} defaultOpen={!trigger} onClose={onClose}>
        {(close) => <>
            <Status type={"error"} text={error}/>
            <Status type={"success"} text={success}/>
            <Loader loading={loading}/>
            <FormContextWrapper value={{state, setState}} onSubmit={() => submit(close)}>
                <TextfieldInput tag={"label"} name={"Tagname"}/>
                <MaxBtn>Ändern</MaxBtn>
            </FormContextWrapper>
        </>}
    </MyModal>
}

export class TagsInput extends React.Component {
    static contextType = FormContext;

    Option = (props, edit) => {
        return <>
         <span style={{float: "right", cursor: "pointer", marginRight: "20px", marginTop: "10px"}} onClick={(e) => {
             //console.log("newcourses", this.state.tags.filter(t => t.value !== props.data.value))
             this.setState({tags: this.state.tags.filter(t => t.value !== props.data.value)});
             this.apiPost(`/tags/hide/${this.props.entity}/${props.data.value}`)
         }}><FaTrashAlt/></span>
            {edit && <span style={{float: "right", cursor: "pointer", marginRight: "10px", marginTop: "10px"}} onClick={(e) => {
                this.setState({tagEdited: props.data});
            }}><FaEdit/></span>}
            <components.Option {...props}>
                {props.children}
            </components.Option>
        </>;
    };

    constructor(props) {
        super(props);

        this.props = props
        this.tag = (this.props.tag + "_0").split("_");
        this.apiGet = apiGet.bind(this);
        this.apiPost = apiPost.bind(this);
        this.setState = this.setState.bind(this);
    }

    loadTags = (reload = false) => {
        if ((reload || this.state.loadedTags === false) && this.props.entity) {
            this.apiGet(`/tags/get_avail/${this.props.entity}`, resp => {
                const allTags = !!this.props.edit || reload ? resp.tags : this.state.tags.concat(resp.tags);
                if (!!this.props.pushTags) {
                    this.props.pushTags(allTags)
                }
                if (!!this.props.edit) {
                    (this.context.state[this.tag[0]] || {})[this.tag[1]] = null
                }
                this.setState({tags: allTags, loadedTags: true},)
            })
        } else {
            this.setState({tags: (this.context.state[this.tag[0]] || [])[this.tag[1]], loadedTags: true})
        }
    }

    componentDidMount = this.loadTags;
    componentDidUpdate = (prevProps) => {
        if (!!this.props.edit && prevProps.entity !== this.props.entity) {
            this.setState({loadedTags: false}, this.loadTags);
        }
    }

    handleCreateNew = (v) => {
        console.log("CREATE_NEW", v)
        if (!!this.props.forbidCreation) {
            alert("Das Erstellen von neuen Tags ist hier nicht erlaubt! Bitte wende dich an Max Matschiner (max@vereinfacht.at).")
            return
        }
        if (this.props.entity) {
            this.apiPost(`/tags/set/${this.props.entity}/${this.props.ID || ""}`, v, resp => {
                // console.log((this.context.state[this.tag[0]] || []), this.context.state);
                this.handleChange(!this.props.multiple ? resp : ((this.context.state[this.tag[0]] || {})[this.tag[1]] || []).concat(resp));
                this.setState({tags: this.state.tags.concat(resp)})
            })
        } else {
            console.log(v)
            const data = {label: v, value: v}
            this.setState(a => ({tags: (a.tags || []).concat(data), loadedTags: true, inputValue: ""}))
            this.handleChange(this.props.multiple ? [...((this.context.state[this.tag[0]] || [])[this.tag[1]] || []), data] : data)
        }

        //}
    };
    handleChange = (v) => {
        console.log("HANDLE", v)
        this.context.setState(a => ({...a, [this.tag[0]]: {...(a || {})[this.tag[0]], [this.tag[1]]: v}}));

        if (this.props.afterUpdate !== undefined) {
            this.props.afterUpdate(this.tag[0], this.tag[1], v)
        }
    };
    state = {inputValue: '', tags: !!this.props.addTags ? this.props.addTags : [], loadedTags: false};

    render() {
        const {error} = this.state;

        return <div style={{...this.props.style}}>
            <Status type={"error"} text={error}/>
            {!this.props.noLabel && <label style={{lineHeight: "190%", fontWeight: "bold"}}>{this.props.name}</label>}
            <UserContext.Consumer>
                {
                    ({user, club}) =>
                        <><CreatableSelect
                            isMulti={!!this.props.multiple}
                            //options={selectValsTest}
                            value={(this.context.state[this.tag[0]] || {})[this.tag[1]]}
                            onChange={this.handleChange}
                            onCreateOption={this.handleCreateNew}
                            components={{Option: (props) => this.Option(props, user?.role > 80)}}
                            noOptionsMessage={() => "fange zu tippen an"}
                            placeholder={"Tippe ..."}
                            formatCreateLabel={(val) => `Erstelle "${val}"`}
                            options={!this.props.appearingTags ? this.state.tags : this.state.tags.filter(t => this.props.appearingTags.indexOf(t.value) > -1)}
                            classNamePrefix={"MaxSelect"}
                            menuPortalTarget={document.getElementById("react-select-portal")}
                            hideSelectedOptions={!this.props.edit}
                            menuIsOpen={!!this.props.edit && this.state.tagEdited?.value === undefined ? true : undefined}

                            theme={(theme) => {
                                return {
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        primary25: club.color,
                                        primary: club.color,
                                        neutral20: club.color,
                                        neutral30: club.color,
                                    },
                                }
                            }}
                        />
                            {user?.role > 80 && this.state.tagEdited?.value !== undefined && <TagsEditModal entity={this.props.entity} data={this.state.tagEdited} onClose={this.afterEdit}/>}
                        </>
                }
            </UserContext.Consumer>
        </div>
    }

    afterEdit = () => {
        this.setState({tagEdited: {}, loadedTags: false}, () => {
            this.loadTags(true)
        })
    }
}
