import React from "react";
import {apiGet} from "../core/api";
import {UserContext} from "../user/UserContext";
import Status from "../core/status";
import CourseInfo from "./course_info";
import {dateFormatUnixSmall} from "../core/dateFuncs";
import {Link} from "react-router-dom";
import {Container, Loader, MaxBtn, MaxLink} from "../core/components/components";
import {MyModal} from "../core/components/modal";
import {MyReactTable} from "../core/components/table";
import {SchoolyearSelect} from "../core/input/selectSpecial";
import Consts from "../core/consts"
import {EventsPrint} from "./course_helpers";
import {encodeGetParams} from "../core/helpers";
import {CourseEditMultipleModal} from "./edit/course_edit_multiple";
import deepEqual from "deep-equal";

const coursesViewNum = 201;
export default class ViewCoursesFor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: 0,
            loading: true,
            tags_map: [],
            courses: [],
            tags: [],
            error: "",
            filterTagIDs: [],
            filterWeekdayNrs: [],
            bigDataLoaded: false,
        };
        this.apiGet = apiGet.bind(this);


    }

    componentDidMount = () => {
        const date = this.props.match.params.date;
        const props = this.props;
        if (props.id === "all") {
            return
        }
        this.apiGet((props.id === "all" ? "/courses" : "/user/" + props.id + "/courses?") + (this.props.category ? "category=" + this.props.category : "") + (date !== undefined ? "date=" + date : "") + (this.state.schoolyear !== undefined ? "schoolyear=" + this.state.schoolyear : ""), resp => {
            this.setState({
                ...resp,
                courses: resp.courses
                    .map(c => ({...c, startdateDate: new Date(c.startdate * 1000), startdatesDate: (c.eventsList || "").split(",").map(a => new Date(a.split("|")[0] * 1000))}))
                    //.sort((a, b) => a.name < b.name ? -1 : 1)
                    .sort((a, b) => a.name < b.name ? -1 : (a.name === b.name ? (a.startdate < b.startdate ? -1 : 1) : 1))

                //.sort((a, b) => a.name.toLowerCase().replace(/[0-9]{4} /, "") < b.name.toLowerCase().replace(/[0-9]{4} /, "") ? -1 : 1)
            })
        });
    }

    static contextType = UserContext;
    setTagFiltering = (id) => {
        if (this.state.filterTagIDs.indexOf(id) > -1) {
            this.setState({filterTagIDs: this.state.filterTagIDs.filter(fid => id !== fid)})
        } else {
            this.setState({filterTagIDs: this.state.filterTagIDs.concat([id])})
        }
    };
    setTagFilteringWeekday = (id) => {
        if (this.state.filterWeekdayNrs.indexOf(id) > -1) {
            this.setState({filterWeekdayNrs: this.state.filterWeekdayNrs.filter(fid => id !== fid)})
        } else {
            this.setState({filterWeekdayNrs: this.state.filterWeekdayNrs.concat([id])})
        }
    };

    render() {
        let dayMap;
        const {fullListViewer} = this.props;
        if (!fullListViewer) {
            dayMap = this.state.courses.reduce((obj, c) => {
                obj[(c.startdateDate.getDay() + 6) % 7] += 1
                return obj
            }, [0, 0, 0, 0, 0, 0, 0]);
        }
        const {user} = this.context;
        const tagsMap = this.state.tags_map;
        const errorSplit = !!this.state.error ? this.state.error.split(" ") : null;
        return (
            <div>
                {
                    this.state.error?.includes("Daten kontrollieren") && <MyModal defaultOpen>
                        <Container name={this.state.error} center>
                            {this.state.error}<br/>
                            <MaxLink to={"/benutzer/profil/" + this.props.id}>Hier klicken um zu den Personendaten zu gelangen!</MaxLink>
                        </Container>
                    </MyModal>
                }
                {
                    this.state.error?.includes("zuerst eingeben.") ?
                        <MyModal defaultOpen>
                            <Container name={(!!errorSplit ? errorSplit[0] : "Feld") + " erforderlich"} center>
                                Bitte {this.state.error}<br/>
                                <MaxLink to={"/benutzer/profil/" + this.props.id}>Bitte hier klicken!</MaxLink>
                            </Container>
                        </MyModal> : (
                            this.state.error?.includes("gesetzliche Vertretung") ?
                                <MyModal defaultOpen>
                                    <Container name={"Gesetzliche Vertretung erforderlich"} center>
                                        {this.state.error}<br/>
                                        <MaxLink to={"/verwalterRegistrieren"}>Bitte hier klicken!</MaxLink>
                                    </Container>
                                </MyModal> :
                                <Status type="error" text={this.state.error}/>
                        )

                }
                <Status type="success" text={this.state.status}/>
                {
                    !fullListViewer && this.state.courses.length > 3 &&
                    <>
                        Nur Angebote anzeigen, die an folgenden farbig hinterlegten Tagen stattfinden: <br/>
                        {dayMap.map((count, dayNr) => <>
                            <b
                                className={"courseTag " + (this.state.filterWeekdayNrs.indexOf(dayNr) > -1 ? "active" : "inactive")}
                                style={this.state.filterWeekdayNrs.indexOf(dayNr) > -1 ? {borderBottom: "2px solid #444"} : null}
                                onClick={() => this.setTagFilteringWeekday(dayNr)}
                            >
                                {Consts.weekdayNames[dayNr]} ({count})
                            </b> &nbsp;
                        </>)
                        }
                    </>
                }
                <br/>
                {
                    Object.keys(tagsMap).length > 1 && this.state.courses.length > 3 &&
                    <>
                        {this.context.club?.verein_ID === 9 ? "Filtere unser Angebot nach folgenden Stichwörtern (auch Mehrfachauswahl möglich):" : "Nur Angebote anzeigen, die folgende farbig hinterlegte Stichworte beinhalten:"} <br/>
                        {

                            Object.keys(tagsMap)
                                /*-tagsMap[a] + tagsMap[b]*/
                                .sort((a, b) => this.state.tags[a]?.name < this.state.tags[b]?.name ? -1 : 1)
                                .map(id => <>
                                    <b
                                        className={"courseTag " + (this.state.filterTagIDs.indexOf(id) > -1 ? "active" : "inactive")}
                                        style={this.state.filterTagIDs.indexOf(id) > -1 ? {borderBottom: "2px solid #444"} : null}
                                        onClick={() => this.setTagFiltering(id)}
                                    >
                                        {this.state.tags[id].name} ({tagsMap[id]})
                                    </b> &nbsp;
                                </>)
                        }
                        {
                            window.innerWidth < 600 && [<br/>, <br/>]
                        }
                    </>
                }
                {
                    /*user?.verein_ID === 8 &&
                    <div style={{color: "red"}}><b><br/>Bitte beachte die aktuell gültigen Covid19-Regeln auf unserer Homepage: <a href={"http://www.innsbrucker-turnverein.at"}>www.innsbrucker-turnverein.at</a><br/><br/></b></div>
                     */
                }
                {
                    this.state.courses !== undefined &&
                    <>
                        {
                            (!fullListViewer || this.props.category !== undefined) &&
                            this.state.courses
                                .filter(c => {
                                    if (this.state.filterTagIDs.length === 0) {
                                        return true
                                    }

                                    const tags = ((c || {}).tags || "").split(",");
                                    const bools = this.state.filterTagIDs.map(id => {
                                        if (tags.indexOf(id) === -1) {
                                            return false
                                        }
                                    });
                                    return !(bools.indexOf(false) > -1)
                                })
                                .filter(c => {
                                    if (this.state.filterWeekdayNrs.length === 0) {
                                        return true
                                    }
                                    return (c.startdatesDate || []).some(w => this.state.filterWeekdayNrs.includes((w.getDay() + 6) % 7));
                                })
                                .map(c => <CourseInfo
                                    course={c}
                                    existingMember={this.state.existing_member}
                                    linkPrefix={this.props.linkPrefix}
                                    tags={this.state.tags}
                                    setTagFiltering={this.setTagFiltering}
                                    filterTagIDs={this.state.filterTagIDs}
                                    child_ID={this.props.id}
                                />)
                        }

                        {
                            !fullListViewer && <Loader loading={this.state.loading}/>
                        }
                        {
                            fullListViewer && this.props.category === undefined && <AllCoursesMultiList {...this.props} />
                        }

                        {this.state.courses.length === 0 && !this.state.loading &&
                            <b>Leider ist derzeit kein Kurs verfügbar</b>
                        }
                    </>
                }


            </div>
        )
    }
}

class AllCoursesMultiList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ids: [],
            courses: [],
        }
    }

    onCoursesChange = (ids, courses) => {
        this.setState({ids, courses})
    }

    render() {
        return <AllCoursesList {...this.props} selectable={true} onChange={this.onCoursesChange} selected={this.state.ids} additionalButtonsRight={
            this.state.courses.length > 0 && <><CourseEditMultipleModal courses={this.state.courses} onSubmit={() => this.onCoursesChange([], [])}/></>
        }/>
    }
}

export class AllCoursesList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {courses: [], selectedCourses: this.props.selected || [], lastSearchQuery: ""}
        this.apiGet = apiGet.bind(this)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // console.log(this.props.selected, prevProps.selected, this.state.selectedCourses, prevState.selectedCourses)
        if (!deepEqual(this.props.selected, prevProps.selected)) {
            const currentState = this.state
            this.setState({selectedCourses: this.props.selected}, () => (currentState.selectedCourses !== this.props.selected) && this.loadCoursesLimitless())
        }
    }

    loadCoursesLimitless = () => {
        const getParams = encodeGetParams({limitless: true, schoolyear: this.state.schoolyear, course_ids: this.state.selectedCourses.join(",")})
        if (getParams === this.state.lastSearchQuery) {
            return
        }
        this.setState({loading: true, lastSearchQuery: getParams})
        this.apiGet("/courses?" + getParams, resp => {
            this.setState({loading: false, ...resp})
        })
    }

    updateSelected = (ID, select) => {
        if (this.state.loading) {
            return
        }
        this.setState(a => ({...a, locked: true, selectedCourses: [...(a.selectedCourses || []).filter(x => ID !== x), ...(select ? [ID] : [])]}), () => {
            this.props.onChange && this.props.onChange(this.state.selectedCourses, this.state.selectedCourses.map(x => this.state.courses.find(y => y.ID === x)))
        })
    }

    render() {
        return <>
            <SchoolyearSelect onChange={(sy) => this.setState({schoolyear: sy}, this.loadCoursesLimitless)}/>
            <MyReactTable
                data={this.state.courses.sort((c0, c1) => -c0.ID + c1.ID)}
                loading={this.state.loading}
                onPageChange={(page) => {
                    if (page >= (coursesViewNum / 25) && !this.state.bigDataLoaded) {
                        this.loadCoursesLimitless()
                    }
                }
                }
                columns={[
                    {
                        Header: "Name",
                        filterable: true,
                        key: "name",
                        accessor: "name",
                        minWidth: Math.min(window.innerWidth / 2, 350),
                        Cell: row => <><Link to={`/kurs${(this.props.linkPrefix || '') === "" ? "e" : ""}${this.props.linkPrefix || ''}/${row.original.ID}`}>{row.value}</Link> {row.original.tags === "1" && "(MB)"}</>
                    },
                    ...(this.props.selectable ? [{
                        Header: "Auswählen",
                        id: "auswahl",
                        pdfShow: false,
                        accessor: (original) => (this.state.selectedCourses || []).includes(original.ID),
                        Cell: ({original, value}) => {
                            return value ? <b onClick={() => this.updateSelected(original.ID, false)}><a>ausgewählt</a></b> : <a onClick={() => this.updateSelected(original.ID, true)}>auswählen</a>
                        },
                    }] : []),
                    {
                        Header: "Trainer",
                        filterable: true,
                        key: "trainers",
                        accessor: "trainers",
                    },
                    {
                        Header: "Teilnehmer",
                        accessor: "parts_count",
                        maxWidth: 110,
                        filterable: true,
                        Cell: row => row.value + " / " + row.original.maxparts + (row.original.queue_count > 0 ? " (" + row.original.queue_count + ")" : "") + (row.original.minparts > row.value ? " (min)" : "")
                    },
                    {
                        Header: "Zeitraum",
                        accessor: "startdate",
                        maxWidth: 210,
                        Cell: row => row.value > 0 ? <>
                            <span style={{display: "inline-block", width: "80px", textAlign: "right", marginRight: "-3px"}}>{dateFormatUnixSmall(row.value)}</span>&nbsp;
                            bis {dateFormatUnixSmall(row.original.enddate)}
                        </> : "",
                        pdfCell: row => row.value > 0 ? dateFormatUnixSmall(row.value) + " bis " + dateFormatUnixSmall(row.original.enddate) : "",
                    },
                    {
                        Header: "Ort",
                        accessor: "eventsLocation",
                        maxWidth: 210,
                        filterable: true,
                    },
                    {
                        Header: "Zeitpunkt",
                        maxWidth: 210,
                        filterable: true,
                        id: "eventsList",
                        accessor: ({eventsList}) => eventsList ? EventsPrint({short: true, events: (eventsList || "").split(",")}) : null,

                    },
                    {
                        Header: "Anzeigen von / bis",
                        maxWidth: 210,
                        filterable: true,
                        id: "showStartVisual",
                        Cell: ({original}) => original.showStart > 0 ? dateFormatUnixSmall(original.showStart) + " bis " + dateFormatUnixSmall(original.showEnd) : "",
                        defaultHidden: true,
                    },
                    {
                        Header: "Buchbar von / bis",
                        maxWidth: 210,
                        filterable: true,
                        id: "bookingStartVisual",
                        Cell: ({original}) => original.bookingStart > 0 ? dateFormatUnixSmall(original.bookingStart) + " bis " + dateFormatUnixSmall(original.bookingEnd) : "",
                        defaultHidden: true,
                    },
                    {
                        Header: "Altersfreigabe",
                        maxWidth: 180,
                        filterable: false,
                        Cell: ({original}) => {
                            if ((!original.olderthan || original.olderthan === 0) && (!original.youngerthan || original.youngerthan === 120) && !original.yearAllowedLeft && !original.yearAllowedRight) {
                                return "keine Beschränkung"
                            }
                            if (original.olderthan || original.youngerthan) {
                                return (original.olderthan || 0) + " bis " + (original.youngerthan || 120)
                            } else {
                                return "JG " + (original.yearAllowedLeft || 1900) + " bis " + (original.yearAllowedRight || new Date().getFullYear())
                            }
                        },
                        sortable: false,
                        accessor: "olderthan",
                        defaultHidden: true,
                    },

                    ...(["price", "pricenm", "membersOnly", "youngerthan", "olderthan", "yearAllowedLeft", "yearAllowedRight", "trainersAllowed", "minparts", "maxparts", "enableOnlineConferences", "tags","enableMedia", "description", "information", "audience", "bookingStart", "allBookingStart", "bookingEnd", "showStart", "showEnd", "paymentDueDate"].map(f => ({
                        Header: Consts.translate(f),
                        accessor: f,
                        defaultHidden: true,
                        sortable: true, filterable: true,
                        Cell: ({value}) => {
                            if (f==="tags")
                                return value.split(",").filter(a=>!!a).map(a=>(this.state?.tags || {})[a] ?? a).join(", ")
                            if (f.includes("price"))
                                return value / 100
                            if (f.includes("Start") || f.includes("End") || f.includes("Date"))
                                return !!value ? dateFormatUnixSmall(value) : ""
                            if (["membersOnly", "trainersAllowed"].includes(f) || f.startsWith("enable"))
                                return value ? "ja" : "nein"
                            return value ?? ""
                        }
                    }))),


                    /*{
                    Header: "Ansehen",
                    sortable: false,
                    maxWidth: 90,
                    Cell: row => <Link className={"btn btn-primary"} to={`/kurse/${row.original.ID}`}>anschauen</Link>
                }*/

                ]}
                exportData
                additionalButtonsRight={this.props.additionalButtonsRight}
                defaultSorted={(this.props.orderBySelection ? [{id: "auswahl", desc: true}] : [])}
            />
            {
                this.state.courses.length === coursesViewNum && <MaxBtn onClick={() => this.loadCoursesLimitless()}>Alle Angebote laden</MaxBtn>
            }
        </>
    }
}
