import React, {useMemo, useState} from 'react';
import "../dashboard.sass"
import {apiGet, apiPost} from "../core/api";

import "react-datepicker/dist/react-datepicker.css";
import {Container, LightContainer, Loader, MaxBtn, MiniBtn} from "../core/components/components";
import {MyModal} from "../core/components/modal";
import {MyReactTable} from "../core/components/table";
import {CheckboxInput, TextareaInput} from "../core/input/basic";
import {TagsInput} from "../core/input/tags";
import Status from "../core/status";
import {dateFormatTimeSeconds2Digit, dateFormatTimeShort, dateFormatUnix} from "../core/dateFuncs";
import {Link} from "react-router-dom";
import {FormContextWrapper} from "../core/form_context";
import {maxiGetObj} from "../core/maxiosLeg";
import Select, {components} from "react-select";
import DOMPurify from "dompurify"
import {isJson} from "../core/helpers";
import Consts from "../core/consts";

const routeMap = {
    "course": "kurs",
};

export default class UserHistory extends React.Component {
    state = {
        loading: true,
        history: [],
        cats: [],
        search: {
            cat: [],
        },
        tags: {},
        viewMode: parseInt(localStorage.getItem("viewMode") || 0),
    };

    constructor(props) {
        super(props)
        this.apiGet = apiGet.bind(this)
        //this.apiPost = apiPost.bind(this)
        this.setState = this.setState.bind(this)
        this.loadData()

    }

    loadData = (viewMode = this.state.viewMode) => {
        const {personType, userID} = this.props;
        this.apiGet(`/user/history/get/${userID}/0?viewMode=${viewMode}&personType=${(personType || "user")}`, (resp) => {
            const cats = resp.history.reduce((obj, h) => {
                if (obj.indexOf(h.history.type) === -1) {
                    return obj.concat(h.history.type)
                }
                return obj
            }, []);
            this.setState({...resp, loading: false, cats: cats});
        });
    };

    getTags = (tags) => {
        this.setState({
            tags: tags.reduce((obj, curr) => {
                obj[curr.value] = curr.label;
                return obj
            }, {})
        })
    };

    render() {
        const {history, search} = this.state;
        const {personType, userID} = this.props;
        const naTags = history.filter(h => isNaN(parseInt(h.history.type)));
        const {addTags} = naTags
            .map(h => ({value: h.history.type, label: prettify(h.history.type)}))
            .reduce((obj, h) => {
                if (obj.ids.indexOf(h.value) === -1) {
                    obj.ids = [...obj.ids, h.value];
                    obj.addTags = [...obj.addTags, h]
                }
                return obj

            }, {addTags: [], ids: []});

        const appearingCats = history.map(h => parseInt(h.history.type)).concat(naTags.map(h => h.history.type));

        //console.log(appearingCats)
        const selectedCats = !!search.cat ? search.cat.map(a => a.value) : [];
        //console.log({search, selectedCats})
        const historyFiltered = history.filter(h => {
            //console.log({type: parseInt(h.history.type), typeId: h.history.type})
            return selectedCats.length === 0 || selectedCats.indexOf(h.history.type) > -1 || selectedCats.indexOf(parseInt(h.history.type)) > -1
        });
        const subjectOrObject = (userID === "all" ? "object" : "subject");
        return <>
            <Status type={"error"} text={this.state.error}/>
            <Loader loading={this.state.loading}/>
            {
                userID !== "all" &&
                <>
                    <HistoryAdd child_ID={userID} personType={personType} reload={this.loadData}/>
                </>
            }

            {
                history.length > 0 &&
                <>
                    <FormContextWrapper
                        value={{
                            state: this.state,
                            setState: this.setState,
                            updateState: (a, b, c) => this.setState({[a]: {...this.state[a], [b]: c}})
                        }}
                        style={{width: "calc(100% - 200px)"}}
                        afterUpdate={(root, child, value) => {
                            if (root === "viewMode") {
                                localStorage.setItem("viewMode", value);
                                this.loadData(value)
                            }
                        }
                        }
                    >
                        <TagsInput name={"Kategorie"} noLabel multiple entity={"history"} addTags={addTags}
                                   appearingTags={appearingCats} pushTags={this.getTags} ID={0} tag={"search_cat"}/>
                        <CheckboxInput tag={"viewMode"} labelWidth={250} name={"Gruppiere nach erstem Eintrag"}/>
                    </FormContextWrapper>
                    <MyReactTable
                        data={historyFiltered}
                        columns={[
                            {
                                Header: "Name",
                                filterable: true,
                                accessor: "history.description",
                                Cell: (row) => descriptionRenderModal(row, this.state),

                            },
                            {
                                Header: "Kategorie",
                                filterable: true,
                                accessor: "history.type",
                                maxWidth: 230,
                                Filter: (props, onChange) => {
                                    return <Select
                                        isMulti={true}
                                        multi={true}
                                        onChange={entry => {
                                            this.setState({search: {cat: entry}});
                                        }}
                                        components={{MultiValueContainer}}
                                        closeMenuOnSelect={false}
                                        options={this.state.cats.map(c => ({label: prettify(c), value: c}))}
                                        styles={{
                                            menuList: (base) => ({...base, zIndex: 100}),
                                            menu: (base) => ({...base, zIndex: 100, position: 'relative'})
                                        }}
                                        className="multi-select-with-see-more"
                                        classNamePrefix="select"
                                        menuPosition={"fixed"}
                                        placeholder={"Suche"}
                                        menuPortalTarget={document.getElementById("react-select-portal")}
                                        autosize={false}
                                        value={this.state.search.cat}
                                    />
                                },
                                Cell: ({value, original}) => !isNaN(parseInt(value)) && !!this.state.tags[value] ? this.state.tags[value] : prettify(original.history.type)
                            },
                            {
                                Header: "Wer",
                                filterable: true,
                                accessor: subjectOrObject + ".fullname",
                                maxWidth: 200,
                                Cell: ({value, original}) => original[subjectOrObject] ? <Link
                                    to={"/benutzer/profil/" + original[subjectOrObject].ID}>{value}</Link> : "Web User",
                            },
                            {
                                Header: "Zeit",
                                filterable: true,
                                maxWidth: 158,
                                accessor: "history.time",
                                Cell: row => !!row.value && dateFormatTimeShort(row.value, true)
                            },
                        ]
                        }
                        defaultSorted={[
                            {
                                id: "history.time",
                                desc: true
                            }
                        ]}
                    />
                </>
            }
        </>
    }

    /*toggleViewMode = () => {
    const viewMode = this.state.viewMode === 0 ? 1 : 0;
    this.setState({viewMode}, this.loadData);
    localStorage.setItem("viewMode", viewMode);
}*/
}

const descriptionRenderModal = (row, state) => {
    let value = row.value;
    if (row.original.type === "message") {
        value = value.replace(/<([a-z]+)_([0-9]+)>/g, (all, entity, ID) => {
            //console.log(all, entity, ID);
            return <a href={"/" + routeMap[entity] + "/" + ID}>{state.course_names[ID]}</a>
        })
    }

    const json = isJson(value)
    let content = <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(value.replace("\n", "<br>"))/*.split("\n").map(l => [l, <br/>])*/}}/>;
    let trigger = content

    if (json) {
        const data = JSON.parse(value)
        content = <ContentChangeTable json={data}/>
        trigger = <>{JSON.parse(value).length > 1 ? JSON.parse(value).length + " Änderungen:" : "1 Änderung"}
            <ul>
                {
                    Object.values(data).map(x => {
                        if (x.type !== "json" && x.type !== "html") {
                            return <li key={x.name}><b>{Consts.translate(x.name)}:</b> Änderung von "{renderValue(x.old, x.type)}" zu "{renderValue(x.new, x.type)}" </li>

                        } else {
                            return <li key={x.name}><b>{Consts.translate(x.name)}:</b> Datenobjekt</li>
                        }
                    })
                }
            </ul>
        </> // JSON.parse(value).length + " Änderungen"
    }
    const subcontent = ((row.original || {}).linked_messages || []).concat({
        ...row.original.history,
        status: row.original.history.description
    });

    return <MyModal trigger={trigger} additionalPaperStyles={"WideDialogPaper"}>
        <LightContainer name={json ? "Änderungen" : "Text"}>
            {content}
            {["login", "qr_scan"].includes((row.original.history || {}).type) && <> (<PrettifyIPAddress ip={(value).split(" von ")[1]}/>)</>}
        </LightContainer>

        {
            (row.original.newsletter || {}).subject &&
            <LightContainer name={"Newsletter"}>
                {row.original.newsletter.subject}
            </LightContainer>
        }

        {
            state.viewMode === 1 && !!subcontent && subcontent.length > 1 &&
            <LightContainer name={"Vorhergehende Nachrichten"}>
                <MyReactTable
                    data={subcontent}
                    defaultSorted={[{id: "time", desc: true}]}
                    columns={[
                        {
                            Header: "Zeit",
                            accessor: "time",
                            maxWidth: 300,
                            minWidth: 200,
                            Cell: row => !!row.value && dateFormatTimeShort(row.value)
                        }, {
                            Header: "Name",
                            accessor: "status",
                            minWidth: 300,
                            maxWidth: 500
                        },
                        {
                            Header: "Kategorie",
                            accessor: "type",
                            maxWidth: 300,
                            minWidth: 200,
                            Cell: ({value, original}) => !isNaN(parseInt(value)) && !!state.tags[value] ? state.tags[value] : prettify(original.type)
                        }
                    ]}/>
            </LightContainer>

        }
    </MyModal>
}

function renderValue(value, type) {
    if (value === null || value === undefined) {
        return ""
    }
    switch (type) {
        case "birthdate":
            let date = `${value}`
            return `${date.substr(6, 2)}.${date.substr(4, 2)}.${date.substr(0, 4)}`
        case "date":
            return typeof (value) !== "number" && isNaN(Date.parse(value)) ? value : dateFormatUnix(value)
        case "datetime":
            return dateFormatTimeSeconds2Digit(value)
        case "boolean":
            return value === "1" || value === "True" || value === 1 || value === true ? "wahr" : "falsch"
        default:
            if (Array.isArray(value)) {
                return value.join(", ")
            }
            if (typeof value === "object") {
                return "Object" + value.toString()
            }
            return value
    }
}

const ContentChangeTable = ({json}) => {
    const CellRenderer = ({original, value}) => {
        switch (original.type) {
            case "html":
                return <div style={{whiteSpace: "break-spaces"}} dangerouslySetInnerHTML={{__html: value}}></div>
            case "json":
                return <MyModal trigger={<MiniBtn>Datenobjekte ansehen</MiniBtn>}><CompareTables data={original} title={"Vergleich für Spalte " + Consts.translate(original.name)}/></MyModal>
            default:
                return typeof value === "object" ? JSON.stringify(value) : renderValue(value, original.type)
        }
    }

    return <MyReactTable
        data={json}
        columns={[
            {
                "Header": "Feldname",
                "accessor": "name",
                Cell: ({value}) => Consts.translate(value) || value,
            },
            {
                "Header": "Alter Wert",
                "accessor": "old",
                Cell: CellRenderer
            },
            {
                "Header": "Neuer Wert",
                "accessor": "new",
                Cell: CellRenderer
            },
        ]}/>
}

const CompareTables = ({data, title}) => {
    let columns, preparedData, html
    data.old = data.old || []
    data.new = data.new || []
    if (Array.isArray(data.old) && Array.isArray(data.new)) {
        if (typeof data.old[0] === "object" || typeof data.new[0] === "object") {
            columns = [...new Set([...data.old.reduce((cols, nextRow) => [...cols, ...Object.keys(nextRow)], []), ...data.new.reduce((cols, nextRow) => [...cols, ...Object.keys(nextRow)], [])])]
            preparedData = data.old.length > data.new.length ? data.old.map((row, index) => columns.map(x => ({name: x, old: row[x] || "-", new: (data.new[index] || {})[x] || "-"}))) :
                data.new.map((row, index) => columns.map(x => ({name: x, old: (data.old[index] || {})[x] || "-", new: row[x] || "-"})))
            html = preparedData.map((table, index) => <div>
                <p><b>Datensatz {index + 1}</b></p>
                <ContentChangeTable json={table}/>
            </div>)
        } else {
            preparedData = data.old.length > data.new.length ? data.old.map((row, index) => ({name: "Wert " + (index + 1), old: row, new: data.new[index] || "-"})) : data.new.map((row, index) => ({
                name: "Wert " + (index + 1),
                new: row,
                old: data.old[index] || "-"
            }))
            html = <ContentChangeTable json={preparedData}/>
        }
    } else {
        columns = [...new Set([...Object.keys(data.old), ...Object.keys(data.new)])]
        preparedData = columns.map(c => ({name: c, old: data.old[c] || "-", new: data.new[c] || "-"}))
        html = <ContentChangeTable json={preparedData}/>
    }

    return <Container name={title}>
        {html}
    </Container>
}

// modification for multi value selects, where after the first element a "N mehr" entry should follow.
export const MultiValueContainer = props => {
    // Shows the first value element full text and beginning with the second shows "N mehr"
    return props.selectProps.value[0].value === props.data.value && (
            <span>
        <components.MultiValueContainer {...props} />
        </span>
        )
        || props.selectProps.value[1].value === props.data.value && (
            <span>
        <span style={{
            "margin-left": "5px",
            "font-size": "12px"
        }}>+ {(props.selectProps.value.length) - 1} mehr</span>
        </span>
        );
};

// Prettifies category titles and translates predefined category titles to their german equivalent.
const prettify = s => {
    const transformations = {
        'email_coursereg': 'Email-Kursanmeldung',
        'pwreset': 'Passwort zurücksetzen',
        'role_add': 'Neue Rolle',
        'user_picture': 'Benutzerbild',
        'bev_update': 'BEV Update',
        'role_mod': 'Rolle modifiziert',
        'email_userreg': 'Benutzerregistrierung (Mail)',
        'email_paymentReminder': 'Zahlungserinnerung (Mail)',
        'user_data_change': 'Benutzerdatenänderung',
        'club_data_change': 'Vereinsdatenänderung',
        'user_death': "Tod",
        'user_removal': 'Entfernung gemäß DSGVO',
        'course_data_change': 'Kursdatenänderung',
        'course_shift_planner': 'Kurs-Schicht-Verwaltung',
        'qualification_data_change': 'Aus-/Weiterbildung bearbeitet',
        'qualification_added': 'Aus-/Weiterbildung hinzugefügt',
        'trainer_details_change': 'Trainer Daten bearbeitet',
        'block_change': 'Block bearbeitet',
        'view': 'Video angeschaut',
        'modification': "Veränderung",
        'added': "Hinzugefügt",
        'attributed': "Zugeordnet",
        'account_attributed': "Kontozuordnung",
        'imported': "Importiert",
        'permission_revoked': "Berechtigung entzogen",
        'permission_granted': "Berechtigung erteilt",
        'data_confirmed': "Bestätigung ",
        'user_change': "Verwalter ",
        'email_pwreset': "Passwort ",
        'pwreset_req': "Passwort Anfrage",
        ModelPaymentPlan: "Zahlungsweisen-Gruppe",
        CoursePaymentPlan: "Kurs-Zahlungsweise",
    }

    for (var index in transformations) {
        if (s === index) {
            return transformations[index];
        }
    }
    return s.split("_").map(a => (a[0] || "").toUpperCase() + a.substr(1)).join(" ");
}

class HistoryAdd extends React.Component {

    setState = this.setState.bind(this);
    state = {
        new: {
            child_ID: this.props.child_ID,
        }
    };
    apiGet = apiGet.bind(this);
    apiPost = apiPost.bind(this);

    handleSubmit = (e, close) => {
        const {personType} = this.props;
        e.preventDefault();
        this.apiPost("/user/history/add?personType=" + personType, this.state.new, resp => {
            this.setState({new: {child_ID: this.props.child_ID}});
            this.props.reload();
            close()
        })
    };

    render() {
        return <MyModal
            trigger={<MaxBtn style={{float: "right", marginTop: "0px", marginLeft: "20px", marginRight: 0}}>Eintrag
                Hinzufügen</MaxBtn>}>
            {close =>
                <Container name={"Eintrag in Geschichte Hinzufügen"}>
                    <FormContextWrapper value={{state: this.state, setState: this.setState}}
                                        onSubmit={e => this.handleSubmit(e, close)}>
                        <TextareaInput name="Text" tag={"new_text"}/><br/>
                        <TagsInput name={"Kategorie"} entity={"history"} ID={0} tag={"new_category"}/>
                        <MaxBtn>Hinzufügen</MaxBtn>
                    </FormContextWrapper>
                </Container>
            }
        </MyModal>
    }
}


function PrettifyIPAddress({ip}) {
    const [prettyIP, setPrettyIP] = useState(null);
    useMemo(() => {
        maxiGetObj({
            url: "/user/history/reverse_ip?ip=" + ip,
            success: ({hostname}) => {
                setPrettyIP(hostname)
            }
        })
    }, [ip]);
    return prettyIP || "..."
}

export {ContentChangeTable, descriptionRenderModal}
