import React, {Component, useEffect, useMemo,} from 'react';
import {BrowserRouter as Router, Route} from "react-router-dom";

import {Switch} from "react-router";
import {Container} from "./core/components/components";
// ie stuff
import "babel-polyfill";
import {polyfill} from 'es6-promise';
import cssVars from 'css-vars-ponyfill';
import {clubMap, domainMap, placeholderClub} from "./core/custom";
import Nav from "./nav";
import CheckLogin from "./user/check_login";
import {UserContext} from "./user/UserContext";

import Login from "./user/login";
import {ChildsByRole, UsersList, TrainersList, ActiveList, ActiveAdminsList, UsersListFast, UsersManagerList, LatestBookings, ActiveUserActiveManagerList} from "./user/user_general_list_instances";
// import UsersManagerList from "./user/user_manager_list";

import Courses from "./course/courses";
import CoursesAvailable from "./course/courses_available";
import CourseView from "./course/course_view";
import Abrechnung from "./abrechnung/main";
import LedgerView from "./buchhaltung/ledger_view";

import UserView from "./user/user_view";
import UserHistoryAll from "./user/user_history_all";
import UserMembership from "./user/user_membership";
import ErrorBoundary from "./core/error_boundary";
import ChangePW from "./user/changepw";
//import PaymentMethodAdd from "./abrechnung/zahlungsmethode_add";
//import StripeAuthWrapper from "./abrechnung/stripe_auth_wrapper";
import Register from "./user/register";
import OffenstaendeList from "./user/user_offen";
import BirthdaysList from "./user/user_birthdays";
import CourseLastBookings from "./course/courses_lastBookings";
import UserMessage from "./user/user_message";
import BookingRouter from "./course/booking_router";
import CourseAttendance from "./course/attendance";
import CourseEmbed from "./course/embed";
// import ChildsByRole from "./user/user_byRole";
import UserAddMyVerwalter from "./user/user_addMyVerwalter";
import NewsRedir from "./course/news_redir";
import CourseTagUsers from "./user/course_tag_users";
import UserRegisterAsAdmin from "./user/user_registerAsAdmin";
import ResetAdminUserChange from "./user/reset_admin_user_change";
import AsyncComponentWrapper from "./core/components/asyncComponentWrapper";
import FAQ from "./docs/faq";
import {CoursePaymentModelList} from "./course/paymentPlan/course_payment_model_list";

polyfill();

// end ie stuff
function ScrollToTop({location}) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);
    return null;
}

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navOpen: localStorage.getItem("mainContainer") === null ? (window.innerWidth > 1025):localStorage.getItem("mainContainer") !== "false",
            navToggle: () => {
                localStorage.setItem("mainContainer", !this.state.navOpen);
                this.setState({navOpen: !this.state.navOpen})
            },
            mounted: false,
            club: JSON.parse(localStorage.getItem("verein_cache")) || placeholderClub
        };
        this.setCssVars(this.state.club)
    }

    addUserFromStatus = resp => {
        !!resp.club && this.setCssVars(resp.club);
        this.setState(resp);
    };

    setCssVars = club => {
        window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify(window.safeAreaInsets))
        const variables = {
            "mainColor": club.color,
            "fontColor": club.fontColor || "#444",
            "fontColorInverse": club.fontColorInverse || "#fff",
            ...["Top", "Right", "Bottom", "Left"].reduce((obj, curr) => ({...obj, ["safeAreaInset" + curr]: ((window.safeAreaInsets || {})[curr.toLowerCase()] ?? 0) + "px"}), {}),
        };
        cssVars({
            rootElement: document,
            variables,
        });
    }
    cacheInsetVariable = () => {
        if (!window.safeAreaInsets) {
            window.safeAreaInsets = JSON.parse(localStorage.getItem("safeAreaInsets") || "{}")
        } else {
            localStorage.setItem("safeAreaInsets", JSON.stringify(window.safeAreaInsets))
        }
    }

    componentWillMount() {
        const club = JSON.parse(localStorage.getItem("verein_cache")) || placeholderClub;
        this.cacheInsetVariable()
        this.setCssVars(club)
        if (window.ReactNativeWebView) {
            window.setInterval(() => {
                if (window.safeAreaInsets2 && JSON.stringify(window.safeAreaInsets2) !== localStorage.getItem("safeAreaInsets")) {
                    this.cacheInsetVariable()
                    const club = JSON.parse(localStorage.getItem("verein_cache")) || placeholderClub;
                    this.setCssVars(club)
                }
            }, 500)
        }
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {

    }

    render() {
        if (window.embeddedMode) {
            return <Router>
                <Route path={"/"} component={CourseEmbed}/>
            </Router>
        }
        return (
            <Router>

                <UserContext.Provider value={{...this.state, setState: this.setState}}>
                    <div className={"version-tag"} style={{
                        position: "fixed",
                        bottom: (window.safeAreaInsets?.bottom || 0) + "px",
                        right: 0,
                        fontSize: "10px",
                        zIndex: 1000,
                        backgroundColor: "white",
                        padding: "5px",

                    }}>
                        version {window.vatomatorVersion}
                    </div>
                    <div className={"App " + (window.location.hostname === "localhost" ? "blue" : "tvg")}>
                        <ErrorBoundary key={"ljljfs"}>
                            <Route path="/" render={(props) => <CheckLogin {...props} addUserFromStatus={this.addUserFromStatus}/>}/>
                            {
                                /*!!this.state.user && this.state.user.status === "logged_in" &&*/
                                <Route path="/" component={Nav}/>
                            }
                            <Route path="/" component={ScrollToTop}/>
                        </ErrorBoundary>
                        <MainContainer>
                            <div id={"mainOverlayForNav"}/>
                            <ErrorBoundary key={"ljljfs"}>
                                <Route path="/" render={(props) => <ResetAdminUserChange {...props}/>}/>
                                <Switch>
                                    <Route exact path="/benutzer/changepw/:token" component={ChangePW}/>

                                    <Route exact path="/faq/:topic" component={FAQ}/>
                                    <Route exact path="/faq" component={FAQ}/>

                                    <Route exact path="/benutzer/trainer" component={TrainersList}/>
                                    <RouteMax exact path="/benutzer/tote" component={() => import("./user/user_dead_list")}/>
                                    <RouteMax exact path="/benutzer/registrierungen" component={() => import("./user/user_lastregistrations")}/>
                                    <Route exact path="/benutzer/saldo" component={OffenstaendeList}/>
                                    <Route exact path="/benutzer/sview/:userID" component={OffenstaendeList}/>
                                    <Route exact path="/benutzer/sprofil/:userID" component={OffenstaendeList}/>
                                    <Route exact path="/benutzer/jubilare" component={BirthdaysList}/>
                                    <Route exact path="/benutzer/aktiv" component={ActiveList}/>
                                    <Route exact path="/benutzer/aktivOderVerwalter" component={ActiveUserActiveManagerList}/>
                                    <Route exact path="/benutzer/aview/:userID" component={ActiveList}/>
                                    <Route exact path="/benutzer/aprofil/:userID" component={ActiveList}/>
                                    <Route exact path="/benutzer/admins" component={ActiveAdminsList}/>
                                    <Route exact path="/benutzer/adview/:userID" component={ActiveAdminsList}/>
                                    <Route exact path="/benutzer/liste/:userID" component={UsersList}/>
                                    <Route exact path="/benutzer/liste" component={UsersList}/>
                                    <Route exact path="/benutzer/registrieren" component={UserRegisterAsAdmin}/>
                                    <Route exact path="/benutzer/verwalterliste" component={UsersManagerList}/>
                                    <Route exact path="/benutzer/schnell" component={UsersListFast}/>
                                    <Route exact path="/benutzer/view/:userID" component={UserView}/>
                                    <Route exact path="/benutzer/profil/:userID" component={UserView}/>
                                    <Route exact path="/benutzer/history" component={UserHistoryAll}/>
                                    <Route exact path="/benutzer/nachRolle" component={ChildsByRole}/>
                                    <RouteMax exact path="/session" component={() => import("./user/user_session")}/>
                                    <Route exact path="/abrechnung" component={Abrechnung}/>
                                    <Route exact path="/abrechnung/:userID" component={Abrechnung}/>
                                    <RouteMax path="/abrechnung/authentication" component={() => import("./abrechnung/stripe_auth_wrapper")}/>

                                    <RouteMax exact path="/bezahlmethode/add/:preferredMethod" component={() => import("./abrechnung/zahlungsmethode_add")}/>
                                    <RouteMax exact path="/bezahlmethode/add/:preferredMethod/:child_ID" component={() => import("./abrechnung/zahlungsmethode_add")}/>
                                    <RouteMax exact path="/bezahlmethode/add" component={() => import("./abrechnung/zahlungsmethode_add")}/>
                                    <RouteMax exact path="/bezahlmethode/add_for/:child_ID" component={() => import("./abrechnung/zahlungsmethode_add")}/>

                                    <Route path="/buchhaltung/buchungen/:kontonr" component={LedgerView}/>
                                    <Route path="/buchhaltung/buchungen" component={LedgerView}/>

                                    <RouteMax path="/buchhaltung/bankimport" component={() => import("./buchhaltung/bank_import")}/>
                                    <RouteMax path="/buchhaltung/bankexport" component={() => import("./buchhaltung/bank_export")}/>
                                    <RouteMax path="/buchhaltung/geschaeftsjahr" component={() => import("./buchhaltung/business_year")}/>
                                    <RouteMax path="/buchhaltung/konten" component={() => import("./buchhaltung/accounts")}/>
                                    <RouteMax path="/buchhaltung/berichte" component={() => import("./buchhaltung/accounting_reports")}/>
                                    <RouteMax path="/buchhaltung/zuordnung" component={() => import("./buchhaltung/attribution")}/>
                                    <RouteMax path="/buchhaltung/mitgliederzuordnung" component={() => import("./buchhaltung/user_attribution")}/>
                                    <RouteMax path="/buchhaltung/dashboard" component={() => import("./buchhaltung/dashboard")}/>

                                    <RouteMax path="/rechnungen" component={() => import("./buchhaltung/invoices")}/>
                                    <RouteMax path="/rechnung/vorlagen" component={() => import("./buchhaltung/invoice_templates_list")}/>

                                    <RouteMax path="/änderungen" component={() => import("./core/list_commits")}/>
                                    <RouteMax path="/export" component={() => import("./core/exportDump")}/>

                                    <Route exact path="/mitgliedschaft" component={UserMembership}/>
                                    <Route exact path="/kontakt" component={UserMessage}/>
                                    <RouteMax exact path="/mitgliedsbeiträge" component={() => import("./club/membershipfees")}/>
                                    <RouteMax exact path="/rollen" component={() => import("./club/roles")}/>

                                    <Route exact path="/registrieren" component={Register}/>
                                    <Route exact path="/verwalterRegistrieren" component={UserAddMyVerwalter}/>
                                    <Route exact path="/aktivieren" component={Register}/>
                                    <Route exact path="/registrieren/:course_ID" component={Register}/>

                                    <RouteMax exact path="/dash" component={() => import("./dashboard/members")}/>

                                    {/*<Route path="/benutzer/newsletter" component={BeeTemplates}/>*/}
                                    {/*<Route path="/benutzer/bee" component={MyBee}/>*/}
                                    {/*<Route path="/benutzer/stripo" component={Stripo}/>*/}
                                    {/*<Route path="/newsletter/unlayer" component={Unlayer}/>*/}
                                    <RouteMax path="/newsletter/vorlagen" component={() => import("./newsletter/templates_list")}/>
                                    <RouteMax path="/newsletter/sendungen" component={() => import("./newsletter/newsletters")}/>
                                    <RouteMax path="/newsletter/statistik/:newsletter_ID" component={() => import("./newsletter/newsletter_stats")}/>
                                    <RouteMax path="/newsletter/:template_ID" component={() => import("./newsletter/unlayer")}/>
                                    <RouteMax path="/newsletter/neu" component={() => import("./newsletter/unlayer")}/>
                                    <RouteMax path="/cloudstorage/anleitung" component={() => import("./user/nextcloud_guide")}/>
                                    <RouteMax path="/cloudstorage" component={() => import("./user/nextcloud")}/>
                                    <RouteMax path="/editor/:template_ID/:accessToken" component={() => import("./pages/file_editor")}/>

                                    <RouteMax exact path="/" component={() => import("./dashboard/defaultPage")}/>
                                    <Route exact path="/kurs/meine" component={Courses}/>
                                    <Route exact path="/kurs/meine_online" component={Courses}/>
                                    <Route exact path="/kurs/trainer" component={Courses}/>
                                    <Route exact path="/kurs/buchung/:date" component={CoursesAvailable}/>
                                    <Route exact path="/kurs/buchung" component={BookingRouter}/>
                                    <Route exact path="/kurs/buche/:courseID" component={BookingRouter}/>
                                    <Route exact path="/kurs/buchungen" component={LatestBookings}/>
                                    <Route exact path="/kurs/buchungen/kurs/:courseID" component={CourseLastBookings}/>
                                    <Route exact path="/kurs/buchungen/user/:userID" component={CourseLastBookings}/>
                                    <RouteMax exact path="/kurs/tags" component={() => import("./course/course_tags")}/>
                                    <Route exact path="/kurstag/benutzer" component={CourseTagUsers}/>
                                    <Route path="/kurs/:courseID/anwesenheit/:eventID" component={CourseAttendance}/>
                                    <Route path="/kurs/:courseID/personenanwesenheit/:childID" component={CourseAttendance}/>
                                    <RouteMax exact path="/kurse/umsatz" component={() => import("./course/turnover")}/>
                                    <RouteMax exact path="/kurse/nachrichten" component={() => import("./course/course_news_multi")}/>
                                    <Route exact path="/zahlungsmodelle" component={CoursePaymentModelList}/>
                                    <Route path="/kurse/:courseID" component={CourseView}/>
                                    <Route path="/kurs/:news_ID" component={NewsRedir}/>


                                    <RouteMax exact path="/kalender/atHome" component={() => import("./course/conference_calendar")}/>
                                    <RouteMax exact path="/kalender/export" component={() => import("./calendar/export")}/>
                                    <RouteMax exact path="/kalender/monat" component={() => import("./calendar/calendar")}/>
                                    <RouteMax exact path="/kalender/raum" component={() => import("./calendar/rooms")}/>

                                    <RouteMax exact path="/neuigkeiten" component={() => import("./dashboard/facebook")}/>

                                    <RouteMax exact path="/kontakte" component={() => import("./contacts/contacts_list")}/>
                                    <RouteMax exact path="/kontakte/:contactID" component={() => import("./contacts/contacts_list")}/>

                                    <RouteMax exact path="/verein" component={() => import("./club/basic_data")}/>
                                    <RouteMax exact path="/migrieren/person" component={() => import("./club/migrate_person")}/>
                                    <RouteMax exact path="/migrieren/kontenrahmen" component={() => import("./club/migrate_kontenrahmen")}/>
                                    <RouteMax exact path="/verein/konfiguration" component={() => import("./club/configure")}/>
                                    <RouteMax exact path="/verein/vorlagen" component={() => import("./club/templates")}/>
                                    <RouteMax exact path="/verein/vorstand" component={() => import("./club/board")}/>
                                    <RouteMax exact path="/standesmeldung" component={() => import("./club/standesmeldung")}/>
                                    <RouteMax exact path="/berechtigungen" component={() => import("./club/permissions")}/>
                                    <RouteMax exact path="/tags" component={() => import("./core/TagsEditor")}/>

                                    <RouteMax exact path="/meineMitgliedskarte" component={() => import("./user/ams_usercode")}/>
                                    <RouteMax exact path="/ams/kiosk" component={() => import("./user/ams_kiosk")}/>

                                    <RouteMax exact path="/gutscheine" component={() => import("./voucher/vouchers_list")}/>
                                    <RouteMax exact path="/gutscheine/verkaufen" component={() => import("./voucher/vouchers_sell")}/>
                                    <RouteMax exact path="/gutscheine/kaufen" component={() => import("./voucher/voucher_buy")}/>

                                    <RouteMax exact path="/athome/:subslot" component={() => import("./pages/turnverein_at_home")}/>
                                    {/*<RouteMax exact path="/medien" component={() => import("./pages/medien")}/>*/}
                                    <RouteMax exact path="/medien" component={() => import("./pages/medien")}/>
                                    <RouteMax exact path="/medien/:subslot" component={() => import("./pages/medien")}/>
                                    <RouteMax exact path="/formulare/:subslot" component={() => import("./pages/forms")}/>
                                    <RouteMax exact path="/formular/uebersicht" component={() => import("./pages/form_overview")}/>

                                    <RouteMax exact path={"/embed/konfigurator"} component={() => import("./course/embed_config")}/>
                                    <RouteMax exact path={"/embed/:verein/:tag"} component={() => import("./course/embed")}/>
                                    <RouteMax exact path={"/getCustomClub"} component={() => import("./core/custom_exporter")}/>
                                    <RouteMax exact path={"/testraum"} component={() => import("./course/conference_test")}/>
                                    <RouteMax exact path={"/recordee"} component={() => import("./recordee/recordee")}/>
                                    <RouteMax exact path={"/recordee/:roomName"} component={() => import("./recordee/recordee")}/>


                                    <RouteMax exact path={"/profil/:child_ID"} component={() => import("./user/profile")}/>
                                    <RouteMax exact path={"/trainer"} component={() => import("./user/profileTrainer")} props={{mode: "trainer"}}/>
                                    <RouteMax exact path={"/vorstand"} component={() => import("./user/profileTrainer")} props={{mode: "board"}}/>
                                    <RouteMax exact path={"/trainer/anwesenheitprokurs"} component={() => import("./user/trainer_attendances")}/>
                                    <RouteMax exact path={"/sendungseinstellungen"} component={() => import("./user/notificationSettings")}/>

                                    <RouteMax exact path="/raum/buchungen" component={() => import("./room/room_reservation")}/>
                                    <RouteMax exact path="/raum/preise" component={() => import("./room/room_prices")}/>

                                    <RouteMax exact path="/meineVereine" component={() => import("./user/vereinid")}/>

                                    <RouteMax exact path="/rabatte" component={() => import("./course/discount")}/>
                                    <RouteMax exact path="/zahlungen" component={() => import("./abrechnung/zahlungen_alle")}/>

                                    <Route path={"/"} component={Notfound}/>
                                </Switch>
                            </ErrorBoundary>
                        </MainContainer>
                        <article style={{zIndex: 100, width: "100%"}}>
                            <Route path="/benutzer/select" component={UsersList}/>
                            <Route path="/benutzer/login" component={Login}/>
                            <Route path="/benutzer/logout" render={(props) => <Login {...props} addUserFromStatus={this.addUserFromStatus}/>}/>

                        </article>

                    </div>
                </UserContext.Provider>
            </Router>
        );
    }
}

function RouteMax({path, component, props, ...rest}) {
    console.log("maxprops1", rest)
    const asyncComponentMemorized = useMemo(() => (props2) => <AsyncComponentWrapper myImport={component}  {...props2}{...props}/>, [path])
    return <Route {...rest} path={path} component={asyncComponentMemorized}/>
}


class MainContainer extends React.Component {
    state = {
        mounted: false,
    };
    componentDidMount = () => {
        window.setTimeout(() => {
            this.setState({mounted: true})
        }, 2000);
    };
    static contextType = UserContext;

    render() {
        const userContext = this.context;
        return <article style={{zIndex: 10}} id={"mainContainer"}
                        className={
                            (userContext.navOpen && !!userContext.user && userContext.user.status === "logged_in" ?
                                    "open" :
                                    (!!userContext.user && userContext.user.status === "logged_in" ? "closed" : "navNotExistent")
                            ) +
                            (this.state.mounted ? " postLoad" : "")}
                        onClick={() => userContext.navOpen && !!userContext.user && userContext.user.status === "logged_in" && window.innerWidth < 500 && userContext.navToggle()}
        >
            {this.props.children}
        </article>
    }
}

function Notfound(props) {
    if (props.location.pathname.indexOf("/benutzer") > -1) {
        return null
    }
    return <Container name={"Fehler"}>Diese Funktion wird in Kürze hinzugefügt!</Container>
}

export default App;
