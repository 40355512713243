import React from "react";
import {LightContainer} from "../core/components/components";
import AsyncComponentWrapper from "../core/components/asyncComponentWrapper";

export default function CourseMedia({id, allowedTrainerOrAdmin}) {
    return <>
        <AsyncComponentWrapper slot={"course/" + id} myImport={() => import("../pages/page_view")}/>

        {
            allowedTrainerOrAdmin && <LightContainer name={"Video hochladen"}>
                <AsyncComponentWrapper course_ID={id} myImport={() => import("../pages/vimeoUpload")}/>
            </LightContainer>
        }


    </>
}
