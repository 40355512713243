import React, {useMemo, useState} from "react";
import {maxiGet} from "../core/maxios";

export default function NewsRedir({match}) {
    useMemo(() => {
        //maxiGet("/course/news/" + match.params?.news_ID).then(resp => {
        //  if (resp.news?.content?.course_ID) {
        window.location.href = "/kurse/" + match.params?.news_ID + "#neuigkeiten"
        //}
        //})
    }, [])

    return null
}
