import {CourseShiftPlannerShiftEntry} from "../../core/interfaces/core"
import React, {useContext, useState} from "react"
import {UserContext} from "../../user/UserContext"
import {maxiPost} from "../../core/maxios"
import EntryStyle from "./CourseShiftPlannerEntry.module.sass"
import LocationStyle from "./CourseShiftPlannerLocation.module.sass"
import {dateFormatTime, dateFormatTimeTime} from "../../core/dateFuncs"
import {FaEdit, FaInfo, FaInfoCircle, FaTrashRestore, FaUserPlus, FaUsers} from "react-icons/fa"
import {ShiftEntryChangerModal} from "./modals"
import {Link} from "react-router-dom"
import {FaTimes, FaTrashAlt} from "react-icons/all"
import {MiniBtn} from "../../core/components/components"
import {CourseShiftPlannerContext, LocalDispatcher} from "./CourseShiftPlanner"
import UserSearchSelect from "../../user/user_search_select"
import {MyModal} from "../../core/components/modal"
import {StatusVar} from "../../club/club_inputs";
import Status from "../../core/status";
import UserHistory from "../../user/user_history";

export function CourseShiftPlannerEntry({entry, onChange, showDate, onlyShowUnmetShifts}: { entry: CourseShiftPlannerShiftEntry, onChange: LocalDispatcher<CourseShiftPlannerShiftEntry>, showDate: boolean, onlyShowUnmetShifts: boolean }) {
    const {isEditable, courseShiftPlanner, isDirty, reload, course_ID,} = useContext(CourseShiftPlannerContext)
    const [{error, loading}, setStatusVar] = useState<StatusVar>({});
    const userContext = useContext(UserContext)
    const {user} = userContext

    const participants = courseShiftPlanner.participations.filter(participant => participant.shift_ID === entry.ID)

    const adminMayAddRemove = isEditable && !isDirty && user.role >= 80

    const joinShift = () => {
        if (!window.confirm("Bist du sicher, dass du dich für diese Schicht anmelden möchtest?")) {
            return
        }
        maxiPost("/shiftplanner/join/" + course_ID, {shift_ID: entry.ID}).then(() => reload())
    }
    const cancelAdmin = (child_ID: number) => {
        if (!window.confirm("Bist du sicher, dass du diese Person aus dieser Schicht entfernen möchtest?")) {
            return
        }
        maxiPost("/shiftplanner/cancel/admin/" + course_ID, {shift_ID: entry.ID, child_ID}).then(() => reload())
    }
    if (participants.length > 0 && participants.length >= (entry.count || 100000) && onlyShowUnmetShifts) {
        return null
    }


    if (entry.deleted && participants.length === 0) {
        return null
    }
    const LinkOrEmpty = adminMayAddRemove ? Link : React.Fragment;

    return <div className={EntryStyle.entry + " " + (entry.deleted ? (isEditable ? LocationStyle.deletedadmin : LocationStyle.deleted) : "")}>
        <div className={EntryStyle.header}>
            <div className={EntryStyle.headerText}>{entry.name}<br/>{showDate ? <>von {dateFormatTime(entry.dateFrom)}<br/>bis {dateFormatTime(entry.dateTo)}</> : <>von {dateFormatTimeTime(entry.dateFrom)} bis {dateFormatTimeTime(entry.dateTo)}</>}</div>

            <div className={EntryStyle.options}>
                {
                    !!entry.info && <MyModal trigger={<FaInfoCircle/>}>
                        <>
                            {entry.info.split("\n").map(a => [a, <br/>])}
                        </>
                    </MyModal>
                }
                &nbsp;
                {
                    isEditable && <>
                        <ShiftEntryChangerModal trigger={<FaEdit/>} onFinish={(edited: CourseShiftPlannerShiftEntry) => onChange({...entry, ...edited})} data={entry}/>
                        &nbsp;
                        <div className={LocationStyle.inline + " " + LocationStyle.pointer} onClick={() => window.confirm("Löschen?") && onChange({...entry, deleted: !entry.deleted})}>{entry.deleted ? <FaTrashRestore/> : <FaTrashAlt/>}</div>
                        &nbsp;
                        <MyModal trigger={<FaUsers/>}>
                            <UserHistory personType={"course_shift_association"} userID={entry.ID}/>
                        </MyModal>
                    </>
                }
            </div>

        </div>

        <div className={EntryStyle.body}>
            <b className={EntryStyle.participantHeader}>Personen ({participants.length} / {entry.count})</b>
            {
                participants.map(participant => <li>
                    <LinkOrEmpty to={"/benutzer/liste/" + participant.child_ID}>{participant.child_ID === user.ID ? <b>{participant.fullname || "-"}</b> : participant.fullname || "-"}</LinkOrEmpty>&nbsp;
                    {adminMayAddRemove && <FaTimes className={EntryStyle.cancel} onClick={() => cancelAdmin(participant.child_ID)}/>}
                </li>)
            }
            {
                !isDirty && (
                    isEditable ?
                        <MyModal trigger={<MiniBtn className={EntryStyle.join}><FaUserPlus/> Person anmelden {participants.length >= (entry.count || 100000) && "(voll)"}</MiniBtn>}>
                            {close => <>
                                <Status type={"error"} text={error}/>
                                <UserSearchSelect
                                    selectButton={(child_ID: number) => <em onClick={() => {
                                        !loading && maxiPost("/shiftplanner/join/multiple/" + course_ID, {shift_ID: entry.ID, child_IDs: [child_ID]}, {setStatusVar}).then(() => {
                                            close()
                                            reload()
                                        })

                                    }}>
                                        anmelden
                                    </em>
                                    }
                                    usersHide={participants.map(p => p.child_ID)}
                                />
                            </>
                            }
                        </MyModal> :
                        (
                            participants.length < (entry.count || 100000) && !participants.some(p => p.child_ID === user.ID) && <div className={EntryStyle.joinContainer}><MiniBtn onClick={joinShift} className={EntryStyle.join}>
                                <FaUserPlus/> Für Schicht anmelden</MiniBtn>
                            </div>
                        )
                )
            }

        </div>
    </div>
}
