import React, {useEffect, useMemo, useState} from "react"
import deepEqual from "deep-equal"
import {FilterRender} from "react-table"

const replacements: Record<string, string> = {"plz": "postal_code"}

type IdValues = { id: string, value: any }[]

function onFilterChangeForUsers<T = any>(filterTexts: IdValues, old: Record<string, any>, loadData: (t: any) => any, include: string[] = [], update?: (data: T) => any) {
    const filterTextsAllowed = !include || include.length === 0 ? (filterTexts || []) : (filterTexts || []).filter(x => include.some(y => y === x.id))
    const filterTextsModified = filterTextsAllowed.reduce((obj, next) => ({...obj, [replacements[next.id] || next.id]: next.value}), {}) //JSON.stringify(filterTextsAllowed.reduce((obj, next) => ({...obj, [next.id]: next.value}), {}))
    if (!deepEqual(old, filterTextsModified)) {
        loadData && loadData(filterTextsModified)
    }
    return filterTextsModified
}

function defaultChangeForUsersFilterMethod<T = any>(setState: Function, loadData: Function) {
    return (a: IdValues, old: Record<string, any>, updateTableFilterMethod: (data: T) => any) => onFilterChangeForUsers<T>(a, old, (x: T) => setState({searchUser: x}, loadData), ["name", "email"], updateTableFilterMethod)
}

const TimeoutTextColumnFilter: FilterRender = ({...props}) => {
    const [isChanged, setIsChanged] = useState(false)
    const [data, setData] = useState<string>(props.filter?.value || "")
    const [changedData, setChangedData] = useState<string>(props.filter?.value || "")
    const [timer, setTimer] = useState<number | null>(null)

    useEffect(() => {
        if (!isChanged) {
            setIsChanged(true)
            return
        }

        if (timer) {
            clearTimeout(timer)
        }
        setChangedData(data)
        setTimer(window.setTimeout(() => {
            props.onChange && props.onChange(data.trim() === "" ? "" : data)
            setTimer(null)
        }, 500))
    }, [data])
    useMemo(() => {
        if(data !== props.filter?.value)
            setChangedData(props.filter?.value || "")
    }, [props.filter])

    const onChangeLocal = (e: any) => {
        e.preventDefault()
        const v = e.target?.value
        setData(!v || v.trim() === "" ? " " : v)
    }
    return <input value={changedData} onChange={onChangeLocal} style={{width: "100%"}}/>
}

export default onFilterChangeForUsers
export {defaultChangeForUsersFilterMethod, TimeoutTextColumnFilter}
