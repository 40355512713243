import React from 'react';
import "../dashboard.sass"
import Status from "../core/status";
import "../course/courses.sass"
import {Container, LightContainer, MaxLink} from "../core/components/components";
import {MyModal} from "../core/components/modal";
import {MyReactTable} from "../core/components/table";
import {FaCcAmex, FaCcMastercard, FaCcVisa, FaCreditCard, FaPlus} from "react-icons/fa";
import {Link} from "react-router-dom";
import {apiGet, apiPost} from "../core/api";
import {UserContext} from "../user/UserContext";
import {dateFormatTimeSeconds, dateFormatUnix} from "../core/dateFuncs";
import {PaymentReference} from "./paymentRef";
import {FaTrashAlt} from "react-icons/all";
import {maxiPost} from "../core/maxios";
import Consts from "../core/consts";

function DeleteMethod({ID, def, reload, type}) {
    console.log({reload})
    return <div style={{position: "absolute", marginLeft: 210, cursor: "pointer", marginTop: type !== "card" ? -20 : 0}} onClick={(e) => {
        e.stopPropagation()
        //if (def) {
        //    alert("Bitte zuerst eine andere Bezahlmethode hinzufügen")
        //} else {
        window.confirm("Wirklich löschen?") && maxiPost("/user/payments/delete_method", {ID}).then(reload || (() => null))
        //}
    }}>
        <FaTrashAlt/>
    </div>
}

class Zahlungsmethoden extends React.Component {
    static contextType = UserContext;

    constructor(props, context) {
        super(props);
        this.state = {
            open: 2,
            auth_checked: false,
            zahlungsmethoden: {},
            customer: {},
            zahlungsmethoden2: [
                {
                    ID: 1,
                    type: "credit_card",
                    card_type: "american express",
                    expiration: "2019/11",
                    ending: "007",
                },
                {
                    ID: 2,
                    type: "sepa",
                    bankname: "Erste Bank",
                    iban: "AT10275017501715",
                    owner: "Max Matschiner",
                }
            ]
        };
        this.apiGet = apiGet.bind(this);
        this.apiPost = apiPost.bind(this);
    }

    componentDidMount = () => {
        this.loadMethods()
    }
    loadMethods = () => this.apiGet("/user/payments/methods_list/" + this.props.userID, resp => {
        let defaultPM = resp.customer && resp.customer.invoice_settings !== undefined ? resp.customer.invoice_settings.default_payment_method : null;
        if (defaultPM === null) {
            defaultPM = resp.customer ? resp.customer.default_source : null;
        }
        if (resp.child?.paymentMethod <= 2) {
            defaultPM = resp.child.paymentMethod === 2 ? "sofort" : "bank"
        }
        if (resp.child?.paymentMethod === 5) {
            defaultPM = "sepm"
        }
        this.setState({...resp, defaultPM: defaultPM})
    })


    render() {
        let now = new Date();
        const {club, user} = this.context;

        const zahlungsmethoden = !!this.state.zahlungsmethoden.data ? this.state.zahlungsmethoden.data.concat(this.state.customer?.sources?.data || []) : this.state.customer.sources?.data || [];

        /*if (!this.state.auth_checked && !this.state.loading && this.context.user !== undefined && this.context.user.ID === this.props.userID) {
            window.setTimeout(() => {

            }, 100)
        }*/

        const {child} = this.state;
        if (!!child && child.ID !== child.verwalter_ID) {
            return null
        }
        return <LightContainer name={"Zahlungsmethoden"} visible class={"centercontent"}>
            <Status type="error" text={this.state.error}/>
            {
                this.context.user !== undefined && <CCAuthentication/>
            }

            Auf Feld klicken um Zahlungsmethode als Standard zu definieren.

            <br/>
            {
                zahlungsmethoden
                    .sort((a, b) => a.created - b.created).reverse()
                    .map(z => {
                        let expiryDate = z.type === "card" ? new Date(z.card.exp_year, z.card.exp_month, 1) : null;

                        return <div key={z.id} onClick={() => {
                            if (z.type !== 5) {
                                this.setState({defaultPM: z.id});
                            } else {
                                this.setState({defaultPM: "sepm"});
                            }
                            this.apiPost("/user/payments/make_default_stripe", {method_ID: z.id, kind: z.type, child_ID: this.props.userID}, resp => this.setState({...resp}))
                        }}>
                            {
                                z.type === "card" &&
                                <div key={z.id} className={"kachel small " + (now > expiryDate ? "inactive " : " ") + (z.id === this.state.defaultPM ? " standardmethod " : " ")} style={{padding: "15px 0"}}>
                                    <DeleteMethod ID={z.id} def={z.id === this.state.defaultPM} type={z.type} load={this.loadMethods}/>
                                    <CreditCard type={z.card.brand} className={"icon"}/>
                                    <br/>
                                    {prettify(z.card.brand)}<br/>
                                    <span className={"ccstars"}>****</span>{z.card.last4}, {z.card.exp_month}/{z.card.exp_year}<br/>
                                    <small>hinzu {dateFormatUnix(z.created)}</small>
                                </div>
                            }
                            {
                                z.type === "sepa_debit" &&
                                <div key={z.id} className={"kachel small" + (z.id === this.state.defaultPM ? " standardmethod " : " ")}>
                                    <DeleteMethod ID={z.id} def={z.id === this.state.defaultPM} type={z.type} reload={this.loadMethods}/>
                                    <span className={"sepa"}><b>SEPA</b> Lastschrift</span>
                                    <br/>
                                    {z.sepa_debit.country} <span className={"ccstars"}>****</span>{z.sepa_debit.last4}
                                    <br/>
                                    {/*<small><em><a target={"_blank"} onClick={e=>e.stopPropagation()} href={z.sepa_debit.mandate_url}>{z.sepa_debit.mandate_reference} anzeigen</a></em></small>
                                    <br/>*/}
                                    {

                                        (z.created<1722470400 || z.created>1722556800 /*migration*/)&& <small>hinzu {dateFormatUnix(z.created)}</small>

                                    }
                                </div>
                            }
                            {
                                z.type === 5 &&
                                <div key={z.ID} className={"kachel small" + ("sepm" === this.state.defaultPM ? " standardmethod " : " ")}>
                                    <span className={"sepa"}><b>SEPA</b> Lastschrift</span>
                                    <br/>
                                    {!!z.data?.bank?.name && <>{z.data.bank.name.slice(0, 20)}{z.data.bank.name.length > 20 && "..."}<br/></>}
                                    {z.data?.country} <span className={"ccstars"}>****</span>{z.data?.IBAN}
                                    <br/>
                                    <small>
                                        <MyModal trigger={<em><a>Mandat {z.reference}, anzeigen</a></em>}>
                                            <Container name={"SEPA Mandat"}>
                                                <MyReactTable
                                                    columns={[
                                                        {
                                                            Header: "Feld",
                                                            accessor: "0"
                                                        },
                                                        {
                                                            Header: "Wert",
                                                            accessor: "1",
                                                        }
                                                    ]}
                                                    data={[
                                                        ["Mandatsreferenz", z.reference],
                                                        ["Unterfertigt am", dateFormatTimeSeconds(z.timeOfSigning)],
                                                        ["Art", "Wiederholende SEPA Mandat"],
                                                        ["Name des Zahlungspflichtigen", z.data?.accountHolder],
                                                        ["IBAN des Zahlungspflichtigen ", (z.data?.country || "") + "*****" + z.data?.IBAN],
                                                        ["BIC des Zahlungspflichtigen", z.data?.bank?.bic],
                                                        ["Bank des Zahlungspflichtigen", z.data?.bank?.name],
                                                        ["Name des Zahlungsempfängers ", club.fullName],
                                                        ["Adresse des Zahlungsempfängers ", club.address],
                                                        ["IBAN des Zahlungsempfängers ", club.iban],
                                                        ["Bank des Zahlungsempfängers ", club.bankname],
                                                        ["Creditor ID des Zahlungsempfängers ", club.creditorID],
                                                    ]}
                                                />
                                            </Container>
                                        </MyModal>
                                    </small>
                                    <br/>
                                    <small>hinzu {dateFormatUnix(z.timeOfSigning)}</small>
                                </div>
                            }
                        </div>

                    })
            }
            {
                (this.context.user?.verein_ID !== Consts.CLUB_TV_TAUFKIRCHEN || this.state.child?.paymentMethod > 0) && ["sofort", "bank"].includes(this.state.defaultPM) && <div
                    key={"std"}
                    className={"kachel small " + (["sofort", "bank"].includes(this.state.defaultPM) ? " standardmethod " : " ")}
                    onClick={() => {
                        if (["sofort", "bank"].includes(this.state.defaultPM)) {
                            window.location.href = "/bezahlmethode/add/sofort"
                        }
                        if (this.state.defaultPM !== "sofort") {
                            this.setState({defaultPM: "bank"})
                            this.apiPost("/user/payments/make_default_stripe", {method_ID: null, kind: 1, child_ID: this.props.userID}, resp => this.setState({...resp}))
                        }
                    }
                    }>
                    {this.state.defaultPM === "sofort" && [<br/>]}
                    <span className={"pm_alt"}>{this.state.defaultPM === "sofort" ? "Sofortüberweisung" : "Banküberweisung"}</span>
                    <br/>
                    {
                        this.state.defaultPM === "sofort" ?
                            <>
                                <button className={"maxbtn mini"}><Link to={"/bezahlmethode/add/sofort"}>Tätigen</Link></button>
                                <br/>
                            </> :
                            <span>
                                Zahlungen an IBAN <br/>{club.iban}<br/>
                                Zahlungsreferenz: <br/>{PaymentReference(this.props.userID)}
                                <br/>
                            </span>
                    }
                    <small><em/></small>
                    <br/>
                    {["sofort", "bank"].includes(this.state.defaultPM) ? <small>Standard</small> : null}
                </div>
            }


            {
                this.context.user !== undefined && this.context.user.ID === this.props.userID ?
                    <div className={"kachel small add"}>
                        <Link to={"/bezahlmethode/add"}>
                            <FaPlus className={"icon"}/><br/>
                            Zahlungsmethode hinzufügen
                        </Link>
                    </div> : null
            }


            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            {
                this.context?.user?.role > 80 && <MaxLink to={"/bezahlmethode/add_for/" + this.props.userID}>Zahlungsmethode für diese Person hinzufügen</MaxLink>
            }

        </LightContainer>
    }
}


class CCAuthentication extends React.Component {
    constructor(props) {
        super(props);
        this.apiGet = apiGet.bind(this);
        this.state = {loading: true};
        this.apiGet("/user/payments/auth_required", resp => this.setState({...resp, auth_checked: true, loading: false}))
    }

    render() {
        return <div>
            {this.state.client_secret &&
                <Link to={"/abrechnung/authentication"}>Bitte berechtige uns erneut, deine {this.state.last_payment_error.payment_method.card.brand.toUpperCase()} (endend auf {this.state.last_payment_error.payment_method.card.last4}) zu belasten.
                    Klicke dazu hier!</Link>}
        </div>
    }
}

//<DefaultText id={z.id} defaultPM={defaultPM}/>
//const DefaultText = ({id, defaultPM}) => id === defaultPM ? <div className={"standard"}>Standard</div> : null;

function CreditCard(props) {
    switch (props.type) {
        case "mastercard":
            return <FaCcMastercard className={props.className}/>;
        case "visa":
            return <FaCcVisa className={props.className}/>;
        case "american express":
            return <FaCcAmex className={props.className}/>;
        default:
            return <FaCreditCard className={props.className}/>;
    }
}

const prettify = s => s.split("_").map(w => w[0].toUpperCase() + w.substr(1)).join(" ");

//
export default Zahlungsmethoden;
