import React, {useMemo, useState} from "react";
import {Loader, MaxBtn} from "../core/components/components";
import {FaFilePdf} from "react-icons/fa";
import {maxiGet} from "../core/maxios";
import {downloadAsPdf} from "../core/download";
import Status from "../core/status";

export default function CourseParticipationCertificate({course, participant}) {
    const [verified, setVerified] = useState(null)
    const [{loading, error}, setStatusVar] = useState({})

    useMemo(() => {
        setStatusVar({loading: true})
        maxiGet("/course/certificate/verify/" + course?.ID, {setStatusVar}).then(resp => setVerified(resp.verified))
    }, [])

    const download = () => {
        setStatusVar({loading: true})
        maxiGet("/course/certificate/download/" + participant?.ID, {setStatusVar}).then(resp => {
            downloadAsPdf(resp.content, "Zertifikat.pdf")
        })
    }

    return <div>
        <Loader loading={loading} />
        <Status type={"error"} text={error} />
        {
            verified ? <>
                <p>Lade dir hier dein Teilnahmezertifikat herunter.</p>
                <MaxBtn onClick={download}><FaFilePdf/> Herunterladen</MaxBtn>
            </> : (verified === null ? <></> : <b>Es kann leider kein Zertifikat heruntergeladen werden.</b>)
        }
    </div>

}
