import axios from "axios";
import Consts from "./consts";

type AnyVoidFunc = (arg: any) => {};

/*
// legacy:
interface Maxios {
    url: string,
    data?: any,
    noApi?: boolean,
    noErrorSet?: boolean,
    success: AnyVoidFunc,
    setError?: AnyVoidFunc,
}
*/

interface MaxiosOptions {
    noApi?: boolean,
    noErrorSet?: boolean,
    setError?: AnyVoidFunc,
    setStatusVar?: any,
    responseType?: "json" | "arraybuffer" | "blob" | "document" | "text" | "stream" | undefined
}

const handleErrorTemplate = (setStatusVar: any, setError: any, noErrorSet: boolean, reject: any) => {
    return (error: any) => {
        let message = error.toString();
        if (typeof error !== "string" && !error.response) {
            message = "Es besteht leider keine Internet-Verbindung."
        }else if (typeof error !== "string" && error?.response?.status) {
            message = "Leider ist derzeit keine Verbindung zu VEREINfacht möglich (" + error.response?.statusText + ")."
        }
        setStatusVar && setStatusVar({
            error: message,
            loading: false,
        })
        if (!noErrorSet && setError !== undefined) {
            setError(message)
        }
        reject(message)
    }
}

export async function maxiGet<T = any>(url: string, options: MaxiosOptions = {}): Promise<T> {
    const {noApi, noErrorSet, setError, setStatusVar, responseType} = options;
    setStatusVar && setStatusVar({loading: true})

    return new Promise<T>((resolve, reject) => {
        const handleError = handleErrorTemplate(setStatusVar, setError, !!noErrorSet, reject);
        axios.get((!noApi ? Consts.API_PREFIX : "") + url, {
            headers: {
                'Content-Type': 'application/json',
            },
            responseType: responseType,
        })
            .then(response => {

                if (response.data.status === "error") {
                    handleError(response.data.message)
                } else {
                    setStatusVar && setStatusVar((old: any) => ({
                        ...old,
                        ...(noErrorSet ? {} : {error: ""}),
                        loading: false,
                    }));
                    //success(response.data)
                    resolve(response.data)
                }

            })
            .catch(handleError);
    })
}

export async function maxiPost<T = any>(url: string, data: any, options: MaxiosOptions = {}): Promise<T> {
    const {noApi, noErrorSet, setError, setStatusVar} = options;
    setStatusVar && setStatusVar({loading: true})

    return new Promise<T>((resolve, reject) => {
        const handleError = handleErrorTemplate(setStatusVar, setError, !!noErrorSet, reject);
        axios.post((!noApi ? Consts.API_PREFIX : "") + url, data, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(response => {

                if (response.data.status === "error") {
                    handleError(response.data.message)
                } else {
                    setStatusVar && setStatusVar((old: any) => ({
                        ...old,
                        ...(noErrorSet ? {} : {error: ""}),
                        loading: false,
                    }));
                    resolve(response.data)
                }

            })
            .catch(handleError);
    })
}



