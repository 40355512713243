import React, {useState} from "react"
import deepEqual from "deep-equal"
import {Container, MaxBtn} from "../components"
import {MySortableList} from "../sortable"
import {FaMinus, FaPlus} from "react-icons/fa"
import {ColumnType} from "../table"

interface ColumnSelectorProps<T> {
    columns: ColumnType<T>[]
    onChange: Function
    resetColumns: ColumnType<T>[]
    onClose: Function
}

export function ColumnSelector<T>({columns, onChange, resetColumns, onClose}: ColumnSelectorProps<T>) {
    const [columnsChanged, setColumns] = useState(columns.filter(x => x.show !== false))

    const updateColumn = (index: number) => setColumns(a => {
        a[index].defaultHidden = !a[index]?.defaultHidden
        onChange(a)
        return a
    })

    const toggleAll = (hide: boolean) => {
        setColumns(columns => {
            const newCols = columns.map(c => ({...c, defaultHidden: hide}))
            onChange(newCols)
            return newCols
        })
    }

    const updateColumns = (columnsLocal: ColumnType<T>[]) => {
        if (!deepEqual(columnsLocal, columnsChanged)) {
            setColumns(columnsLocal)
            onChange(columnsLocal)
        }
    }
    const reset = () => {
        setColumns(resetColumns)
        onChange(resetColumns)
        onClose()
    }

    return <Container name={"Zeige / verstecke Spalten"}>
        <p>Bitte wähle die Spalten aus, die du anzeigen bzw. verstecken möchtest. Du kannst auch mittels Drag &amp; Drop die Spaltenreihenfolge ändern.</p>
        <MySortableList ulId={"myReactTableColumnHideShow"} list={columnsChanged.map((c, index) => ({...c, id: c.id || (typeof c.accessor !== "function" ? ""+c.accessor : (""+index))}))} setList={updateColumns} ulStyle={{}}>
            {
                columnsChanged.filter(col => col.show !== false).map((col, index) => <div key={col.id || (""+col.accessor)} style={{display: "inline-block"}}>
                    <input type="checkbox" onClick={() => updateColumn(index)} checked={!col.defaultHidden}/> <span><>{col.Header || col.accessor || "nicht zugeordnet"}</></span>
                </div>)
            }
        </MySortableList>
        <MaxBtn onClick={reset}>Zurücksetzen</MaxBtn>
        <MaxBtn onClick={() => toggleAll(false)}><FaPlus/> Alle Spalten anzeigen</MaxBtn>
        <MaxBtn onClick={() => toggleAll(true)}><FaMinus/> Alle Spalten verstecken</MaxBtn>
    </Container>
}
