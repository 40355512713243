import React from 'react';
import "../dashboard.sass"
import Consts from "../core/consts";
import Status from "../core/status";
import "./courses.sass"
import {apiGet} from "../core/api";
import {Container, MaxBtn, TopButtonDiv} from "../core/components/components";
import {MyModal} from "../core/components/modal";
import {MyTabs} from "../core/components/tabs";
import {TextfieldInput} from "../core/input/basic";
import {UserContext} from "../user/UserContext";
import "../style/modal.sass"
import ViewCoursesFor from "./courses_for";
import {checkPermissions} from "../club/permissionsHelpers";
import {FaPlus} from "react-icons/fa";

class CoursesAvailable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: 2,

        };
        this.apiGet = apiGet.bind(this);

        this.apiGet("/user/subusers?group=only_mine", resp => {
            this.setState({"users": resp.users})
        });
    }

    componentDidMount() {
        console.log("courses avail mounted")
    }

    componentWillUnmount() {
        console.log("courses avail unmounted")
    }

    static contextType = UserContext;

    render() {
        const {user} = this.context;
        const fullListViewer = user?.role === 11 || checkPermissions(this.context, "course_read") || checkPermissions(this.context, "course/participants_read"); // add in python as well
        return (
            <Container visible broad>
                <h2>Angebot</h2>
                {
                    (checkPermissions(this.context, "course") || checkPermissions(this.context, "course/data")) &&
                    <TopButtonDiv>
                        <MyModal trigger={<button className={"maxbtn"}><FaPlus/> Neues Hinzufügen</button>} modal>
                            <div>
                                <form action={Consts.API_PREFIX + "/course/addnew"} method={"POST"}>
                                    <h2>Neuen Kurs hinzufügen</h2>
                                    <TextfieldInput state={this.state} tag={"name"} name={"Name"}/>
                                    <br/>
                                    <MaxBtn>Erstellen</MaxBtn>
                                </form>
                            </div>
                        </MyModal>
                    </TopButtonDiv>
                }

                <Status type="error" text={this.state.error}/>
                <Status type="success" text={this.state.status}/>

                {
                    !fullListViewer &&
                    <>
                        {
                            this.state.users !== undefined && Object.keys(this.state.users).length > 1 && <>
                                <b>Buche Kurse / Einheiten für</b>: &nbsp;
                                <MyTabs
                                    noDefaultTab
                                    noDefaultTabMessage={"Bitte zuerst Benutzer mit Klick auf Name auswählen."}
                                    tabs={Object.values(this.state.users)
                                    .sort((aVal, bVal) => {
                                        return (aVal.ID === aVal.verwalter_ID ? 0 : aVal.birthdate) < (bVal.ID === aVal.verwalter_ID ? 0 : bVal.birthdate) ? -1 : 1
                                    })
                                    .map(user => ({
                                        name: user.prename,
                                        content: <div>
                                            <ViewCoursesFor linkPrefix={"/buche"}{...this.props} id={user.ID}/>
                                        </div>
                                    }))}/>
                            </>
                        }
                        {
                            this.state.users !== undefined && Object.keys(this.state.users).length === 1 && <div>
                                <ViewCoursesFor {...this.props} id={this.state.users[Object.keys(this.state.users)[0]].ID}/>
                            </div>
                        }
                    </>
                }

                {
                    fullListViewer &&
                    <div>
                        <ViewCoursesFor linkPrefix={"/buche"} {...this.props} fullListViewer={fullListViewer} id={"all"}/>
                    </div>
                }

            </Container>
        )
    }
}


export default CoursesAvailable;
