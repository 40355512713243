import React from "react"

function dateFormatUnix(date) {
    if (date === 0 || date === undefined) {
        return ""
    }
    let d = new Date(date * 1000);

    return d.toLocaleString('de-AT', {
        timeZone: 'Europe/Vienna',
        year: 'numeric',
        month: 'short',
        day: '2-digit',
    });
}

function dateFormatUnixSmall(date) {
    if (!date) {
        return ""
    }
    let d = new Date(date * 1000);
    //return date_string
    return d.toLocaleString('de-AT', {
        timeZone: 'Europe/Vienna',
        year: '2-digit',
        month: 'short',
        day: '2-digit',
    }).split(".").join("");
}

export function dateFormatUnixNumeric(date) {
    if (date === 0) {
        return ""
    }
    let d = new Date(date * 1000);
    //return date_string
    //return d.toLocaleDateString("en-US")
    return d.toLocaleString('de-AT', {
        timeZone: 'Europe/Vienna',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    });
}

function dateFormatTime(inp, fullyear = false) {
    if (!!inp) {
        try {
            return new Date(inp < 1000000000000 ? inp * 1000 : inp).toLocaleString('de-AT', {
                timeZone: 'Europe/Vienna',
                year: fullyear ? "long" : 'numeric',
                month: 'short',
                day: '2-digit',
                hour: 'numeric',
                minute: "2-digit",
                weekday: "short"
            });
        } catch (e) {
            return "ungültiges Format"
        }
    }
    return "unbekannt"
}

export function dateFormatTimeIfNotMidnight(inp, fullyear = false) {
    const d = new Date(inp < 1000000000000 ? inp * 1000 : inp)
    if (d.getHours() * 100 + d.getMinutes() != 0) {
        return dateFormatTime(inp, fullyear)
    } else {
        return dateFormatUnix(inp)
    }
}

export function dateFormatTimeSeconds(inp) {
    if (inp) {
        return new Date(inp < 1000000000000 ? inp * 1000 : inp)?.toLocaleString('de-AT', {
            timeZone: 'Europe/Vienna',
            year: 'numeric',
            month: 'short',
            day: '2-digit',
            hour: 'numeric',
            minute: "2-digit",
            second: "2-digit",
            weekday: "short"
        });
    }
    return "unbekannt"
}

export function dateFormatTimeSeconds2Digit(inp) {
    if (!inp) {
        return "-"
    }
    try {
        return new Date(typeof inp === "string" ? new Date(inp) : typeof inp === "number" ? new Date(inp * 1000) : inp)
            ?.toLocaleString('de-AT', {
                timeZone: 'Europe/Vienna',
                year: '2-digit',
                month: '2-digit',
                day: '2-digit',
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
            })
    } catch (e) {
        return "ungültiges Format"
    }
}

function dateFormatTimeTime(inp, fullYear = false) {
    if (inp !== 0) {
        return new Date(inp * 1000)
            ?.toLocaleString('de-AT', {
                timeZone: 'Europe/Vienna',
                hour: 'numeric',
                minute: "2-digit",
            })
    }
    return "unbekannt"
}

function dateFormatTimeShort(inp, fullYear = false) {
    if (inp !== 0) {
        return new Date(inp * 1000)
            ?.toLocaleString('de-AT', {
                timeZone: 'Europe/Vienna',
                year: fullYear ? 'numeric' : '2-digit',
                month: 'short',
                day: '2-digit',
                hour: 'numeric',
                minute: "2-digit",
            })
    }
    return "unbekannt"
}

function timeFormat(inp) {
    if (inp !== 0) {
        return new Date(inp * 1000).toLocaleString('de-AT', {
            timeZone: 'Europe/Vienna',
            hour: 'numeric',
            minute: "2-digit",
        });
    }
    return "unbekannt"
}

function dateFormat(date) {
    if (date === 0) {
        return ""
    }
    date = `${date}`;
    let date_string = `${date.substring(0, 4)}-${date.substring(4, 6)}-${date.substring(6, 8)}`;
    let d = new Date(date_string);
    //return date_string
    if (isNaN(d.getTime())) {
        return ""
    }
    return d.toLocaleString('de-AT', {
        timezone: "Europe/Vienna",
        year: 'numeric',
        month: 'short',
        day: '2-digit',
    });
}

export function dateFormatDate(date) {
    if (!date) {
        return ""
    }
    try {
        const d = date instanceof Date || typeof date === "number" ? date : new Date(date)
        return d.toLocaleString('de-AT', {
            timezone: "Europe/Vienna",
            year: 'numeric',
            month: 'short',
            day: '2-digit',
        });
    } catch (e) {
        return "-"
    }
}

const monthNames = [
    "Jan.", "Feb.", "Mär.", "Apr.", "Mai", "Jun.", "Jul.", "Aug.", "Sep.", "Okt.", "Nov.", "Dez."
]

function dateFormatUnixInline(date) {
    if (date === 0) {
        return ""
    }
    let d = new Date(date * 1000);
    return dateFormatDateInline(d)
}

const dateFormatDateInline = d => <span className={"dateFormat"}>
        <span>{String("0" + d.getDate()).slice(-2)}.</span>&nbsp;
    <span>{monthNames[d.getMonth()]}</span>&nbsp;
    {d.getFullYear()}
    </span>;

function dateFormatBirthday(date) {
    if (date === 0) {
        return ""
    }
    date = `${date}`;
    let date_string = `${date.substring(0, 4)}-${date.substring(4, 6)}-${date.substring(6, 8)}`;
    let d = new Date(date_string);
    return dateFormatDate(d)
}

function dateFormatInline(date) {
    if (date === 0) {
        return ""
    }
    date = `${date}`;
    let date_string = `${date.substring(0, 4)}-${date.substring(4, 6)}-${date.substring(6, 8)}`;
    let d = new Date(date_string);
    return dateFormatDateInline(d)
}

function dateFormatFromYMD(date) {
    if (date === 0) {
        return ""
    }
    date = `${date}`;
    let date_string = `${date.substring(0, 4)}-${date.substring(4, 6)}-${date.substring(6, 8)}`;
    let d = new Date(date_string);
    //return date_string
    return dateFormatDate(d)
}

//741

export {dateFormatTimeTime, dateFormatUnix, dateFormat, dateFormatInline, dateFormatTime, timeFormat, dateFormatUnixSmall, dateFormatUnixInline, dateFormatTimeShort, dateFormatDateInline, dateFormatFromYMD, dateFormatBirthday}
