import {Link} from "react-router-dom";
import {dateFormatTime, dateFormatTimeIfNotMidnight, dateFormatUnix} from "../core/dateFuncs";
import React, {useContext} from "react";
import Consts from "../core/consts";
import {UserContext} from "../user/UserContext";
import {Container} from "../core/components/components";
import {MyModal} from "../core/components/modal";
import {EventsPrint} from "./course_helpers";

const MyA = ({href, children}) => <a target={"_blank"} href={href}>{children}</a>;

function TrainerPrint({club, trainers, LinkContainer, linkPrefix}) {
    const l = trainers.length
    return trainers.map((a, i) => {
        const trainer = a.split("|");
        return [[0].includes(club?.verein_ID) ? <LinkContainer to={"/profil/" + trainer[0]}
                                                               href={(linkPrefix || "") + "/profil/" + trainer[0]}>{trainer[1]}</LinkContainer> : trainer[1], i < l - 1 ? ", " : null]
    })
}

const textAreaPrint = (inp) => !!inp ? inp.split("\n").map(l => [l, <br/>]) : "";


function CourseInfo({
                        course: c,
                        short = false,
                        tags,
                        filterTagIDs,
                        setTagFiltering,
                        child_ID,
                        linkPrefix,
                        externalLink,
                        withinSnippet,
                        existingMember
                    }) {
    const now = new Date().getTime() / 1000;
    const user = useContext(UserContext);

    c.bookingStart = (existingMember || !c.allBookingStart || c.allBookingStart === 0) ? c.bookingStart : c.allBookingStart

    const onlyOneDate = Math.round(c.startdate / 86400) === Math.round(c.enddate / 86400);

    const LinkContainer = externalLink !== undefined || !user?.user?.ID ? MyA : Link;
    const LinkToRegister = ({children}) => <LinkContainer href={`${linkPrefix || ""}/registrieren/${c.ID}`} to={{
        pathname: `/kurs${(linkPrefix || "") === "" ? "e" : ""}${linkPrefix || ""}/${c.ID}`,
        state: {preferredChild: child_ID}
    }}>
        {children}
    </LinkContainer>
    return <div className={"courselist"} key={c.ID}>
        <LinkToRegister>
            <div className={"righticon"}/>
        </LinkToRegister>
        <h2>
            <LinkContainer to={`/kurse/${c.ID}`}
                           href={`${linkPrefix || ""}/registrieren/${c.ID}`}>{c.name}</LinkContainer>
            {
                (c.parts_count - c.maxparts >= 0 && (c.participant_role === undefined || c.participant_role === 2)) && c.bookingStart !== c.bookingEnd &&
                <span> - Warteliste</span>
            }
        </h2>
        {
            !!c.startdate && (user?.config?.showDateSpan ?? true) && !onlyOneDate && <>von {dateFormatUnix(c.startdate)} bis {dateFormatUnix(c.enddate)}, </>
        }
        {
            !!c.startdate && onlyOneDate && <>am {dateFormatUnix(c.startdate)}, </>
        }

        {
            c.tags !== "1" && !!c.startdate && <>
                <b><EventsPrint events={(c.eventsList || "").split(",")} onlyOneDate={onlyOneDate}/></b>
                {/*c.eventsList.split(",").sort((a, b) => a < b ? -1 : 1).map(a => <EventsPrint onlyOneDate={onlyOneDate} event={a}/>)*/}
                {c.nextevent > 0 && !onlyOneDate && <>, nächstes Mal am <b>{dateFormatTime(c.nextevent)} Uhr</b></>}
                {(user?.config?.showDateSpan ?? true) ? <><br/>Ort: </> : ", "}
                <b>{c.eventsLocation}</b>
                <br/>
            </>
        }

        {
            !short && <>
                {
                    (user?.config?.showAgeRestrictions ?? true) && (!c.audience || c.audience === "") && (c.youngerthan < 100 || c.olderthan > 0 || c.yearAllowedLeft || c.yearAllowedRight) &&
                    <>
                        {
                            !!c.olderthan &&
                            <>
                                {c.olderthan < 2 && c.olderthan !== 1 && <>ab <b>{Math.round(c.olderthan * 12)} Monaten</b></>}
                                {c.olderthan >= 2 && c.olderthan !== 1 && <>ab <b>{Math.round(c.olderthan)} Jahren</b></>}
                                {c.olderthan === 1 && <>von <b>einem Jahr</b></>}
                            </>
                        }
                        {
                            !!c.youngerthan && 0 < c.youngerthan && c.youngerthan < 100 &&
                            <>
                                {c.youngerthan < 2 && <> bis <b>{Math.round(c.youngerthan * 12)} Monate</b></>}
                                {c.youngerthan >= 2 && <> bis <b>{c.youngerthan} Jahre</b></>}
                            </>
                        }
                        {(c.yearAllowedLeft || c.yearAllowedRight) && "für Jahrgänge"}
                        {c.yearAllowedLeft && " ab inklusive " + c.yearAllowedLeft}
                        {(c.yearAllowedLeft && c.yearAllowedRight) && " und "}
                        {c.yearAllowedRight && " bis inklusive " + c.yearAllowedRight}
                        <br/>
                    </>
                }

                {(user?.config?.showCosts ?? true) && !user.club?.noMembership && <>Kosten: <b>{Consts.moneyMaxCalc({s: c.price / 100, club: user?.club, addTax: true, user: user?.user})} für
                    Mitglieder
                    / {c.membersOnly ? "Mitgliedschaft erforderlich" : Consts.moneyMaxCalc({s: c.pricenm / 100, club: user?.club, addTax: true, user: user?.user}) + " für Nichtmitglieder"}</b>
                    <br/></>}
                {user.club?.noMembership && <>Kosten: <b>{Consts.moneyMaxCalc({s: c.pricenm / 100, club: user?.club, addTax: true, user: user?.user})}</b> <br/></>}

                {/*c.bookingEnd > 0 && <>Buchbar {c.bookingStart > 0 && <>von {dateFormatUnix(c.bookingStart)}</>} bis {dateFormatUnix(c.bookingEnd)}<br/></>*/}
            </>
        }

        {
            !!c.audience && (user?.config?.showAudience ?? true) &&
            <span>Zielgruppe: <b>{c.audience}</b><br/></span>
        }
        {
            !!c.trainers && (user?.config?.showTrainers ?? true) && <span>Leitung: <b>{c.trainers ?
                <TrainerPrint club={user.club} trainers={c.trainers.split(",")} LinkContainer={LinkContainer}
                              linkPrefix={linkPrefix}/> : "Trainerteam"}</b><br/></span>
        }
        {
            !short && (user?.config?.showBookingStart ?? true) && !(c.bookingStart < now && c.bookingEnd > now) && c.bookingStart !== c.bookingEnd &&
            <>
                <b>{c.bookingStart > now ? "buchbar ab " + dateFormatTimeIfNotMidnight(c.bookingStart) : "nicht mehr buchbar"}</b><br/></>
        }
        {
            !short && (user?.config?.showBookingEnd ?? true) && c.bookingStart < now && c.bookingEnd > now && c.bookingStart !== c.bookingEnd &&
            <><b>Anmeldeschluss: {dateFormatTimeIfNotMidnight(c.bookingEnd)}</b><br/></>
        }
        {
            !short && (user?.config?.showBookingEnd ?? true) && !existingMember && (c.allBookingStart || 0) >= (c.bookingEnd || 1) &&
            <><b>Dieser Kurs ist nur für bestehende Mitglieder buchbar.</b><br/></>
        }

        {
            (!(user.club?.hideCapacity ?? true)) && <>
                {
                    c.parts_count < c.maxparts && (c.parts_count >= c.maxparts * 0.8 || user.club?.verein_ID === 9) && <>
                        noch {c.maxparts - c.parts_count > 1 ? <b>{c.maxparts - c.parts_count} Plätze verfügbar</b> : <b>noch ein Platz verfügbar</b>}<br/>
                    </>
                }
                {
                    (c.parts_count - c.maxparts >= 0 && (c.participant_role === undefined || c.participant_role === 2)) && c.bookingStart !== c.bookingEnd && <>
                        Aktuell keine Plätze verfügbar – kostenfreie Anmeldung auf Warteliste möglich! Automatische Nachrückung, sobald ein Platz frei wird.<br/>
                    </>
                }
            </>
        }
        {
            withinSnippet && <>
                <br/>
                <MyModal trigger={<em style={{marginLeft: "-4px"}}><a href={"#"}><b>Mehr Informationen</b></a></em>}>
                    <Container name={c.name}>
                        <p>{textAreaPrint(c.description)}</p>


                        {
                            !!c.startdate && onlyOneDate && <>am {dateFormatUnix(c.startdate)}, </>
                        }
                        {
                            c.tags !== "1" && !!c.startdate && <>
                                <b><EventsPrint events={(c.eventsList || "").split(",")} onlyOneDate={onlyOneDate}/></b>
                                {
                                    !onlyOneDate && <>&nbsp;von {dateFormatUnix(c.startdate)} bis {dateFormatUnix(c.enddate)}, </>
                                }
                                {c.nextevent > 0 && <>, nächstes Mal am <b>{dateFormatTime(c.nextevent)} Uhr</b></>}
                                {!!c.nextlocation && <><br/>Ort: <b>{c.nextlocation}</b></>}
                            </>
                        }
                        <br/>


                        Ort: <b>{c.eventsLocation}</b><br/>


                        {(!c.audience || c.audience === "") && c.youngerthan < 100 && c.olderthan > 0 &&
                            <>
                                {
                                    !!c.olderthan &&
                                    <>
                                        {c.olderthan < 2 && c.olderthan !== 1 && <>ab <b>{Math.round(c.olderthan * 12)} Monaten</b></>}
                                        {c.olderthan > 2 && c.olderthan !== 1 && <>ab <b>{Math.round(c.olderthan)} Jahren</b></>}
                                        {c.olderthan === 1 && <>von <b>einem Jahr</b></>}
                                    </>
                                }
                                {
                                    !!c.youngerthan && 0 < c.youngerthan && c.youngerthan < 100 &&
                                    <>
                                        {c.youngerthan < 2 && <> bis <b>{Math.round(c.youngerthan * 12)} Monate</b></>}
                                        {c.youngerthan >= 2 && <> bis <b>{c.youngerthan} Jahre</b></>}
                                    </>
                                }
                                {(c.yearAllowedLeft || c.yearAllowedRight) && "für Jahrgänge"}
                                {c.yearAllowedLeft && " ab inklusive " + c.yearAllowedLeft}
                                {(c.yearAllowedLeft && c.yearAllowedRight) && " und "}
                                {c.yearAllowedRight && " bis inklusive " + c.yearAllowedRight}
                                <br/>
                            </>
                        }
                        Kosten: <b>{Consts.moneyMaxCalc({s: c.price / 100, club: user?.club, addTax: true, user: user?.user})} {
                        user.club?.noMembership ? "" : <>für Mitglieder
                            / {c.membersOnly ? "Mitgliedschaft erforderlich" : Consts.moneyMaxCalc({s: c.pricenm / 100, club: user?.club, addTax: true, time: 0, user: user?.user}) + " für Nichtmitglieder"}</>
                    }</b> <br/>

                        {/*c.bookingEnd > 0 && <>Buchbar {c.bookingStart > 0 && <>von {dateFormatUnix(c.bookingStart)}</>} bis {dateFormatUnix(c.bookingEnd)}<br/></>*/}


                        {!!c.audience && <span>Zielgruppe: <b>{c.audience}</b><br/></span>}
                        {
                            !!c.trainers && <span>Leitung: <b>{c.trainers ?
                                <TrainerPrint club={user.club} trainers={c.trainers.split(",")} LinkContainer={LinkContainer}
                                              linkPrefix={linkPrefix}/> : "Trainerteam"}</b><br/></span>
                        }

                        {!short && !(c.bookingStart < now && c.bookingEnd > now) && c.bookingStart !== c.bookingEnd && <>
                            <b>{c.bookingStart > now ? "buchbar ab " + dateFormatTimeIfNotMidnight(c.bookingStart) : "nicht mehr buchbar"}</b><br/></>}
                        {!short && c.bookingStart < now && c.bookingEnd > now && c.bookingStart !== c.bookingEnd && <>
                            <b>Anmeldeschluss: {dateFormatTimeIfNotMidnight(c.bookingEnd)}</b><br/></>}

                        {
                            (!(user.club?.hideCapacity ?? true)) && <>
                                {
                                    c.parts_count < c.maxparts && (c.parts_count >= c.maxparts * 0.8 || user.club?.verein_ID === 9) && <>
                                        noch {c.maxparts - c.parts_count > 1 ? <b>{c.maxparts - c.parts_count} Plätze verfügbar</b> : <b>noch ein Platz verfügbar</b>}<br/>
                                    </>
                                }
                                {
                                    (c.parts_count - c.maxparts >= 0 && (c.participant_role === undefined || c.participant_role === 2)) && c.bookingStart !== c.bookingEnd && <>
                                        Aktuell keine Plätze verfügbar – kostenfreie Anmeldung auf Warteliste möglich! Automatische Nachrückung, sobald ein Platz frei wird.<br/>
                                    </>
                                }
                            </>
                        }

                        {!!c.information &&
                            <>
                                <b>Informationen: </b>
                                <p>{textAreaPrint(c.information)}</p>
                            </>
                        }
                        {
                            tags !== undefined && c.tags !== undefined &&
                            c.tags
                                .split(",")
                                .filter(t => t !== "" && tags[t])
                                .map(t => <b
                                    className={"courseTag " + (filterTagIDs.indexOf(t) > -1 ? "active" : "inactive")}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setTagFiltering(t)
                                    }}
                                >
                                    {tags[t]?.name}
                                </b>)
                        }

                    </Container>
                </MyModal>
                &nbsp;&nbsp;&nbsp;
                <LinkToRegister>
                    <em><b>Anmelden</b></em>
                </LinkToRegister>
                <br/>
            </>
        }

        {
            (user?.config?.showTags ?? true) && tags !== undefined && c.tags !== undefined &&
            c.tags
                .split(",")
                .filter(t => t !== "" && tags[t])
                .sort((a, b) => tags[a]?.name < tags[b]?.name ? -1 : 1)
                .map(t => <b
                    className={"courseTag " + (filterTagIDs.indexOf(t) > -1 ? "active" : "inactive")}
                    onClick={(e) => {
                        e.preventDefault();
                        setTagFiltering(t)
                    }}
                >
                    {tags[t]?.name}
                </b>)
        }


        {/*{c.parts_count} / {c.maxparts} Teilnehmer
            {
                c.queue_count > 0 &&
                <span>({c.queue_count} auf Warteliste)</span>
            }<br/>*/}
        {/*<span style={{display: "block"}} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(c.description)}}/>*/}
        {/*<Link className={"btn btn-primary"} to={`/kurse/${c.ID}`}>
                anschauen
            </Link>*/}
    </div>
}


export default CourseInfo
