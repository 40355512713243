import React from 'react';
import "../dashboard.sass"
import {Container, MiniBtn} from "../core/components/components";
import {MyReactTable} from "../core/components/table";
import {CheckboxInput, InputContainer} from "../core/input/basic";
import Status from "../core/status";
import {dateFormat, dateFormatInline} from "../core/dateFuncs"
import {apiGet, apiPost} from "../core/api";


import {FormContext, FormContextWrapper} from "../core/form_context";
import {Link} from "react-router-dom";
import {downloadAsExcel} from "../core/download";
import {UserContext} from "./UserContext";
import {encodeGetParams, objGetEntries} from "../core/helpers";
import {FaStickyNote} from "react-icons/all";
import {FaList} from "react-icons/fa";
import onFilterChangeForUsers, {TimeoutTextColumnFilter} from "./onFilterChangeForUsers";
import {DateInputUnix} from "../core/input/date";
import myDeepQual from "../core/myDeepEqual";


export default class BirthdaysList extends React.Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            user_ids: [],
            search: {
                memberSince: false,
                dateStart: (new Date()).getTime() / 1000,
                dateEnd: (new Date()).getTime() / 1000 + 86400 * 30,
            },
            tableData: [],
            showPagination: true
        };

        this.tableRef = React.createRef();
        this.apiGet = apiGet.bind(this);
        this.apiPost = apiPost.bind(this);
        this.setState = this.setState.bind(this);
        this.loadData({...this.state.search, forceInstant: true});
    }

    loadData = (search = this.state.search) => {
        const {memberSince = false, dateStart, dateEnd} = search
        if (!dateStart && !dateEnd) {
            return
        }

        this.setState({loading: true})
        this.apiGet("/user/jubilare?" + encodeGetParams({mode: memberSince ? "memberSince" : "birthday", ...search, dateStart, dateEnd}), (resp) => {
            const tableData = Object.keys(resp.users).map(id => ({
                ...resp.users[id],
                name: resp.users[id].lastname.trim() + ", " + resp.users[id].prename,
                verwalter: resp.users[id].verwalter_ID === resp.users[id].ID ? "ja" : "nein",
                birthdateString: dateFormat(resp.users[id].birthdate)
            }));
            this.setState({
                loading: false, ...resp.users,
                user_ids: Object.keys(resp.users),
                tableSize: Object.keys(resp.users).length,
                tableData: tableData,
                tableDataRaw: tableData,
                tableIDsFiltered: tableData.map(u => u.ID),
            });


            if (Object.keys(resp.users).length < 6) {
                this.setState({open: Object.keys(resp.users).map(id => id * 1)})
            }
        });
    };
    isEditMode = () => (this.props.location.pathname || "").substr(0, 14) === "/benutzer/profil";
    isListMode = () => (this.props.location.pathname || "").substr(0, 15) === "/benutzer/liste";
    isSelectMode = () => false;


    /*handleChange = (e) => {
        const s = e.target.name.split("_");
        if (s[1] === "memberSince") {
            this.loadData(e.target.checked)
            this.setState({
                loading: true,
                tableData: [],
                [s[0]]: {...this.state[s[0]], [s[1]]: e.target.type === 'checkbox' ? e.target.checked : e.target.value},
            })
        } else {
            const boundLower = s[1] === "olderthan" ? parseInt(e.target.value) || 0 : this.state.search.olderthan || 0;
            const boundUpper = s[1] === "youngerthan" ? parseInt(e.target.value) || 150 : this.state.search.youngerthan || 150;

            const filtered = this.state.tableDataRaw.filter(u => boundLower <= u.age && u.age <= boundUpper);
            this.setState({
                [s[0]]: Object.assign(
                    {},
                    this.state[s[0]],
                    {[s[1]]: e.target.type === 'checkbox' ? e.target.checked : e.target.value}
                ),
                tableData: filtered,
                tableSize: filtered.length,
                tableIDsFiltered: [],
            });
        }


    };
*/
    render() {
        const {user, club} = this.context;
        const {memberSince} = this.state.search;

        return <>
            <Status type="error" text={this.state.error}/>


            <Container name={"Liste aller Jubilare"} visible broad class={"mobileBroad"}>
                {
                    user?.role > 10 &&
                    <>
                        <FormContextWrapper value={{state: this.state, setState: this.setState}} afterUpdate={(a, b, c) => {
                            if (c) {
                                this.loadData(c)
                            }
                        }}>
                            <InputContainer>
                                <CheckboxInput name={"nach Beitrittsdatum"} tag={"search_memberSince"}/> &nbsp; &nbsp;
                            </InputContainer><br/>
                            <InputContainer>
                                <DateInputUnix name={"von"} tag={"search_dateStart"} afterUpdate={(a, b, c) => this.loadData(c)}/>
                            </InputContainer>
                            <InputContainer>
                                <DateInputUnix name={"bis"} tag={"search_dateEnd"} afterUpdate={(a, b, c) => this.loadData(c)}/>
                            </InputContainer>
                        </FormContextWrapper>
                    </>
                }


                <MyReactTable
                    data={this.state.tableData}
                    columns={[

                        {
                            Header: "Name",
                            filterable: true,
                            accessor: "name",
                            Cell: row => <Link to={"/benutzer/profil/" + row.original.ID}>{row.value}</Link>,
                            Filter: TimeoutTextColumnFilter,
                            filterMethod: () => true,
                        },
                        {
                            Header: "E-Mail",
                            filterable: true,
                            accessor: "email",
                            Cell: ({value}) => <a href={"mailto:" + value}>{value}</a>,
                            Filter: TimeoutTextColumnFilter,
                            filterMethod: () => true,
                        },
                        {
                            Header: "Geburtsdatum",
                            filterable: true,
                            accessor: "birthdate",
                            maxWidth: 130,
                            //filterMethod: (filter, row) => row._original.birthdateString.toLowerCase().indexOf(filter.value.toLowerCase()) > -1,
                            Cell: row => dateFormatInline(row.value),
                        },
                        {
                            Header: "Beitrittsdatum",
                            id: "memberSince",
                            accessor: row => {
                                if (!!row.memberSince || !!row.roles_start) {
                                    const d = new Date((row.roles_start || row.memberSince) * 1000)
                                    return d.getFullYear() * 10000 + (d.getMonth() + 1) * 100 + d.getDate()
                                }
                                return null;
                            },
                            filterable: true,
                            maxWidth: 130,
                            //filterMethod: (filter, row) => row._original.birthdateString.toLowerCase().indexOf(filter.value.toLowerCase()) > -1,
                            Cell: row => row.value ? dateFormatInline(row.value) : "",
                            //Cell: row => !!row.value ? dateFormatUnix(row.value) : null,
                        },
                        {
                            Header: "PLZ",
                            key: "plz",
                            filterable: true,
                            accessor: "postal_code",
                            maxWidth: 90,
                            Filter: TimeoutTextColumnFilter,
                            filterMethod: () => true,
                        },
                        /*{
                            Header: memberSince ? "Jubiläum in" : "Geburtstag in",
                            filterable: true,
                            accessor: "daysToBirthday",
                            maxWidth: 120,
                            Cell: ({value}) => value !== undefined ? `${value} Tagen` : "",
                            sortMethod: (a, b) => (a || +Infinity) - (b || +Infinity)
                        },*/
                        {
                            Header: memberSince ? "Nächstes Jubiläum" : "Nächster Geburtstag",
                            key: "nextBirthday",
                            filterable: true,
                            accessor: "nextFullAge",
                            maxWidth: 120,
                            Cell: ({value}) => `${value} Jahre`,
                        },
                        {
                            Header: "Score",
                            filterable: true,
                            accessor: "score",
                            maxWidth: 120,
                            Cell: ({value}) => (value?.toFixed(3) || null)
                        },
                        {
                            Header: "Status",
                            accessor: r => club.memberStatesShort[r.memberStatus],
                            id: "memberState",
                            show: window.innerWidth > 1000,
                            maxWidth: 90,
                            filterable: true,
                            Filter: TimeoutTextColumnFilter,
                            filterMethod: () => true,
                        },
                    ]}
                    defaultSorted={Object.keys(this.state.search || {}).length === 0 ? [
                        {
                            id: "score",
                            desc: true
                        }
                    ] : []}
                    loading={this.state.loading}
                    onTableChange={(data) => this.setState({tableSize: data.length, tableIDsFiltered: data.map(u => u._original.ID)})}
                    onFilteredChange={(a, old, updateTableFilterMethod) => {
                        return onFilterChangeForUsers(a, old, (x) => {
                            const newEntries = objGetEntries(x)
                            if (!myDeepQual(
                                objGetEntries(this.state.search).filter(y => !!y.value && newEntries.map(e => e.key).includes(y.key)).sort((a, b) => a.key.localeCompare(b.key)),
                                newEntries.filter(x => !!x.value).sort((a, b) => ("" + a.key).localeCompare(b.key))
                            )) {
                                this.setState(state => {
                                    return {...state, search: {...(state.search || {}), name: undefined, email: undefined, ...x}}
                                }, this.loadData)
                            }
                        }, ["name", "email", "postal_code", "memberState"], updateTableFilterMethod)
                    }}
                    additionalButtonsRight={user?.role > 50 ? <><MiniBtn>
                        <Link to={{
                            pathname: "/newsletter/vorlagen", state: {
                                emailAddresses: this.state.tableData.filter(t => this.state.tableIDsFiltered.indexOf(t.ID) > -1).map(t => ([t.email, t.ID, t.name])),
                            }
                        }}>
                            <FaStickyNote/> Nachricht senden
                        </Link>
                    </MiniBtn>
                        <MiniBtn onClick={() => {
                            this.apiPost("/user/download_excel", {
                                user_ids: this.state.tableIDsFiltered,
                                additional: [
                                    {
                                        name: "Beitrittsdatum",
                                        type: "date",
                                        ...this.state.tableData.reduce((obj, t) => ({...obj, [t.ID]: new Date((t.roles_start || t.memberSince) * 1000).toISOString().slice(0, 10)}), {}),
                                    }
                                ]
                            }, ({content}) => {
                                downloadAsExcel(content, "Personen.xlsx")
                            })
                        }
                        }><FaList/> Liste herunterladen</MiniBtn></> : null}
                />

            </Container>
        </>

    }

}

