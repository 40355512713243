import React, {useMemo, useState} from "react";
import {MaxBtn, MiniBtn} from "../core/components/components";
import {MyModal} from "../core/components/modal";
import {CheckboxInput} from "../core/input/basic";
import {SelectfieldInput} from "../core/input/select";
import UserSearchSelect from "../user/user_search_select";
import {FormContextWrapper} from "../core/form_context";
import Status from "../core/status";

import {maxiGet, maxiPost} from "../core/maxios";

export const permissionsSelectable = [
    {
        label: "Buchhaltung",
        value: "accounting",
    },
    {
        label: "Kontakte",
        value: "contacts",
    },
    {
        label: "Kurs - Alles",
        value: "course",
    },
    {
        label: "Kurs - Termine",
        value: "course/events",
    },
    {
        label: "Kurs - Konferenzen",
        value: "course/conferences",
    },
    {
        label: "Kurs - Medien",
        value: "course/page_blocks",
    },
    {
        label: "Kurs - News",
        value: "course/news",
    },
    {
        label: "Kurs - Teilnehmer",
        value: "course/participants",
    },
    {
        label: "Kurs - Daten",
        value: "course/data",
    },
    {
        label: "Kurs - Teilnehmer - Saldo",
        value: "course/saldo",
    },

    {
        label: "Newsletter",
        disclaimer: "Auswahl von Empfängern nur mit Benutzer-Leseberechtigung möglich",
        value: "newsletter",
    },
    /*{
        label: "Raumbuchung",
        value: "rooms",
        disclaimer: "Auswahl von Buchenden nur mit Benutzer-Leseberechtigung möglich",
    },*/

    /*{
        label: "Benutzer - Buchunge & Zahlungen",
        value: "user/financials",
    },
    {
        label: "Benutzer - Qualifikationen",
        value: "user/qualifications",
    },
    {
        label: "Benutzer - Rollen",
        value: "user/roles",
    },
    {
        label: "Benutzer - Stats",
        value: "user/stats",
    },
    {
        label: "Benutzer - History",
        value: "user/history",
    },
    {
        label: "Benutzer - Daten",
        value: "user/data",
    },*/
    {
        label: "Benutzerdaten",
        value: "user",
    },
    {
        label: "Tags-Bearbeiten",
        value: "tags"
    },
    {
        label: "Gutscheine",
        value: "vouchers"
    },
    {
        label: "Medien-Seiten",
        value: "pageblocks",
        disclaimer: "Leseberechtigung für einzelne Seiten kann bei den Seiten vergeben werden"
    },

    {
        label: "Rechnungen",
        value: "invoice",
    }
]

const permissionsSelectableAllNoTrainers = [
    ...permissionsSelectable.map(a => ({...a, label: a.label + " (Lese- und Schreibzugriff)"})),
    ...permissionsSelectable.map(({label, value}) => ({label: label + " (Lesezugriff)", value: value + "_read"})),
]

const removePermissions = ["course/saldo", "course/saldo_trainer"]
export const permissionsSelectableAll = [
    ...permissionsSelectableAllNoTrainers,
    ...permissionsSelectableAllNoTrainers.filter(a => a.value.includes("course/")).map(({label, value}) => ({label: label + " (falls Trainer)", value: value + "_trainer"})),
    {
        label: "Cloudstorage",
        value: "cloudstorage",
    },
    {
        label: "Verein - (Lesezugriff)",
        value: "club_read",
    },
    {
        label: "Terminübersicht anzeigen",
        value: "events_read",
    },
    {
        label: "Export",
        value: "export",
    },
]
    .filter(a => !removePermissions.includes(a.value))
    .sort((a, b) => a.label <= b.label ? -1 : 1)


export const permissionsSelectableMap = permissionsSelectableAll.reduce((obj, curr) => ({...obj, [curr.value]: curr.label}), {})

export function UserPermissionsAddToPerson({close, entity_type, entity_ID}) {
    const [{error}, setStatusVar] = useState({});
    const [personsPickable, setPersonsPickable] = useState([]);
    useMemo(() => {
        if (entity_type === "course") {
            maxiGet("/course/parts/" + entity_ID, {setStatusVar}).then(resp => {
                setPersonsPickable(resp.parts.filter(({child, registration}) => registration.role === 1 && registration.timeLeave === 0))
            });
        }
    }, [entity_ID])
    const [state, setState] = useState({});
    return <>
        <p>
            {
                personsPickable.map((a,index) => <>{index>0&&", "}<MiniBtn onClick={()=>setState(b=>({...b, child_ID: a.child.ID, childName: a.child.lastname + ", " + a.child.prename}))}>{a.child.fullname} (auswählen)</MiniBtn></>)
            }
        </p>

        <b>Person: </b>{state.childName} &nbsp;
        <MyModal trigger={<MiniBtn>auswählen</MiniBtn>}>
            {close =>
                <UserSearchSelect
                    selectButton={(child_ID, child) => <em onClick={() => {
                        setState(a => ({...a, child_ID, childName: child.lastname + ", " + child.prename}))
                        close()
                    }}>
                        auswählen
                    </em>
                    }
                    usersHide={[]}
                />
            }
        </MyModal>
        <FormContextWrapper value={{state, setState}}>
            <SelectfieldInput name={"Berechtigungen"} tag={"child_permissions"} type={"reactselect"} multiple
                              selectives={permissionsSelectableAll.filter(({value}) => entity_type === undefined || (value.includes(entity_type) && !value.includes("_trainer")))}/>
        </FormContextWrapper>
        <Status type={"error"} text={error}/>
        <MaxBtn onClick={() => {
            maxiPost("/club/permissions", {child_ID: state.child_ID, permissions: state.child?.permissions?.map(a => a.value), entity_type, entity_ID}, {setStatusVar}).then(close)
        }
        }>diese Berechtigung(en) erteilen</MaxBtn>
    </>
}

export function UserAddSuperuser({close}) {
    const [{error}, setStatusVar] = useState({});
    const [state, setState] = useState({child: {}});
    return <>
        <b>Person: </b>{state.childName} &nbsp;
        <MyModal trigger={<MiniBtn>auswählen</MiniBtn>}>
            {close =>
                <UserSearchSelect
                    selectButton={(child_ID, child) => <em onClick={() => {
                        setState(a => ({...a, child_ID, childName: child.lastname + ", " + child.prename}))
                        close()
                    }}>
                        auswählen
                    </em>
                    }
                    usersHide={[]}
                />
            }
        </MyModal>
        <FormContextWrapper value={{state, setState}}>
            <CheckboxInput name={"Schreibzugriff?"} tag={"child_writeAccess"}/>
        </FormContextWrapper>
        <Status type={"error"} text={error}/>
        <MaxBtn onClick={() => {
            maxiPost("/club/superusers", {child_ID: state.child_ID, newRole: state.child?.writeAccess === 1 ? 90 : 50}, {setStatusVar}).then(close)
        }
        }>diese Berechtigung(en) erteilen</MaxBtn>
    </>
}
