import {useEffect, useState} from "react"
import {maxiGet} from "../maxios"

export function useClubTemplates() {
    const [templates, setTemplates] = useState<Partial<{defaultChildRoleChangedEmailText: string}>>({})

    useEffect(() => {
        maxiGet("/club/defaultTexts").then(resp => setTemplates(resp.texts || {}))
    }, [])

    return templates
}
