import CourseEdit from "./course_edit";
import React from "react";
import {useMyState} from "../../core/helpers";
import {FormContext} from "../../core/form_context";
import {MyModal} from "../../core/components/modal";
import {FiEdit} from "react-icons/all";
import {MiniBtn} from "../../core/components/components";

export function CourseEditMultipleModal({onSubmit, ...props}) {
    return <MyModal additionalPaperStyles={"WideDialogPaper"} trigger={<MiniBtn><FiEdit/> Ausgewählte Kurse bearbeiten</MiniBtn>}>
        {(close) => <CourseEditMultiple {...props} onSubmit={() => {
            onSubmit && onSubmit()
            close()
        }} /> }
    </MyModal>
}

export default function CourseEditMultiple({courses, onSubmit}) {
    const {state, updateState} = useMyState({course: {ID: courses.map(x => x.ID).join(",")}})

    return <>
        <h1>Bearbeiten von Kursen</h1>
        <b>Geändert werden folgende Kurse ({courses.length}):</b>
        <ul>
            {courses.map(x => <li>{x.name}</li>)}
        </ul>
        Geändert werden nur die Felder, die einen Wert per Eingabe erhalten haben. Ein Wert wird durch rote Textfarbe als eingegeben erkenntlich gemacht.
        <FormContext.Provider value={{state: state, setState: updateState}}>
            <CourseEdit multiple={true} courses={courses} id={courses.map(x => x.ID).join(",")+","} onSubmit={onSubmit} />
        </FormContext.Provider>
    </>
}
