import React, {useContext, useState} from 'react';
import "../dashboard.sass"

import "react-datepicker/dist/react-datepicker.css";
import {UserContext} from "./UserContext";
import UserHistory from "./user_history";
import {Container} from "../core/components/components";

function UserHistoryAll(props) {

    const context = useContext(UserContext);
    const id = props.match.params.userID;
    const user = context.user;
    const [childName, setChildName] = useState(null);

    return [<Container>
        {user !== undefined && user.role >= 30 &&
        <>
            <UserHistory userID={"all"} personType={"user"}/>
        </>
        }
    </Container>]
}


export default UserHistoryAll;
