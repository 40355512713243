import React, {Component} from 'react';
import CourseVisits from "./course_visits"
import Zahlungen from "./zahlungen"
import Zahlungsmethoden from "./zahlungsmethoden"
import ZahlungenSaldo from "./zahlungen_saldo";
import {AbrechnungsContext} from "./context";
import {UserContext} from "../user/UserContext";
import {Container, LightContainer, MaxLink} from "../core/components/components";
import Status from "../core/status";
import {Link} from "react-router-dom";

export default class Abrechnung extends Component {
    state = {
        ausgaben: 0,
        eingaben: 0,
        saldo: 0,
        reloadPayments: () => null,
    };

    constructor(props) {
        super(props);
        this.setState = this.setState.bind(this)

    }

    static contextType = UserContext;

    render() {
        const pn = window.location.href;
        if (pn.indexOf("src_client_secret") > -1) {
            window.setTimeout(() => window.location.href = "/abrechnung", 5000)
        }
        const user = this.context.user;
        if (user === undefined || (!user.verwalter && user?.role < 10)) {
            return null
        }
        const Wrapper = this.props.noWrapper ? React.Fragment : Container;
        if (!this.context.user.ID > 0) {
            return null;
        }


        return <AbrechnungsContext.Provider value={{
            ausgaben: this.state.ausgaben,
            eingaben: this.state.eingaben,
            setState: this.setState,
            state: this.state,
            saldo: 0,
            userID: parseInt(this.props.match.params.userID),
        }}>
            <Wrapper name={"Abrechnung"}>
                {
                    (pn.indexOf("src_client_secret") > -1 || (pn.indexOf("payment_intent") > -1 && !pn.includes("redirect_status=failed"))) && <Status type={"success"} text={"Zahlung erfolgreich bestätigt. Diese wird in Kürze hier aufscheinen. Vielen Dank!"}/>
                }
                {
                    ((pn.indexOf("payment_intent") > -1 && pn.includes("redirect_status=failed"))) && <Status type={"error"} text={"Deine Zahlung ist leider fehlgeschlagen bzw. wurde abgebrochen. Bitte probiere es erneut eventuell auf eine andere Art. Vielen Dank!"}/>
                }
                <ZahlungenSaldo name={this.props.child?.fullname}/>
                {
                    this.props.match.params.userID !== undefined &&
                    <>
                        <CourseVisits userID={parseInt(this.props.match.params.userID)}/>
                        {
                            this.props.child?.ID!==this.props.child?.verwalter_ID && <LightContainer>
                                <Link to={"/benutzer/profil/"+this.props.child.verwalter_ID}>zum Profil des Verwalters wechseln</Link>
                        </LightContainer>}
                        {
                            user?.role >= 80 &&
                            <>
                                <Zahlungen userID={parseInt(this.props.match.params.userID)}/>
                                <Zahlungsmethoden userID={parseInt(this.props.match.params.userID)}/>
                            </>
                        }

                    </>
                }
                {this.context.user !== undefined && this.props.match.params.userID === undefined &&
                <>

                    <CourseVisits userID={this.context.user.ID}/>

                    <Zahlungen userID={this.context.user.ID}/>
                    <Zahlungsmethoden userID={this.context.user.ID}/>

                </>
                }


            </Wrapper>
        </AbrechnungsContext.Provider>
    }

}
//const MySpan = ({children}) => <span>{children}</span>;
/*
class UserIdContextController extends React.Component {
    static contextType = AbrechnungsContext;

    render() {
        if (!isNaN(this.context.userID)) {
            return <CourseVisitsCore userID={this.context.userID}/>
        }
        return <UserContext.Consumer>
            {
                userContext => userContext.user ? <CourseVisitsCore userID={userContext.user.ID}/> : null
            }
        </UserContext.Consumer>
    }
}*/
