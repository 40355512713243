import React from 'react';
import {UserContext} from "./UserContext";
import {MiniBtn} from "../core/components/components";
import {apiPost} from "../core/api";
import {FaEye, FaEyeSlash} from "react-icons/fa";


class ResetAdminUserChange extends React.Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);

        this.state = {show: true}
        this.apiPost = apiPost.bind(this)
    }

    resetAdminUserChange = () => {
        this.apiPost("/user/change/reset", null, () => {
            window.location.reload()
        })
    }

    render() {
        if(this.context?.user?.originalUser){
            const user = this.context?.user || {}
            return <div style={{position: "fixed", top: 0, textAlign: "left", marginLeft: 20, zIndex: 101}}>
                <span style={{fontSize: "1.2em", marginRight: 10}} title={"Sie sind momentan in einer anderen Benutzerperspektive."} onClick={() => this.setState(a => ({show: !a.show}))}>{this.state.show ? <FaEyeSlash/> : <FaEye/>}</span>
                { this.state.show && <MiniBtn onClick={this.resetAdminUserChange}> Momentan angemeldet als {user.prename} {user.lastname} ({user.email}). Zum originalen Benutzer ({user.originalUser?.email}) zurückwechseln.</MiniBtn>}
            </div>
        }
        return null
    }

}

export default ResetAdminUserChange;
