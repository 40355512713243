import React from "react"
import Consts from "./consts";
import {InfoTooltip} from "./components/components";
import {AdditionalField} from "../user/UserContext"

interface Club {
    verein_ID: number,
    fullName: string,
    fullNameDeclined: string,
    shortName: string,
    domain: string,
    title: string,
    logo?: JSX.Element,
    color: string,
    fontColor?: string,
    fontColorInverse?: string,
    showLogo: boolean,
    iban: string,
    bic?: string,
    bankname?: string,
    bank?: {
        bic: string,
        bankname: string,
    }
    facebook?: string,
    memberStates: Record<number, string>,
    memberStatesShort?: Record<number, string>,
    arnName: string,
    arn: string,
    sendReminders: boolean,
    address: string,
    creditorID: string,
    email: string,
    phone?: number,
    website: string,
    links?: {
        dataprotection?: string,
        statuten?: string,
        agbs?: string,
    },
    stripeKey?: string,
    paymentMethods: string[],
    msFees: {
        junior: number,
        normal: number,
        senior: number,
        supporter?: number,
        addText?: string,
    },
    noMembership?: boolean,
    otherClubs?: number[],
    additionalFields?: Partial<AdditionalField>[],
    roomDependencies?: {
        localRoomID: number,
        foreignRoomID: number
    }[],
    showDiscounts?: boolean
    vatRegisteredSince?: number
}


type _domainMap = Record<string, Club>
const memberStatesStandard = {
    0: "Nichtmitglied",
    1: "ordentliches Mitglied",
    2: "außerordentliches Mitglied",
    3: "unterstützendes Mitglied",

    400: "befreites Mitglied",
    401: "ruhendes Mitglied",
    410: "gesperrte Person",
    //402: "Trainer",

    500: "Zeugwart Stellvertretung",
    600: "Zeugwart",

    700: "Sportliche Leitung Stellvertretung",
    800: "Sportliche Leitung",
    900: "Kassier Stellvertretung",
    1000: "Kassier",
    1100: "Schriftführer Stellvertretung",
    1200: "Schriftführer",
    1300: "Obfrau/Obmann Stellvertretung",
    1400: "Obfrau/Obmann",


    //100: "Vorstand"
};

const stripeMethods = ["sepa", "card", "sofort", "bank", "voucher"];

// https://www.iban-rechner.de/iban_validieren.html


const domainMapInitial: _domainMap = {
    "vereinfacht.at": {
        verein_ID: 1,
        fullName: "VEREINfacht",
        fullNameDeclined: "VEREINfacht",
        shortName: "VEREINfacht",
        domain: "mein.vereinfacht.at",
        title: "VEREINfacht",
        logo: <h1 style={{fontSize: "160%"}}>VEREINfacht</h1>,
        showLogo: true,
        email: "max@vereinfacht.at",
        color: "#bdd94d",
        iban: "AT12 3456 7890 1234 5678",
        facebook: "Turnverein",
        memberStates: memberStatesStandard,
        arnName: "VEREINfacht <mail@vereinfacht.at>",
        arn: "arn:aws:ses:eu-west-1:639823883075:identity/vereinfacht.at",
        paymentMethods: stripeMethods,
        sendReminders: true,
        address: "Musterstraße 14, St. Agatha",
        creditorID: "ATZ12314151515151",
        website: "vereinfacht.at",
        msFees: {
            junior: 30,
            normal: 50,
            senior: 40,
        }
    },
    "mein-turnverein.at": {
        verein_ID: 0,
        fullName: "Allgemeiner Turnverein St. Valentin 1911",
        fullNameDeclined: "Allgemeinen Turnverein St. Valentin 1911",
        shortName: "Turnverein St. Valentin",
        domain: "mein-turnverein.at",
        title: "VEREINFacht Turnverein St. Valentin",
        color: "#bdd94d",
        showLogo: true,
        logo: <img style={{backgroundColor: "#bdd94d", padding: "20px", marginBottom: "10px"}} height={103} width={"auto"}
                   src={"https://www.turnverein-st-valentin.at/files/cto_layout/themedesigner/uploads/logo_web_weiss.svg"}/>,
        iban: "AT56 4715 0000 0023 5374",
        bank: {
            bic: "VBOEATWWNOM",
            bankname: "Volksbank Niederösterreich AG",
        },
        facebook: "TurnvereinSt.Valentin",
        memberStates: memberStatesStandard,
        arnName: "Allgemeiner Turnverein St. Valentin 1911 <office@turnverein-st-valentin.at>",
        arn: "arn:aws:ses:eu-west-1:639823883075:identity/turnverein-st-valentin.at",
        sendReminders: true,
        address: "Altenhofnerstraße 26, 4300 St. Valentin",
        creditorID: "AT18ZZZ00000064796",
        paymentMethods: stripeMethods,
        email: "office@turnverein-st-valentin.at",
        website: "turnverein-st-valentin.at",
        links: {
            dataprotection: "https://www.turnverein-st-valentin.at/datenschutz",
            statuten: "https://www.turnverein-st-valentin.at/files/dokumente/turnverein-st-valentin-statuten.pdf",
        },
        stripeKey: "pk_live_s1oTJtfCOSNcFvSQuz37edmO007pAQkKtz",
        msFees: {
            junior: 65,
            normal: 80,
            senior: 70,
            supporter: 20,
            addText: " (Halber Betrag ab Jänner) ",
        },
        showDiscounts: true,
    },
    "nyc.vereinfacht.at": {
        verein_ID: 1,
        fullName: "Allgemeiner Turnverein NYC 1888",
        fullNameDeclined: "Allgemeinen Turnverein NYC 1888",
        shortName: "Turnverein NYC",
        domain: "nyc.vereinfacht.at",
        title: "VEREINfacht Turnverein NYC",
        website: "vereinfacht.at",
        showLogo: true,
        color: "#bdd94d",
        logo: <img
            src={"https://upload.wikimedia.org/wikipedia/commons/thumb/8/8a/New_York_Yankees_logo.svg/129px-New_York_Yankees_logo.svg.png"}/>,
        iban: "AT12 3456 7890 1234 5678",
        facebook: "Turnverein",
        paymentMethods: stripeMethods,
        memberStates: memberStatesStandard,
        arnName: "VEREINfacht <mail@vereinfacht.at>",
        email: "max@vereinfacht.at",
        arn: "arn:aws:ses:eu-west-1:639823883075:identity/vereinfacht.at",
        sendReminders: true,
        address: "Musterstraße 14, St. Agatha",
        creditorID: "ATZ12314151515151",
        msFees: {
            junior: 30,
            normal: 50,
            senior: 40,
        },
        showDiscounts: true,
    },
    "atv-muster.vereinfacht.at": {
        verein_ID: 1,
        fullName: "Allgemeiner Turnverein Muster",
        fullNameDeclined: "Allgemeinen Turnverein Muster",
        shortName: "ATV Muster",
        domain: "atv-muster.vereinfacht.at",
        title: "VEREINfacht ATV Muster",
        color: "#bdd94d",
        showLogo: true,
        logo: <h1 style={{fontSize: "160%"}}>ATV Muster</h1>,
        iban: "AT12 3456 7890 1234 5678",
        facebook: "",
        paymentMethods: stripeMethods,
        memberStates: memberStatesStandard,
        arnName: "VEREINfacht <mail@vereinfacht.at>",
        arn: "arn:aws:ses:eu-west-1:639823883075:identity/vereinfacht.at",
        website: "vereinfacht.at",
        sendReminders: true,

        bank: {
            bankname: "Muster Bank",
            bic: "ABCDEFGHIJK",
        },
        email: "max@vereinfacht.at",
        address: "Musterstraße 14, St. Agatha",
        creditorID: "ATZ12314151515151",
        msFees: {
            junior: 30,
            normal: 50,
            senior: 40,
        },
        showDiscounts: true,
    },
    "atv-test.vereinfacht.at": {
        verein_ID: -1,
        fullName: "Allgemeiner Testverein",
        fullNameDeclined: "Allgemeinen Testverein",
        shortName: "ATestV",
        domain: "atv-test.vereinfacht.at",
        title: "VEREINfacht ATV Test",
        color: "orange",
        showLogo: false,
        logo: <></>,
        iban: "AT12 3456 7890 1234 5678",
        facebook: "",
        paymentMethods: stripeMethods,
        memberStates: memberStatesStandard,
        arnName: "TESTVereinFacht <mail@vereinfacht.at>",
        arn: "arn:aws:ses:eu-west-1:639823883075:identity/vereinfacht.at",
        website: "vereinfacht.at",
        sendReminders: true,

        bank: {
            bankname: "Muster Bank",
            bic: "ABCDEFGHIJK",
        },
        email: "max@vereinfacht.at",
        address: "Musterstraße 12, Musterstadt",
        creditorID: "ATZ12314151515151",
        msFees: {
            junior: 30,
            normal: 50,
            senior: 40,
        },
        showDiscounts: true,
    },
    "tv-enns.vereinfacht.at": {
        verein_ID: 2,
        fullName: "Turnverein Enns ÖTB 1862",
        fullNameDeclined: "Turnverein Enns ÖTB 1862",
        shortName: "TV Enns",
        domain: "tv-enns.vereinfacht.at",
        title: "VEREINfacht TV Enns",
        color: "#04833e",
        fontColor: "#fff",
        fontColorInverse: "#ffcbb5",
        showLogo: true,
        paymentMethods: stripeMethods,
        logo: <img src={"http://www.tv-enns.at/templates/styletvennsturnen_260915/images/object156654335.png"}/>,
        iban: "AT31 4715 0000 0010 1188",
        bank: {
            bic: "VBOEATWWNOM",
            bankname: "Volksbank Niederösterreich AG",
        },
        website: "tv-enns.at",
        facebook: "TVEnns",
        email: "tv-enns@vereinfacht.at",
        memberStates: {
            ...memberStatesStandard,
            1: "A Mitglied",
            2: "Jugend/Student",
            3: "unterstützendes Mitglied",
            4: "Mitglied Partner",
            5: "Familienmitglied Zahler",
            6: "Familienmitglied",
            7: "Faust Ball Mitglied",
            8: "Faust Ball Mitglied Zahler",
        },
        arnName: "TV Enns <tv-enns@vereinfacht.at>",
        arn: "arn:aws:ses:eu-west-1:639823883075:identity/vereinfacht.at",
        sendReminders: false,
        address: "Musterstraße 14, St. Agatha",
        creditorID: "ATZ12314151515151",
        msFees: {
            junior: 58,
            normal: 68,
            senior: 68,
            supporter: 25,
        }
    },
    "meine.bewegungswelt.at": {
        verein_ID: 3,
        fullName: "Bewegungswelt von Theresa Gansterer",
        fullNameDeclined: "Bewegungswelt von Theresa Gansterer",
        shortName: "Bewegungswelt",
        domain: "meine.bewegungswelt.at",
        title: "VEREINFacht Bewegungswelt",
        color: "#F4CAB1",
        showLogo: true,
        paymentMethods: stripeMethods,
        logo: <img style={{backgroundColor: "#bdd94d"}} height={100}
                   width={"auto"}
                   src={"https://bewegungswelt.at/wp-content/uploads/2019/08/cropped-Logo-größer-1-300x200.jpg"}/>,

        iban: "AT824715011587050000",
        facebook: "bewegungswelt",
        memberStates: memberStatesStandard,
        arnName: "Bewegungswelt <theresa@bewegungswelt.at>",
        arn: "arn:aws:ses:eu-west-1:639823883075:identity/bewegungswelt.at",
        sendReminders: true,
        address: "Lisztstraße 4, 4300 St. Valentin",
        creditorID: "AT03ZZZ00000071371",
        stripeKey: "pk_live_51JaxM4KZEGKZVaeAzeUqTyTjK1He2zNdPV1ENPZu9y9A0Mi8gXzgUyCtFNVrk2YL6Op42dPLYhksIStMkBfHabEL00V8q0y0IZ",
        website: "bewegungswelt.at",
        bank: {
            bankname: "Volksbank Enns - St. Valentin eG",
            bic: "VBOEATWWNOM",
        },
        email: "theresa@bewegungswelt.at",
        links: {
            dataprotection: "https://bewegungswelt.at/datenschutz/",
            agbs: "https://bewegungswelt.at/agbs/",
        },
        msFees: {
            junior: 0,
            normal: 0,
            senior: 0,
        },
        noMembership: true,
    },
    "atv-andorf.vereinfacht.at": {
        verein_ID: 4,
        fullName: "ÖTB Allgemeiner Turnverein Andorf",
        fullNameDeclined: "ÖTB Allgemeiner Turnverein Andorf",
        shortName: "ATV Andorf",
        domain: "atv-andorf.vereinfacht.at",
        title: "VEREINfacht ATV Andorf",
        //color: "rgba(36,118,157,0.51)",
        color: "#24769D",
        fontColor: "#fff",
        fontColorInverse: "#BAD460",
        // color: "rgb(186,212,96)",
        email: "martin.doblinger@gmx.at",
        showLogo: true,
        logo: <img id="comp-jnlkxecbimgimage" alt="ATV Andorf Logo_bearbeitet.png" data-type="image" itemProp="image"
                   src="https://static.wixstatic.com/media/69b39a_c2d9b5e1b11a45b89b266fb2d5621822~mv2.png/v1/fill/w_230,h_166,al_c,usm_0.66_1.00_0.01/ATV%20Andorf%20Logo_bearbeitet.png"/>,
        iban: "AT 82 2032 0131 0000 5268",
        bank: {
            bic: "ASPKAT2LXXX",
            bankname: "Allgemeine Sparkasse Oberösterreich BankAG",
        },
        facebook: "atvandorf",
        paymentMethods: ["sepm", "bank", "voucher"],
        website: "atv-andorf.com",
        memberStates: {
            ...memberStatesStandard,
            500: "Zeugwart Stellvertretung",
            600: "Zeugwart",

            740: "2. Säckelprüfer/in",
            750: "1. Säckelprüfer/in",
            780: "Beirat",
            790: "Organisation",
            800: "Turnausschuss",
            810: "Jugendausschuss",
            820: "Vereinszeitung",
            830: "Zeugwart Stellvertretung",
            840: "Zeugwart",
            850: "Wanderwart",
            900: "Säckelwart Stellvertretung",
            1000: "Säckelwart",
            1100: "Schriftwart Stellvertretung",
            1200: "Schriftwart",
            1300: "Obfrau/Obmann Stellvertretung",
            1400: "Obfrau/Obmann",
        },
        arnName: "ATV Andorf <atv-andorf@vereinfacht.at>",
        arn: "arn:aws:ses:eu-west-1:639823883075:identity/vereinfacht.at",
        sendReminders: true,
        address: "Hans-Holz-Straße 1, 4770 Andorf",
        creditorID: "AT50ZZZ00000010579",
        phone: 4369910550498,
        links: {
            dataprotection: "https://www.atv-andorf.com/sonstiges",
        },

        msFees: {
            junior: 25,
            normal: 35,
            senior: 35,
            supporter: 15,
        },
        otherClubs: [14],
    },
    "tv-taufkirchen.vereinfacht.at": {
        verein_ID: 5,
        fullName: "Turnverein Taufkirchen",
        fullNameDeclined: "Turnverein Taufkirchen",
        shortName: "TV Taufkirchen",
        domain: "tv-taufkirchen.vereinfacht.at",
        title: "VEREINfacht TV Taufkirchen",
        color: "#62d3ee",
        showLogo: true,
        logo: <img style={{padding: "0px", marginTop: "-5px"}} width={200} height={"auto"} src={"https://mein.vereinfacht.at/static/logos/taufkirchen.png"}/>,
        iban: "AT 27 3445 5000 0671 9025",
        bank: {
            bankname: "Raiffeisenbank Region Schärding eGen",
            bic: "RZOOAT2L455",
        },
        facebook: "tvtaufkirchen",
        paymentMethods: stripeMethods,
        website: "tv-taufkirchen.at",
        memberStates: {
            ...memberStatesStandard,
            510: "Jugendwart",
            515: "Jugendwart Stellvertretung",
            520: "Leiter Arbeitskreis Stellvertretung",
            525: "Leiter Arbeitskreis",
            530: "Leiter Stockschützen Stellvertretung",
            535: "Leiter Stockschützen",
            540: "EDV-Wart",
            550: "Skiwart",
            560: "Fahnenträger",
            570: "Kassenprüfer",
            580: "Vorstand/Beiräte",
        },
        links: {
            dataprotection: "http://www.turnverein-taufkirchen.at/dsgvo",
        },

        arnName: "TV Taufkirchen <tv-taufkirchen@vereinfacht.at>",
        arn: "arn:aws:ses:eu-west-1:639823883075:identity/vereinfacht.at",
        email: "turnverein.taufkirchen@gmail.com",
        sendReminders: true,
        address: "Schwendt 39, 4775 Taufkirchen",
        creditorID: "AT76ZZZ00000051627",
        stripeKey: "pk_live_51HMdKGBTzRJ7O5Au2WHbYOGGM3hS1LRqhPYfW015Lru9R7gTbtQ4kSkbssLFRXo8e2R5jZHwuHgw8RjLcFveoG9s00fIF50EEw",
        msFees: {
            junior: 25,
            normal: 25,
            senior: 25,
            supporter: 15,
        }
    },
    "schwanenstadt.vereinfacht.at": {
        verein_ID: 6,
        fullName: "Turnverein Schwanenstadt",
        fullNameDeclined: "Turnverein Schwanenstadt",
        shortName: "TV Schwanenstadt",
        domain: "schwanenstadt.vereinfacht.at",
        title: "VEREINfacht TV Schwanenstadt",
        showLogo: true,
        logo: <img style={{backgroundColor: "#bdd94d", padding: "20px", marginTop: "-5px"}} width={300} height={"auto"} src={"https://mein.vereinfacht.at/static/logos/schwanenstadt.jpg"}/>,
        iban: "AT 44 2032 0145 0075 5792",
        bank: {
            bankname: "Allgemeine Sparkasse Oberösterreich BankAG",
            bic: "ASPKAT2LXXX",
        },
        email: "turnverein.schwanenstadt@gmx.at",
        facebook: "tvschwanenstadt",
        website: "schwanenstadt.turnfest.at",
        paymentMethods: stripeMethods,
        color: "#089aae",
        fontColor: "#fff",
        fontColorInverse: "#DCFF9C",
        arnName: "Turnverein Schwanenstadt <schwanenstadt@vereinfacht.at>",
        arn: "arn:aws:ses:eu-west-1:639823883075:identity/vereinfacht.at",
        sendReminders: true,
        links: {
            dataprotection: "http://schwanenstadt.turnfest.at/unser-verein/datenschutzerklaerung",
        },
        memberStates: {
            ...memberStatesStandard,
            1: "A Mitglied",
            2: "Jugend",
            3: "unterstützendes Mitglied",
            4: "A Tennis",
            5: "Aerobic/Bodystyling",
            6: "Anschluss B",
            7: "Anschluss J",
            8: "B Mitglied",
            9: "B Tennis",
            10: "Tennis Kind",
            11: "Tennis Jugend",
            12: "Student",


            440: "Beirat",
            445: "Beirat Tennis",
            450: "Beirat Tennis Stellvertretung",
            455: "Beirat Vereinsheim",
            460: "Beirat Vereinsheim Stellvertretung",
            465: "Beirat Volleyball",

            470: "Fitsportwart",
            475: "Pressewart Stellvertretung",
            480: "Pressewart",
            485: "Jugendwart",
            490: "Jugendwart Stellvertretung",

            500: "Zeugwart Stellvertretung",
            600: "Zeugwart",

            700: "Turnwart Stellvertretung",
            800: "Turnwart",
            900: "Kassier Stellvertretung",
            1000: "Kassier",
            1100: "Schriftführer Stellvertretung",
            1200: "Schriftführer",
            1300: "Obfrau/Obmann Stellvertretung",
            1400: "Obfrau/Obmann",
            //10: "Familie",
        },
        stripeKey: "pk_live_51H1CQ1JxZl0lUqHEoUc64hnLHacpyUoNGuovEFwdupZuzpU5ehkq30UhZshwNjj2Tni35FZxrpeSVttShN6wT08F00vAO6cB1m",
        address: "Molkereistraße 6, 4690 Schwanenstadt",
        creditorID: "AT26ZZZ00000018771",
        msFees: {
            junior: 32,
            normal: 50,
            senior: 33,
            supporter: 27,
        },
        additionalFields: [
            /*{
                tag: "IBAN",
                label: "IBAN",
                editable: "internal",
                type: "text",
            },*/
            {
                tag: "Laufende Nummer/\nMandatsreferenz f. Abbucher",
                label: "Laufende Nummer/\\nMandatsreferenz f. Abbucher",
                editable: "internal",
                type: "text",
            },
            {
                tag: "Bilder ver\u00f6ffentlichen ja",
                label: "Bilder veröffentlichen",
                editable: "internal",
                type: "checkbox",
            },
            {
                tag: "Post",
                label: "Post",
                editable: "internal",
                type: "checkbox",
            },
            {
                tag: "BTZ",
                label: "BTZ",
                editable: "internal",
                type: "checkbox",
            }

        ]
    },
    "turnvereinmondsee.vereinfacht.at": {
        verein_ID: 7,
        fullName: "Turn- und Sportverein Mondsee",
        fullNameDeclined: "Turn- und Sportvereins Mondsee",
        shortName: "Turnverein Mondsee",
        domain: "turnvereinmondsee.vereinfacht.at",
        title: "Turn- und Sportverein Mondsee",
        color: "#006ab2",
        fontColor: "#fff",
        fontColorInverse: "#f0d857",
        email: "turnvereinmondsee@outlook.com",
        showLogo: true,
        logo: <div><img style={{padding: "20px", paddingBottom: 0, marginTop: "-30px"}} width={150} height={"auto"} src={"https://mein.vereinfacht.at/static/logos/mondsee.png"}/></div>,
        iban: "AT11 4501 0350 1368 0000",
        bank: {
            bic: "VBOEATWWSAL",
            bankname: "Volksbank Salzburg eG",
        },
        facebook: "turnvereinmondsee",
        paymentMethods: stripeMethods,
        website: "turnvereinmondsee.at",
        memberStates: {
            ...memberStatesStandard,
            440: "Vorturnerhelfer",
            450: "Jugendvorturner",
            210: "Beirat",
            220: "Ehrenmitglied",
            230: "Ehrenzeichen Silber",
            231: "Ehrenzeichen Gold",
        },
        arnName: "Turn- und Sportverein Mondsee <turnvereinmondsee@vereinfacht.at>",
        arn: "arn:aws:ses:eu-west-1:639823883075:identity/vereinfacht.at",
        sendReminders: true,
        address: "Hilfberg 25, 5310 Mondsee",
        creditorID: "AT91ZZZ00000070951",
        phone: 0,
        stripeKey: "pk_live_51JHtuqK6x6iyB4PucAMflzSpDdf8hw2sgQAd3Jb0zJvkB8quRryTrClPyiZIrs3VwPj3oyirFNd19AihEt0Ms88I00OoJa702E",
        links: {
            dataprotection: "http://www.turnvereinmondsee.at/datenschutz.htm",
        },
        msFees: {
            junior: 49,
            normal: 60,
            senior: 60,
            supporter: 49,
        },
    },
    "itv.vereinfacht.at": {
        verein_ID: 8,
        fullName: "Innsbrucker Turnverein 1863 (1849)",
        fullNameDeclined: "Innsbrucker Turnvereins 1863 (1849)",
        shortName: "Innsbrucker Turnverein",
        domain: "itv.vereinfacht.at",
        title: "VEREINfacht - Innsbrucker Turnverein",
        showLogo: true,
        logo: <div><img style={{padding: "20px", marginTop: "-5px"}} width={150} height={"auto"} src={"https://mein.vereinfacht.at/static/logos/innsbruck.png"}/> <h1 style={{marginTop: "-10px", color: "var(--mainColor)"}}>Innsbrucker Turnverein</h1></div>,
        iban: "AT 57 2050 3000 0003 2292",
        bank: {
            bankname: "Tiroler Sparkasse BankAG Innsbruck",
            bic: "SPIHAT22XXX",
        },
        facebook: "InnsbruckerTurnverein",
        color: "#205a9f",
        fontColor: "#fff",
        fontColorInverse: "#bbb",
        paymentMethods: ["sepa", "sofort", "bank", "voucher"],
        arnName: "Innsbrucker Turnverein 1863 (1849) <itv@vereinfacht.at>",
        arn: "arn:aws:ses:eu-west-1:639823883075:identity/vereinfacht.at",
        email: "itv@tirol.com",
        stripeKey: "pk_live_51HCibaFv2OePDmi35Tlvg4mbzXaM8o84HrMCnWATN1ZY644dgNBnBpoRi0pFjLHhSnPidXroYfeggnFzjHOwFsDr00Tk5jLrWZ",
        sendReminders: false,
        website: "innsbrucker-turnverein.at",
        links: {
            dataprotection: "https://www.innsbrucker-turnverein.at/datenschutz",
        },
        address: "Fallmerayerstraße 12, 6020 Innsbruck",
        creditorID: "AT87ZZZ00000068016",
        msFees: {
            junior: 45,
            normal: 86,
            senior: 56,
        },
        memberStates: {
            ...memberStatesStandard,
            1: "Turner Innen Anw",
            2: "Kind Jugendlich",
            3: "Förderer",
            4: "Student",
            5: "Lehrling",
            6: "Turner Innen Mitgl",
            7: "2. Kind / Jugendlicher",
            8: "weiteres Kind/Jugendlicher",
            9: "Kletter Kurs Teilnehmer",
            10: "Kurs Teilnehmer",
            11: "Leistungs Zentrum",
            12: "Rhythmische Gymnastik USI",
            13: "Rhythmische Gymnastik ITV",
            14: "Ehepartner",

            17: "Parkplatzmieter",


            20: "Turn 10",
            22: "Jahreshauptversammlung",

            51: "Bezahlter Trainer",

            410: "Ehrenmitglied",
            500: "Beisitzer",

            600: "Fachwart",
            610: "Fachwart Klettern Stv.",
            620: "Fachwart Klettern",
            630: "Fachwart Rhythmische Gymnastik",
            640: "Fachwart Kinderturnen",
            650: "Fachwart Turn 10",
            660: "Fachwart Gruppen-Gymnastik",
            670: "Fachwart Leistungsturnen wbl.",
            680: "Fachwart Leistungsturnen ml.",
            690: "Fachwart Kondtionstr. & Geräteturnen",


            700: "Gerätewart Stellvertretung",
            800: "Gerätewart",
            900: "Kassier Stellvertretung",
            1000: "Kassier",
            1100: "Schriftwart Stellvertretung",
            1200: "Schriftwart",
            1300: "Obfrau/Obmann Stellvertretung",
            1400: "Obfrau/Obmann",
        },
        additionalFields: [
            {
                type: "select",
                label: "Corona-Status",
                tag: "threeg",
                showIfNonExistent: true,
                editable: "write",
                required: false,
                showInAMS: true,
                props: {
                    selectives: [["gen", "genesen"], ["gei", "geimpft"]],
                    demandSelect: true,
                }
            },
            {
                type: "date",
                label: "1. Corona Impfung",
                tag: "threegDate",
                showIfNonExistent: true,
                editable: "write",
                required: false,
                showInAMS: true,
                props: {}
            },
            {
                type: "date",
                label: "2. Corona Impfung / positiver Test",
                tag: "threegDate6m",
                showIfNonExistent: true,
                editable: "write",
                required: false,
                showInAMS: true,
                props: {}
            },
            {
                type: "date",
                label: "3. Corona Impfung",
                tag: "threegDate3",
                showIfNonExistent: true,
                editable: "write",
                required: false,
                showInAMS: true,
                props: {}
            },
            {
                type: "checkbox",
                label: "Ich bestätige die Richtigkeit der Angaben zu meinem Corona-Status!",
                tag: "threegCorrect",
                showIfNonExistent: true,
                editable: "write",
                required: false,
                showInAMS: false,
                props: {}
            },
        ]
    },
    "mein.oetb-linz.at": {
        verein_ID: 9,
        fullName: "ÖTB Turnverein Linz",
        fullNameDeclined: "ÖTBs Turnverein Linz",
        shortName: "ÖTB Linz",
        domain: "mein.oetb-linz.at",
        title: "VEREINfacht - ÖTB Turnverein Linz",
        showLogo: true,
        logo: <img src={"https://mein.vereinfacht.at/static/logos/linz.png"} height={150} width={"auto"} alt={"ÖTB Linz Logo"}/>,
        iban: "AT93 1860 0000 1000 0537",
        bank: {
            bankname: "Volkskreditbank AG",
            bic: "VKBLAT2LXXX",
        },
        facebook: "tvlinz",
        color: "#2d3483",
        fontColor: "#fff",
        fontColorInverse: "#ded019",
        arnName: "<buero@oetb-linz.at>",
        paymentMethods: ["card", "sofort", "bank", "voucher"],
        arn: "arn:aws:ses:eu-west-1:639823883075:identity/oetb-linz.at",
        email: "buero@oetb-linz.at",
        sendReminders: true,
        website: "oetb-linz.at",
        links: {
            dataprotection: "http://www.oetb-linz.at/datenschutz/",
            statuten: "http://www.oetb-linz.at/images/data/ÖTB%20Turnverein%20Linz%20Statut%202016.pdf",
        },
        address: "Prunerstraße 6, 4020 Linz",
        creditorID: "AT27ZZZ00000068126",
        msFees: {
            junior: 30,
            normal: 50,
            senior: 30,
        },
        additionalFields: [
            {
                tag: "Festnetz",
                label: "Festnetz",
                editable: "internal",
                type: "text",
            },
            {
                tag: "Erziehungsberechtigte",
                label: "Erziehungsberechtigte",
                editable: "internal",
                type: "text",
            }
        ],
        stripeKey: "pk_live_51HHmC7CCTZ0CGdYquTpkS6pUdVVKQYDWcd0OQYbn6QBg3zzK5EnoxSqWAq3ZcbqxcGa3bAF5dJN5hZr4Wp61AwAU00S07uq8hm",
        memberStates: {
            ...memberStatesStandard,
            2: "ordentliches Mitglied (minderjährig)",
            20: "ordentliches Mitglied (Familienmitglied)",

            200: "BTZ",
            210: "TV1862",

            420: "Ehrenmitglied",
            430: "Ehrenobmann",
            440: "außerordentliches Mitglied",

            500: "Beirat",
            655: "Hüttenwart Stellvertretung",
            660: "Hüttenwart",
            670: "Zeugwart",
            680: "Hauswart",
            690: "Jugendwart",
            694: "Dietwart Stv.",
            696: "Dietwart",

            700: "Turnwart Stellvertretung",
            800: "Turnwart",
            900: "Säckelwart Stellvertretung",
            1000: "Säckelwart",
            1100: "Schriftwart Stellvertretung",
            1200: "Schriftwart",
            1300: "Obfrau/Obmann Stellvertretung",
            1400: "Obfrau/Obmann",
        },
    },
    "oetb-ausseerland.vereinfacht.at": {
        verein_ID: 10,
        fullName: "ÖTB Ausseerland",
        fullNameDeclined: "ÖTBs Ausseerland",
        shortName: "ÖTB Ausseerland",
        domain: "oetb-ausseerland.vereinfacht.at",
        title: "VEREINfacht - ÖTB Ausseerland",
        showLogo: true,
        logo: <div><h1 style={{marginTop: "50px", color: "var(--mainColor)"}}>ÖTB Ausseerland</h1></div>,
        iban: "AT61 4501 0350 5633 0000",
        bank: {
            bankname: "Volksbank Salzburg eG",
            bic: "VBOEATWWSAL"
        },
        facebook: "",
        website: "oetb-ausseerland.at",
        color: "#205a9f",
        fontColor: "#fff",
        fontColorInverse: "#bbb",
        arnName: "OTB Ausseerland <oetb-ausseerland@vereinfacht.at>",
        email: "tv.ausseerland@gmail.com",
        paymentMethods: ["bank"],
        arn: "arn:aws:ses:eu-west-1:639823883075:identity/vereinfacht.at",
        sendReminders: true,
        links: {},
        address: "Gotschmanninstraße 128, 8990 Bad Aussee",
        creditorID: "AT15ZZZ00000068051",
        stripeKey: "pk_live_51HIA3tJjEGJuJAEkotDLz5DzYDZkPeHLGF8lmvzs1W0f3BxqdNCJjiOmXqTo7MrVhpzpKU3ebglk49T3CuBqIqTX00WqRdLZ6F",
        msFees: {
            junior: 35,
            normal: 65,
            senior: 65,
        },
        memberStates: {
            ...memberStatesStandard,
            2: "ordentliches Mitglied Kind/Jugend",
            450: "Jugendwart",
            500: "Turnwart Stellvertretung",
            600: "Turnwart",
            900: "Säckelwart Stellvertretung",
            1000: "Säckelwart",

        },
    },
    "tv-steyr.vereinfacht.at": {
        verein_ID: 11,
        fullName: "Turnverein Steyr 1861",
        fullNameDeclined: "Turnvereins Steyr 1861",
        shortName: "TV Steyr",
        domain: "tv-steyr.vereinfacht.at",
        title: "VEREINfacht - TV Steyr",
        showLogo: true,
        logo: <img className="img-fluid" src="https://mein.vereinfacht.at/static/logos/steyr.png" width="220" height="auto" alt=""/>,
        iban: "AT40 3411 4000 0000 6932",
        facebook: "tvsteyr1861",
        color: "#4ca23a",
        fontColor: "#fff",
        fontColorInverse: "#1b2d6f",
        website: "tv-steyr.at",
        arnName: "Turnverein Steyr 1861 <turnverein@tv-steyr.at>",
        email: "turnverein@tv-steyr.at",
        paymentMethods: stripeMethods,
        arn: "arn:aws:ses:eu-west-1:639823883075:identity/tv-steyr.at",
        sendReminders: true,
        links: {
            statuten: "http://www.tv-steyr.at/unser-verein/statuten",
            dataprotection: "http://www.tv-steyr.at/fileadmin/vereinsdateien/tvsteyr/Anmeldung/Zustimmungserkl._fuer_Homepage.pdf",
        },
        bank: {
            bankname: "Raiffeisenbank Steyr eGen",
            bic: "RZOOAT2L114",
        },
        address: "Fachschulstraße 1, 4400 Steyr",
        creditorID: "AT67ZZZ00000068182",
        stripeKey: "pk_live_51HSlnzJqti006tpawTfdjklTGkwlkw4PSGGNCBVjIMhk32bUssrB2tO2uvNAXI6E3Rkj289TARjjfSuOwT8qrKGr00ZOS4hyNC",
        msFees: {
            junior: 90,
            normal: 110,
            senior: 110,
        },
        memberStates: {
            ...memberStatesStandard,
            4: "3. Familienmitglied",
        },
        roomDependencies: [
            {localRoomID: 3415, foreignRoomID: 3230},
            {localRoomID: 3418, foreignRoomID: 3241},
            {localRoomID: 3421, foreignRoomID: 3242},
        ],
    },
    "atv-schwertberg.vereinfacht.at": {
        verein_ID: 12,
        fullName: "Allgemeiner Turnverein Schwertberg",
        fullNameDeclined: "Allgemeinen Turnverein Schwertberg",
        shortName: "ATV Schwertberg",
        domain: "atv-schwertberg.vereinfacht.at",
        title: "VEREINfacht - ATV Schwertberg",
        showLogo: true,
        logo: <img className="img-fluid" src="https://mein.vereinfacht.at/static/logos/schwertberg.gif" width="300" height="auto" alt=""/>,
        iban: "AT41 3461 3000 0001 2955",
        facebook: "",
        color: "#039ec3",
        fontColor: "#fff",
        fontColorInverse: "#d1dcdb",
        website: "atv-schwertberg.at",
        arnName: "ATV Schwertberg <atv-schwertberg@vereinfacht.at>",
        email: "obmann@atv-schwertberg.at",
        paymentMethods: ["bank", "voucher"],
        arn: "arn:aws:ses:eu-west-1:639823883075:identity/vereinfacht.at",
        sendReminders: true,
        links: {
            statuten: "http://atv.hametner.info/index.php?option=com_content&view=article&id=60&Itemid=55",
            dataprotection: "https://atv-schwertberg.vereinfacht.at/nextcloud/index.php/s/yJFstgHs8qFydeF",
        },
        bank: {
            bankname: "Raiffeisenbank Schwertberg eGen",
            bic: "RZOOAT2L613",
        },
        address: "Stelzhamerstraße 14, 4311 Schwertberg",
        creditorID: "",
        msFees: {
            junior: 20,
            normal: 35,
            senior: 35,
            supporter: 20,
        },
        memberStates: {
            ...memberStatesStandard,
            4: "ordentliches Mitglied (Familie)",
            650: "Beirat",
            670: "Rechnungsprüfer",
        },
    },
    "gunclub.vereinfacht.at": {
        verein_ID: 13,
        fullName: "Gun Club Lustenau",
        fullNameDeclined: "Gun Club Lustenau",
        shortName: "Gun Club",
        domain: "gunclub.vereinfacht.at",
        title: "VEREINfacht - Gun Club",
        showLogo: true,
        logo: <img className="img-fluid" src="https://mein.vereinfacht.at/static/logos/gunclub.png" width="300" height="auto" alt=""/>,
        iban: "AT383742000000217612",
        facebook: "",
        color: "#030303",
        fontColor: "#fff",
        fontColorInverse: "#f50303",
        website: "gunclub.at",
        arnName: "Gun Club Lustenau <gunclub@vereinfacht.at>",
        email: "post@gunclub.at",
        paymentMethods: ["bank", "voucher"],
        arn: "arn:aws:ses:eu-west-1:639823883075:identity/vereinfacht.at",
        sendReminders: true,
        links: {},
        bank: {
            bankname: "Raiffeisen im Rheintal",
            bic: "RVVGAT2B420",
        },
        address: "Rasis Bündt 15, 6890 Lustenau",
        creditorID: "AT20ZZZ00000069610",
        msFees: {
            junior: 20,
            normal: 35,
            senior: 35,
        },
        memberStates: {
            ...memberStatesStandard,

            5: "Standnutzer",
            500: "Zeugwart Stellvertretung",
            600: "Zeugwart",

            700: "Standaufsicht",
            800: "Matchwart",
            1300: "Obmann/Oberschützenmeister Stellvertretung",
            1400: "Obmann/Oberschützenmeister",
        },
        additionalFields: [
            {
                type: "select",
                label: "Ausweisart",
                tag: "idKind",
                showIfNonExistent: true,
                editable: "registerWrite",
                required: true,
                props: {
                    selectives: [["wbk", "WBK/Waffenpass/Jagdkarte"], ["pass", "Reisepass/Personal-Ausweis"]],
                    demandSelect: true,
                }
            },
            {
                type: "text",
                label: "Reisepass/Personal-Ausweis-Nummer",
                tag: "idNumber",
                showIfNonExistent: true,
                editable: "registerWrite",
                required: false,
                props: {
                    ph: "123456789"
                }
            },
            {
                type: "file",
                label: "Waffenrechtliches Dokument / Reisepass / Personalausweis",
                info: <InfoTooltip>Hinweis: Waffenrechtliches Dokument für die Mitgliedschaft erforderlich!</InfoTooltip>,
                tag: "wbk",
                showIfNonExistent: true,
                editable: "registerWrite",
                required: true,

            }
        ]
    },
    "atv-andorf-schwimmen.vereinfacht.at": {
        verein_ID: 14,
        fullName: "Schwimmen / ÖTB Allgemeiner Turnverein Andorf",
        fullNameDeclined: "Schwimmen / ÖTB Allgemeiner Turnverein Andorf",
        shortName: "ATV Andorf Schwimmen",
        domain: "atv-andorf-schwimmen.vereinfacht.at",
        title: "VEREINfacht ATV Andorf Schwimmen",
        //color: "rgba(36,118,157,0.51)",
        color: "#24769D",
        fontColor: "#fff",
        fontColorInverse: "#BAD460",
        // color: "rgb(186,212,96)",
        email: "martin.doblinger@gmx.at",
        showLogo: true,
        logo: <img id="comp-jnlkxecbimgimage" alt="ATV Andorf Logo_bearbeitet.png" data-type="image" itemProp="image"
                   src="https://static.wixstatic.com/media/69b39a_c2d9b5e1b11a45b89b266fb2d5621822~mv2.png/v1/fill/w_230,h_166,al_c,usm_0.66_1.00_0.01/ATV%20Andorf%20Logo_bearbeitet.png"/>,
        iban: "AT14 2032 0321 0057 7176",
        bank: {
            bic: "ASPKAT2LXXX",
            bankname: "Allgemeine Sparkasse Oberösterreich BankAG",
        },
        facebook: "atvandorf",
        paymentMethods: ["bank"],
        website: "atv-andorf.com",
        memberStates: {
            ...memberStatesStandard,
            650: "Abteilungsleitung Schwimmen",

            740: "2. Säckelprüfer/in",
            750: "1. Säckelprüfer/in",
            900: "Säckelwart Stellvertretung",
            1000: "Säckelwart",
            1100: "Schriftwart Stellvertretung",
            1200: "Schriftwart",
            1300: "Obfrau/Obmann Stellvertretung",
            1400: "Obfrau/Obmann",
        },
        arnName: "ATV Andorf <atv-andorf@vereinfacht.at>",
        arn: "arn:aws:ses:eu-west-1:639823883075:identity/vereinfacht.at",
        sendReminders: true,
        address: "Hans-Holz-Straße 1, 4770 Andorf",
        creditorID: "AT50ZZZ00000010579",
        phone: 4369910550498,
        links: {
            dataprotection: "https://www.atv-andorf.com/sonstiges",
        },
//
        msFees: {
            junior: 25,
            normal: 35,
            senior: 35,
            supporter: 15,
        },
        otherClubs: [4],
    },
    "vbc-steyr.vereinfacht.at": {
        verein_ID: 15,
        fullName: "Union Volleyballclub Steyr  ",
        fullNameDeclined: "Union Volleyballclubs Steyr  ",
        shortName: "VBC Steyr",
        domain: "vbc-steyr.vereinfacht.at",
        title: "VBC Steyr",
        //color: "rgba(36,118,157,0.51)",
        color: "#08097F",
        fontColor: "#fff",
        fontColorInverse: "#DDE5F2",
        // color: "rgb(186,212,96)",
        email: "unionvbcsteyr@gmail.com",
        showLogo: true,
        logo: <img alt="Union VBC Steyr Logo_bearbeitet.png" data-type="image" itemProp="image" src="https://mein.vereinfacht.at/static/logos/vbc_steyr.jpg"/>,
        iban: "AT86 3227 8000 0008 3378",
        bank: {
            bic: "ASPKAT2LXXX",
            bankname: "Raiffeisenbank Haidershofen",
        },
        facebook: "UnionVbcSteyr",
        paymentMethods: stripeMethods,
        website: "volleyball-steyr.com",
        memberStates: {
            ...memberStatesStandard,
            2: "ordentliches Mitglied (Jugend)",
            4: "ordentliches Mitglied (Student)",
            650: "Abteilungsleitung Schwimmen",
        },
        arnName: "VBC Steyr <vbc-steyr@vereinfacht.at>",
        arn: "arn:aws:ses:eu-west-1:639823883075:identity/vereinfacht.at",
        sendReminders: true,
        address: "Rastgruberstraße 17, 4451 Garsten",
        creditorID: "",
        phone: 436641225201,
        links: {
            dataprotection: "https://www.volleyball-steyr.at/datenschutz",
        },
//
        msFees: {
            junior: 90,
            normal: 110,
            senior: 110,
            supporter: 50,
        },
        roomDependencies: [
            {localRoomID: 3230, foreignRoomID: 3415},
            {localRoomID: 3241, foreignRoomID: 3418},
            {localRoomID: 3242, foreignRoomID: 3421},
        ],
    },
    "oetb-pichling.vereinfacht.at": {
        verein_ID: 16,
        fullName: "ÖTB Turnverein Pichling",
        fullNameDeclined: "ÖTB Turnvereins Pichling",
        shortName: "ÖTB Pichling",
        domain: "oetb-pichling.vereinfacht.at",
        title: "ÖTB Pichling",
        color: "#bdd94d",

        email: "oetbpichling@gmail.com",
        showLogo: true,
        logo: <img alt="ÖTB Pichling" data-type="image" itemProp="image" src="https://mein.vereinfacht.at/static/logos/pichling.jpg" width={250}/>,
        iban: "AT88 2032 0175 0005 5237",
        bank: {
            bic: "ASPKAT2LXXX",
            bankname: "Allgemeine Sparkasse Oberösterreich Bankaktiengesellschaft",
        },
        facebook: "",
        paymentMethods: ["bank"],
        website: "oetb-pichling.jimdosite.com",
        memberStates: {
            ...memberStatesStandard,
        },
        arnName: "OETB Turnverein Pichling <oetb-plichling@vereinfacht.at>",
        arn: "arn:aws:ses:eu-west-1:639823883075:identity/vereinfacht.at",
        sendReminders: true,
        address: "Neufelderstraße 54, 4030 Linz",
        creditorID: "",
        phone: 0,
        links: {
            dataprotection: "https://oetb-pichling.jimdosite.com/datenschutz/",
        },
        msFees: {
            junior: 0,
            normal: 0,
            senior: 0,
            supporter: 0,
        },
    },
    "valentinmuseum.vereinfacht.at": {
        verein_ID: 17,
        fullName: "Geschichtliches Museum der Stadt St. Valentin",
        fullNameDeclined: "Geschlichten Museums der Stadt St. Valentin",
        shortName: "Museum St. Valentin",
        domain: "valentinmuseum.vereinfacht.at",
        title: "Museum St. valentin",
        color: "#0088c2",
        fontColor: "#f9dd33",

        email: "office@valentinmuseum.at",
        showLogo: true,
        logo: <img src="https://valentinmuseum.at/source/logo.png" width={320}/>,
        iban: "AT26 4392 0000 0023 5671",
        bank: {
            bic: "VOENAT21XXX",
            bankname: "Volksbank Enns - St. Valentin eG",
        },
        facebook: "valentinmuseum",
        paymentMethods: ["bank"],
        website: "valentinmuseum.at",
        memberStates: {
            4: "Familienmitglied",
            5: "Gemeinde",
            ...memberStatesStandard,
        },
        arnName: "Geschichtliches Museum der Stadt St. Valentin <valentinmuseum@vereinfacht.at>",
        arn: "arn:aws:ses:eu-west-1:639823883075:identity/vereinfacht.at",
        sendReminders: true,
        address: "Hauptplatz 5, 4300 St. Valentin",
        creditorID: "",
        phone: 436645644055,
        links: {},
        msFees: {
            junior: 22,
            normal: 22,
            senior: 22,
        },
    },
    "atv-weyer.vereinfacht.at": {
        verein_ID: 18,
        fullName: "Allgemeiner Turnverein Weyer",
        fullNameDeclined: "Allgemeinen Turnvereins Weyer",
        shortName: "ATV Weyer",
        domain: "atv-weyer.vereinfacht.at",
        title: "VEREINfacht ATV Weyer",
        color: "#9ac77d",

        email: "office@turnvereinweyer.at",
        showLogo: true,
        logo: <img alt="ATV Weyer" data-type="image" itemProp="image" src="https://mein.vereinfacht.at/static/logos/weyer.png" width={150}/>,
        iban: "AT29 2032 0056 0000 3082",
        bank: {
            bic: "ASPKAT2LXXX",
            bankname: "Allgemeine Sparkasse Oberösterreich Bankaktiengesellschaft",
        },
        stripeKey: "pk_live_51JagFSEHPuUAs2vi57DNRMKskBqsTTbvRft9iOkzMIwPzRqEBMDbLAQIwJTmIwa1s3McK5sjnU0i7igy56DHysOE00GdimHIvx",
        facebook: "turnvereinweyer",
        paymentMethods: stripeMethods,
        website: "turnvereinweyer.at",
        memberStates: {
            ...memberStatesStandard,
            1: "Mitglied (Erwachsen)",
            2: "Mitglied (Kind/Jugend)",
            4: "Mitglied (Student)",
        },
        arnName: "Allgemeiner Turnverein Weyer <atv-weyer@vereinfacht.at>",
        arn: "arn:aws:ses:eu-west-1:639823883075:identity/vereinfacht.at",
        sendReminders: true,
        address: "Waidhofner Straße 14, 3335 Weyer",
        creditorID: "AT82ZZZ00000050258",
        phone: 0,
        links: {
            statuten: "https://atv-weyer.vereinfacht.at/nextcloud/index.php/s/PpaLTFp92PkK4Tj",
            dataprotection: "http://turnvereinweyer.at/datenschutz/",
        },
        msFees: {
            junior: 10,
            normal: 20,
            senior: 20,
        },
    },
};
const domainMap: _domainMap = Object.keys(domainMapInitial).reduce((obj, curr) => ({
    ...obj,
    [curr]: {
        ...domainMapInitial[curr],
        iban: domainMapInitial[curr].iban,
        memberStatesShort: Object.keys(domainMapInitial[curr].memberStates)
            .reduce((obj, k) => ({
                ...obj,
                [parseInt(k)]: domainMapInitial[curr].memberStates[parseInt(k)].split(" ").map((u: any) => u.replace("(", "")[0]).join(".")
            }), {})
    }
}), {});
const domainName = window.location.hostname.split(".").slice(-2).join(".");
const domainNameLong = window.location.hostname.split(".").slice(-3).join(".");
const clubMap = Object.keys(domainMap).reduce((obj, curr) => ({...obj, [domainMap[curr].verein_ID]: curr}), {});


let ClubPart = domainMap[domainNameLong] || (
    (domainName.indexOf("localhost") > -1 || domainName.indexOf("10.0.0") > -1 || !domainMap[domainName]) ?
        domainMap["vereinfacht.at"] :
        domainMap[domainName]
);
// @ts-ignore
if (window?.VEREINfachtConfig?.verein_ID) {
// @ts-ignore
    ClubPart = Object.values(domainMap).find(a => a.verein_ID === window?.VEREINfachtConfig?.verein_ID) || domainMap["vereinfacht.at"];
}

if (Consts.f(true, false)) {

    //ClubPart = domainMap["tv-enns.vereinfacht.at"];
    //ClubPart = domainMap["schwanenstadt.vereinfacht.at"];
    //ClubPart = domainMap["meine.bewegungswelt.at"];
    //ClubPart = domainMap["mein-turnverein.at"];
    //ClubPart = domainMap["vereinfacht.at"];
    //ClubPart = domainMap["my.vereinfacht.com"];
    //ClubPart = domainMap["atv-andorf.vereinfacht.at"];
    ClubPart = domainMap["mein.oetb-linz.at"];
    //ClubPart = domainMap["itv.vereinfacht.at"];
    //ClubPart = domainMap["tv-taufkirchen.vereinfacht.at"];
    //ClubPart = domainMap["tv-steyr.vereinfacht.at"];
    //ClubPart = domainMap["atv-schwertberg.vereinfacht.at"];
    //ClubPart = domainMap["gunclub.vereinfacht.at"];
    //ClubPart = domainMap["atv-andorf-schwimmen.vereinfacht.at"];
    //ClubPart = domainMap["vbc-steyr.vereinfacht.at"];
    //ClubPart = domainMap["turnvereinmondsee.vereinfacht.at"];
    //ClubPart = domainMap["valentinmuseum.vereinfacht.at"];
    //ClubPart = domainMap["atv-weyer.vereinfacht.at"];
    //ClubPart = domainMap["atv-test.vereinfacht.at"];

}

const Club = {
    ...ClubPart,
}; // domain: window.location.host

const placeholderClub: Club = {
    verein_ID: -1,
    fullName: "",
    fullNameDeclined: "",
    shortName: "",
    domain: "vereinfacht.at",
    title: "VEREINfacht",
    color: "#aaa",
    fontColor: "black",
    fontColorInverse: "black",
    email: "max@vereinfacht.at",
    showLogo: true,
    // @ts-ignore
    logo: "",
    iban: "",
    bic: "",
    bankname: "",
    stripeKey: "",
    paymentMethods: stripeMethods,
    website: "vereinfacht.at",
    memberStates: {
        ...memberStatesStandard,
    },
    arnName: "",
    arn: "",
    sendReminders: true,
    address: "",
    creditorID: "",
    phone: 0,
    links: {},
    msFees: {
        junior: 40,
        normal: 50,
        senior: 50,
        supporter: 20,
    },
}


export {domainMap, clubMap, placeholderClub, Club};
