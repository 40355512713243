import React, {useContext, useState} from 'react';
import "../dashboard.sass"


import UserEdit from "./user_edit";
import Abrechnung from "../abrechnung/main";
import {UserContext} from "./UserContext";
import UserHistory from "./user_history";
import {Container, Loader} from "../core/components/components";
import {MyTabsExtended} from "../core/components/tabs";
import {FaArrowCircleLeft, FaTrashAlt, FaUserTimes} from "react-icons/fa";
import {Link} from "react-router-dom";
import ChildRoles from "./user_roles";
import DeathAdd from "./user_death";
import {maxiPost} from "../core/maxios";
import Status from "../core/status";
import {checkPermissions} from "../club/permissionsHelpers";


function UserView(props) {

    const context = useContext(UserContext);
    //const [id, setId] = useState(props.match.params.userID);
    const id = props.match.params.userID
    const user = context.user;
    const [child, setChild] = useState(null);

    return <>
        <Container>

            {user !== undefined && checkPermissions(context, "user_read") &&
                <>
                    <MyTabsExtended tabs={[
                        {
                            name: "Benutzerdaten",
                            content: <UserEdit id={id} key={id} setChild={setChild}/>,
                        },
                        {
                            name: "Rollen",
                            content: <ChildRoles child={child} childID={id} key={id}/>
                        },
                        {
                            name: "Benutzer Geschichte",
                            content: <UserHistory userID={id} key={id} personType={"user"}/>
                        },
                        {
                            name: "Abrechnung",
                            content: <Abrechnung child={child} match={props.match} key={id} noWrapper/>
                        },
                    ]}
                    >
                        <div style={{float: "right", marginTop: "20px"}}>
                            <FaArrowCircleLeft style={{marginBottom: "-2px"}}/>
                            <em onClick={() => window.history.back()}> zurück</em>
                            <FaArrowCircleLeft style={{marginBottom: "-2px"}}/>
                            <Link to="/benutzer/liste"> zur Übersicht</Link> <br/><br/>
                        </div>
                        <h1>Benutzer{!!child?.fullname ? " " + child?.fullname : "-Administration"}</h1>

                    </MyTabsExtended>
                    <br/>
                    <br/>

                </>
            }
            {
                !checkPermissions(context, "user") &&
                <Container name={"Benutzerdaten"}>
                    <UserEdit id={id}/>
                </Container>
            }
            {
                user === undefined && <Loader loading={true}/>
            }
        </Container>
        <Container>
            {
                checkPermissions(context, "user") && <>

                    <DeathAdd childID={id}/> <DeleteUser child_ID={id}/>
                </>
            }

        </Container></>
}


function DeleteUser({child_ID}) {
    const [{error}, setStatusVar] = useState({})
    const removeGDPR = () => window.confirm("Diese Person wirklich anonymisieren? Dies kann nicht rückgängig gemacht werden") && maxiPost("/user/gdpr_removal", {child_ID}, {setStatusVar})
        .then(() => window.history.back())
    const remove = () => window.confirm("Diese Person wirklich löschen? Dies führt dazu, dass sich diese Person nicht mehr einloggen kann. Weiters wird sie stets als gelöscht angezeigt.") && maxiPost("/user/removal", {child_ID}, {setStatusVar})
        .then(() => window.history.back())
    return <>
        <em onClick={removeGDPR}><FaTrashAlt/> Person gemäß DSGVO enfernen / anonymisieren</em>
        <em onClick={remove}><FaUserTimes/> Person löschen (Markierung als gelöscht)</em>
        <br/>
        <br/>
        <Status type={"error"} text={error}/>
    </>
}

export default UserView;
