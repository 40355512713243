import React from "react";

import {FormContext} from "../core/form_context";
import {dateFormatTimeIfNotMidnight, dateFormatUnix} from "../core/dateFuncs";
import ReactImageFallback from "react-image-fallback";
import {UserContext} from "../user/UserContext";
import UploadTrainerPicture from "../user/profile_picture";
import {Link} from "react-router-dom";


const textAreaPrint = (inp) => !!inp ? inp.split("\n").map(l => [l, <br/>]) : "";

export default class CourseDesription extends React.Component {
    static contextType = FormContext;

    render() {
        const {course: c, trainers} = this.context.state;
        if (c === null || c === undefined) {
            return null
        }
        const short = false;

        const now = new Date().getTime() / 1000;

        return <>
            {/*<div id={"course_description"} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(c.description)}}/>*/}
            <p>{textAreaPrint(c.description)}</p>
            {
                !!c.events_start && <>von {dateFormatUnix(c.events_start)} bis {dateFormatUnix(c.events_end)}, siehe genauere Termininfo unten</>
            }

            <br/>


            {!short && <>
                {(!c.audience || c.audience === "") &&
                <>
                    {
                        !!c.olderthan &&
                        <>
                            {c.olderthan < 1 && <>ab <b>{Math.round(c.olderthan * 12)} Monaten</b></>}
                            {c.olderthan > 1 && <>ab <b>{Math.round(c.olderthan)} Jahren</b></>}
                            {c.olderthan === 1 && <>von <b>einem Jahr</b></>}
                        </>
                    }
                    {
                        !!c.youngerthan && 0 < c.youngerthan && c.youngerthan < 100 &&
                        <>
                            {c.youngerthan < 2 && <> bis <b>{Math.round(c.youngerthan * 12)} Monate</b></>}
                            {c.youngerthan >= 2 && <> bis <b>{c.youngerthan} Jahre</b></>}
                        </>
                    }
                    {(c.yearAllowedLeft || c.yearAllowedRight) && "für Jahrgänge"}
                    {c.yearAllowedLeft && " ab inklusive " + c.yearAllowedLeft}
                    {(c.yearAllowedLeft && c.yearAllowedRight) && " und "}
                    {c.yearAllowedRight && " bis inklusive " + c.yearAllowedRight}
                    <br/>
                </>
                }
            </>
            }

            {!!c.audience && <span>Zielgruppe: <b>{c.audience}</b><br/></span>}

            {!short && (!(c.bookingStart < now && c.bookingEnd > now)) && <><b>{c.bookingStart > now ? "buchbar ab " + dateFormatTimeIfNotMidnight(c.bookingStart) : "nicht mehr buchbar"}</b><br/></>}


            {
                c.permittedCourses && c.permittedCourses.length>0 && <>
                    <b>Dieses Angebot berechtigt zur Teilnahme an folgenden Kursen</b>
                    <ul>

                        {
                            c.permittedCourses.sort((a,b)=>a.name<b.name?-1:1).map((cc) => <li><Link to={"/kurs/buche/" + cc.ID}>{cc.name}</Link></li>)
                        }
                    </ul>
                </>
            }

            <b>Leitung</b>:
            <UserContext.Consumer>
                {
                    ({user}) =>
                        <div className={"trainersList"}>
                            {
                                trainers.map(t => {
                                    const image = t.image ? <ReactImageFallback
                                        src={`https://vereinfacht-public.s3-eu-west-1.amazonaws.com/profile_pictures/${c.verein_ID}/${t.image.indexOf("_mid.jpg") > -1 ? t.image : t.image.replace(".jpg", "_mid.jpg")}`} // "https://www.turnverein-st-valentin.at/timthumb/timthumb.php?src=/files/turnverein/team/" + t.image + "&w=600&h=640&a=t"
                                        fallbackImage="/static/img/team_fallback.jpg"
                                        initialImage="https://uid-suche.eu/src/img/ajax-loader-fff.gif"
                                        width
                                        alt={t.fullname}
                                    /> : <img src={"/static/img/team_fallback.jpg"}/>;
                                    return <div> {
                                        !!user && (user.role > 10 || (t.ID === user.ID && user.verein_ID !== 0)) ?
                                            <UploadTrainerPicture child={t} trigger={image}/> :
                                            image
                                    }
                                        {user.verein_ID === 0 ? <span><Link to={"/profil/" + t.ID}>{t.fullname}</Link></span> : <span>{t.fullname}</span>}
                                    </div>
                                })
                            }
                        </div>
                }
            </UserContext.Consumer>
            {!!c.information &&
            <>
                <h3>Informationen: </h3>
                <p>{textAreaPrint(c.information)}</p>
            </>
            }
        </>
    }
}
