import {Container, InfoTooltip, MaxBtn, MaxLink, MiniBtn} from "../core/components/components";
import {MyModal} from "../core/components/modal";
import {FaUserEdit} from "react-icons/fa";
import UserHistory from "../user/user_history";
import React, {useContext, useMemo, useRef, useState} from "react";
import {maxiGet, maxiPost} from "../core/maxios";
import {UserContext} from "../user/UserContext";
import Status from "../core/status";
import {encodeGetParams} from "../core/helpers";
import Consts from "../core/consts";

import {checkPermissions} from "../club/permissionsHelpers";
import {FormContextWrapper} from "../core/form_context";
import {DiscountInput} from "../core/input/discountInput";
import {ParticipantPaymentListModal} from "./paymentPlan/course_parts_payment_list";

const t0 = (new Date()).getTime() / 1000;

export function PartsEdit({original, value = null, course_ID, course, reload: reloadRaw, children = null}) {
    const context = useContext(UserContext);
    const [keyCounter, setKeyCounter] = useState(0);
    const [discount, setDiscount] = useState({})

    const [{loading, error, success}, setStatusVar] = useState({})
    const {registration,} = original;

    const reload = () => {
        setKeyCounter(a => a + 1)
        reloadRaw()

    }

    const doDeregister = (refund) => () => maxiPost("/course/deregister?refund=" + refund, {
        course_ID,
        child_ID: original.child.ID,
        course_parts_ID: registration.ID,
    }, {setStatusVar}).then(()=>{
        reload()
          if (registration.price>0 && refund && context.club.sendInvoicePDFs) {
            alert("Es wurde möglicherweise eine Storno-Rechnung automatisch erstellt, jedoch musst du diese selbst direkt aussenden.")
        }
    })

    const applyDiscount = () => {
        const {course_ID, child_ID, ID} = registration
        const {discountCode} = discount
        maxiPost("/discount/apply", {
            course_ID, child_ID, ID, discountCode
        }, {setStatusVar}).then(reload)
    }

    if (!checkPermissions(context, "course/participants_read", course_ID)) {
        return null
    }

    return <MyModal trigger={<em>{children}<FaUserEdit/></em>}>
        <Container name={"Teilnahme bearbeiten"}>
            <Status type={"error"} text={error}/>
            <Status type={"success"} text={success}/>
            {
                registration.timeLeave === 0 ?
                    <div className={"breakTableField"} style={{whiteSpace: "break"}}>
                        {
                            ((registration.role === 0 || registration.role === 2) && registration.timeLeave === 0) &&
                            <>
                                <div>
                                    <MiniBtn onClick={doDeregister(0)}>
                                        {registration.role === 2 ? "streichen" : (registration.price === 0 ? "abmelden" : "abmelden und Kosten nicht gutschreiben")}
                                    </MiniBtn>
                                </div>
                                {
                                    registration.role === 0 && <>
                                        {registration.price !== 0 &&
                                            <div>
                                                <MiniBtn onClick={doDeregister(1)}>
                                                    abmelden und Kosten gutschreiben
                                                </MiniBtn>
                                            </div>
                                        }
                                        <div>
                                            <MiniBtn onClick={() => maxiPost("/course/toggleRole", {course_ID, child_ID: original.child.ID, role: 2, course_parts_ID: registration.ID}, {setStatusVar}).then(reload)}>
                                                erneut auf Warteliste setzen
                                            </MiniBtn>
                                        </div>
                                    </>
                                }
                                {
                                    registration.role === 2 &&
                                    <div>
                                        <MiniBtn onClick={() => {
                                            if (!registration.informed || window.confirm("Die Person wird hierbei nicht automatisch informiert (im Gegensatz zu automatischen Erhebung von der Warteliste.")) {
                                                maxiPost("/course/register?force", {course_ID, child_ID: original.child.ID}, {setStatusVar}).then(reload)
                                            }
                                        }}>
                                            aufnehmen von WL
                                        </MiniBtn>
                                    </div>
                                }
                            </>
                        }
                        {
                            checkPermissions(context, "course/participants", course_ID) && registration.timeLeave === 0 &&
                            <div>
                                <MiniBtn
                                    onClick={() => maxiPost("/course/toggleRole", {course_ID, child_ID: original.child.ID, role: 1, course_parts_ID: registration.ID}, {setStatusVar}).then(reload)}>
                                    {registration.role !== 1 ? "mache zum Trainer" : "entziehe Trainerstatus"}
                                </MiniBtn>
                            </div>
                        }
                        {
                            checkPermissions(context, "course/participants", course_ID) && (course?.coursePaymentModel_ID ?? null) !== null &&
                            <div>
                                <ParticipantPaymentListModal participantPayments={course.payments} course={course} onChange={reload} label={"Zahlungsfälligkeiten anpassen"}/>
                            </div>
                        }
                        <br/>
                        <p><b>Bemerkung zu Teilnahme:</b> <InfoTooltip>Z.B. Einteilung in Trainingsgruppen – wird dem Teilnehmer ebenfalls angezeigt und ist nur von Adminstratoren vorzunehmen.</InfoTooltip></p>
                        <AdminEdit registration={registration} reload={reload} field={"noteAdmin"}/>
                        <br/>
                        <p><b>Preis</b></p>
                        <AdminEdit registration={registration} reload={reload} field={"price"}/>


                    </div> :
                    "nichts zu machen"
            }
        </Container>

        <Container name={"Rabatte"}>
            {!registration.discount_ID ? <FormContextWrapper value={{state: discount, setState: setDiscount}}>
                <DiscountInput child_ID={registration.child_ID} course_ID={registration.course_ID} course_parts_ID={registration.ID}/>
                {!isNaN(discount.discountAmount) && <MaxBtn onClick={applyDiscount}>Anwenden</MaxBtn>}
            </FormContextWrapper> : <p>Es wurde ein Rabatt in Höhe von {Consts.moneyMax((registration.price_nominal - registration.price) / 100)} eingelöst.</p>}
        </Container>

        {
            registration.timeStart > 0 && course.tags !== "1" &&
            <LoadAttendance courseID={registration.course_ID} childID={registration.child_ID}/>
        }
        <Container name={"Protokoll"}>
            <UserHistory userID={registration.ID} key={keyCounter} personType={"course_parts"}/>
        </Container>
    </MyModal>
}


export function AdminEdit({registration, reload, focus, field}) {
    const isPrice = ["price"].includes(field)
    const [value, setValue] = useState(isPrice ? registration[field] / 100 : registration[field]);
    const ref = useRef();
    useMemo(() => {
        if (focus) {
            window.setTimeout(() => {
                if (ref.current) {
                    ref.current.focus()
                }
            }, 100)
        }
    }, [focus])
    const store = () => value !== registration[field] ? maxiPost("/course/parts/setAdmin", {ID: registration.ID, [field]: isPrice ? value * 100 : value}).then(() => reload(true)) : reload(false);
    return <form onSubmit={e => {
        e.preventDefault()
        store()
    }} style={{height: 13, marginTop: -4, paddingTop: 0}}>
        <input
            ref={ref}
            value={value}
            style={{height: 13}}
            onChange={e => {
                e.preventDefault()
                setValue(e.target.value)
            }
            }
            onBlur={store}
        />
    </form>

}

function LoadAttendance({courseID, childID}) {
    const [stats, setStats] = useState(null);
    const [attendances, setAttendances] = useState([]);
    useMemo(() => {
        maxiGet("/course/attendances/user/stats?" + encodeGetParams({courseID, childID})).then(({attendances, stats}) => {
            setAttendances(attendances)
            setStats(stats)
        })
    }, [courseID, childID])
    return <div>
        <MaxLink style={{float: "right"}} to={`/kurs/${courseID}/personenanwesenheit/${childID}`}>Mehr anzeigen</MaxLink>
        <h2>Anwesenheit</h2>
        <table className={"table"}>
            {
                stats && [1, 3, 2, 0].map((i) => {
                    const {status, count, ratio} = stats[i];
                    return <tr>
                        <td>{Consts.attendanceExprMap[status]}</td>
                        <td>{count} Mal</td>
                        <td>{Math.round(ratio * 100)} %</td>
                    </tr>
                })
            }
        </table>

    </div>
}
