import React, {Component, useMemo, useState} from "react";
/*
export default function AsyncComponentWrapper({myImport, children, ...props}) {
    const [Component, setComponent] = useState(React.Fragment);
    useMemo(() => {
        myImport().then(component => setComponent(component.default));
    }, []);
    return <Component {...props}>
        {children}
    </Component>
}
*/


export default class AsyncComponentWrapper extends Component {
    state = {
        component: null
    };

    async componentDidMount() {
        const {default: component} = await this.props.myImport();

        this.setState({
            component
        });
    }

    render() {
        const C = this.state.component;

        return C ? <C {...{...this.props, myImport: undefined}} /> : null;
    }
}
