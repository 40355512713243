import React from 'react';
import {Container, LightContainer, Loader, MaxBtn, MiniBtn} from "../core/components/components";
import {MyModal} from "../core/components/modal";
import {MyReactTable} from "../core/components/table";
import {TextareaInput} from "../core/input/basic";
import "./course_form.css"
import Status from "../core/status";
import {apiGet, apiPost} from "../core/api";
import {Link} from "react-router-dom";
import {dateFormatTime, timeFormat} from "../core/dateFuncs";
import {FormContextWrapper} from "../core/form_context";
import {FaChartPie} from "react-icons/fa";
import {UserContext} from "../user/UserContext";
import {encodeGetParams} from "../core/helpers";
import Consts from "../core/consts";
import DOMPurify from "dompurify"

export default class CourseAttendance extends React.Component {

    static contextType = UserContext;

    constructor(props) {
        super(props);
        this.apiGet = apiGet.bind(this);

        this.apiPost = apiPost.bind(this);
        this.setState = this.setState.bind(this);
        this.state = {
            error: "",
            events: [],
            parts: [],
            loading: true,
            attendancesMap: {},
            attendancesOld: {},
            usersAttendancesNewest: [],
            usersAttendancesOld: {},
            editMode: false,
        };
        this.componentWillReceiveProps(props, {})

    }

    componentDidMount() {
        const {eventID} = (this.props.match || {}).params;
        if (!(this.props.location || {}).state && !!eventID) {
            this.apiGet("/events/coursebyeventid/" + eventID, (resp) => {
                this.setState({eventData: resp})
            })
        }
    }

    loadAttendance = (params) => {
        this.apiGet("/course/attendances?" + encodeGetParams(params), resp => {
            if (Object.entries(resp.attendancesNewest).filter(([k, a]) => a.status !== 0).length === 0) {
                this.setState({editMode: true, userKeys: [123]})
            } else {
                this.setState({
                    ...resp.attendancesNewest,
                    userKeys: Object.keys(resp.attendancesNewest).map(a => parseInt(a)),
                    attendancesOld: resp.attendancesOld,
                    usersAttendancesNewest: [],
                    notes: Object.entries(resp.attendancesNewest).reduce((obj, curr) => ({...obj, [curr[0]]: curr[1].note}), {})
                })
            }
        });
    }
    loadParts = (courseID, event_ID) => {
        this.apiGet(`/course/parts/${courseID}?include_attendants_for=${event_ID}`, resp => {
            this.setState({parts: resp.parts.filter(p => p.registration?.role !== 2)})
        });
    };
    loadUsersAttendance = (params) => {
        this.apiGet("/course/attendances/user?" + encodeGetParams(params), resp => {
            this.setState({editMode: false, loading: false, ...resp})
        });
    };
    changeAttendance = child_ID => {
        const {...attendances} = this.state;
        this.setState({[child_ID]: {...attendances[child_ID] || {}, status: (attendances[child_ID] || {}).status === 10 ? 1 : ((((attendances[child_ID] || {}).status || 0) + 1) % Consts.numOfAttendanceStatuses) || 1}})
    };
    saveFromClose = (close, child_ID) => (note) => {
        const {notes} = this.state;
        this.setState({notes: {...notes, [child_ID]: note}});
        close()
    };
    store = () => {
        const {parts, editMode, notes, error, success, loading, usersAttendancesNewest, usersAttendancesOld, child, ...attendances} = this.state;
        if (editMode) {
            const {params} = this.props.match;
            /*if (!parts.every(p => ((attendances[p.child.ID] || {}).status || 0) > 0)) {
                this.setState({error: "Bitte bei allen Personen angeben, ob sie anwesend oder nicht sind/waren."})
                return;
            }*/
            this.setState({editMode: false, loading: true})
            this.apiPost("/course/attendances?" + encodeGetParams(params), parts.map(p => ({child_ID: p.child.ID, ...attendances[p.child.ID], note: (notes || {})[p.child.ID] || ""})), resp => {
                this.setState({
                    success: "Die Anwesenheit wurde erfolgreich eingetragen!",
                })
            }, false, error => {
                this.setState({editMode: true})
            })
        } else {
            this.setState({editMode: true})
        }
    };

    componentWillReceiveProps = (nextProps, nextContext) => {
        const {courseID, childID, eventID} = (nextProps.match || {}).params;
        this.setState({loading: true})
        if (childID === undefined) {
            this.loadParts(courseID, eventID);
            this.loadAttendance({courseID, eventID});
        } else {
            this.loadUsersAttendance({courseID, childID});
        }
    }

    render() {
        const {event, course} = (this.props.location || {}).state || this.state.eventData || {};
        const {parts, attendancesOld, editMode, notes, loading, usersAttendancesNewest, usersAttendancesOld, child, ...attendances} = this.state;
        const partsUserKeys = parts.map(a => a.child.ID)
        const {params} = this.props.match;
        const allPersons = !params.childID;
        return <Container ID={"attendance"} name={
            !!event && allPersons ?
                <>
                    Anwesenheit in <Link to={"/kurse/" + params.courseID}>{(course || {}).name || ""}</Link> am {dateFormatTime(event.start)} - {timeFormat(event.end)}
                </> :
                (!!child ? <>
                        Anwesenheit von&nbsp;
                        <UserContext.Consumer>
                            {context => !!context && context.user.role >= 20 ? <Link to={"/benutzer/profil/" + child.ID}>{child.fullname}</Link> : child.fullname}
                        </UserContext.Consumer>&nbsp;
                        in <Link to={"/kurse/" + params.courseID}>{(course || {}).name || ""}</Link>
                    </> :
                    "Anwesenheit")
        }>
            <div>
                <table>
                    <tbody>
                    {
                        Object.entries(Consts.attendanceExprMap).map(([status, statusDescr]) => {
                            const count = allPersons ?
                                Object.entries(attendances).reduce((cum, [, a]) => cum + (a.status === parseInt(status) ? 1 : 0), 0) :
                                usersAttendancesNewest.reduce((cum, a) => cum + (a.status === parseInt(status) ? 1 : 0), 0);
                            if (count === 0) {
                                return null;
                            }
                            return <tr key={status}>
                                <td>{statusDescr}</td>
                                <td>{count} {allPersons ? "Person" : "Mal"}{count > 1 && allPersons && "en"} ({Math.round(count * 100 / (allPersons ? parts.filter(p => p.registration?.timeLeave === 0 || p.registration?.timeLeave > event?.end) : usersAttendancesNewest).length)} %)</td>
                            </tr>
                        })
                    }
                    </tbody>
                </table>
                <br/>
            </div>
            {
                allPersons &&
                <MaxBtn onClick={this.store}>{editMode ? "Speichern" : "Bearbeiten"}</MaxBtn>
            }
            {
                allPersons && editMode && <MaxBtn onClick={() => this.setState({editMode: false})}>Bearbeitung abbrechen</MaxBtn>
            }
            <Status type="error" text={this.state.error}/>
            <Status type="success" text={this.state.success}/>
            <Loader loading={loading}/>
            <MyReactTable
                data={(allPersons ? parts.filter(p => p.registration?.timeLeave === 0 || p.registration?.timeLeave > event?.end ||this.state[p.child?.ID]?.status<0) : usersAttendancesNewest)}
                key={"1234"}
                columnsConfigurable={false}
                configTitle={"Anwesenheiten"}
                pageSizeDef={this.context?.user?.verein_ID === Consts.CLUB_OETB_LINZ ? 50 : undefined}
                columns={[
                    {
                        Header: "Name",
                        show: allPersons,
                        accessor: "child.lastname",
                        filterable: true,
                        Cell: ({original, value}) => <>
                            {
                                this.context.user.role >= 20 ?
                                    <Link to={"/benutzer/profil/" + original.child?.ID}>{original.child?.lastname}, {original.child?.prename}</Link> :
                                    original.child?.lastname + ", " + original.child?.prename
                            }
                            &nbsp;
                            {editMode && <Link to={{pathname: `/kurs/${params.courseID}/personenanwesenheit/${original.child?.ID}`, state: (this.props.location || {}).state}}><FaChartPie/></Link>}
                        </>,
                    },

                    ...(["email", "phone", "route", "street_number", "postal_code", "locality"].map(f => ({
                        Header: Consts.translate(f),
                        accessor: "child." + f,
                        defaultHidden: true,
                    }))),
                    {
                        Header: "Trainer",
                        width: 65,
                        show: allPersons,
                        accessor: "registration.role",
                        filterable: true,
                        Cell: ({value}) => ["nein", "ja"][value] ??"nein"
                    },
                    {
                        Header: "Name",
                        show: !allPersons,
                        accessor: "event.name",
                        filterable: true,
                        Cell: ({original, value}) => <Link to={{pathname: `/kurse/${params.courseID}/anwesenheit/${original?.event?.ID}`, state: {course, event: original.event}}}>{value}</Link>
                    },
                    {
                        Header: "Abwesenheit in diesem Kurs",
                        show: allPersons && !editMode,
                        id: "ratio",
                        accessor: row => (this.state[row.child?.ID]?.ratio || {})[1],
                        Cell: ({original}) => {
                            const ratio = this.state[original.child?.ID]?.ratio;
                            if (!ratio) {
                                return ""
                            }
                            const all = Object.entries(ratio || []).reduce((cum, curr) => curr[1] + cum, 0);
                            return <Link to={{pathname: `/kurs/${params.courseID}/personenanwesenheit/${original.child?.ID}`, state: (this.props.location || {}).state}}>
                                zu {all > 0 ? Math.round(((ratio[0] ?? 0) + (ratio[2] ?? 0) + (ratio[3] ?? 0)) * 100 / all) : 100} % abw. {ratio[3] > 0 ? "(" + Math.round(all > 0 ? 100 * ratio[3] / all : 100) + " % entsch.)" : ""}
                            </Link>
                        },
                    },
                    {
                        Header: "Zeitpunkt",
                        show: !allPersons,
                        accessor: "event.start",
                        Cell: ({original}) => {
                            const e = original.event;
                            if (!e) {
                                return null;
                            }
                            return <>
                                am {dateFormatTime(e.start)} bis&nbsp;
                                {
                                    (e.end - e.start) < 86400 ?
                                        timeFormat(e.end) :
                                        dateFormatTime(e.end)
                                }
                            </>
                        }
                    },
                    {
                        Header: "Status",
                        id: "status1",
                        accessor: "child.ID",
                        show: editMode && allPersons,
                        Cell: ({value: ID}) => editMode ?
                            <MiniBtn onClick={() => this.changeAttendance(ID)}>{Consts.attendanceExprMap[(this.state[ID] || {}).status || 0]}</MiniBtn> :
                            Consts.attendanceExprMap[(this.state[ID] || {}).status || null] || "abwesend (auto)",
                        filterable: true,
                        filterMethod: (filter, {_original, value: ID}) => Consts.attendanceExprMap[(this.state[_original.child?.ID] || {}).status || 0].startsWith(filter.value),
                        sortMethod: (a, b) => ((this.state[a] || {}).status || Consts.numOfAttendanceStatuses) - ((this.state[b] || {}).status || Consts.numOfAttendanceStatuses),
                        maxWidth: 130,
                    },
                    {
                        Header: "Bemerkung",
                        id: "note",
                        show: editMode,
                        Cell: ({original}) => ((this.state[original.child?.ID] || {}).status || 0) > 0 ? <MyModal key={original.child?.ID} key2={original.child?.ID} trigger={<MiniBtn>hinzufügen</MiniBtn>}>
                            {close => <NoteInput child={original.child} close={this.saveFromClose(close, original.child?.ID)} notes={(notes || {})[original.child?.ID]}/>}
                        </MyModal> : "",
                        maxWidth: 130,
                    },
                    {
                        Header: "Zeit",
                        show: !editMode,
                        id: "attendance_time",
                        accessor: row => {
                            const {ID} = row.child || {};
                            const attendance = allPersons ? this.state[ID] || {} : row;
                            return attendance?.time;
                        },
                        Cell: ({value}) => dateFormatTime(value),
                        maxWidth: 200,
                    },
                    {
                        Header: "Statuse",
                        show: !editMode,
                        Cell: ({original, value: status}) => {
                            const {ID} = original.child || {};
                            const attendance = allPersons ? this.state[ID] || {} : original;
                            const attendanceOld = (allPersons ? attendancesOld[ID] : usersAttendancesOld[attendance?.event?.ID]) || [];
                            return <MyModal key={ID} trigger={<em style={{color: this.state[original.child?.ID]?.status<0?"red":""}}>{status}</em>}>
                                <LightContainer name={`Bemerkung zu ${original.child?.fullname}`}>
                                    {attendance.time > 0 ? <>
                                        <label><span style={{fontWeight: 700}}>Status:</span> {status}</label>
                                        <label><span style={{fontWeight: 700}}>Hinzugefügt von:</span> {attendance.processorName} ({dateFormatTime(attendance.time)})</label>

                                        <span style={{fontWeight: 700}}>Bemerkung:</span>
                                        <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(attendance.note || "").replace("\n", "<br>")}}/>
                                        {
                                            attendanceOld.length > 0 && <>
                                                <br/>
                                                <b>Vorherige Versionen</b>

                                                {attendanceOld.map(a =>
                                                    <li key={a.ID}>
                                                        {dateFormatTime(a.time)} {a.processorName}: {Consts.attendanceExprMap[a.status || null] || "abwesend (auto)"}<br/>
                                                        <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(a.note || "").replace("\n", "<br>")}}/>
                                                    </li>
                                                )}
                                            </>
                                        }
                                    </> : "da keine Eingabe erfolgt ist, wird diese Person automatisch als abwesend erfasst"
                                    }
                                </LightContainer>
                            </MyModal>
                        },
                        maxWidth: 150,
                        filterable: true,
                        id: "status2",
                        accessor: row => {
                            const {ID} = row.child || {};
                            const attendance = allPersons ? this.state[ID] || {} : row;
                            return Consts.attendanceExprMap[attendance.status || null] || "abwesend (auto)";
                        },
                    }
                ]}
                defaultSorted={[{id: "registration.role"}, {id: allPersons ? "child.lastname" : "event.start", desc: false,}]}
            />
            {
                allPersons &&
                <MaxBtn onClick={this.store}>{editMode ? "Speichern" : "Bearbeiten"}</MaxBtn>
            }
            {
                allPersons && editMode && <MaxBtn onClick={() => {
                    this.loadAttendance(this.props.match.params)
                    this.setState({editMode: false})
                }}>Bearbeitung abbrechen</MaxBtn>
            }


        </Container>

    }
}


class NoteInput extends React.Component {
    setState = this.setState.bind(this);
    state = {
        notes: {
            thisChild: this.props.notes,
        }
    };

    render() {
        const {child} = this.props;
        const {notes} = this.state;
        return <FormContextWrapper value={{state: this.state, setState: this.setState}}>
            <LightContainer name={`Bemerkung zu ${child.fullname}`}>
                <TextareaInput name={"Bemerkung"} tag={"notes_thisChild"}/>
                <MaxBtn onClick={() => this.props.close(notes.thisChild)}>Speichern</MaxBtn>
            </LightContainer>
        </FormContextWrapper>
    }
}
