import {Course, CourseShiftPlanner, CourseShiftPlannerShiftLocation} from "../../core/interfaces/core"
import React, {useContext, useEffect, useState} from "react"
import {LightContainer} from "../../core/components/container"
import {AlwaysLoader, MaxBtn} from "../../core/components/components"
import {FaList, FaPlus, FaSave} from "react-icons/fa"
import {LocationNameChangerModal} from "./modals"
import {UserContext} from "../../user/UserContext"
import deepEqual from "deep-equal"
import {maxiGet, maxiPost} from "../../core/maxios"
import {StatusVar} from "../../club/club_inputs"
import Status from "../../core/status"
import {CourseShiftPlannerLocation} from "./CourseShiftPlannerLocation"
import {v4 as uuidv4} from "uuid"
import Consts from "../../core/consts"
import {Link} from "react-router-dom"
import {CourseShiftPlannerUserTable} from "./CourseShiftPlannerUserTable"
import {checkPermissions} from "../../club/permissionsHelpers";
import {MyModal} from "../../core/components/modal";

interface CourseShiftPlannerProps {
    course: Course
    onChange?: (planner: CourseShiftPlanner) => any
}

interface CourseShiftPlannerContextProps {
//    setCourseShiftPlanner: Dispatch<SetStateAction<CourseShiftPlanner>>
    courseShiftPlanner: CourseShiftPlanner
    isEditable: boolean
    isDirty: boolean
    reload: Function
    course_ID: number
    lastUpdated?: number
}

export type LocalDispatcher<T> = ((x: T) => any) //| ((func: (x: T) => T) => any) |

export const CourseShiftPlannerContext = React.createContext<CourseShiftPlannerContextProps>({
    courseShiftPlanner: {participations: [], locations: []},
    isEditable: false,
    isDirty: false,
    reload: Function(),
    course_ID: 0,
})

function useShiftPlanner(courseId: number) {
    const [originalShiftPlanner, setOriginalShiftPlanner] = useState<CourseShiftPlanner>()
    const [shiftPlanner, setShiftPlanner] = useState<CourseShiftPlanner>()
    const [statusVar, setStatusVar] = useState<StatusVar>()
    const [lastUpdated, setLastUpdated] = useState<number>()

    const reload = () => {
        maxiGet<CourseShiftPlanner>("/shiftplanner/get/" + courseId, {setStatusVar}).then(resp => {
            setShiftPlanner(resp || {participations: [], locations: []})
            setOriginalShiftPlanner(resp)
            setLastUpdated(new Date().getTime())
        })
    }

    useEffect(reload, [courseId])

    const updateShiftPlannerToDatabase = () => {
        maxiPost("/shiftplanner/update/" + courseId, {data: shiftPlanner}, {setStatusVar}).then(() => {
            reload()
            setStatusVar({success: "Erfolgreich gespeichert"})
        })
    }

    return {
        shiftPlanner,
        originalShiftPlanner,
        setShiftPlanner,
        updateToDb: updateShiftPlannerToDatabase,
        isDirty: !deepEqual(originalShiftPlanner, shiftPlanner),
        statusVar,
        reload,
        lastUpdated,
    }
}

export function CourseShiftPlannerComponent({course}: CourseShiftPlannerProps) {
    const context = useContext(UserContext)
    const {user, club} = context;

    const isEditable = checkPermissions(context, "course/data")

    const {
        setShiftPlanner,
        updateToDb,
        shiftPlanner,
        isDirty,
        statusVar,
        reload,
        originalShiftPlanner,
        lastUpdated,
    } = useShiftPlanner(course.ID)

    const [onlyShowUnmetShifts, setOnlyShowUnmetShifts] = useState<boolean>(false)

    const [appendedLocation, setAppendedLocation] = useState<CourseShiftPlannerShiftLocation | null>(null)

    if (statusVar?.loading) {
        return <AlwaysLoader/>
    }

    if (statusVar?.error) {
        return <Status type={"error"} text={statusVar?.error}/>
    }
    if (!shiftPlanner) {
        return null
    }

    const onLocationChange = (index: number) => (location: CourseShiftPlannerShiftLocation) => setShiftPlanner(plan => {
        return plan ? {
            ...plan,
            locations: location ? [...plan.locations.slice(0, index), location, ...plan.locations.slice(index + 1)] : [...plan.locations.slice(0, index), ...plan.locations.slice(index + 1)]
        } : plan
    })
    const appendEmptyLocation = () => {
        const appendedLocation = {ID: uuidv4(), name: "", deleted: false, shifts: []}
        //setShiftPlanner((plan) => ({...plan, locations: [...plan.locations, appendedLocation]}))
        setAppendedLocation(appendedLocation)
    }
    const updateAppendedLocation = (edited: CourseShiftPlannerShiftLocation) => {
        if (!!edited.name) {
            onLocationChange(shiftPlanner.locations.length)(edited)
        }
        setAppendedLocation(null)
    }

    if (club.verein_ID === Consts.CLUB_ATV_ST_VALENTIN && !isEditable && !((user?.data || {})["T-Shirt-Größe"])) {
        return <LightContainer>
            Bitte gehe zuerst in dein <Link to={"/benutzer/profil/" + user.ID}>Profil</Link> und ergänze deine T-Shirt-Größe.
            Vielen Dank.
        </LightContainer>
    }

    return <CourseShiftPlannerContext.Provider value={{courseShiftPlanner: shiftPlanner, isDirty, isEditable, reload, course_ID: course.ID, lastUpdated}}><LightContainer>
        {appendedLocation && <LocationNameChangerModal key={appendedLocation.ID} onFinish={updateAppendedLocation} data={appendedLocation}/>}
        {
            isEditable && <MaxBtn onClick={appendEmptyLocation}><FaPlus/> Neuen Ort hinzufügen</MaxBtn>
        }

        {
            isEditable && <>
                <MyModal additionalPaperStyles={"WideDialogPaper"} trigger={<MaxBtn><FaList style={{marginTop: 4}}/> Alle angemeldeten Personen anzeigen</MaxBtn>}>
                    <CourseShiftPlannerUserTable/>
                </MyModal>
                <MaxBtn onClick={() => setOnlyShowUnmetShifts(b => !b)}>
                    {onlyShowUnmetShifts ? "Zeige alle Schichten an" : "Zeige nur nicht volle Schichten an"}
                </MaxBtn>
            </>
        }

        {
            originalShiftPlanner && shiftPlanner.locations.filter(x => !x.deleted).length > 0 ?
                shiftPlanner.locations.map((location, index) => <CourseShiftPlannerLocation key={location.ID} {...{location, onLocationChange, index, onlyShowUnmetShifts}} previous={shiftPlanner.locations[index - 1]} next={shiftPlanner.locations[index + 1]} onChange={onLocationChange(index)}/>) :
                "Bisher keine Orts-Einträge vorhanden"
        }

        {
            isEditable && <div>
                <MaxBtn onClick={() => updateToDb()}><FaSave/> Speichern</MaxBtn>
            </div>
        }
        <Status type={"error"} text={statusVar?.error}/>
        <Status type={"success"} text={statusVar?.success}/>

    </LightContainer>
    </CourseShiftPlannerContext.Provider>
}

