import React from 'react'
import {BlankLink, Container, Loader, MaxBtn, MySwitch} from "../core/components/components"
import {MyModal} from "../core/components/modal"
import {MyTabs} from "../core/components/tabs"
import {CheckboxInput, TextareaInput, TextfieldInput} from "../core/input/basic"
import "./course_form.css"
import Status from "../core/status"
import {apiGet, apiPost} from "../core/api"
import {UserContext} from "../user/UserContext"
import {dateFormatUnix} from "../core/dateFuncs"
import Consts from "../core/consts"
import {FormContextWrapper} from "../core/form_context"

import {Link} from "react-router-dom"
import {CoronaMessage, coronaMessageExists, RegisterAusserordentlichesMitglied, RegisterDisclaimer, RegisterOrdentlichesMitglied} from "../user/registerDisclaimers"
import {getAge} from "../buchhaltung/helper"
import {Child, CourseParticipant, CoursePaymentGroup, CourseWithAdditionalProps} from "../core/interfaces/core"
import {StatusVar} from "../club/club_inputs"
import {CoursePaymentPlanSelect} from "./paymentPlan/CoursePaymentPlanSelect"
import {OnParticipantPaymentListChangeType, ParticipantPaymentListModal} from "./paymentPlan/course_parts_payment_list"
import {DiscountInput} from "../core/input/discountInput"


interface CourseParticipateProps {
    id: number
    preferredChild?: number
    reload: Function
}

interface CourseParticipateRegistration {
    note: string,
    approved: boolean,
    acceptResp: boolean,
    confirmed: boolean,
    agbs: boolean,
    covid19?: boolean | number,
    birthdate: any,
    svn?: string
}

interface Participate {
    child: Child
    participating: CourseParticipant
    allowed: boolean
    existing_member: boolean
}

type CourseOptional = Pick<CourseWithAdditionalProps, "price" | "pricenm"> & Partial<Omit<CourseWithAdditionalProps, "price" | "pricenm">>

interface CourseParticipateState {
    error: string
    events: any[]
    loading: boolean
    registration: CourseParticipateRegistration
    t0: number
    participate?: Participate[]
    course: CourseOptional
    status?: string
    statusRich?: string | JSX.Element
    discountCode?: string
    discountAmount?: number
    selectedPaymentPlan?: CoursePaymentGroup
}

export default class CourseParticipate extends React.Component<CourseParticipateProps, CourseParticipateState, UserContext> {
    state: CourseParticipateState = {
        error: "",
        events: [],
        loading: true,
        registration: {
            note: "",
            approved: false,
            acceptResp: false,
            confirmed: false,
            agbs: false,
            birthdate: undefined,
            covid19: undefined,
        },
        course: {price: 0, pricenm: 0},
        t0: (new Date().getTime() / 1000)
    }
    apiGet: any
    apiPost: any
    id: number
    context!: React.ContextType<typeof UserContext>

    constructor(props: CourseParticipateProps) {
        super(props)
        this.id = props.id
        this.apiGet = apiGet.bind(this)
        this.apiPost = apiPost.bind(this)
        this.setState = this.setState.bind(this)
        this.loadParticipation()
        window.setTimeout(() => {
            this.setState({t0: (new Date().getTime() / 1000)})
        }, 1000)
    }

    static contextType = UserContext
    loadParticipation = () => {
        this.apiGet("/course/participate/" + this.id, (resp: any) => {
            // resp.course.bookingStart = !user?.existing_member && resp.course?.allBookingStart !== null && resp.course?.allBookingStart !== 0 ? resp.course?.allBookingStart : resp.course?.bookingStart
            this.setState({participate: resp.children, course: resp.course, loading: false})
        })
    }
    doRegister = (getMember: boolean, child_ID: number, memberStatus: any) => {
        const {club} = this.context
        if (this.state.loading) {
            console.log("already loading")
            return
        }
        if (!!this.state.course.paymentPlan?.length && !this.state.selectedPaymentPlan) {
            this.setState({error: "Bitte wähle eine Zahlungsweise aus, um dich für den Kurs anmelden zu können."})
            return
        }

        if (coronaMessageExists(club) && this.state.registration.covid19 !== 1) {
            this.setState({error: "Bitte zuerst die Kenntnisnahme der Covid 19 Hinweise bestätigen!"})
            return
        }
        if ([Consts.CLUB_BEWEGUNGSWELT].includes(this.context.club?.verein_ID) && !this.state.registration.agbs) {
            this.setState({error: "Bitte zuerst die AGBs bestätigen!"})
            return
        }
        if ([Consts.CLUB_ATV_ST_VALENTIN].includes(this.context.club?.verein_ID) && !this.state.registration.agbs && !!this.context?.club?.agbsLink) {
            this.setState({error: "Bitte zuerst die Allgemeinen Vereinbestimmungen bestätigen!"})
            return
        }
        if (getMember && ((!this.state.registration.acceptResp && (getAge(this.state.registration) || 18) < 18) || !this.state.registration.approved || !this.state.registration.confirmed) && memberStatus === 0) {
            this.setState({error: "Bitte zuvor die Bedingungen durchlesen und akzeptieren!" + (this.state.course.membersOnly ? " Für diesen Kurs ist eine Mitgliedschaft erforderlich!" : "")})
            return
        }
        if (!getMember && !!this.state.registration.confirmed) {
            this.setState({error: "Wenn du Mitglied werden willst, dann bitte auch auf den richtigen Knopf drücken."})
            return
        }
        this.setState({loading: true})
        this.apiPost("/course/register", {
            course_ID: this.id,
            child_ID: child_ID,
            getMember: getMember,
            note: this.state.registration.note,
            discountCode: this.state.discountCode,
            paymentPlan_ID: this.state.selectedPaymentPlan?.ID,
        }, () => {
            this.loadParticipation()
            this.props.reload && this.props.reload()
            this.setState({
                status: "Angebot verbindlich gebucht! Du erhälst eine Bestätigung per E-Mail innerhalb der nächsten 40 Minuten.",
                statusRich: <> Bitte gehe in den Abschnitt <em><Link to={"/abrechnung/"}>Abrechnung</Link></em> um etwaige Offenstände zu begleichen.</>,
                loading: false
            })
            //window.location.reload()
        })
    }

    render() {
        const {course, t0} = this.state
        const {user, club} = this.context

        if (!user.verwalter) {
            return "Wende dich bitte an deinen Verwalter, nur der kann Angebote für dich buchen."
        }

        const moreParticipantsThanCapacity = (course.parts_count || 0) >= (course.maxparts || 0)
        const createPriceString = (defaultPrice: number, child: Child, withMembership?: boolean, discountAmount?: number, ifMember:boolean=true) => {
            const paymentPlanPriceSum = this.state.selectedPaymentPlan ? this.state.selectedPaymentPlan.plan.reduce((sum, plan) => sum + (course.membersOnly || ifMember ? plan.price : plan.pricenm), 0) : null
            const realPrice = (paymentPlanPriceSum ?? defaultPrice) - (discountAmount || 0)
            return paymentPlanPriceSum !== null || !course.paymentPlan?.length ?
                `(${Consts.moneyMaxCalc({s: realPrice / 100, club, addTax: true, user: child})}${withMembership && ![Consts.CLUB_ARTANDDANCE_CENTER, Consts.CLUB_ARTANDDANCE_CONNECTION].includes(club.ID) ? ' plus Mitgliedsbeitrag' : ""})` : ""
        }

        return <>

            {
                !this.state.statusRich && <FormContextWrapper value={{state: this.state, setState: this.setState}}>
                    {
                        this.state.participate !== undefined && this.state.participate.length > 0 &&
                        <MyTabs
                            noRewrite
                            preferredTabKey={this.props.preferredChild}
                            hideHeaderIfOne
                            tabs={
                                this.state.participate
                                    .sort(({child: aVal}, {child: bVal}) => {
                                        return (aVal.ID === aVal.verwalter_ID ? 0 : aVal.birthdate) < (bVal.ID === aVal.verwalter_ID ? 0 : bVal.birthdate) ? -1 : 1
                                    })
                                    .map(({child, existing_member, ...data}) => ({
                                        name: child.prename,
                                        test: (() => {
                                            //console.log("test", child.memberStatus)
                                        })(),
                                        key: child.ID,
                                        content: (data.participating !== null ?
                                                <AlreadyParticipantView participant={data.participating} course={course} onChange={this.loadParticipation}/> :
                                                data.allowed ?
                                                    (((!existing_member && course?.allBookingStart !== null && course?.allBookingStart !== 0 ? course?.allBookingStart : course?.bookingStart) || 0) > t0 || t0 > (course?.bookingEnd || 0) ?
                                                        "Dieses Angebot ist derzeit leider nicht buchbar." :
                                                        <>
                                                            {
                                                                !!course.paymentPlan?.length ?
                                                                    <><br/><CoursePaymentPlanSelect discountAmount={this.state.discountAmount} membersOnly={!!course.membersOnly} paymentGroups={course.paymentPlan} onChange={(planGroup) => this.setState({selectedPaymentPlan: planGroup})}/></> : <>
                                                                        {
                                                                            !club.noMembership && (course?.membersOnly !== 0 || club.verein_ID !== Consts.CLUB_ITV_INNSBRUCK) && <li>
                                                                                {Consts.moneyMaxCalc({s: course.price / 100, club, addTax: true, user: child})} für Mitglieder
                                                                                {
                                                                                    child.memberStatus === 0 && ![Consts.CLUB_ARTANDDANCE_CENTER, Consts.CLUB_ARTANDDANCE_CONNECTION].includes(club.ID) &&
                                                                                    <> (Mitgliedsbeitrag fällig, da du noch kein Mitglied bist. Andere Kurse und Workshops können durch die Mitgliedschaft vergünstigt gebucht werden.)</>
                                                                                }
                                                                                {
                                                                                    child.memberStatus === 3 && <> (durch die Buchung wird eine Änderung der Mitgliedschaft veranlasst, da du derzeit {club.memberStates[child.memberStatus]} bist)</>
                                                                                }
                                                                            </li>
                                                                        }
                                                                        {
                                                                            !course.membersOnly &&
                                                                            <><br/>
                                                                                <li>{Consts.moneyMaxCalc({s: course.pricenm / 100, club, addTax: true, user: child})} für {club.noMembership ? "dich" : "Nichtmitglieder"}</li>
                                                                            </>
                                                                        }
                                                                    </>}


                                                            {/*+ " (da " + Club.memberStates[this.state.participate[0].child.memberStatus] + ")"*/}
                                                            <br/>
                                                            <h2>Verbindliche Anmeldung {moreParticipantsThanCapacity && <em>auf Warteliste</em>}</h2>
                                                            {
                                                                moreParticipantsThanCapacity && !!course.membersOnly && child.memberStatus === 0 && <p>
                                                                    <b>Du wirst erst <em> dann Mitglied, wenn ein Platz im Kurs frei wird!</em> Derzeit ist leider noch kein Platz frei.</b>
                                                                </p>
                                                            }

                                                            {
                                                                user !== undefined &&

                                                                <>{user.ID === child.verwalter_ID && child.svn.length !== 32 && club.svnRequired &&
                                                                    <AddSVN child={child}/>}
                                                                    {
                                                                        child.memberStatus === 0 && !club.noMembership && (course.membersOnly !== 0 || club.verein_ID !== Consts.CLUB_ITV_INNSBRUCK) &&
                                                                        <>
                                                                            <>
                                                                                <div style={{
                                                                                    paddingBottom: "30px",
                                                                                    float: "left",
                                                                                    paddingRight: "10px"
                                                                                }}>
                                                                                    <CheckboxInput name={"Bestätigung"} tag={"registration_confirmed"} resetOnUnmount noLabel/>
                                                                                </div>
                                                                                {
                                                                                    child.age !== null && child.age < 18 ?
                                                                                        <RegisterAusserordentlichesMitglied club={club} Kursbuchung subjectAndPraedicate={user.ID === child.ID ? "Ich will" : "Diese Person soll"}/> :
                                                                                        <RegisterOrdentlichesMitglied Kursbuchung club={club} subjectAndPraedicate={user.ID === child.ID ? "Ich will" : "Diese Person soll"}/>
                                                                                }
                                                                            </>
                                                                            <RegisterDisclaimer club={club} registration={"registration"} noCovid19/>
                                                                            <br/><br/>
                                                                        </>
                                                                    }
                                                                </>
                                                            }

                                                            {
                                                                !!course && (course.tags?.split(",") || []).indexOf("43") > -1 && <>Falls du Geschwisterkinder unter 6 Monaten in diese Einheit mitbringst, musst du diese als Benutzer unter&nbsp;
                                                                    <Link to={"/benutzer/liste"}>Benutzerdaten | Benutzer registrieren</Link> hinzufügen und hier in der Anmerkung Name und Geburtsdatum ergänzen.<br/>
                                                                </>
                                                            }

                                                            {
                                                                club.verein_ID !== Consts.CLUB_OETB_LINZ && <>
                                                                    <b>Anmerkung</b>&nbsp;
                                                                    <MySwitch text={"hinzufügen"}>
                                                                        <TextareaInput name={"Anmerkung zur Buchung"} tag={"registration_note"}/>
                                                                    </MySwitch>
                                                                </>
                                                            }
                                                            <br/>

                                                            {
                                                                coronaMessageExists(club) && <CoronaMessage registration={"registration"} club={club} child={child.age !== null && child.age < 18}/>
                                                            }

                                                            {
                                                                club.showDiscounts && <DiscountInput changeArray={[this.state.selectedPaymentPlan]} hideCode={[Consts.CLUB_ARTANDDANCE_CENTER, Consts.CLUB_ARTANDDANCE_CONNECTION].includes(club.verein_ID)}
                                                                                                     child_ID={child.ID} course_ID={course.ID} onChange={(amount) => this.setState({discountAmount: amount})}
                                                                                                     isMember={child.memberStatus !== 0} payment_group={this.state.selectedPaymentPlan}/>
                                                            }
                                                            <br/>
                                                            {
                                                                [Consts.CLUB_BEWEGUNGSWELT].includes(club.verein_ID) &&
                                                                <>
                                                                    <p>
                                                                        <div style={{
                                                                            paddingBottom: "10px",
                                                                            marginTop: "-10px",
                                                                            float: "left",
                                                                            paddingRight: "10px"
                                                                        }}>
                                                                            <CheckboxInput name={"akzeptiere"}
                                                                                           tag={"registration_agbs"}
                                                                                           resetOnUnmount
                                                                                           noLabel/>
                                                                        </div>
                                                                        <span>
                                                                    Ich habe die <b>Datenschutzerklärung</b> {
                                                                            !!club.dataprotectionLink && <>(verfügbar
                                                                                unter&nbsp;
                                                                                <BlankLink>{club.dataprotectionLink}</BlankLink>) </>
                                                                        }{
                                                                            !!club.agbsLink && <>und
                                                                                die <b>AGBs</b> (verfügbar unter&nbsp;
                                                                                <BlankLink>{club.agbsLink}</BlankLink>)</>
                                                                        } gelesen und zur Kenntnis genommen.
                                                                </span>
                                                                    </p>
                                                                    <br/>
                                                                </>
                                                            }
                                                            {
                                                                [Consts.CLUB_ATV_ST_VALENTIN].includes(club.verein_ID) && club.agbsLink &&
                                                                <>
                                                                    <p>
                                                                        <div style={{
                                                                            paddingBottom: "10px",
                                                                            marginTop: "-10px",
                                                                            float: "left",
                                                                            paddingRight: "10px"
                                                                        }}>
                                                                            <CheckboxInput name={"akzeptiere"}
                                                                                           tag={"registration_agbs"}
                                                                                           resetOnUnmount
                                                                                           noLabel/>
                                                                        </div>
                                                                        <span>Hiermit akzeptiere ich die <b>Allgemeinen Vereinsbestimmungen</b> (verfügbar unter&nbsp;<BlankLink>{club.agbsLink}</BlankLink>).</span>
                                                                    </p>
                                                                    <br/>
                                                                </>
                                                            }
                                                            {
                                                                (club.noMembership || (course.membersOnly === 0 && club.verein_ID === Consts.CLUB_ITV_INNSBRUCK)) ?
                                                                    <MaxBtn onClick={() => this.doRegister(false, child.ID, child.memberStatus)}>
                                                                        {
                                                                            moreParticipantsThanCapacity ?
                                                                                "auf die Warteliste" :
                                                                                "anmelden"
                                                                        }
                                                                        {createPriceString(course.pricenm, child)}</MaxBtn> :
                                                                    <>
                                                                        <MaxBtn style={{marginRight: "10px"}} onClick={() => this.doRegister(true, child.ID, child.memberStatus)}>
                                                                            {
                                                                                moreParticipantsThanCapacity ?
                                                                                    "auf die Warteliste" :
                                                                                    "anmelden"
                                                                            }&nbsp;
                                                                            {
                                                                                child.memberStatus === 0 && `und Mitglied werden${course.membersOnly !== 0 && child.memberStatus === 0 && moreParticipantsThanCapacity ? ", wenn Platz frei wird" : ""} `
                                                                            }
                                                                            {createPriceString(course.price, child, child.memberStatus === 0, this.state.discountAmount)}</MaxBtn>
                                                                        {
                                                                            course.membersOnly === 0 && child.memberStatus === 0 &&
                                                                            <MaxBtn onClick={() => this.doRegister(false, child.ID, child.memberStatus)}>
                                                                                {
                                                                                    moreParticipantsThanCapacity ?
                                                                                        "auf die Warteliste" :
                                                                                        "anmelden"
                                                                                } ohne Mitgliedschaft {createPriceString(course.pricenm, child, undefined, undefined, false)}</MaxBtn>
                                                                        }
                                                                    </>
                                                            }
                                                        </>) :
                                                    <>
                                                        Teilnahmebedingungen für {child.prename} nicht erfüllt
                                                        {
                                                            child.birthdate === 0 && <>, da kein Geburtsdatum eingeben wurde, dieses kann <Link to={"/benutzer/profil/" + child.ID}>hier</Link> eingegeben werden.</>
                                                        }
                                                        {
                                                            child.ID === child.verwalter_ID && <> Falls du dein Kind anmelden möchtest, musst du es vorab in deinem <Link to={"/benutzer/liste"}>Account</Link> registrieren.</>
                                                        }
                                                    </>
                                        )
                                    }))
                            }/>

                    }

                </FormContextWrapper>
            }
            <Status type="error" text={this.state.error}/>

            <Status type="successRegistration" text={this.state.status}>
                {this.state.statusRich}
            </Status>
            <Loader loading={this.state.loading}/>
        </>

    }
}

class AddSVN extends React.Component<{ child: any }, { status?: any, new: { svnAdd?: boolean } } & StatusVar> {
    setState: any = this.setState.bind(this)
    apiGet = apiGet.bind(this)
    apiPost = apiPost.bind(this)
    static contextType = UserContext
    context!: React.ContextType<typeof UserContext>

    constructor(props: { child: any }) {
        super(props)
        this.state = {
            new: {}
        }
    }

    render() {
        const {child} = this.props

        return <MyModal
            modal
            defaultOpen={true}
            onClose={(trigger) => {
                if (trigger !== "child") {
                    alert("Die Sozialversicherungsnummer muss eingegeben werden.")
                    return false
                }
            }}
            trigger={<></>}
        >{
            close =>

                <Container
                    name={this.context?.user?.ID === child.ID ? "Ergänze deine Sozialversicherungsnummer" : "Sozialversicherungsnummer von " + child.prename + " ergänzen"}>
                    <FormContextWrapper value={{state: this.state, setState: this.setState}} onSubmit={(e: any) => {
                        e.preventDefault()
                        this.setState({loading: true})
                        this.apiPost("/user/addsvn", {svn: this.state.new.svnAdd, child_ID: child.ID}, (resp) => {
                            this.setState({loading: false, status: "SVN Erfolgreich ergänzt!"})
                            window.setTimeout(() => close(), 2000)
                        })
                    }}>
                        <h4>Vor der Buchung ist es erforderlich, uns die Sozialversicherungsnummer mitzuteilen.</h4>
                        Die Angabe der Sozialversicherungsnummer dient lediglich zur eindeutigen Identifikation von
                        Personen und wird in unserem System verschlüsselt abgespeichert, somit hat darauf
                        niemand Zugriff.<br/><br/>
                        <Status type={"error"} text={this.state.error}/>
                        <Status type={"success"} text={this.state.status}/>
                        <TextfieldInput name={"SVN"} tag={"new_svnAdd"} ph={Consts.placeHolders["svn"]}/>
                        <MaxBtn>Speichern</MaxBtn>
                    </FormContextWrapper>
                </Container>
        }
        </MyModal>
    }
}

function AlreadyParticipantView({participant, course, onChange}: { participant: CourseParticipant, course: CourseOptional, onChange?: OnParticipantPaymentListChangeType }) {
    return <>
        {
            participant.timeLeave > 0 ?
                "Teilnahme beendet (" + dateFormatUnix(participant.timeLeave) + "), bei erneuter Buchung bitte an Vereinsverwaltung wenden" :
                (
                    participant.role === 0 ?
                        "Teilnehmer" :
                        (participant.role === 1 ?
                            "Trainer" :
                            "auf Warteliste")
                ) +
                (participant.role <= 1 ?
                        " (seit " + dateFormatUnix(participant.timeStart) + ")" :
                        " (seit " + dateFormatUnix(participant.timeCreation) + ")"
                )
        }
        {
            //participant.role === 0 && (participant.timeLeave===0 || !participant.refunded) &&
            !!course.paymentPlan?.length && <p>&nbsp;
                <ParticipantPaymentListModal onChange={onChange} participantPayments={participant.payments} course={{...participant, ...course, cp_ID: participant.ID, course_ID: course.ID, cp_price: participant.price}}/>
            </p>
        }
        {
            !!participant.noteAdmin && <>
                <br/><b>{participant.noteAdmin}</b><br/></>
        }
    </>
}
