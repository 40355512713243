import React, {useState} from "react";
import {Container, InfoTooltip, Loader, MaxBtn} from "../core/components/components";
import Status from "../core/status";
import {maxiPostObj} from "../core/maxiosLeg";


export default function VouchersRedeem({}) {
    const [vouchercode, setVouchercode] = useState("");
    const [loading, setLoading] = useState(false);
    const [bought, setBought] = useState(false);
    const [error, setError] = useState("");


    const handleCodeChange = (e) => {
        const val = e.target.value.toUpperCase().trim();
        if ((val.indexOf("-") === -1 && val.length > 8) || val.length > 9) {
            return;
        }
        setVouchercode(val)
    };

    const submitRedeem = (e) => {
        e.preventDefault();
        setError("")
        maxiPostObj({
            url: "/vouchers/redeem", data: {voucherCode: vouchercode}, success: resp => {
                setLoading(false);
                setBought(true)
            }, setError: e => setError(e.toString())
        });
    }
    return <Container name={"Gutschein einlösen"} style={{padding: 0}}>
        {
            bought ?
                <Status type={"success"} text={"Der Gutschein wurde erfolgreich eingelöst!"}/> :
                <>
                    <Status type={"error"} text={error}/>
                    <form onSubmit={submitRedeem}>
                        <label><span>Gutschein-Code <InfoTooltip>
                    Der Gutscheincode sollte eine 8 Buchstaben und Zahlen lange Zeichenkette sein.
                </InfoTooltip></span><input type="text" name={"Code"} value={vouchercode} onChange={handleCodeChange}/></label>
                        <br/>
                        <br/><br/>
                        Mit dem Klick auf Einlösen wird der Beitrag auf dem Gutschein Ihrem Account gutgeschrieben.<br/>

                        <MaxBtn>Einlösen</MaxBtn>
                        <Loader loading={loading}/>
                    </form>
                </>
        }
    </Container>

}
