import React from "react";
import {apiGet} from "../core/api";
import Status from "../core/status";
import {Container, Loader, MaxLink} from "../core/components/components";
import {MyModal} from "../core/components/modal";
import CourseInfo from "./course_info";
import {Link} from "react-router-dom";

class ViewCoursesOf extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: 0,
            loading: true,
            pathname: window.location.pathname,
            courses: [],
        };
        this.apiGet = apiGet.bind(this);

        this.loadCourses(window.location.pathname, true);
    }

    componentWillReceiveProps = (nextProps, nextContext) => {
        if (this.state.open !== undefined && window.location.pathname !== this.state.pathname) {
            this.setState({pathname: window.location.pathname})
            this.loadCourses(window.location.pathname)
        }
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (this.state.pathname !== window.location.pathname && nextState.pathname !== window.location.pathname) {
            this.loadCourses(window.location.pathname)
            return false;
        }
        return true
    }

    loadCourses = (pn = this.state.pathname, fromConstructor = false) => {
        if (!fromConstructor) {
            this.setState({loading: true, courses: [], pathname: pn})
        }
        const trainerProfile = (pn.startsWith("/profil/") || pn.startsWith("/pprofil/"));
        this.apiGet(`/user/${this.props.id}/courses${trainerProfile ? "public" : ""}?parts=${(trainerProfile ? "trainer" : ({"/kurs/trainer": "trainer", "/kurs/meine_online": "online"}[pn] || "booked"))}`, resp => {
            this.setState({"courses": resp.courses, loading: false})
        });
    };

    render() {
        const errorSplit = !!this.state.error ? this.state.error.split(" ") : null;

        return (
            <>
                {
                    this.state.error?.includes("Daten kontrollieren") && <MyModal defaultOpen>
                        <Container name={this.state.error} center>
                            {this.state.error}<br/>
                            <MaxLink to={"/benutzer/profil/" + this.props.id}>Hier klicken um zu den Personendaten zu gelangen!</MaxLink>
                        </Container>
                    </MyModal>
                }
                {
                    this.state.error?.includes("zuerst eingeben.") ?
                        <MyModal defaultOpen>
                            <Container name={(!!errorSplit ? errorSplit[0] : "Feld") + " erforderlich"} center>
                                Bitte {this.state.error}<br/>
                                <MaxLink to={"/benutzer/profil/" + this.props.id}>Bitte hier klicken!</MaxLink>
                            </Container>
                        </MyModal> : (
                            this.state.error?.includes("gesetzliche Vertretung") ?
                                <MyModal defaultOpen>
                                    <Container name={"Gesetzliche Vertretung erforderlich"} center>
                                        {this.state.error}<br/>
                                        <MaxLink to={"/verwalterRegistrieren"}>Bitte hier klicken!</MaxLink>
                                    </Container>
                                </MyModal> :
                                <Status type="error" text={this.state.error}/>
                        )


                }
                <Status type="success" text={this.state.status}/>
                <Loader loading={this.state.loading}/>


                {
                    this.state.courses.map(c => <CourseInfo short course={c}/>)
                }

                {
                    this.state.courses.length === 0 && !this.state.loading && <b>Es wurden keine Angebote gefunden. <Link to={"/kurs/buchung"}>Angebote anzeigen</Link></b>
                }


            </>
        )
    }
}


export default ViewCoursesOf
