import React from "react";
import {FormContext} from "../form_context";
import axios from "axios";
import Consts from "../consts";
import {encodeGetParams} from "../helpers";
import {dateFormatTime} from "../dateFuncs";
import {FaUpload} from "react-icons/fa";

export class FileUpload extends React.Component {
    static contextType = FormContext;
    saveFile = (e) => {
        e.preventDefault();
        this.setState({loading: true});
        const setStateParent = this.props.setStateParent || this.context.setState;

        var formData = new FormData();
        formData.append("excel_file", this.fileField.current.files[0]);
        axios.post(Consts.API_PREFIX + "/file/upload?" + encodeGetParams({"prefix": this.props.prefix}), formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: progressEvent => {
                this.setState({
                    progress: Math.round(progressEvent.loaded / progressEvent.totalSize * 100)
                })
            }
        })
            .then(response => {
                const s = this.context === undefined || this.context.state === undefined ? this.props.state : this.context.state;

                if (response.data.status === "error") {
                    setStateParent({
                        error: response.data.message,
                        status: ""
                    });
                    this.setState({loading: false})
                } else {
                    const fileData = this.props.saveFullFileObject ? response.data : response.data.file_name
                    setStateParent({
                        status: response.data.message,
                        error: "",
                        [this.fieldNameSplit[0]]: this.fieldNameDual ? {...s[this.fieldNameSplit[0]], [this.fieldNameSplit[1]]: fileData} : fileData,
                    });
                    this.setState({loading: false, renew: false})
                }
            })
            .catch((error) => {
                console.log("error", error);
                //this.setState({loading: false})
                setStateParent({
                    error: error.message,
                    status: ""
                })
            });
    };

    constructor(props) {
        super(props);

        this.fieldName = props.tag === undefined ? "fileID" : props.tag;
        this.fieldNameSplit = this.fieldName.split("_");
        this.fieldNameDual = this.fieldNameSplit.length === 2;


        this.fileField = React.createRef();

        this.state = {
            showing: 1,
            loading: false,
            progress: 100,

        };
        this.setState = this.setState.bind(this);
    }

    /* loadBox(){
         let folderId = '59771699940';
         var accessToken = 'abc';
         var contentExplorer = new Box.ContentExplorer();
         contentExplorer.show(folderId, "qzCTquq9kJNCFYYj8k4fhUppIgCZ0quQ", {
             container: '.container'
         });
     }*/
    render() {
        const s = this.context === undefined || this.context.state === undefined ? this.props.state : this.context.state;
        const setStateParent = this.props.setStateParent || this.context.setState;
        const valueTemp = this.fieldNameDual ? (s[this.fieldNameSplit[0]] || {})[this.fieldNameSplit[1]] : s[this.fieldName]
        const value = valueTemp?.file_name || valueTemp
        return <div>
            {(s.loading === undefined || !s.loading) && (!value) &&
                <div id="excel_file">
                    <label>
                        <span>
                            {this.props.name}
                        </span>
                        <input
                            type={"file"}
                            readOnly={this.props.readOnly}
                            ref={this.fileField}
                        />
                        {this.state.loading &&
                            <label>
                                <img alt={"loader"} src="https://uid-suche.eu/src/img/ajax-loader-fff.gif"/>
                                {this.state.progress} % {this.state.progress === 100 && <span>(Datei verarbeiten)</span>}
                            </label>
                        }
                    </label>
                    {
                        !this.props.readOnly &&
                        <button type={"button"} className={"maxbtn"} onClick={this.saveFile}><FaUpload/> Datei uploaden</button>
                    }
                </div>
            }
            {
                value &&
                <label>
                    <span>{this.props.name}</span>
                    <a href={Consts.API_PREFIX + "/file/download/" + value}>
                        download&nbsp;
                    </a>
                    ({value.split(".")[1]},&nbsp;
                    {
                        dateFormatTime((() => {
                            const sp = value?.split("/")
                            return sp[sp.length - 1].split("-")[0] * 1000
                        })())
                    }) &nbsp;&nbsp;&nbsp;&nbsp;
                    {
                        !this.props.readOnly && <em onClick={() => {
                            setStateParent({[this.fieldNameSplit[0]]: this.fieldNameDual ? {...s[this.fieldNameSplit[0]], [this.fieldNameSplit[1]]: ""} : ""});
                        }}>
                            neue Datei
                        </em>
                    }
                    <br/>
                </label>
            }
        </div>
    }
}
