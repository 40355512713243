import React from 'react';
import "../dashboard.sass"
import {CheckboxInput, TextareaInput} from "../core/input/basic";
import {Container} from "../core/components/components";
import {Loader, MaxBtn} from "../core/components/components";
import "react-datepicker/dist/react-datepicker.css";
import {FormContextWrapper} from "../core/form_context";
import {apiGet, apiPost} from "../core/api";
import Status from "../core/status";
import {UserContext} from "./UserContext";
import {coronaMessageExists, RegisterDisclaimer, RegisterOrdentlichesMitglied} from "./registerDisclaimers";
import {SelectfieldInput} from "../core/input/select";
import {dateFormatUnix} from "../core/dateFuncs";
import {getAge} from "../buchhaltung/helper";

class UserMembership extends React.Component {
    state = {
        austritt: {
            note: "",
            who: undefined,
        },
        users: {},
        status: "",
        error: "",
        loading: false,
    };
    setState = this.setState.bind(this);

    constructor(props) {
        super(props);
        this.apiGet = apiGet.bind(this);
        this.apiPost = apiPost.bind(this);
        this.apiGet("/user/subusers", resp => {

            const ids = Object.keys(resp.users);
            this.setState({users: resp.users, austritt: {...this.state.austritt}})
        });
        this.setState = this.setState.bind(this)
    }

    static contextType = UserContext;
    handleSubmit = (e) => {
        e.preventDefault();
        const {club} = this.context;
        const {austritt} = this.state;
        if (!austritt.what) {
            this.setState({error: "Bitte gebe die Art deiner Mitgliedschafts-Änderung an!"});
            return
        }
        if (!austritt.who) {
            this.setState({error: "Bitte gebe an, für wem du die Mitgliedschaft ändern möchtest!"});
            return
        }
        if (austritt.what === "upgrade") {
            console.log({austritt});
            if (austritt.approved !== 1 || austritt.confirmed !== 1) {
                this.setState({error: "Bitte bestätige die Bedingungen zuerst!"});
                return
            }
            if (coronaMessageExists(club) && austritt.covid19 !== 1) {
                this.setState({error: "Bitte zuerst die Kenntnisnahme der Covid 19 Hinweise bestätigen!"});
                return;
            }
        } else {

            if (!austritt.approval) {
                this.setState({error: "Bitte bestätige die Anfrage!"});
                return
            }

        }
        if (!austritt.note || austritt.note === "") {
            this.setState({error: "Bitte gebe einen kurzen Text ein!"});
            return
        }
        this.setState({loading: true});
        this.apiPost("/user/request_leave", austritt, resp => this.setState({
            loading: false, status: this.context.user?.verein_ID === 0 ?
                ({
                    upgrade: "Die Anfrage wurde erfolgreich entgegengenommen!",
                    leave: `Wir haben deine Meldung erhalten und deine Mitgliedschaft wird per ${dateFormatUnix(new Date().getTime() / 1000 + 7 * 86400)} beendet. Wir wünschen dir alles Gute! 
Du kannst dich jederzeit in deinem Account wieder für Kurse/Einheiten anmelden und deine Mitgliedschaft aktivieren.`,
                    downgrade: `Wir haben deine Meldung erhalten und werden diese sobald wie möglich bearbeiten.
Vielen lieben Dank für deine Unterstützung!`,
                }[austritt.what])
                : "Die Anfrage wurde erfolgreich entgegengenommen!"
        }))
    };

    render() {
        const {austritt} = this.state;
        const {club, user} = this.context;
        const [who_ID, who] = Object.entries(this.state.users).find(([k, child]) => k === austritt.who) || [null, null]
        return <>
            <Container name={"Eintritt/Austritt beantragen für:"}>
                <Status type={"error"} text={this.state.error}/>
                <Status type={"success"} text={this.state.status}/>
                {
                    (this.state.status === "") &&
                    <FormContextWrapper value={{state: this.state, setState: this.setState}} onSubmit={this.handleSubmit}>
                        <SelectfieldInput
                            name={"Wer?"}
                            tag={"austritt_who"}
                            demandSelect={true}
                            selectives={Object.entries(this.state.users).map(([k, child]) => [k, `${child.fullname} (${club.memberStates[child.memberStatus]})`])}
                        />
                        <br/>
                        <SelectfieldInput
                            name={"Was?"}
                            tag={"austritt_what"}
                            demandSelect
                            selectives={[
                                ["upgrade", "Mitglied werden"],
                                ...(user?.verein_ID !== 9 ? [["downgrade", "Wechsel auf unterstützende Mitgliedschaft"]] : []),
                                ["leave", "Austritt"],
                            ]}
                        />
                        {
                            who?.memberStatus > 0 && austritt.what === "upgrade" && <div style={{color: "red"}}>Diese Person ist bereits {club.memberStates[who.memberStatus]}!</div>
                        }
                        <TextareaInput name={"Bitte gebe eine Anmerkung zum Mitgliedsschaft-Wechsel an!"} tag={"austritt_note"}/>
                        {
                            (austritt.what === "downgrade" || austritt.what === "leave") &&
                            <>
                                <CheckboxInput name={<>
                                    {austritt.what === "downgrade" && "Wechsel"}

                                    {austritt.what === "leave" && "Austritt"} bestätigen</>} tag={"austritt_approval"}/> <br/>
                            </>
                        }


                        {austritt.what === "upgrade" &&
                        <>
                            <p>
                                <div style={{paddingBottom: "30px", float: "left", paddingRight: "10px"}}>
                                    <CheckboxInput name={"Bestätigung"} tag={"austritt_confirmed"} resetOnUnmount noLabel/>
                                </div>
                                <RegisterOrdentlichesMitglied club={club} subjectAndPraedicate={"Ich will"}/>
                            </p>
                            <RegisterDisclaimer club={club} noAdminDisclaimer registration={"austritt"} age={getAge(this.state.austritt)}/>

                            <br/>
                        </>
                        }
                        <MaxBtn>
                            beantragen
                        </MaxBtn>
                        <Loader loading={this.state.loading}/>
                    </FormContextWrapper>
                }
            </Container>
        </>

    }
}


export default UserMembership;
