import axios from "axios";
import Consts from "./consts";

function apiGet(url, success = (resp) => {
    this.setState({
        error: "",
        status: resp.message !== undefined ? resp.message : "",
        loading: false,
    });
    return resp;
}, noApi = false, noErrorSet = false) {
    axios.get(Consts.f("", ((window.VEREINfachtConfig || {}).verein_ID !== undefined ? "https://mein.vereinfacht.at" : "")) + (!noApi ? Consts.API_PREFIX : "") + url, {
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then(response => {
            if (response.data.status === "error") {
                this.setState({
                    error: response.data.message,
                    status: "",
                    loading: false,
                })

            } else {
                this.setState({
                    ...(noErrorSet ? {} : {error: ""}),
                    loading: false,
                });
                success(response.data)
            }

        })
        .catch((error) => {
            console.log("error", error);
            let message = error.message;
            if (!error.response) {
                message = "Es besteht keine Internet-Verbindung."
            }
            if (error?.response?.status) {
                message = "Leider ist derzeit keine Verbindung zu VEREINfacht möglich (" + error.response?.statusText + ")."
            }
            this.setState({
                error: message,
                status: "",
                loading: false,
            })

        });
}

function apiPost(url, data, success = (resp) => {
    this.setState({
        error: "",
        status: resp.message !== undefined ? resp.message : "",
        loading: false,
    })
}, noErrorSet = false, errorFunc = null) {
    axios.post(Consts.API_PREFIX + url, data, {
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then(response => {
            if (response.data.status === "error") {
                if (!!errorFunc) {
                    errorFunc(response.data.message)
                }
                this.setState({
                    error: response.data.message,
                    status: "",
                    loading: false,
                })

            } else {
                this.setState({
                    ...(noErrorSet ? {} : {error: ""}),
                    loading: false,
                });
                success(response.data, response)
            }

        })
        .catch((error) => {
            console.log("error", error, error.message, error.response);
            let message = error.message;
            if (!error.response) {
                message = "Es besteht keine Internet-Verbindung."
            }
            if (error?.response?.status) {
                message = "Leider ist derzeit keine Verbindung zu VEREINfacht möglich (" + error.response?.statusText + ")."
            }
            if (!!errorFunc) {
                errorFunc(message)
            }
            this.setState({
                error: message,
                status: "",
                loading: false,
            })


        });
}

export {apiGet, apiPost}
