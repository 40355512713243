import {FormContext} from "../core/form_context";
import {Container, InfoTooltip, Loader, MaxBtn} from "../core/components/components";
import {TextfieldInput} from "../core/input/basic";
import {DateTimeInput} from "../core/input/date";
import {SelectfieldInput} from "../core/input/select";
import {TagsInput} from "../core/input/tags";
import Status from "../core/status";
import React from "react"
import {apiGet, apiPost} from "../core/api";
import {encodeGetParams} from "../core/helpers";
import Link from "@material-ui/core/Link";


const durationSelectives=[
    ...[...Array(24).keys()].map(i => [(i * 5).toString(), `${i * 5}min`]),
    //.concat([...Array(4).keys()].map(i => [(60 + i * 15).toString(), `${60 + i * 15}min`]))
    ...[...Array(49).keys()].map(i => [(120 + i * 15).toString(), `${Math.floor(2 + i / 4)}h ${(i * 15) % 60}min`]),
];

class EventsAdd extends React.Component {
    constructor(props) {
        super(props);
        this.id = props.id;
        this.apiGet = apiGet.bind(this);
        this.apiPost = apiPost.bind(this);
        this.setState = this.setState.bind(this);
        let d = new Date();
        this.state = {
            loading: false,
            event: {
                duration: 60,
                course_ID: this.props.course_ID || 0,
                period: "0",
                start: Math.floor((new Date(Math.ceil(d.getTime() / (15 * 60 * 1000), 0) * 15000 * 60)).getTime() / 1000),
                room: "",
                timeZoneOffset: d.getTimezoneOffset(),
                visibility: 1,
                ...props?.event,
            }
        };
        if (props.event !== undefined) {
            const dur=(props.event.end !== undefined ? (props.event.end - props.event.start) / 60 : 60).toString();
            this.state.event.duration = durationSelectives.map(a=>a[0]).includes(dur.toString())?dur:"end"
            console.log(this.state.event.duration ,durationSelectives.map(a=>a[0]).includes(dur.toString()), durationSelectives.map(a=>a[0]))
        }

    }


    handleSubmit = (event) => {
        this.setState({loading: true})
        this.apiPost("/events/addnew?type=" + event, this.state.event, (resp) => {
            if (this.props.addEvents !== undefined) {
                this.props.addEvents(resp.events)
                let d = new Date();
                this.setState({
                    event: {
                        duration: 60,
                        course_ID: this.props.course_ID || 0,
                        period: "0",
                        start: Math.floor((new Date(Math.ceil(d.getTime() / (15 * 60 * 1000), 0) * 15000 * 60)).getTime() / 1000),
                        room: "",
                        name: "",
                        timeZoneOffset: d.getTimezoneOffset(),
                        visibility: 1
                    }
                })

            }
        });
    };
    handleChange = (e) => {
        const s = e.target.name.split("_");
        if (s.length === 2) {
            this.setState({
                [s[0]]: Object.assign(
                    {},
                    this.state[s[0]],
                    {[s[1]]: e.target.type === 'checkbox' ? e.target.checked : e.target.value}
                )
            });
        } else {
            this.setState({
                    [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
                }
            )

        }
    }

    loadFirstEventOfGroup = () => {
        this.apiGet("/events/get/" + this.state.event.previous_ID, (resp) => {
            console.log(resp)
            this.setState(a => ({
                event: {...a.event, ...resp}
            }))
        })
    }

    render() {
        return <FormContext.Provider
            value={{state: this.state, setState: this.setState, updateState: this.updateState}}>
            <Status type="error" text={this.state.error}/>
            { this.state.event.ID && this.state.event?.previous_ID !== 0 && <><p>
                <span style={{color: "red"}}>Achtung: Dies ist nicht der erste Termin dieser Termingruppe! <Link onClick={this.loadFirstEventOfGroup}>Bearbeite stattdessen den ersten Termin der Gruppe.</Link></span>
            </p></>}
            <form id={"course_events_form"} onChange={this.handleChange} onSubmit={e => e.preventDefault()}>
                <TextfieldInput name={"Name"} tag={"event_name"}/>
                <br/>
                <DateTimeInput name={"Beginn"} tag={"event_start"}/>
                <br/>
                <SelectfieldInput name={"Dauer"} tag={"event_duration"} selectives={[
                    ["end","End-Datum/Zeit auswählen"],
                    ...durationSelectives,
                ]}/>
                <br/>
                {
                    this.state.event?.duration==="end" && <>
                    <DateTimeInput name={"Ende"} tag={"event_end"}/><br/>
                    </>
                }
                <SelectfieldInput name={"Wiederholen"} tag={"event_period"} selectives={[
                    ["0", "nie"],
                    ["1w", "wöchentlich"],
                    ["2w", "zwei-wöchentlich"],
                    ["3w", "drei-wöchentlich"],
                    ["1d", "täglich"],
                ]}/>

                <br/>
                {this.state.event.period !== undefined && this.state.event.period !== "0" &&
                <>
                    <DateTimeInput name={"Ende"} tag={"event_stop"}/>
                    <br/>
                </>
                }

                {this.state.event.course_ID === 0 &&
                <SelectfieldInput name={"Sichtbarkeit"} tag={"event_visibility"} selectives={[
                    [1, "Nur ich"],
                    [2, "Alle Administratoren"],
                    [3, "Alle Trainer"],
                    [4, "Alle"]
                ]}/>
                }

                <TagsInput name={"Örtlichkeit"} entity={"event"} ID={this.state.event.ID || 0} tag={"event_room"}/>
                <br/>
                {
                    this.state.event.ID === undefined ?
                        <MaxBtn onClick={() => this.handleSubmit("all_future")}>hinzufügen</MaxBtn> : <>
                            {
                                this.state.event.period === "0" ?
                                    <MaxBtn onClick={() => this.handleSubmit("only_this")}>Aktualisieren</MaxBtn> :
                                    <>
                                        <MaxBtn onClick={() => this.handleSubmit("only_this")}>nur dieses Ereignis aktualisieren</MaxBtn>
                                        <MaxBtn onClick={() => this.handleSubmit("all_future")}>alle Zukünftigen aktualisieren</MaxBtn>
                                    </>
                            }
                            <br/>
                            <MaxBtn onClick={() => {
                                if (window.confirm("wirklich entfernen?")) {
                                    this.apiPost("/events/hide/" + this.state.event.ID, null, resp => {
                                        if (this.props.addEvents !== undefined)
                                            this.props.addEvents()
                                    })
                                }
                            }}>
                                entfernen
                            </MaxBtn>
                        </>

                }
                <Loader loading={this.state.loading}/>
            </form>
        </FormContext.Provider>

    }
}


export default EventsAdd;
