import {useCallback, useMemo, useState} from "react"
import {CoursePaymentModel} from "../../core/interfaces/core"
import {maxiGet} from "../../core/maxios"
import {WithSetStatusVar} from "../../club/club_inputs"

export const usePaymentModels = ({apiUrl, setStatusVar}: { apiUrl?: string} & WithSetStatusVar) => {
    const [paymentModels, setPaymentModels] = useState<CoursePaymentModel[]>()

    const reload = useCallback(() => {
        return maxiGet<{ data: CoursePaymentModel[] }>(apiUrl || "/course/paymentmodels", {setStatusVar}).then(resp => setPaymentModels(resp.data?.sort((a,b) => (a?.name || "").localeCompare(b?.name))))
    }, [apiUrl])
    useMemo(reload, [])

    return [paymentModels, reload] as const

}
