import React from 'react';
import AddNewPerson from "./user_add";


export default function UserRegisterAsAdmin() {

    return <div style={{marginTop: 40}}>
    <AddNewPerson updateParent={() => null} name={"Verwalter neu registrieren"} childs={[]} defaultOpen/>

    </div>

}
