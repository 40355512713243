import React from 'react';
import "../dashboard.sass"
import {Container, Loader, MaxBtn} from "../core/components/components";
import {TextareaInput, TextfieldInput} from "../core/input/basic";
import {SelectfieldInput} from "../core/input/select";

import "react-datepicker/dist/react-datepicker.css";
import {FormContextWrapper} from "../core/form_context";
import {apiGet, apiPost} from "../core/api";
import Status from "../core/status";
import {UserContext} from "./UserContext";

class UserMessage extends React.Component {
    state = {
        message: {
            note: "",
            who: undefined,
        },
        users: {},
        status: "",
        error: "",
        loading: false,
    };
    setState = this.setState.bind(this);

    constructor(props) {
        super(props);
        this.apiGet = apiGet.bind(this);
        this.apiPost = apiPost.bind(this);
        this.setState = this.setState.bind(this)
    }

    handleSubmit = (e) => {
        e.preventDefault();

        this.setState({loading: true});
        this.apiPost("/user/message", this.state.message, resp => this.setState({loading: false, status: "Die Anfrage wurde erfolgreich entgegengenommen!"}))
    };
    static contextType=UserContext;
    render() {
        const {club}=this.context;
        return <>
            <Container name={club.fullName+" Kontaktieren"}>
                <Status type={"error"} text={this.state.error}/>
                <Status type={"success"} text={this.state.status}/>
                {
                    (this.state.status === "") &&
                    <FormContextWrapper value={{state: this.state, setState: this.setState}} onSubmit={this.handleSubmit}>
                        <SelectfieldInput
                            name={"Thema?"}
                            tag={"message_topicId"}
                            demandSelect={true}
                            selectives={[
                                [2,"Feedback"],
                                [14,"Fragen zur Abrechnung"],
                                [12,"Kündigung"],
                                [13,"Technisches Problem"],
                                [1,"Allgemeine Anfrage"],
                            ]}
                        />
                        <br/>
                        <TextfieldInput name={"Betreff"} tag={"message_subject"}/><br/>
                        <TextareaInput name={"Bitte gebe die Nachricht ein!"} tag={"message_note"}/>

                        <MaxBtn>Nachricht Abschicken</MaxBtn>
                        <Loader loading={this.state.loading}/>
                    </FormContextWrapper>
                }
            </Container>
        </>

    }
}


export default UserMessage;
