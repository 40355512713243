import React from "react";
import {FormContext} from "../form_context";
import DatePicker, {registerLocale} from "react-datepicker";
import de from "date-fns/locale/de";
import "react-datepicker/dist/react-datepicker.css";

registerLocale('de', de);
export class DateInput extends React.Component {
    static contextType = FormContext;
    handleChange = (date) => {
        if (!date) {
            return;
        }
        let datemy = date.getFullYear() * 10000 + (date.getMonth() + 1) * 100 + date.getDate();
        const s = this.props.tag.split("_");
        if (s.length === 1) {
            this.context.setState({[this.props.tag]: datemy})

        } else {
            this.context.setState({
                [s[0]]: Object.assign(
                    {},
                    this.context.state[s[0]],
                    {[s[1]]: datemy}
                )
            });
        }

    };

    render() {
        let d;
        let date;
        const s = this.props.tag.split("_");
        if (this.context.state[s[0]] !== undefined) {
            if (s.length === 2) {
                date = (this.context.state[s[0]] || {})[s[1]];
            } else {
                date = this.context.state[this.props.tag];
            }

            //console.log("date", date)
            if (!!date) {
                date = date.toString();
                let date_string = `${date.substr(0, 4)}-${date.substr(4, 2)}-${date.substr(6, 2)}`;
                try {
                    d = new Date(date_string);
                } catch (e) {

                }
            } else {
                d = null//new Date();
            }
        } else {
            d = null//new Date();
        }
        //console.log("d", d)
        return <>

            <label><span>{this.props.name}</span>
                <DatePicker selected={d}
                            locale={"de"}
                            dateFormat={"dd.MM.yyyy"}
                            placeholderText={"Zeitpunkt auswählen"}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            maxDate={getDateIn3Years(this.props.daysToAddMax ?? 3 * 365)}
                            onChange={this.handleChange}
                            portalId="react-datepicker-portal"
                />
            </label>
        </>;
    }
}

export function getDateIn3Years(daysToAdd = 3 * 365) {
    let today = new Date();
    let tomorrow = new Date();
    tomorrow.setDate(today.getDate() + daysToAdd)
    return tomorrow;
}

export class DateInputUnix extends React.Component {
    static contextType = FormContext;
    handleChange = (date) => {
        const s = this.props.tag.split("_");
        let newData;
        if (s.length === 1) {
            newData = !!date ? date.getTime() / 1000 : null;
            this.context.setState({[this.props.tag]: newData})
        } else {
            newData = {...this.context.state[s[0]], [s[1]]: (!!date ? date.getTime() / 1000 : null)};
            this.context.setState({[s[0]]: newData});

        }
        if (!!this.props.afterUpdate) {
            this.props.afterUpdate(s[0], s[s.length - 1], newData)
        }

    };

    render() {
        let d;
        let date;
        const s = this.props.tag.split("_");
        if (this.context.state[s[0]] !== undefined) {
            if (s.length === 2) {
                date = this.context.state[s[0]][s[1]];
            } else {
                date = this.context.state[this.props.tag];
            }
            if (!date) {
                d = null;
            } else {
                d = new Date(date * 1000);
                if (isNaN(d.getTime()) || date === 0) {
                    d = null//new Date();
                }
            }

        } else {
            d = null//new Date();
        }
        return <>
            <label>
                <span style={(!this.props.labelWidth ? {} : {width: this.props.labelWidth + "px"})}> {this.props.name}</span>

                <DatePicker
                    selected={d}
                    onChange={this.handleChange}
                    locale={"de"}

                    placeholderText={"Zeitpunkt auswählen"}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    maxDate={getDateIn3Years(this.props.daysToAddMax ?? 3 * 365)}
                    dateFormat={"dd.MM.yyyy"}
                    portalId="react-datepicker-portal"
                />
            </label>
        </>;
    }
}


export class DateTimeInput extends React.Component {
    static contextType = FormContext;
    handleChange = (date) => {
        let dateSave = !!date ? Math.floor(date.getTime() / 1000) : null;

        const s = this.props.tag.split("_");
        let newData;
        if (s.length === 1) {
            newData = dateSave;
            this.context.setState({[this.props.tag]: dateSave})
        } else {
            newData = Object.assign(
                {},
                this.context.state[s[0]],
                {[s[1]]: dateSave}
            );
            this.context.setState({[s[0]]: newData});
        }

        if (!!this.props.afterUpdate) {
            this.props.afterUpdate(s[0], s[s.length - 1], newData)
        }

    };

    render() {
        let d = null;
        const s = this.props.tag.split("_");
        if (s.length === 2 && this.context.state[this.props.tag] !== undefined && this.context.state[this.props.tag] > 0) {
            d = new Date(this.context.state[this.props.tag] * 1000);
        } else if (s.length === 2 && this.context.state[s[0]][s[1]] !== undefined && this.context.state[s[0]][s[1]] > 0) {
            d = new Date(this.context.state[s[0]][s[1]] * 1000);
        } else if (s.length === 1 && this.context.state[s[0]] > 0) {
            d = new Date(this.context.state[s[0]] * 1000);
        } else {
            //d = new Date(Math.ceil(d.getTime() / (15 * 60 * 1000), 0) * 15000 * 60);
            if (false && this.props.midNight) {

                d.setHours(0);
                d.setMinutes(0);
                d.setSeconds(0)
            }
        }
        return <>
            <label className={"dateTimeInput"}><span>{this.props.name}</span> <DatePicker selected={d}
                                                                                          locale="de"
                                                                                          showTimeSelect
                                                                                          timeFormat="HH:mm"
                                                                                          timeIntervals={this.props.timeIntervals ?? 5}
                                                                                          dateFormat="dd.MM.y HH:mm"
                                                                                          timeCaption="Zeit"
                                                                                          placeholderText={"Zeitpunkt auswählen"}
                                                                                          portalId="react-datepicker-portal"
                                                                                          onChange={this.handleChange}/>
            </label>
        </>;
    }
}
