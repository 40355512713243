import Consts from "../core/consts";

export function EventsPrint({events, short, onlyOneDate}) {
    let weekday_formatted = []
    let weekdays = []
    events.forEach(event => {
        const formatted = EventsPrintDetail({onlyOneDate, short, event});
        if (formatted && !weekdays.includes(formatted[0] ?? "")) {
            weekday_formatted.push(formatted[1])
            weekdays.push(formatted[0])
        }
    })

    return weekday_formatted
        .map((a, i) => [i, a])
        .sort(([weekdayIndexA], [weekdayIndexB]) => weekdays[weekdayIndexA] < weekdays[weekdayIndexB] ? -1 : 1)
        .map(a => a[1])
        .join(", ")
}

const periodAssignment = {"0": "", "1d": " (täglich)", "1w": " (wöchentlich)", "2w": " (zweiwöchentlich)", "3w": " (dreiwöchentlich)"}

export function EventsPrintDetail({event, short, onlyOneDate}) {
    if (event === "") {
        return null
    }
    const [startUnix, endUnix, period] = event.split("|")
    const start = new Date(startUnix * 1000);
    const end = new Date(endUnix * 1000);
    const periodFormatted = periodAssignment[period] || ""
    return [
        (start.getDay() + 6) % 7,
        (short ?
            `${!onlyOneDate ? Consts.weekdayNames[(start.getDay() + 6) % 7].toUpperCase().slice(0, 2) : ""}, ${start.getHours() + ":" + ("0" + start.getMinutes()).slice(-2)}-${end.getHours() + ":" + ("0" + end.getMinutes()).slice(-2)}` :
            `${onlyOneDate ? Consts.weekdayNames[(start.getDay() + 6) % 7] + ", " : Consts.weekdayNames[(start.getDay() + 6) % 7]+ ", "}${start.getHours() + ":" + ("0" + start.getMinutes()).slice(-2)} bis ${end.getHours() + ":" + ("0" + end.getMinutes()).slice(-2)} Uhr`) + periodFormatted
    ]
}
