import {User, UserContext} from "../../user/UserContext"
import React, {useContext, useEffect, useState} from "react"
import {maxiGet} from "../../core/maxios"
import {MyReactTable} from "../../core/components/table"
import Consts from "../../core/consts"
import {CourseShiftPlannerContext} from "./CourseShiftPlanner"
import {LightContainer} from "../../core/components/container"
import {MyModal} from "../../core/components/modal";

type LocalUser = Pick<User, "ID" | "prename" | "lastname" | "fullname" | "email" | "phone" | "data"> & { shifts: string[] }

function useUserTable() {
    const {course_ID, lastUpdated} = useContext(CourseShiftPlannerContext)
    const [users, setUsers] = useState<LocalUser[]>()

    const reload = () => {
        maxiGet<{ users: LocalUser[] }>(`/shiftplanner/users/${course_ID}`).then(resp => setUsers(resp.users))
    }

    useEffect(reload, [course_ID, lastUpdated])
    return {users, reload}
}

export function CourseShiftPlannerUserTable() {
    const {club} = useContext(UserContext)
    const {users} = useUserTable()

    if (!users || users.length === 0) {
        return null
    }

    return <LightContainer>
        <h3>Personenliste mit zugeordneten Schichten</h3>
        <MyReactTable data={users} exportData={true} title={"Personenliste mit zugeordneten Schichten"} columns={[
            {
                Header: "Vorname",
                accessor: "prename",
                filterable: true,
            },
            {
                Header: "Nachname",
                accessor: "lastname",
                filterable: true,
            },
            {
                Header: "E-Mail",
                accessor: "email",
                filterable: true,
            },
            {
                Header: "Telefon",
                id: "phone",
                accessor: (entry: LocalUser) => entry.phone > 0 ? Consts.phoneNormalise("+" + entry.phone) : "",
                filterable: true,
            },
            {
                Header: "Schichten",
                accessor: "shifts",
                filterable: true,
                Cell: ({value}) => <MyModal trigger={<em>{value.join(", ")}</em>}>{(value.map((a: string) => <li>{a}</li>)) || value}</MyModal>,
                pdfCell: ({value}) => value.join(", ")
            },
            ...(club.ID === Consts.CLUB_ATV_ST_VALENTIN ? [{
                Header: "T-Shirt",
                id: "data.T-Shirt-Größe",
                accessor: (entry: LocalUser) => entry.data?.["T-Shirt-Größe"] || "",
                filterable: true,
            }] : []),
        ]}/>
    </LightContainer>
}
