import {CourseParticipantPaymentEntry, CoursePaymentGroup} from "../../core/interfaces/core"
import {MaxBtn, MiniBtn} from "../../core/components/components"
import {MyModal} from "../../core/components/modal"
import React, {SetStateAction, useCallback, useContext, useMemo, useState} from "react"
import {LightContainer} from "../../core/components/container"
import Consts from "../../core/consts"
import {UserContext} from "../../user/UserContext"
import {dateFormatDate, dateFormatUnix} from "../../core/dateFuncs"
import {CoursePaymentPlanSelect} from "./CoursePaymentPlanSelect"
import {StatusVar, useStatusVar} from "../../club/club_inputs"
import {maxiGet, maxiPost} from "../../core/maxios"
import {CourseWithCoursePartsMixture} from "../../abrechnung/course_visits"
import {FaSave, FaTrashAlt} from "react-icons/fa"
import {StatusGroup} from "../../core/status"

export type OnParticipantPaymentListChangeType = (selected: CoursePaymentGroup) => any

interface ParticipantPaymentListProps {
    participantPayments: CourseParticipantPaymentEntry[] | undefined
    course: Partial<Pick<CourseWithCoursePartsMixture, "timeCreation" | "bookingEnd" | "course_ID" | "cp_ID" | "cp_price" | "price" | "pricenm" | "membersOnly">>
    onChange?: OnParticipantPaymentListChangeType
    label?: string
}

function usePaymentGroups({loadUrl, setStatusVar, callback, disabled}: { loadUrl: string, callback?: (data: CoursePaymentGroup[]) => any, setStatusVar?: SetStateAction<StatusVar>, disabled?: boolean }) {
    const [paymentGroups, setPaymentGroups] = useState<CoursePaymentGroup[]>()
    const reload = useCallback(() => {
        !disabled && maxiGet<{ data: CoursePaymentGroup[] }>(loadUrl, {setStatusVar}).then(resp => {
            setPaymentGroups(resp.data)
            callback && callback(resp.data)
        })
    }, [loadUrl, disabled])
    useMemo(reload, [disabled])

    return [paymentGroups, reload] as const
}


export function ParticipantPaymentListModal({participantPayments, course, onChange, label}: ParticipantPaymentListProps) {
    const {club, user} = useContext(UserContext)
    const [paymentGroups] = usePaymentGroups({loadUrl: `/course/${course.course_ID}/paymentplan/active?withmodel=true&time=${course.timeCreation}`})
    const [selectedPaymentGroup, setSelectedPaymentGroup] = useState<CoursePaymentGroup>()
    const [statusVar, setStatusVar] = useStatusVar()

    const handleChange = () => {
        maxiPost(`/course/parts/${course.cp_ID}/update/paymentplan`, {group_ID: selectedPaymentGroup?.ID}, {setStatusVar}).then(resp => {
            onChange && selectedPaymentGroup && onChange(selectedPaymentGroup)
            setStatusVar({success: "Zahlungsweise erfolgreich angepasst!"})
        })
    }
    const disabledPlanEntryIds = (participantPayments || [])?.map(payment => payment.payment_plan_ID)
    const canChangePaymentPlan = (!!paymentGroups && !participantPayments?.length) || (((course.bookingEnd || 0) > new Date().getTime() / 1000) && !!paymentGroups && paymentGroups.filter(paymentGroup => selectedPaymentGroup?.ID !== paymentGroup.ID).length > 0 && (course.pricenm === course.price || !!course.membersOnly))


    return <MyModal trigger={<MiniBtn>{label || "Fälligkeiten anzeigen"}</MiniBtn>}>
        {close => <LightContainer name={"Aktuelle Teilzahlungen"}>
            {participantPayments && (participantPayments?.length || 0) > 0 ?
                <ul>
                    {
                        participantPayments.map((payment, index) => <li key={payment.ID} style={{marginBottom: 4}}>
                                <b>{index + 1}. Teilzahlungsbetrag{payment.description && ` (${payment.description})`}:</b> {Consts.moneyMaxCalc({s: payment.price / 100, club, addTax: true, user})}, fällig am {dateFormatDate(payment.dueDate)}
                                &nbsp;<MiniBtn onClick={() => window.confirm("Wirklich löschen?") && maxiPost("/course/payment_entries/delete", {ID: payment.ID}, {setStatusVar}).then(() => {
                                    // @ts-ignore
                                onChange  && onChange(null)
                                close()
                            })}><FaTrashAlt/></MiniBtn>
                            </li>
                        )
                    }
                </ul> : <p>Keine Teilzahlung ausgewählt.</p>
            }
            {
                canChangePaymentPlan && <div>
                    <h3>Andere Zahlungsweise auswählen (möglich bis {dateFormatUnix(course.bookingEnd)})</h3>
                    <CoursePaymentPlanSelect paymentGroups={paymentGroups || []} onChange={setSelectedPaymentGroup} disabledPaymentPlanEntryIds={disabledPlanEntryIds} preselectFirstEntry={false} membersOnly={course.membersOnly===1}/>
                    {selectedPaymentGroup && <MaxBtn onClick={handleChange}><FaSave/> Zahlungsweise wechseln</MaxBtn>}
                </div>
            }
            <StatusGroup {...statusVar} />
        </LightContainer>
        }
    </MyModal>
}
