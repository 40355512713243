import {CoursePaymentGroup} from "../../core/interfaces/core"
import React, {useEffect, useMemo, useState} from "react"
import CoursePaymentPlanStyle from "./CoursePaymentPlanSelect.module.sass"
import {Collapse, MaxBtn} from "../../core/components/components"
import Consts from "../../core/consts"
import {dateFormatDate} from "../../core/dateFuncs"

type CoursePaymentPlanSelectProps = {
    paymentGroups: CoursePaymentGroup[]
    onChange?: (planGroup: CoursePaymentGroup | undefined) => any
    disabledPaymentPlanEntryIds?: number[]
    discountAmount?: number
    preselectFirstEntry?: boolean
    membersOnly?: boolean
}

export function CoursePaymentPlanSelect(props: CoursePaymentPlanSelectProps) {
    const {paymentGroups, onChange, disabledPaymentPlanEntryIds, discountAmount, preselectFirstEntry = true} = props
    const [selected, setSelected] = useState<number>()
    const localOnChange = (group: CoursePaymentGroup | undefined) => {
        setSelected(group?.ID)
        onChange && onChange(group)
    }
    useMemo(() => {
        if (selected && disabledPaymentPlanEntryIds?.includes(selected)) {
            localOnChange(undefined)
        }
    }, [disabledPaymentPlanEntryIds, selected])
    useEffect(() => {
        if (paymentGroups.length && preselectFirstEntry) {
            localOnChange(paymentGroups[0])
        }
    }, [paymentGroups])


    return <div className={CoursePaymentPlanStyle.container}>
        {
            paymentGroups.map(group => {
                const allGroupPricesAreSame = new Set(group.plan.map(entry => entry.price)).size === 1
                const isDisabled = group.plan.filter(plan => plan.ID && (disabledPaymentPlanEntryIds || []).includes(plan.ID)).length > 0
                const isSelected = group.ID === selected

                return <div key={group.ID} onClick={() => !isDisabled && localOnChange(group.ID === selected ? undefined : group)}
                            className={`${CoursePaymentPlanStyle.paymentGroup} ${isSelected ? CoursePaymentPlanStyle.selected : ""} ${isDisabled ? CoursePaymentPlanStyle.disabled : ""}`}>
                    <div>
                        <h3>
                            {group.name} {allGroupPricesAreSame && `(${group.plan.length}x ${Consts.moneyMax((group.plan[0]?.price || 0) / 100)}`}
                            {!props.membersOnly ? ` falls Mitglied, sonst ${Consts.moneyMax((group.plan[0]?.pricenm || 0) / 100)}` : ``}
                            )
                            {isSelected && !!discountAmount && <> - Rabatt von {Consts.moneyMax(discountAmount / 100)}</>}
                        </h3>
                        {!allGroupPricesAreSame && <p>Gesamtpreis: {Consts.moneyMax(group.plan.reduce((a, b) => a + b.price, 0) / 100)}</p>}

                        <Collapse stopPropagation={true} name={<span>Details</span>} initialCollapsed={allGroupPricesAreSame}>
                            <ul>
                                {
                                    group.plan.map((plan, index) => <li key={plan.ID || index}><b>{plan.description}</b>: {Consts.moneyMax(plan.price / 100)}
                                        {!props.membersOnly ? ` falls Mitglied, sonst ${Consts.moneyMax((plan.pricenm || 0) / 100)}` : ``}
                                        &nbsp;fällig am {dateFormatDate(plan.dueDate)}</li>)
                                }
                            </ul>
                        </Collapse>
                    </div>
                    <div className={CoursePaymentPlanStyle.buttonGroup}>
                        {
                            !isDisabled ? <>{
                                isSelected ?
                                    <MaxBtn className={CoursePaymentPlanStyle.selectButton}>ausgewählt</MaxBtn> :
                                    <MaxBtn className={CoursePaymentPlanStyle.selectButton}>auswählen</MaxBtn>
                            }</> : <MaxBtn className={CoursePaymentPlanStyle.selectButton} disabled={true}>bisher ausgewählt</MaxBtn>
                        }
                    </div>

                </div>
            })
        }
    </div>

}
