import React from "react"
import {FormContextWrapper} from "../core/form_context";
import {Container, Loader, MaxBtn} from "../core/components/components";
import {MyModal} from "../core/components/modal";
import {DateInputUnix} from "../core/input/date";
import {apiGet, apiPost} from "../core/api";
import Status from "../core/status";

export default class DeathAdd extends React.Component {
    setState = this.setState.bind(this);
    state = {
        new: {
            child_ID: this.props.childID,
        },
        loading: true,
    };

    constructor(props) {
        super(props)
        this.apiGet = apiGet.bind(this)
        this.apiPost = apiPost.bind(this)


    }

    render() {
        return <MyModal onOpen={() => {
            this.apiGet("/user/subusers?edit=" + this.props.childID, resp => {
                this.setState({loading: false, new: {...this.state.new, deathFromDB: resp.users[this.props.childID].deathdate}})
            })
        }} trigger={<em>Person ist verstorben?</em>}>
            {close =>
                <Container name={"Sterbedatum hinzufügen"}>
                    <FormContextWrapper value={{state: this.state, setState: this.setState}} onSubmit={(e) => {
                        e.preventDefault();
                        this.apiPost("/user/death/set", this.state.new, resp => {
                            close()
                        });
                        //alert("Diese Funktion existiert noch nicht!")
                    }}>
                        {!!this.state.new.deathFromDB ? "Diese Person ist bereits gestorben." :
                            <>
                                <Loader loading={this.state.loading}/>
                                <Status type={"error"} text={this.state.error}/>
                                <DateInputUnix name={"Todestag"} tag={"new_death"}/>
                                <MaxBtn>Hinzufügen</MaxBtn>
                            </>
                        }
                    </FormContextWrapper>
                </Container>
            }
        </MyModal>
    }

}
