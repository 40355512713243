import React from 'react';
import {Container, InfoTooltip, Loader} from "../core/components/components";
import {TextfieldInput} from "../core/input/basic";
import "./login.sass"
import Status from "../core/status"
import {apiGet, apiPost} from "../core/api";
import {UserContext} from "./UserContext";

class ChangePW extends React.Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);
        this.state = {
            displayname: "",
            password1: "",
            password2: "",
            token: props.match.params.token,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.apiGet = apiGet.bind(this);
        this.apiPost = apiPost.bind(this);
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.type === 'checkbox' ? (event.target.checked ? 1 : 0) : event.target.value
        })

    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({loading: true});
        this.apiPost("/user/changepw", this.state, resp => {
            this.setState({
                error: "",
                status: resp.message,
            });
            if (resp.status === "success") {
                window.location.href = "/"
            }
        });

    }

    render() {
        //<img src={Porträt} style={{float: "right"}} width={"300px"} className={"animgrey"}/>
        const {password1, password2} = this.state;
        const notMinReq = (password1.length < 8 || !password1.match(/[a-z]/) || !password1.match(/[A-Z]/) || !password1.match(/[0-9]/));
        const notConfirming = password1 !== password2;
        return (
            <form id="form" name="form" onSubmit={this.handleSubmit} onChange={this.handleChange}>
                <Container name={"Passwort einrichten"} visible>
                    {this.context.user === undefined &&
                    <>
                        <Status type="error" text={this.state.error}/>
                        <Status type="success" text={this.state.status}/>
                        {/*<Status type="success" text={this.state.status}/>*/}
                        <TextfieldInput onBlur={() => {
                            if (password1.length > 0 && notMinReq) {
                                this.setState({error: "Das Passwort muss zumindest aus 8 Zeichen bestehen und zumindest einen Groß-, einen Kleinbuchstaben und eine Zahl enthalten."})
                            } else if (!notConfirming) {
                                this.setState({error: ""})
                            }
                        }} name={<>Passwort <InfoTooltip>Das Passwort muss zumindest aus 8 Zeichen bestehen und zumindest einen Groß-, einen Kleinbuchstaben und eine Zahl enthalten.</InfoTooltip></>} tag={"password1"} type={"password"} state={this.state}/><br/>
                        <TextfieldInput onBlur={() => {
                            if (notConfirming && password1.length > 0 && password2.length > 0) {
                                this.setState({error: "Die beiden Passwörter stimmen nicht überein."})
                            } else if (!notMinReq) {
                                this.setState({error: ""})
                            }
                        }
                        } name={"Passwort erneut"} tag={"password2"} type={"password"} state={this.state}/><br/>
                        <button className={"maxbtn"}>Einrichten</button>
                        <Loader loading={this.state.loading}/>
                    </>
                    }
                    {this.context.user !== undefined &&
                    <>
                        You are already logged in with {this.context.user.email}!
                    </>
                    }

                </Container>
            </form>
        )
    }
}

export default ChangePW;
