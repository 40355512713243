import React from "react";
import {apiGet, apiPost} from "../core/api";
import axios from "axios";
import Consts from "../core/consts";
import {LightContainer, MaxBtn} from "../core/components/components";
import {MyModal} from "../core/components/modal";
import {FaPlusCircle} from "react-icons/fa";
import Status from "../core/status";

export default class UploadTrainerPicture extends React.Component {
    constructor(props,) {
        super(props);
        this.apiGet = apiGet.bind(this);
        this.apiPost = apiPost.bind(this);
        this.setState = this.setState.bind(this);
        this.fileField = React.createRef();

        this.state = {
            showing: 1,
            loading: false,
            progress: 100,

        };
    }

    saveFile = (close) => () => {
        this.setState({loading: true});

        var formData = new FormData();
        formData.append("profile_picture", this.fileField.current.files[0]);
        formData.append("child_ID", this.props.child.ID);
        axios.post(Consts.API_PREFIX + "/user/profile_picture/upload", formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: progressEvent => {
                this.setState({
                    progress: Math.round(progressEvent.loaded / progressEvent.totalSize * 100)
                })
            }
        })
            .then(response => {
                if (response.data.status === "error") {
                    this.setState({
                        error: response.data.message,
                        status: "",
                        loading: false
                    })
                } else {
                    this.setState({
                        status: response.data.message,
                        error: "",
                        [this.fieldName]: response.data.file_name,
                        loading: false, renew: false
                    });
                    window.location.reload();
                    close()
                }
            })
            .catch((error) => {
                console.log("error", error);
                //this.setState({loading: false})
                this.setState({
                    error: error.message,
                    loading: false,
                    status: ""
                })
            });
    };
    handleChange = (e) => {

        const s = e.target.name.split("_");
        this.setState({
            [s[0]]: Object.assign(
                {},
                this.state[s[0]],
                {[s[1]]: e.target.type === 'checkbox' ? e.target.checked : e.target.value}
            )
        });

    };

    handleSubmit = (event) => {
        event.preventDefault();
    };

    render() {
        const {child, trigger} = this.props;

        return <MyModal trigger={child.image ? trigger : <FaPlusCircle style={{fontSize: "150px"}}/>}>
            {
                close =>

                    <LightContainer name={"Foto für " + child.fullname + " uploaden"}>
                        <Status type={"error"} text={this.state.error}/>
                        <form id={"profile_picture_form"} onChange={this.handleChange} onSubmit={this.handleSubmit}>
                            <div id="excel_file">
                                <label>
                                    <span>
                                        Foto (JPG, PNG)
                                    </span>
                                    <input
                                        style={{paddingTop: "20px"}}
                                        type={"file"}
                                        ref={this.fileField}
                                    />
                                    {this.state.loading &&
                                    <label>
                                        <img alt={"loader"}
                                             src="https://uid-suche.eu/src/img/ajax-loader-fff.gif"/> {this.state.progress} % {this.state.progress === 100 &&
                                    <span>(verarbeite Foto)</span>}
                                    </label>
                                    }
                                </label>
                                <MaxBtn onClick={this.saveFile(close)}>Foto uploaden</MaxBtn>
                            </div>
                        </form>
                    </LightContainer>
            }
        </MyModal>
    }
}
