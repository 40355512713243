import React from "react";
import {Container, LightContainer, Loader, MaxBtn} from "../core/components/components";
import {MyModal} from "../core/components/modal";
import {CheckboxInput, InputContainer, TextareaInput, TextfieldInput} from "../core/input/basic";
import {SelectfieldInput} from "../core/input/select";
import ChangeAddressExt from "./address_input";
import {FaCheckCircle, FaInfoCircle} from "react-icons/fa";
import Consts from "../core/consts";
import {coronaMessageExists, RegisterDisclaimer} from "./registerDisclaimers";
import Status from "../core/status";
import {FormContextWrapper} from "../core/form_context";
import {UserContext} from "./UserContext";
import {apiPost} from "../core/api";
import {getAge} from "../buchhaltung/helper";
import DatePicker from "react-datepicker";

export default class UserAddMyVerwalter extends React.Component {
    state = {
        child: {},
        success: "",
    }
    setState = this.setState.bind(this);
    apiPost = apiPost.bind(this);
    handleSubmit = (e) => {
        e.preventDefault()
        const {club} = this.context;
        if (coronaMessageExists(club) && this.state.child.covid19 !== 1) {
            this.setState({error: "Bitte zuerst die Kenntnisnahme der Covid 19 Hinweise bestätigen!"});
            return;
        }
        if (this.state.child.approved !== 1) {
            this.setState({error: "Bitte zuerst die Datenschutzerklärung akzeptieren!"})
            return;
        }
        if (this.state.child.acceptResp !== 1 && (getAge(this.state.child) || 18) < 18) {
            this.setState({error: "Bitte zuerst akzeptieren, dass Sie verantwortlich sind."})
            return;
        }
        if (this.state.loading) {
            return
        }
        this.setState({loading: true})
        this.apiPost("/user/addMeAsVerwalter", this.state.child, resp => {
            this.setState({loading: false, error: "", success: resp.message})
        })
    }
    static contextType = UserContext;

    render() {
        const {child} = this.state;
        const {club} = this.context;
        const age = 0;
        const id = "child";
        return <Container name={"Ergänze die Daten von dir als gesetzliche Vertretung"}>
            <h3>Du bist hier richtig, wenn zutrifft:<br/>
                Du bist im Moment mit dem Account von einem deiner Kinder eingelogged und möchtest dich als gesetzliche Vertretung hinzufügen. </h3>
            {
                this.state.success === "" && <FormContextWrapper value={{state: this.state, setState: this.setState}} onSubmit={this.handleSubmit}>

                    <InputContainer style={{display: "block"}}>
                        <SelectfieldInput name={"Geschlecht"} tag={id + "_sex"} selectives={[["fem", "Weiblich"], ["male", "Männlich"],]} demandSelect/>
                    </InputContainer>
                    {
                            ["titlesAnte", "titlesPost", "prename", "lastname", "email", "phone", "Notfallnummer", "address", "svn", "birthdate", "note",]
                            .map((k, i) => {
                                if (k === "address") {
                                    return <div>
                                        <ChangeAddressExt child={child} changeAddress={a => this.setState({[id]: {...child, ...a}})}/>
                                        {i % 2 === 1 && <br/>}
                                    </div>
                                }

                                if (k === "Notfallnummer") {
                                    if (age === null || age >= 18) {
                                        return null
                                    }
                                    return <InputContainer>
                                        <TextfieldInput name={"Notfallnummer"} tag={id + "_phoneAdd"}/>
                                        {(i % 2 === 1) && <br/>}
                                    </InputContainer>
                                }
                                if (k === "note" || k === "internalnote") {
                                    return <InputContainer className={"textarea"}>
                                        <TextareaInput name={<>{k === "note" ? "Gesundheitliche Einschränkungen und Hinweise (optional)" : "Interne Notizen"}
                                            &nbsp;
                                            <MyModal trigger={<FaInfoCircle/>} on={"hover"}>
                                                <h2>Gesundheitliche Einschränkungen und Hinweise (optional)</h2>
                                                Um noch besser auf die Bedürfnisse der Kinder eingehen zu können bzw. im Notfall richtig gehandelt wird, ist es von großem Vorteil, wenn der Trainer über Gesundheitliche Einschränkungen Bescheid
                                                weiß bzw. Hinweise dazu angegeben werden. Weiters werden zu besonderen Anlässen (Weihnachten, Fasching, letzte Einheit usw.) oftmals eine Kleinigkeit zu essen ausgegeben, deshalb bitte Allergien
                                                und Unverträglichkeiten angeben.<br/>
                                                Mit der Bekanntgabe erklärt sich der gesetzliche Vertreter mit der Verarbeitung und Weitergabe an die Trainer einverstanden. Art. 6 Abs. 1 lit. D DSVGO: „Schutz lebenswichtiger Interessen“
                                            </MyModal>
                                        </>
                                        } tag={id + "_" + k}/>
                                    </InputContainer>
                                }

                                if (typeof child[k] === "boolean") {
                                    return <InputContainer>
                                        <CheckboxInput name={k[0].toUpperCase() + k.substr(1)} tag={id + "_" + k}/>
                                        {i % 2 === 1 && <br/>}
                                    </InputContainer>
                                }
                                if (k === "svn") {
                                    if (child.country !== "AT" || !club.svnRequired) {
                                        return;
                                    }
                                    return [<InputContainer>
                                        <TextfieldInput key={"textfieldinput" + id} name={<>
                                            {Consts.translate(k)}
                                            &nbsp;
                                            <MyModal trigger={<FaInfoCircle/>} on={"hover"}>
                                                <h2>Sozialversicherungsnummer</h2>
                                                Die Angabe der Sozialversicherungsnummer dient lediglich zur eindeutigen Identifikation von Personen und wird in unserem System verschlüsselt
                                                abgespeichert, somit hat darauf niemand Zugriff.
                                            </MyModal>
                                        </>} tag={id + "_" + k} ph={Consts.placeHolders[k]}/>

                                    </InputContainer>,
                                        <br/>]
                                }
                                if (k === "birthdate") {
                                    if (child.country === "AT" && club.svnRequired) {
                                        return;
                                    }
                                    let d;
                                    if (child.birthdate > 0) {
                                        let date = `${child[k]}`;
                                        let date_string = `${date.substr(0, 4)}-${date.substr(4, 2)}-${date.substr(6, 2)}`;
                                        d = new Date(date_string);
                                    } else {
                                        //d = new Date()
                                    }

                                    return [<InputContainer>
                                        <label key={"gbrtst" + id}><span>Geburtsdatum</span>
                                            <DatePicker selected={d}
                                                        locale={"de"}
                                                        dateFormat={"dd.MM.yyyy"}
                                                        placeholderText={"00.00.0000"}
                                                        showYearDropdown
                                                        peekNextMonth
                                                        showMonthDropdown
                                                        maxDate={new Date()}
                                                        dropdownMode="select"
                                                        onChange={(date) => {
                                                            if (date) {
                                                                let datemy = date.getFullYear() * 10000 + (date.getMonth() + 1) * 100 + date.getDate()
                                                                this.setState({[id]: Object.assign({}, this.state[id], {birthdate: datemy})})
                                                            }
                                                        }
                                                        }/></label>
                                    </InputContainer>, <br/>];
                                }
                                if (k === "email") {
                                    return <InputContainer>
                                        <TextfieldInput key={"textfieldinput" + id}
                                                        onBlur={this.checkPhone}
                                                        inputBorderColor={child.emailValid === false ? "red" : null}
                                                        name={<>{Consts.translate(k)} {child.emailValid === true && <FaCheckCircle/>}{child.emailValid === false && "(falsch)"}</>}
                                                        tag={id + "_" + k}
                                                        ph={Consts.placeHolders[k]}
                                        />
                                        {(i % 2 === 1) && <br/>}
                                    </InputContainer>
                                }
                                return <InputContainer>
                                    <TextfieldInput key={"textfieldinput" + id} onBlur={this.checkPhone} name={Consts.translate(k)} tag={id + "_" + k} ph={Consts.placeHolders[k]}/>
                                    {(i % 2 === 1) && <br/>}
                                </InputContainer>
                            })

                    }
                    <br/>
                    <br/>


                    <LightContainer name={"Abschließen"}>
                        <RegisterDisclaimer club={club} registration={"child"} registrationAdmin={"child"}  age={getAge(this.state.child)} />
                        <Status type={"error"} text={this.state.error}/>
                        <br/>
                        <br/>


                        <MaxBtn>hinzufügen</MaxBtn><Loader loading={this.state.loading}/>
                    </LightContainer>
                </FormContextWrapper>
            }

            <Status type={"success"} text={this.state.success}/>
        </Container>
    }
}
