import React, {useContext, useEffect, useState} from "react";
import {Container, LightContainer, MaxBtn} from "../core/components/components";
import {MyModal} from "../core/components/modal";
import {MyReactTable} from "../core/components/table";
import {CheckboxInput, TextareaInput} from "../core/input/basic";
import {DateInputUnix} from "../core/input/date";
import Status from "../core/status";
import {dateFormatUnix} from "../core/dateFuncs";
import {FormContextWrapper} from "../core/form_context";
import {apiPost} from "../core/api";
import {UserContext} from "./UserContext";
import {maxiGet} from "../core/maxios";
import {RoleInput} from "../core/input/selectSpecial";
import {AdaptMembershipFee} from "./user_roles_adapt_membership_fee";

const ChildRoles = ({childID, child}) => {
    const [roles, setRoles] = useState([]);
    const [{error}, setStatusVar] = useState({});
    const {club} = useContext(UserContext);

    const loadRoles = () => maxiGet("/user/roles/list/" + childID, {setStatusVar}).then(resp => setRoles(resp.roles));
    useEffect(() => {
        loadRoles()
    }, []);

    return <LightContainer>
        <Status type={"error"} text={error}/>
        {child?.ID && <AdaptMembershipFee roles={roles || []} child={child} onClose={loadRoles}/>}
        <AddRole child_ID={childID} reload={loadRoles}/>
        <MyReactTable
            data={roles || []}
            defaultSorted={[{id: "timeStart", desc: true}]}
            columns={[
                {
                    Header: "Typ",
                    accessor: "role",
                    Cell: ({value}) => club.memberStates[value] || "",
                    maxWidth: 250,
                },
                {
                    Header: "Von",
                    accessor: "timeStart",
                    Cell: ({value}) => dateFormatUnix(value),
                    maxWidth: 120,
                },
                {
                    Header: "Bis",
                    accessor: "timeEnd",
                    Cell: ({value}) => value !== 0 ? dateFormatUnix(value) : "",
                    maxWidth: 120,
                },
                {
                    Header: "Notiz",
                    accessor: "note",
                },
                {
                    Header: "Aktion",
                    id: "Aktion",
                    Cell: ({original}) => <EndRole child_ID={childID} reload={loadRoles} role={original}/>,
                    maxWidth: 120,
                }
            ]}
        />
    </LightContainer>
};

class AddRole extends React.Component {
    setState = this.setState.bind(this);
    state = {
        new: {
            role: 1,
            note: "",
        }
    };
    apiPost = apiPost.bind(this);
    handleSubmit = (e, close) => {
        e.preventDefault();
        this.apiPost("/user/roles/add/", {child_ID: this.props.child_ID, ...this.state.new}, resp => {
            this.props.reload();
            close()
        })
    };
    static contextType = UserContext;

    render() {
        const {club} = this.context;
        return <MyModal trigger={<MaxBtn style={{float: "right", marginTop: "-40px"}}>Hinzufügen</MaxBtn>} defaultOpen={false}>
            {
                close => <Container name={"Rolle hinzufügen"}>
                    <FormContextWrapper
                        value={{state: this.state, setState: this.setState, updateState: (a, b, c) => this.setState({[a]: {...this.state[a], [b]: c}})}}
                        onSubmit={e => this.handleSubmit(e, close)}
                    >
                        <Status type={"error"} text={this.state.error}/>
                        <RoleInput tag={"new_role"} name={"Rolle"}/>
                        <DateInputUnix name={"Datum des Starts"} tag={"new_timeStart"}/><br/>
                        <TextareaInput name={"Notiz"} tag={"new_note"}/>

                        <MaxBtn>Hinzufügen</MaxBtn>
                    </FormContextWrapper>
                </Container>
            }

        </MyModal>
    }
}

class EndRole extends React.Component {
    setState = this.setState.bind(this);
    state = {
        new: {
            note: this.props.role.note,
            timeStart: this.props.role.timeStart,
            timeEnd: this.props.role.timeEnd === 0 ? (new Date()).getTime() / 1000 : this.props.role.timeEnd,
            finished: this.props.role.timeEnd > 0,
        }
    };
    apiPost = apiPost.bind(this);
    handleSubmit = (e, close) => {
        e.preventDefault();
        this.apiPost("/user/roles/end/", {child_ID: this.props.child_ID, ...this.props.role, ...this.state.new}, resp => {
            this.props.reload();
            close()
        })
    };

    render() {
        return <MyModal trigger={<em>Bearbeiten</em>} defaultOpen={false}>
            {
                close => <Container name={"Rolle bearbeiten / beenden"}>
                    <FormContextWrapper
                        value={{state: this.state, setState: this.setState, updateState: (a, b, c) => this.setState({[a]: {...this.state[a], [b]: c}})}}
                        onSubmit={e => this.handleSubmit(e, close)}
                    >
                        <Status type={"error"} text={this.state.error}/>
                        <DateInputUnix name={"Datum des Starts"} tag={"new_timeStart"}/><br/>
                        <CheckboxInput name={"Beendet"} tag={"new_finished"}/><br/>
                        {
                            !!this.state.new.finished &&
                            <><DateInputUnix name={"Datum des Endes"} tag={"new_timeEnd"} daysToAddMax={20 * 365}/><br/></>
                        }
                        <TextareaInput name={"Notiz"} tag={"new_note"}/><br/>

                        <MaxBtn>Bearbeiten / Beenden</MaxBtn>
                    </FormContextWrapper>
                </Container>
            }

        </MyModal>
    }
}



export default ChildRoles
