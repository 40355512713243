import React, {useContext, useMemo, useState} from "react";
import {Container, MaxBtn, MiniBtn} from "../core/components/components";
import {MyModal} from "../core/components/modal";
import {MyReactTable} from "../core/components/table";
import {Link} from "react-router-dom";
import {maxiGet, maxiPost} from "../core/maxios";
import Status from "../core/status";
import UserHistory from "../user/user_history";
import {FaInfoCircle} from "react-icons/fa";
import {dateFormatUnix} from "../core/dateFuncs";
import {permissionsSelectableMap, UserAddSuperuser, UserPermissionsAddToPerson} from "./permissionsAdd";
import {encodeGetParams} from "../core/helpers";
import {checkPermissions} from "./permissionsHelpers";
import {UserContext} from "../user/UserContext";

export default function UserPermissions({entity_type, entity_ID}) {
    const [permissions, setPermissions] = useState([]);
    const [superUsers, setSuperUsers] = useState([]);
    const [{error, loading}, setStatusVar] = useState({})
    const context = useContext(UserContext);

    const loadPermission = () => maxiGet("/club/permissions?" + encodeGetParams({entity_type, entity_ID}), {setStatusVar}).then(({permissions, superUsers}) => {
        setPermissions(permissions)
        setSuperUsers(superUsers)
    })
    useMemo(loadPermission, [])
    return <Container name={""}>
        <div style={{float: "right"}}>
            {
                checkPermissions(context, "club") && <MyModal trigger={<MaxBtn>andere Person berechtigen</MaxBtn>}>
                    {close =>
                        <Container name={"Person berechtigen"}>
                            <UserPermissionsAddToPerson {...{
                                entity_type, entity_ID, close: () => {
                                    close()
                                    loadPermission()
                                }
                            }}/>
                        </Container>
                    }
                </MyModal>
            }
        </div>
        <h1>Berechtigungen</h1>
        <Status text={error} type={"error"}/>
        <MyReactTable
            data={permissions}
            loading={loading}
            exportData={true}
            title={"Berechtigungen"}
            columns={[
                {
                    Header: "Name",
                    accessor: "child.fullname",
                    filterable: true,
                    maxWidth: 250,
                    Cell: ({value, original}) => <Link to={"/benutzer/profil/" + original.child.ID}>{value}</Link>
                },
                {
                    Header: "Berechtigungen",
                    id: "permissions",
                    accessor: ({permissions}) => permissions.filter(p => !p.timeRevoked).map(p => (permissionsSelectableMap[p.permission] || p.permission) + ({course: " auf Kursebene"}[p.entity_type] || "")).join(", "),
                    Cell: ({value, original}) => <MyModal trigger={<em>{value}</em>}>
                        {
                            close => <Container name={"Berechtigungen von " + original.child.fullname}>
                                {
                                    original.permissions.map(({permission, ID, timeGranted, timeRevoked, entity_type, entity_ID}) => <li key={ID}>
                                        <h3>{permissionsSelectableMap[permission] || permission} {{course: " auf Kursebene"}[entity_type] || ""}</h3>
                                        berechtigt seit {dateFormatUnix(timeGranted)}, {timeRevoked && <>entzogen am {dateFormatUnix(timeRevoked)} </>}
                                        <MyModal trigger={<em><FaInfoCircle/></em>}>
                                            <UserHistory personType={"permission"} userID={ID}/>
                                        </MyModal>
                                        {
                                            !timeRevoked && checkPermissions(context, "club") && <> <em onClick={() => window.confirm("Wirklich?") && maxiPost("/club/permissions/revoke", {ID}).then(loadPermission)}>entziehen?</em></>
                                        }
                                        {
                                            entity_type === "course" && <> <Link to={"/kurse/" + entity_ID}>zum Kurs</Link></>
                                        }
                                    </li>)
                                }
                            </Container>
                        }
                    </MyModal>
                }
            ]}
        />
        {
            !entity_type && <>
                <br/>
                <div style={{float: "right"}}>
                    {
                        checkPermissions(context, "club") && <MyModal trigger={<MaxBtn>Administrator hinzufügen</MaxBtn>}>
                            {close =>
                                <Container name={"Administrator hinzufügen"}>
                                    <UserAddSuperuser close={() => {
                                        close()
                                        loadPermission()
                                    }
                                    }/>
                                </Container>
                            }
                        </MyModal>
                    }
                </div>
                <h1>Administratoren</h1>
                <MyReactTable
                    data={superUsers}
                    exportData={true}
                    title={"Administratoren"}
                    columns={[
                        {
                            Header: "Name",
                            accessor: "fullname",
                            filterable: true,
                            Cell: ({value, original}) => <Link to={"/benutzer/profil/" + original.ID}>{value}</Link>
                        },
                        {
                            Header: "Berechtigung",
                            accessor: "role",
                            filterable: true,
                            maxWidth: 120,
                            Cell: ({value, original}) => value < 80 ? "Leseadmin" : "Admin"
                        },
                        {
                            maxWidth: 300,
                            pdfShow: false,
                            Cell: ({original}) => <>
                                <MiniBtn onClick={() => maxiPost("/club/superusers", {child_ID: original.ID, newRole: original.role < 80 ? 90 : 50}, {setStatusVar}).then(loadPermission)}>Lese/Schreib-Admin ändern</MiniBtn>&nbsp;
                                <MiniBtn onClick={() => window.confirm("Wirklich?") && maxiPost("/club/superusers", {child_ID: original.ID, newRole: 0}, {setStatusVar}).then(loadPermission)}>entziehen</MiniBtn>
                            </>
                        }
                    ]}
                />
            </>
        }
    </Container>
}

