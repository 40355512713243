import React, {useContext, useEffect, useMemo, useState} from "react"
import {LightContainer, Loader, MiniBtn} from "../core/components/components";
import {MyReactTable} from "../core/components/table";
import {TextfieldInput} from "../core/input/basic";
import {apiGet, apiPost} from "../core/api";
import Status from "../core/status";
import {UserContext} from "./UserContext";
import {dateFormat} from "../core/dateFuncs";
import {Link} from "react-router-dom";
import {maxiGet} from "../core/maxios";
import {FormContextWrapper} from "../core/form_context";
import {checkPermissions} from "../club/permissionsHelpers";
import {FaSyncAlt, FaTimes} from "react-icons/all";
import {FaPlus} from "react-icons/fa";

class UserSearchSelect extends React.Component {
    state = {
        users: null,
        searchName: this.props.initialText || "",
        name: this.props.initialText || "",
        searchNameLength: 3,
        userID: this.props?.userID
    };

    constructor(props) {
        super(props);
        if (props.searchNameLength !== undefined) {
            this.state.searchNameLength = props.searchNameLength;
        }
        this.apiGet = apiGet.bind(this);
        this.apiPost = apiPost.bind(this);
        this.setState = this.setState.bind(this)
    }

    componentDidMount() {
        if (this.props?.userID !== undefined || this.props.initialText !== undefined) {
            this.loadUsers(true);
        }
    }

    static contextType = UserContext;
    loadUsers = (forceInstant) => {
        if (this.state.timer) {
            clearTimeout(this.state.timer)
        }
        const timer = window.setTimeout(() => {
            if (this.state.searchName.length >= this.state.searchNameLength) {
                this.setState({loading: true});
                this.apiGet(encodeURI("/user/search?name=" + this.state.searchName) + (this.state.userID ? "&ids=" + this.state.userID : "") + (this.props.furtherFields ? "&furtherFields=" + Object.keys(this.props.furtherFields).join(",") : ""), resp => this.setState({
                    users: resp.users,
                    loading: false
                }))
            } else {
                this.setState({users: []})
            }
        }, forceInstant ? 0 : 500)
        this.setState({timer})
    };

    onChange = (userID, user) => {
        this.setState({userID: userID}, () => {
            if (this.props.onChange !== undefined) {
                this.props.onChange(userID, user);
            }
        })

        if (this.props.storeID !== undefined) {
            this.setState({searchName: "", name: ""}, this.loadUsers);
        }
    }

    render() {

        const usersHide = this.props.usersHide || [];
        return <LightContainer name={this.props.heading ? this.props.heading : "Wähle Benutzer"} style={this.props.style}>
            <span>{this.props.preclaimer}</span>
            <FormContextWrapper value={{state: this.state, setState: this.setState}}>
                <TextfieldInput name={"Name"} tag={"name"} ph={"Max Mustermann"} onChange={(e) => {
                    clearTimeout(this.state.timer)
                    let timer = window.setTimeout(() => {
                        this.loadUsers()
                    }, 250);
                    this.setState({searchName: e.target.value, "timer": timer})
                }}/>
                <span>{this.props.storeID !== undefined && (this.state?.users?.filter(u => u.ID === this.state.userID)[0]?.fullname || "kein Benutzer ausgewählt")}</span>
            </FormContextWrapper>
            <Loader loading={this.state.loading}/>
            <Status type={"error"} text={this.state.error}/>
            {
                this.state.users !== null && this.state.users.length > 0 && <MyReactTable
                    data={this.state.users.filter(this.props.filter || (() => true))}
                    columns={[
                        {
                            Header: "Name",
                            accessor: "lastname",
                            Cell: ({original: u}) => u.lastname + ", " + u.prename,
                            //
                        },
                        {
                            Header: "Aktion",
                            id: "aktion",
                            Cell: ({original: u}) => <>
                                {
                                    usersHide.includes(u.ID) ? <em>nicht auswählbar</em> : (
                                        this.props.storeID === undefined ? this.props.selectButton(u.ID, u) : (this.state.userID === u.ID ? <b>ausgewählt</b> : <em onClick={() => this.onChange(u.ID, u)}>auswählen</em>)
                                    )
                                }
                                <Link to={"/benutzer/profil/" + u.ID}><em>Profil anzeigen</em></Link>
                            </>
                        },
                        {
                            Header: checkPermissions(this.context, "user") ? "Geburtstag" : "Geburtsjahr",
                            accessor: "birthdate",
                            filterable: true,
                            Cell: ({original: u}) => dateFormat(u.birthdate),
                        },
                        ...Object.entries(this.props.furtherFields || {}).map(([key, field]) => ({
                            Header: field.label,
                            accessor: key,
                            Cell: field.Cell,
                        })),

                    ]}
                />
            }
            {
                this.state.users !== null && this.state.users.length === 0 && this.state.searchName !== "" && <>Keine solchen Benutzer<br/></>
            }
        </LightContainer>
    }
}

function DetailUserSearchSelect({
                                    userID = [], userData = [], storeID, searchNameLength = 3, multiple = false, furtherFields = [], onChange = () => {
    }, usersHide = [], style = {}, preclaimer, selectButton = () => {
    }, heading = "", filter = null, includedUsers = [], includeSearch = true, hideSelected = false, showSelectedUsers = true, showHeader = true,
                                }) {
    const [users, setUsers] = useState([])
    const [state, setState] = useState({searchName: ""})
    const [userIDs, setUserIDs] = useState({ids: userID, users: userData})
    const [{loading, error, success}, setStatusVar] = useState({})
    const user = useContext(UserContext).user || {}
    const [timer, setTimer] = useState(null)

    const loadUsers = (force = false) => {
        if (timer) {
            clearTimeout(timer)
        }
        setTimer(window.setTimeout(() => {
            if (state.searchName?.length >= searchNameLength || force) {
                const url = "/user/search?"
                maxiGet(encodeURI(url + "name=" + state.searchName) + (userIDs?.ids?.length > 0 ? userIDs.ids.map(x => ("&ids=" + x)).join("") : "") + (furtherFields ? "&furtherFields=" + Object.keys(furtherFields).join(",") : ""), {setStatusVar}).then(
                    resp => setUsers(resp.users))
            } else {
                setUsers([])
            }
        }, force ? 0 : 500))
    };

    useMemo(() => loadUsers(userID !== null && userID !== []), [])
    useEffect(loadUsers, [state])
    useEffect(() => {
        if (onChange !== undefined) {
            onChange(userIDs.ids, userIDs.users);
        }
    }, [userIDs])

    const onChangeLocal = (newID, user, add = true) => {
        if (multiple) {
            setUserIDs(a => {
                const contained = a.ids.some(x => x === newID)
                if (contained && !add) {
                    return {ids: a.ids.filter(x => x !== newID), users: a.users.filter(x => x.ID !== newID)}
                }
                if (!contained && add) {
                    return {ids: [...a.ids, newID], users: [...a.users, user]}
                }
                return a
            })
        } else {
            setUserIDs(add ? {ids: [newID], users: [user]} : {ids: [], users: []})
        }


        if (storeID !== undefined && !multiple && add) {
            setState({searchName: ""});
        }
    }

    const allUsers = [...(users || []), ...(includedUsers || []), ...(userIDs.users || [])].filter((val, indexOuter, array) => {
        return array.filter((x, indexInner) => x.ID === val.ID && indexInner < indexOuter).length === 0
    }).sort((a, b) => (a.fullname || "").toLowerCase() < (b.fullname || "").toLowerCase() ? -1 : 1)
    const shownUsers = allUsers.filter(filter || (() => true)).filter(y => !hideSelected || !(userIDs.ids.some(x => x === y.ID)))

    return <LightContainer name={""} style={style}>
        {showHeader && <h2>{heading || "Wähle Benutzer"}</h2>}
        {preclaimer && <span>{preclaimer}</span>}
        {includeSearch && <FormContextWrapper value={{state: state, setState: setState}}>
            <TextfieldInput name={"Name"} tag={"searchName"} ph={"Max Mustermann"}/>
            {showSelectedUsers && <>{storeID !== undefined && (allUsers.filter(u => (userIDs.ids || []).some(x => x === u.ID)).map(u => u.fullname).join(", ") || "kein Benutzer ausgewählt")}</>}
        </FormContextWrapper>}
        <Loader loading={loading}/>
        <Status type={"error"} text={error}/>
        {
            (shownUsers.length > 0) && <><MyReactTable
                data={shownUsers}
                columns={[
                    {
                        Header: "Name",
                        accessor: "lastname",
                        Cell: ({original: u}) => <Link to={"/benutzer/profil/" + u.ID}>{u.lastname && u.prename ? u.lastname + ", " + u.prename : u.fullname}</Link>,
                        //
                    },
                    {
                        Header: "Aktion",
                        id: "aktion",
                        Cell: ({original: u}) => usersHide.indexOf(u.ID) > -1 ? <em>nicht auswählbar</em> : (
                            storeID === undefined ? selectButton(u.ID, u) : (userIDs.ids.some(x => x === u.ID) ? <em><b onClick={() => onChangeLocal(u.ID, u, false)}>ausgewählt</b></em> : <em onClick={() => onChangeLocal(u.ID, u)}>auswählen</em>)
                        )
                    },
                    {
                        Header: "Geburtstag",
                        accessor: "birthdate",
                        filterable: true,
                        Cell: ({original: u}) => dateFormat(u.birthdate),
                    },
                    ...Object.entries(furtherFields || {}).map(([key, field]) => ({
                        Header: field.label,
                        accessor: key,
                        Cell: field.Cell,
                    })),

                ]}
            /></>
        }
        {
            shownUsers.length === 0 && state.searchName !== "" && <p>Keine solchen Benutzer.</p>
        }
    </LightContainer>
}

function UserSearchSelectOpener({
                                    userID = [], multiple = false, furtherFields = [], onChange, usersHide = [], style = {}, preclaimer = "", heading = "Person auswählen",
                                    filter = null, newPerson="Neue Person", label = "Ausgewählt", textNotSelected = "Es wurde noch keine Person ausgewählt.", labelStyle = {}
                                }) {
    const [users, setUsers] = useState([])
    const [showSelection, setShowSelection] = useState(false)
    const [{loading, error, success}, setStatusVar] = useState({})

    const loadUsers = (ids) => {
        const data = !!ids ? (Array.isArray(ids) ? ids : [ids]) : []
        // console.log(ids, data)
        const url = "/user/search?"
        maxiGet(encodeURI(url + "name=") + data.map(x => ("&ids=" + (x.ID || x))).join("") + (furtherFields ? "&furtherFields=" + Object.keys(furtherFields).join(",") : ""), {setStatusVar}).then(
            resp => {
                setUsers(resp.users)
            })
    }
    const removeUser = (x) => {
        setUsers(a => a.filter(y => y.ID !== x.ID))
    }
    const onChangeLocal = (ids, newUsers) => {
        // console.log(ids, newUsers)
        if (multiple ? users.length !== newUsers.length : users[0]?.ID !== newUsers[0]?.ID) {
            setShowSelection(false)
            setUsers(newUsers)
        }

    }

    useMemo(() => loadUsers(userID), [])
    useEffect(() => typeof onChange === "function" && onChange(users.map(x => x.ID), users), [users])

    return <><label><span style={labelStyle}><b>{label}</b></span>
        {users.length > 0 ? users.map(x => <MiniBtn key={x} style={{marginRight: 2}}><FaTimes onClick={() => removeUser(x)}/> {x.fullname} </MiniBtn>) : <p style={{marginRight: 10, display: "inline"}}>{textNotSelected}</p>}
        {!showSelection && <MiniBtn onClick={() => setShowSelection(true)}>{multiple ? <><FaPlus/> {newPerson}</> : <><FaSyncAlt/> {newPerson || "Ändere Person"}</>}</MiniBtn>}
        {showSelection && <DetailUserSearchSelect storeID heading={heading} onChange={onChangeLocal} id={users} filter={filter} userID={users.map(x => x.ID)} userData={users}
                                                  searchNameLength={0} multiple={multiple} style={style} hideSelected={true} showSelectedUsers={false}/>}
    </label><br/></>
}

export default UserSearchSelect;
