import {Container, InfoTooltip, Loader, MaxBtn} from "../core/components/components"
import {MyModal} from "../core/components/modal"
import {CheckboxInput, InputContainer, TextareaInput, TextfieldInput} from "../core/input/basic"
import {SelectfieldInput} from "../core/input/select"
import React, {useEffect, useMemo, useState} from "react"
import {apiPost} from "../core/api"
import Status from "../core/status"
import {FormContextWrapper} from "../core/form_context"
import {maxiGetObj} from "../core/maxiosLeg"
import {formatDateRange} from "./ledger_view"
import Consts from "../core/consts"

import UserHistory from "../user/user_history"
import {StatusVar} from "../club/club_inputs"
import useLocalStorageForm from "../core/hooks/useLocalStorageForm"
import {DeepPartial, FormProvider, useWatch} from "react-hook-form"
import {DateTimepickerUnixHook} from "../core/input/datehooks"
import {SelectFieldInputHook} from "../core/input/select2"
import {LabelValuePair, OptionalLabelValuePair} from "../core/components/table"
import {maxiGet} from "../core/maxios"
import deepEqual from "deep-equal"

interface Account {
    ID: number
    kind: number
    nr: number
    name: string
    address: string
    iban: string
    bic: string
}

const CostCenterInput = ({acct}: { acct: Account }) => {

    const [accounts, setAccounts] = useState<Account[]>([])
    useMemo(() => {
        if (parseInt("" + acct.kind) === 0) {
            // @ts-ignore
            maxiGetObj(0, "/accounting/accounts/list?not_customer_accounts", resp => setAccounts(resp.accounts))
        }
    }, [])
    if (parseInt("" + acct.kind) !== 0) {
        return null
    }
    return <SelectfieldInput type={"reactselect"} name={"Kontenklasse"} tag={"acct_costCenter"}
                             selectives={accounts.filter(a => a.kind === 2)?.map(a => ({
                                 value: a.nr,
                                 label: a.nr + " " + a.name
                             }))}/>
}

export function formatAccount(nr: number) {
    return (nr < 1000 ? "0" : "") + (nr < 100 ? "0" : "") + nr
}

export function getAge({birthdate, svn = ""}: { birthdate: number, svn?: string }, forceSVN = false) {
    const today = new Date()
    let d = new Date()
    svn = svn.toString().replaceAll("*", "0").replaceAll(" ", "").replaceAll(".", "").replaceAll("-", "")
    if (!!birthdate && !forceSVN) {
        d = new Date(birthdate / 10000, (birthdate / 100) % 100 - 1, birthdate % 100)
    } else {
        if ((svn || "").toString().length !== 10) {
            return 24 // Max' age at time of programming this line;
        }
        const svnInt = parseInt(svn);
        d = new Date(((svnInt % 100) + (svnInt % 100 <= (today.getFullYear() - 2000) ? 2000 : 1900)), (svnInt / 100) % 100 - 1, (svnInt / 10000) % 100)
    }
    return new Date(today.getTime() - d.getTime()).getFullYear() - 1970
}

interface ShowAccountHolderProps {
    acct: Account
    value: number
    reload: Function
    children?: any
}

interface ShowAccountHolderState extends StatusVar {
    acct: Account

}

export class ShowAccountHolderImport extends React.Component<ShowAccountHolderProps, ShowAccountHolderState> {
    setState: any = this.setState.bind(this)
    state: ShowAccountHolderState = {
        acct: {...this.props.acct, address: this.props.acct.address.split(", ").join("\n")}
    }
    apiPost = apiPost.bind(this)
    handleSubmit = (e: Event, close: Function) => {
        e.preventDefault()
        this.setState({loading: true})
        this.apiPost("/accounting/accounts/update", this.state.acct, resp => {
            close()
        })
    }

    render() {
        const {acct, value} = this.props
        return <MyModal trigger={this.props.children || <em>{value}</em>} modal>
            {close =>
                <Container name={"" + value}>
                    <Status type={"error"} text={this.state.error}/>
                    <FormContextWrapper value={{
                        state: this.state,
                        setState: this.setState,
                        updateState: (a, b, c) => this.setState({[a]: {...this.state[a as keyof ShowAccountHolderState], [b as any]: c}})
                    }} onSubmit={(e: Event) => this.handleSubmit(e, () => {
                        close()
                        this.props.reload()
                    })}>
                        <TextfieldInput name={"Name"} tag={"acct_name"} style={{width: "300px"}}/><br/>
                        {acct.iban && <label><span>IBAN</span>{acct.iban}<br/></label>}
                        {acct.bic && <label><span>BIC</span>{acct.bic}<br/></label>}
                        <TextareaInput name={"Adresse"} tag={"acct_address"}/>
                        <CheckboxInput name={<>Bestandskonto <InfoTooltip>
                            <b>Bestandskonten</b> sind Konten, die im Soll an Wert gewinnen und im Haben an Wert
                            verlieren.<br/>
                            Für die Buchhaltungsübersicht-Übersicht wird dieses Feld verwendet, um
                            für <b>Ertragskonten</b> (die im Haben an Wert gewinnen und im Soll an Wert verlieren, also
                            wo dieser Schalter auf inaktiv steht)
                            das Vorzeichen der Salden zu negieren.
                        </InfoTooltip></>} tag={"acct_inflow"} style={{verticalAlign: "middle"}}/> <br/>
                        <SelectfieldInput name={"Art"} tag={"acct_kind"}
                                          selectives={Object.entries(Consts.kontenKinds).map(([key, value]) => [key.toString(), value])}/>
                        <br/>
                        {
                            (parseInt("" + this.state.acct.kind) === 1 || parseInt("" + this.state.acct.kind) === 5) && <>
                                <TextfieldInput name={"IBAN"} tag={"acct_iban"}/> <br/>
                                <TextfieldInput name={"BIC"} tag={"acct_bic"}/> <br/>
                            </>
                        }
                        {/*<CostCenterInput acct={acct}/>*/}
                        <MaxBtn>Speichern</MaxBtn>
                        <Loader loading={this.state.loading}/>


                    </FormContextWrapper>
                    <MaxBtn
                        onClick={() => window.confirm("Wirklich löschen?") && this.apiPost("/accounting/accounts/delete", {ID: acct.ID}, () => {
                            close()
                            this.props.reload && this.props.reload()
                        })}>Konto entfernen?</MaxBtn>
                    <br/>
                    <UserHistory userID={acct.ID} personType={"account"}/>

                </Container>
            }
        </MyModal>
    }
}

interface BusinessYearFromTo {
    StartDate: number,
    EndDate: number
}

interface BusinessyearSearchProps {
    justBusinessYearSearch?: boolean
    onChange: (val: Businessyear) => any
    localStorageKeyName?: string
}

export interface Businessyear {
    timePeriod: number
    startDate: number
    endDate: number
    endDateShow?: number
}

interface BusinessyearFormValues {
    timePeriod: OptionalLabelValuePair<string, number>
    previousTimePeriod: OptionalLabelValuePair<string, number | null>
    startDate: OptionalLabelValuePair<string, number>
    endDate: OptionalLabelValuePair<string, number>
    endDateShow?: OptionalLabelValuePair<string, number>
}

type BusinessyearWithPreviousTimePeriod = Businessyear & { previousTimePeriod: number | null }

function mapBusinessyearFormToBusinessyear(businessyear: DeepPartial<BusinessyearFormValues>): BusinessyearWithPreviousTimePeriod {
    const {endDateShow, endDate, startDate, timePeriod, previousTimePeriod} = businessyear
    return {
        endDate: endDate?.value || 0,
        endDateShow: endDateShow?.value || endDate?.value || 0,
        startDate: startDate?.value || 0,
        timePeriod: timePeriod?.value || -1,
        previousTimePeriod: previousTimePeriod?.value || -1,
    }
}

function useBusinessYears(props: Pick<BusinessyearSearchProps, "onChange" | "localStorageKeyName">) {
    const {onChange, localStorageKeyName} = props
    const [existingBusinessYears, setExistingBusinessYears] = useState<BusinessYearFromTo[]>()
    const form = useLocalStorageForm<BusinessyearFormValues>({timePeriod: {value: 12}, previousTimePeriod: {value: null}, startDate: {value: 0}, endDate: {value: 0}}, "currentTimePeriodAndStartDate_" + (localStorageKeyName || ""))
    const watch = useWatch(form)
    const [createdStartDates, setCreatedStartDates] = useState<LabelValuePair<string, number>[]>()

    useEffect(() => {
        maxiGet("/accounting/businessyear/list/prepared", {}).then(resp => setExistingBusinessYears(resp))
    }, [])
    useEffect(() => {
        // Prevent wrong determination of local storage selected period
        if (!existingBusinessYears) {
            return
        }
        const formAsBusinessYear = mapBusinessyearFormToBusinessyear(watch)
        const created = createAndUpdateBusinessyearStartDates({firstSearch: !!existingBusinessYears, businessYear: formAsBusinessYear, businessYears: existingBusinessYears || [], isBusinessYearSearch: formAsBusinessYear.timePeriod > 0})
        setCreatedStartDates(created.startDates)
        const {search} = created
        const {startDate, endDateShow, endDate} = search
        if (!deepEqual({startDate, endDateShow, endDate}, {startDate: watch.startDate?.value, endDate: watch.endDate?.value, endDateShow: watch.endDateShow?.value})) {
            form.setValue("startDate.value", search.startDate)
            form.setValue("endDate.value", search.endDate)
            form.setValue("endDateShow.value", search.endDateShow)
            form.setValue("previousTimePeriod.value", search.previousTimePeriod)
        }
        if (formAsBusinessYear.endDate !== 0) {
            onChange(created.search)
        }
    }, [watch.timePeriod?.value, watch.startDate?.value, watch.endDateShow?.value, existingBusinessYears])
    return {form, createdStartDates}
}

export function BusinessyearSearch(props: BusinessyearSearchProps) {
    const {justBusinessYearSearch, onChange, localStorageKeyName} = props
    const {form, createdStartDates} = useBusinessYears({onChange, localStorageKeyName})

    const selectives = [
        {value: -2, label: "keine Einschränkung"},
        {value: 12, label: "jährlich"},
        {value: 6, label: "halbjährlich"},
        {value: 3, label: "quartalsweise"},
        {value: 1, label: "monatlich"},
        ...(justBusinessYearSearch ? [] : [{value: -1, label: "benutzerdefiniert"}])
    ]

    return <>
        <form>
            <FormProvider {...form}>
                <SelectFieldInputHook name={"Periode"} tag={"timePeriod"}
                                      selectives={selectives}/>

                {form.getValues("timePeriod.value") != -2 && <>{
                    form.getValues("timePeriod.value") == -1 && justBusinessYearSearch === false ? <>
                            <InputContainer>
                                <DateTimepickerUnixHook justDate={true} name={<>Datum von (&ge;)</>} tag={"startDate.value"}/>
                            </InputContainer>
                            <InputContainer>
                                <DateTimepickerUnixHook justDate={true} name={<>Datum bis (&le;)</>} tag={"endDateShow.value"}/>
                            </InputContainer>
                        </> :
                        <SelectFieldInputHook selectives={createdStartDates || []} name={"Datumsbereich"} tag={"startDate"}/>}</>}
            </FormProvider>
        </form>
    </>
}

export const createAndUpdateBusinessyearStartDates = (props: { businessYear: BusinessyearWithPreviousTimePeriod, businessYears: BusinessYearFromTo[], firstSearch: boolean, isBusinessYearSearch: boolean }) => {
    const {firstSearch, businessYear, businessYears, isBusinessYearSearch} = props

    let startDates: LabelValuePair<string, number>[] = []
    const timePeriod = businessYear.timePeriod
    const numberOfElements = 120 / timePeriod
    const now = new Date()

    let dateTo: Date | null
    if (businessYears?.length === 0) {
        dateTo = new Date(new Date().getUTCFullYear() + 1, 0, 1)
    } else {
        dateTo = new Date(Math.max(...businessYears.map(x => parseInt("" + x.EndDate))) * 1000) // + 86400000);
    }

    let dateFrom = null
    let startDate: LabelValuePair<string, number> | null = null
    for (let i = 0; i < numberOfElements; i++) {
        dateFrom = new Date(dateTo)
        dateFrom.setMonth(dateFrom.getMonth() - timePeriod)

        // Only starting dates in the past are allowed
        if (now.getTime() >= dateFrom.getTime()) {
            const currentValue = {value: dateFrom.getTime() / 1000, label: formatDateRange(dateFrom, businessYear.timePeriod)}

            if (now.getTime() < dateTo.getTime()) {
                startDate = currentValue
            }
            startDates.push(currentValue)
        }

        startDate = firstSearch === true ? startDate : (startDates.find(x => {
            return x.value === parseInt("" + businessYear.startDate)
        }) || startDate)

        dateTo = dateFrom
    }

    if (firstSearch) {
        startDate = startDates.find(x => {
            return x.value >= parseInt("" + businessYear.startDate) - 43200 && x.value <= parseInt("" + businessYear.startDate) + 43200
        }) || startDate || startDates[0]
    }

    let endDateShow = businessYear?.endDateShow || 0
    let endDate
    if (isBusinessYearSearch) {
        let dateToDate = new Date((startDate?.value || 0) * 1000)
        dateToDate.setMonth(dateToDate.getMonth() + businessYear.timePeriod)
        endDateShow = dateToDate.getTime() / 1000 - 86400
        endDate = endDateShow + 86399
    } else {
        if (businessYear.timePeriod == -2) {
            startDate = {value: 0, label: ""}
            const hours = new Date()
            hours.setHours(0, 0, 0)
            endDateShow = Math.floor(hours.getTime() / 1000)
            endDate = endDateShow + 86399
        } else {
            startDate = {value: parseInt("" + businessYear.startDate), label: ""}
            const date = new Date(endDateShow * 1000)
            date.setHours(0, 0, 0)
            endDateShow = Math.floor(date.getTime() / 1000)
            businessYear.previousTimePeriod = -1
            endDate = endDateShow + 86399
        }
    }

    const searchObject = {
        timePeriod: businessYear.timePeriod,
        previousTimePeriod: businessYear.timePeriod !== -1 ? businessYear.timePeriod : businessYear.previousTimePeriod,
        startDate: startDate?.value || 0,
        endDateShow: endDateShow || 0,
        endDate: endDate
    }
    return {startDates: startDates || [], search: searchObject, firstSearch: false}
}
