import React, {useContext, useEffect, useMemo, useState} from "react";
import {maxiGet} from "../maxios";
import {UserContext} from "../../user/UserContext";
import {SelectfieldInput} from "./select";
import {maxiGetObj} from "../maxiosLeg";
import {FormContext, FormContextWrapper} from "../form_context";
import {dateFormatUnix} from "../dateFuncs";
import Consts from "../consts";

export function useRoles() {
    const {club} = useContext(UserContext);
    const roles = !club ? [] : Object.entries(club.memberStates).filter(s => s[0] > 0).map(([key, descr]) => ({value: parseInt(key), label: descr}));
    return roles
}

export function RoleInput({tag, name, width, type = "reactselect", multiple = false, additionalRoles = [], ...props}) {
    const roles = useRoles()
    return <SelectfieldInput name={name} width={width} selectives={[...additionalRoles, ...roles]} tag={tag} type={type} multiple={multiple} {...props} />
}

export class AccountSelect extends React.Component { // ({name, filter = (acc) => true, sort=(a1, a2) => 1, onChange = (a) => {}}) {
    constructor(props) {
        super(props);
        this.setState = this.setState.bind(this);
        this.props = props;
        this.state = {
            accounts: [],
            account: -1
        }
    }

    componentDidMount() {
        this.loadAccounts()
    }

    loadAccounts = () => maxiGetObj({
        url: "/accounting/accounts/list",
        success: resp => {
            const accounts = resp.accounts
                .filter(({kind, nr}) => kind === 0)
                .filter(this.props.filter)
                .sort(this.props.sort)
                .map(({nr, name}) => ({value: nr, label: nr + " " + name}))
            this.setState({
                accounts: accounts,
                account: accounts.length > 0 ? accounts[0].value : this.state.account
            }, () => this.props.onChange(this.state.account))
        }
    });

    render() {
        return <FormContextWrapper
            value={{
                state: this.state,
                setState: this.setState,
                updateState: (a, b, c) => {
                    console.log("UPDATE_STATE");
                    this.setState({account: c}, () => this.props.onChange(c))
                }
            }}
        >
            <SelectfieldInput
                inline
                name={<>{this.props.name}</>}
                tag={"account"}
                selectives={
                    this.state.accounts
                }
                //onChange={(e) => {this.props.onChange(e); this.setState({search: {account: e}}); console.log("ONCHANGE") }}
                type={"reactselect"}
            />
        </FormContextWrapper>

    }
}

export function SchoolyearSelect({onChange}) {
    const year = new Date().getFullYear()
    const {club} = useContext(UserContext);
    const [state, setState] = useState({search: {schoolyear: (club.ID === Consts.CLUB_TURNSPORT_TIROL ? -1 : new Date().getMonth() >= 7 ? year + 1 : year)}})
    useEffect(() => {
        onChange(state.search.schoolyear)
    }, [state])
    return <FormContextWrapper value={{state: state, setState: setState}}>
        <SelectfieldInput tag={"search_schoolyear"} name="Schuljahr auswählen"
                          selectives={[{label: "Alle", value: -1}, ...Array.from(Array(30).keys()).map(x => new Date().getFullYear() - x + 1).map(x => ({value: x, label: "01.08." + (x - 1) + " - " + "31.07." + x}))]} type={"reactselect"}/>
    </FormContextWrapper>
}

export function PageblockSelect({name, multiple, tag, exclude = []}) {
    const [forms, setForms] = useState([])
    const {state, setState} = useContext(FormContext)

    const loadForms = () => {
        maxiGet("/page_blocks/form/slots/get", {}).then(resp => {
            setForms(resp.map(x => x[0]).filter(x => exclude.filter(y => y === x).length === 0))
        })
    }
    useMemo(loadForms, [])

    return <FormContextWrapper value={{state, setState}}>
        <SelectfieldInput type={"reactselect"} selectives={forms.map(x => ({label: x, value: x}))} tag={tag} name={name || "Formular / Umfrage"} multiple={multiple} width={"100%"} labelWidth={600}/>
    </FormContextWrapper>
}

export function InvoiceTemplateSearch({...props}) {
    const [templates, setTemplates] = useState([])

    const loadTemplates = () => maxiGet("/invoice/templates/list", {}).then(({templates}) => setTemplates(templates))
    useMemo(loadTemplates, [])

    return <div style={{display: "block"}}>
        <div style={{display: "inline-block"}}>
            <SelectfieldInput selectives={[{label: "Standard verwenden", value: null}, ...templates.map(x => ({label: `${x.name} (bearbeitet: ${dateFormatUnix(x.timeModified)})`, value: x.ID}))]} type={"reactselect"} {...props}  />
        </div>
    </div>
}
