import React, {ChangeEvent, CSSProperties, useContext, useEffect, useMemo} from "react"
import {FormContext} from "../form_context"
import {ChangeHandler, useFormContext, UseFormRegisterReturn} from "react-hook-form"
import {take} from "../helpers"
import {ErrorTooltip} from "../components/components"
import {ErrorMessage} from "@hookform/error-message"

interface TextInputProps {
    tag: string,
    name?: string | JSX.Element,
    labelStyle?: CSSProperties,
    placeholder?: string,
    style?: CSSProperties,
    inputStyle?: CSSProperties,
    error?: any,
    onChange?: ChangeHandler
    required?: boolean
    minLength?: number
    maxLength?: number
    min?: number
    max?: number
}

export function TextInputHook<FieldValues extends object>(props: TextInputProps) {
    return <InputHookCore {...props} />
}

function InputHookCore<FieldValues extends object>({tag, name, labelStyle, placeholder, style, inputStyle, onChange, valueFunction, type, error, ...otherProps}: TextInputProps & { valueFunction?: (x: any) => any, type?: string }) {
    const form = useFormContext()
    const formProps = take(otherProps, ["maxLength", "required", "minLength", "min", "max"])

    let registered: Partial<UseFormRegisterReturn<string>> & { value?: any } = form.register(tag, {
        maxLength: {value: formProps.maxLength, message: "Maximale Länge: " + formProps.maxLength},
        minLength: {value: formProps.minLength, message: "Minimale Länge: " + formProps.minLength},
        required: {value: formProps.required, message: "Erforderlich: " + formProps.required},
        max: {value: formProps.max, message: "Maximaler Wert: " + formProps.max},
        min: {value: formProps.min, message: "Minimaler Wert: " + formProps.min},
    })
    // Controlled state case
    if (onChange) {
        registered = {onChange, value: valueFunction ? valueFunction(form.getValues(tag)) : form.getValues(tag)}
    }

    return <label style={{...{display: "inline-block"}, ...(style || {})}}>
        {name && <span style={labelStyle}>{name}</span>}
        <input type={type || "text"} className={error ? "error" : undefined} style={inputStyle} placeholder={placeholder} {...registered} {...formProps} />
        {error && <ErrorTooltip>{error}</ErrorTooltip>}
        <ErrorMessage
            name={tag}
            render={({ message }) => <p>{message}</p>}
        />
    </label>
}

export function PriceInputHook<FieldValues extends object>(props: TextInputProps) {
    const form = useFormContext()
    const {tag, placeholder} = props

    const onChange: ChangeHandler = async (event) => {
        form.setValue(tag, Math.floor(parseFloat(event.target.value) * 100))
        return true
    }
    const valueFunction = (x: any) => parseFloat(x) / 100

    const inputRegister = {onChange, valueFunction}

    return <InputHookCore type={"number"} {...props} {...inputRegister} placeholder={placeholder || "0,00"}/>

    /*return <label style={{...{display: "inline-block"}, ...(style || {})}}>
        {name && <span style={labelStyle}>{name}</span>}
        <input placeholder={"0,00"} style={inputStyle} type={"number"} {...inputRegister} />
    </label>*/
}

export function CheckboxInputHook({tag, name, readOnly, style, labelStyle}: CheckboxInputProps) {
    const form = useFormContext()

    return <label className={"switchWrapper"} style={style}>
        {
            name &&
            <span style={labelStyle}>
                    {name}
            </span>
        }

        <label className={"switch"}>
            <input type="checkbox" disabled={readOnly} {...form.register(tag)} />
            <span className="slider"/>
        </label>
    </label>
}

export class TextfieldInput extends React.Component<any, any> {
    private localRef: React.RefObject<any>
    private a: number

    constructor(props: any) {
        super(props)
        this.a = 1 + 1
        this.setState = this.setState.bind(this)
        this.localRef = React.createRef()
    };

    static contextType = FormContext
    context!: React.ContextType<typeof FormContext>;

    componentDidMount() {
        if (this.props.events === undefined) {
            return
        }
        for (var key in this.props.events) {
            this.localRef.current.addEventListener(key, this.props.events[key])
        }
    };

    render() {
        if (!this.props.tag) {
            throw Error("Prop <tag> missing")
        }
        const s = this.props.tag.split("_")
        const state = this.context === undefined || this.context.state === undefined ? this.props.state : this.context.state
        if (s !== undefined && s.length === 2 && state == null) {
            return null
        }
        let dv = ""
        if (s !== undefined && s.length === 2 && state[s[0]]) {
            dv = state[s[0]][s[1]]
        }
        if (s !== undefined && s.length === 1) {
            dv = state[s[0]]
        }
        return (

            <label style={{...(this.props.type === "hidden" ? {display: "none"} : {display: "inline-block"}), ...this.props?.labelstyle}}>
                {!this.props.noLabel &&
                    <span style={this.props?.labeltextstyle}>{this.props.name}</span>
                }
                <input
                    ref={this.props.myref !== undefined ? this.props.myref : (this.props.events !== undefined ? this.localRef : "")}
                    name={this.props.tag}
                    readOnly={this.props?.readOnly === true}
                    style={{...this.props.style, borderColor: this.props.inputBorderColor}}
                    type={this.props.type === undefined ? "text" : this.props.type}
                    value={this.props.valueRenderFunction === undefined ? dv : this.props.valueRenderFunction(dv)}
                    onChange={this.props.onChange}
                    placeholder={this.props.ph}
                    onBlur={this.props.onBlur}
                    autoFocus={!!this.props.autoFocus}
                />
                {this.props.children}

            </label>
        )
    }

}

type CheckboxInputProps = {
    tag: string
    name?: string | JSX.Element | null
    noLabel?: boolean
    labelWidth?: number
    change?: (event: ChangeEvent<HTMLInputElement>) => any
    readOnly?: boolean
    resetOnUnmount?: boolean
    style?: CSSProperties
    labelStyle?: CSSProperties
}

export function CheckboxInput({tag, name, noLabel, labelWidth, change, readOnly, resetOnUnmount = false, style = {}}: CheckboxInputProps) {
    const {state, updateState} = useContext(FormContext)
    let checked
    const tagSplit = tag.split("_")
    if (tagSplit.length === 2) {
        checked = (state[tagSplit[0]] || {})[tagSplit[1]] ? true : false
    } else {
        checked = state[tagSplit[0]] ? true : false
    }
    useEffect(() => () => {
        // uncheck checkbox on unmount of object
        if (!!updateState && typeof updateState == "function" && resetOnUnmount) {
            updateState(tagSplit[0], tagSplit[1], 0)
        }
    }, [])

    const onChangeLocal = (e: ChangeEvent<HTMLInputElement>) => {
        if (change) {
            change(e)
        } else {
            updateState && updateState(tagSplit[0], tagSplit[1], e.target.checked ? 1 : 0)
        }
    }

    return <label className={"switchWrapper"} style={style}>
        {
            !noLabel &&
            <span style={{...(!!labelWidth ? {width: labelWidth.toString() + "px"} : {}), paddingTop: "0px", paddingBottom: "0px"}}>
                    {name}
            </span>
        }

        <label className={"switch"}>
            <input type="checkbox" name={tag} disabled={readOnly} checked={checked} onChange={onChangeLocal}/>
            <span className="slider"/>
        </label>
    </label>
}


interface InputContainerProps {
    children?: any
    block?: boolean
    fullWidth?: boolean
    className?: string
    style?: CSSProperties
}

export function InputContainer({children, block, fullWidth, className, style}: InputContainerProps) {
    return <span className={"inputContainer " + (className || "")} style={{display: block ? "block" : "inline-block", width: fullWidth ? "100%" : undefined, ...style}}>{children}</span>
}

export class TextareaInput extends React.Component<any, any> {
    private a: number
    private localRef: React.RefObject<any>
    context!: React.ContextType<typeof FormContext>;

    constructor(props: any) {
        super(props)
        this.a = 1 + 1
        this.setState = this.setState.bind(this)
        this.localRef = React.createRef()
    };

    static contextType = FormContext

    componentDidMount() {
        if (this.props.events === undefined) {
            return
        }
        for (var key in this.props.events) {
            this.localRef.current.addEventListener(key, this.props.events[key])
        }
    };

    render() {
        const s = this.props.tag.split("_")
        const state = this.context === undefined || this.context?.state === undefined ? this.props.state : this.context.state
        if (s !== undefined && s.length === 2 && state == null) {
            return null
        }
        let dv = ""
        if (s !== undefined && s.length === 2 && state[s[0]]) {
            dv = state[s[0]][s[1]]
        }
        if (s !== undefined && s.length === 1) {
            dv = state[s[0]]
        }
        return (

            <label style={this.props.type === "hidden" ? {display: "none"} : {display: "block"}} className={"textareaLabel"}>
                {!this.props.hideLabel && <><span>
                {this.props.name}
            </span><br/></>}
                <textarea
                    ref={this.props.myref !== undefined ? this.props.myref : (this.props.events !== undefined ? this.localRef : "")}
                    name={this.props.tag}
                    style={{...this.props.style}}
                    // type={this.props.type === undefined ? "text" : this.props.type}
                    value={dv}
                    onChange={() => {
                    }}
                    placeholder={this.props.ph}
                />
                {this.props.children}

            </label>
        )
    }

}

export function RadioInput({label, options, tag}: any) {
    const t = tag.split("_")
    const {state, setState} = useContext<any>(FormContext)
    const handleClick = (value: any) => {
        setState((a: any) => (t.length > 1 ? {...a, [t[0]]: {...(a[t[0]] || {}), [t[1]]: value}} : {...a, [t[0]]: value}))
    }
    const tagValue = t.length > 1 ? (state[t[0]] || [])[t[1]] : state[t[0]]
    useMemo(() => !tagValue && options?.length > 0 && handleClick(options[0]?.value), [])
    return <div className={"radioBlock"}>
        <label><span>{label}</span></label>
        <ul className={"radioList"}>
            {(options || []).map((x: any) => <li>
                <label>
                    <input type="radio"
                           key={tag + x.value}
                           value={x.value}
                           name={tag}
                           checked={x.value === tagValue ? true : undefined}
                           onClick={() => handleClick(x.value)}
                    /> <span>{x.label}</span></label>
            </li>)}
        </ul>
    </div>
}
