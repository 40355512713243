import {CoursePaymentGroup} from "../../core/interfaces/core"
import {LightContainer} from "../../core/components/container"
import React, {useState} from "react"
import {CourseInputHook} from "../../core/input/courseInput"
import {maxiGet} from "../../core/maxios"
import {StatusVar} from "../../club/club_inputs"
import {StatusGroup} from "../../core/status"
import {CoursePlanEdit} from "./course_plan_edit"
import {MaxBtn} from "../../core/components/components"

interface ImportPaymentPlanFromOtherCourseProps {
    onImport: (paymentPlan: CoursePaymentGroup[]) => any
}

export function ImportPaymentPlanFromOtherCourse({onImport}: ImportPaymentPlanFromOtherCourseProps) {
    const [localPaymentPlan, setLocalPaymentPlan] = useState<CoursePaymentGroup[]>()
    const [courseId, setCourseId] = useState<number>()
    const [statusVar, setStatusVar] = useState<StatusVar>({})

    const loadPaymentPlan = (course_ID: number | undefined) => {
        if (!course_ID || isNaN(course_ID)) {
            return
        }
        maxiGet<{ data: CoursePaymentGroup[] }>(`/course/${course_ID}/paymentplan/remove_ids`, {setStatusVar}).then(resp => {
            setLocalPaymentPlan(resp.data)
            setCourseId(course_ID)
        })
    }

    return <LightContainer name={"Zahlungsweise aus anderem Kurs importieren"}>
        <StatusGroup {...statusVar} />
        <CourseInputHook onChange={(courses) => loadPaymentPlan(courses[0]?.ID)} name={"Kurs zum Importieren"}/>
        {courseId && <>
            {localPaymentPlan && localPaymentPlan.length > 0 ? <>
                <div><MaxBtn onClick={() => onImport(localPaymentPlan)}>Importieren</MaxBtn></div>
                <h2>Folgende Zahlungsweisen würden importiert werden</h2>
                <CoursePlanEdit course_ID={courseId} readonly={true}/>
            </> : "Keine Zahlungsweisen in diesem Kurs definiert"}
        </>
        }
    </LightContainer>
}
