// Hook
import {useEffect, useState} from "react"

// secondsSinceLastUpdated - Invalidierung bestimmter Daten.
export default function useLocalStorage<T>(initialValue: T, key: string, secondsSinceLastUpdated?: number, timeoutMs?: number) {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState<T>(() => {
        if (typeof window === "undefined") {
            return initialValue
        }
        try {
            // Get from local storage by key
            const item = window.localStorage.getItem(key)
            // Parse stored json or if none return initialValue
            const returnable = item ? JSON.parse(item) : initialValue
            if (secondsSinceLastUpdated && (!returnable.lastUpdated || returnable.lastUpdated < new Date().getTime() / 1000 - secondsSinceLastUpdated)) {
                return initialValue
            }
            return returnable
        } catch (error) {
            // If error also return initialValue
            console.log(error)
            return initialValue
        }
    })
    const [tempValue, setTempValue] = useState<T>(storedValue)
    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = (value: T | ((val: T) => T)) => {
        try {
            // Allow value to be a function so we have same API as useState
            const valueToStore = value instanceof Function ? value(storedValue) : value
            // Save state
            setStoredValue(valueToStore)
            // Save to local storage
            if (typeof window !== "undefined") {
                window.localStorage.setItem(key, JSON.stringify({...valueToStore, lastUpdated: new Date().getTime() / 1000}))
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if((timeoutMs || 0) > 0) {
            const timeout = setTimeout(() => setValue(tempValue), timeoutMs)
            return () => {
                clearTimeout(timeout)
            }
        } else {
            setValue(tempValue)
        }
    }, [tempValue])

    return [storedValue, setTempValue] as const
}

