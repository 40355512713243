import React from "react";
import {BlankLink, Container} from "../core/components/components";
import {MyModal} from "../core/components/modal";
import {CheckboxInput} from "../core/input/basic";
import {renderHtmlWithPlaceholders} from "../core/helpers";
import Consts from "../core/consts";

export function RegisterDisclaimer({club, registration, noAdminDisclaimer = false, registrationAdmin = null, noCovid19, age = null}) {
    return <>
        <p>
            <div style={{paddingBottom: "10px", float: "left", paddingRight: "10px"}}>
                <CheckboxInput name={"akzeptiere"} tag={registration + "_approved"} resetOnUnmount noLabel/>
            </div>
            <div className={"CheckboxParagraph"}>
                Ich habe die <b>Datenschutzerklärung</b> {!!club.dataprotectionLink && <>(verfügbar unter&nbsp; <BlankLink>{club.dataprotectionLink}</BlankLink>)</>}{!!club.agbsLink && <> und die {club.verein_ID===Consts.CLUB_ATV_ST_VALENTIN ? "Vereinsbestimmungen" : "AGBs"}&nbsp;
                (verfügbar unter&nbsp; <BlankLink>{club.agbsLink}</BlankLink>)</>} gelesen und zur Kenntnis genommen.
                {club.verein_ID === 9 && " Ich stimme der Verarbeitung, Weitergabe und Speicherung personenbezogener Daten sowie der Verwendung von Fotos im darin festgelegten Umfang ausdrücklich zu."}
            </div>
        </p>
        {
            !noCovid19 && <CoronaMessage club={club} registration={registration}/>
        }
        {
            (age || 18) < 18 && !noAdminDisclaimer &&
            <p>
                <div style={{paddingBottom: "10px", float: "left", paddingRight: "10px"}}>
                    <CheckboxInput name={"lsjdf"} tag={(registrationAdmin || registration) + "_acceptResp"} resetOnUnmount noLabel/>
                </div>
                <div className={"CheckboxParagraph"}>
                    Ich akzeptiere, dass ich als <b>Verwalter/Erziehungsberechtigter</b> der angeführten Person <b>verantwortlich für die Bezahlung</b> der Beiträge, für die Einheits-/Kursbuchung und für die Weitergabe von Informationen von und an die mir untergeordneten Benutzerkontos bin.
                </div>
            </p>
        }

        <br/>
    </>
}

const coronaMessages = {
    0: { // st. valentin
        false: <>Hiermit bestätige ich, dass ich die <b>Informationen COVID-19</b> (verfügbar unter <BlankLink>https://www.turnverein-st-valentin.at/hygiene-und-praeventionskonzept</BlankLink>)
            gelesen und verstanden habe, diese an alle von mir verwalteten Personen, die Einheiten/Kurse besuchen, weitergebe
            und sich alle an die Vorgaben halten. Alle handeln in Eigenverantwortung. Die Informationen COVID-19 werden laufend nach den aktuellen Vorgaben der Bundesregierung adaptiert.</>,
        true: <>Hiermit bestätige ich, dass ich die <b>Informationen COVID-19</b> verfügbar unter <BlankLink>https://www.turnverein-st-valentin.at/hygiene-und-praeventionskonzept</BlankLink>)
            gelesen und verstanden haben und sie mit dem von mir angemeldeten Kind besprochen habe, um den Verein somit bei der
            Umsetzung zu unterstützen. Die Informationen COVID-19 werden laufend nach den aktuellen Vorgaben der Bundesregierung adaptiert.</>
    },
    5: <>Hiermit bestätige ich, dass ich die <b>Informationen COVID-19</b> (verfügbar unter <BlankLink>http://www.turnverein-taufkirchen.at/corona-regeln-1</BlankLink>)
        gelesen und verstanden habe, diese an alle von mir verwalteten Personen, die Einheiten/Kurse besuchen, weitergebe
        und sich alle an die Vorgaben halten. Alle handeln in Eigenverantwortung.</>,
    8: <>Hiermit bestätige ich, dass ich die <b>Informationen COVID-19</b> (<MyModal trigger={<em>hier verfügbar</em>}>
        <Container name={"Covid 19 Hinweise"}>
            <p>Mir bzw. meinem/meiner allfälligen gesetzlichen VertreterIn ist bewusst, dass durch die Teilnahme an der angeführten Sportausübung eine Gefährdung meiner körperlichen Integrität – auch im
                Hinblick auf eine Ansteckung mit dem COVID-19-Virus – möglich ist. Ich habe dieses Risiko abgewogen und akzeptiere dieses ausdrücklich mit meiner Teilnahme an der Sportausübung. Weiters verzichte ich in diesem Umfang auch auf allfällige Ersatzansprüche gegenüber dem Betreiber der
                Sportstätte
                bzw. Veranstalter der Sportausübung im Falle einer derartigen Ansteckung, sofern diese oder die ihm zuzurechnenden Personen nicht vorsätzlich oder grob fahrlässig handeln.
            </p>
            <p>
                Ich als Teilnehmende/r nehme ausdrücklich zur Kenntnis, dass die oben angeführte Sportausübung unter Einhaltung der Bestimmungen zur Bewältigung der Corona-Krise erlassenen Gesetze, Verordnungen, Richtlinien und Erlässe stattfindet und bestätige, dass ich in Kenntnis aller dieser
                gesetzlichen Bestimmungen bin. Weiters verpflichte ich mich, mit dem Betreten dieser Sportstätte während des Aufenthaltes zur Einhaltung der gesetzlichen Bestimmungen bzw. der Hausordnung und der allgemeinen Regelwerke des für die durchgeführte Sportart zuständigen
                Bundes-Sportfachverbandes
                sowie sämtlicher im Zusammenhang mit der Corona-Krise erlassener Richtlinien und Leitfäden und Empfehlungen des zuständigen Bundes-Sportfachverbandes, abrufbar unter Bundes-Sportfachverbandes bzw. beiliegend).
            </p>
            <p>
                Ich nehme zur Kenntnis, dass der Betreiber der Sportstätte bzw. Veranstalter der Sportausübung zur Überwachung der Einhaltung der oben angeführten Regelungen berechtigt ist. Dies betrifft neben der Führung von Buchungs- bzw. Zutritts- und Aufenthaltssystemen und -aufzeichnungen auch
                den
                Einsatz von Sicherheitspersonal bzw. Videoüberwachung (vgl. Datenschutzerklärung des Betreibers bzw. Veranstalters. Weiters verpflichte ich mich, allfällige Anweisungen des Betreibers der Sportstätte bzw. Veranstalter der Sportausübung oder deren beauftragten Dritten zur Einhaltung
                der
                obigen Regelungen unverzüglich zu befolgen. Andernfalls kann von diesen auch ein Verweis von der Sportanlage bzw. Ausschluss von der Sportausübung ausgesprochen werden. Auch einen Verweis oder Ausschluss habe ich unverzüglich zu befolgen.
            </p>
            <p>
                Ich bestätige, dass ich nicht wissentlich mit dem COVID-19-Virus infiziert bin oder mit diesbezüglich infizierten Personen in welcher Art und Weise auch immer in Kontakt war bzw. mich nicht innerhalb der letzten 14 Tage vor dem Betreten der Sportstätte in einem COVID-19-Risikogebiet
                aufgehalten habe.
                Weiters bestätige ich, dass ich mich nicht aufgrund eines derartigen Aufenthaltes oder aufgrund eines Kontaktes zu einer infizierten Person in (auch nur häuslicher) Quarantäne befunden habe oder mich aktuell befinde sowie, dass ich nicht einer der Risikogruppe nach den Bestimmungen
                im
                Zusammenhang mit der Bewältigung der Corona-Krise gehöre.
            </p>
            <p>
                Im Falle der Nichteinhaltung der COVID-19-Bestimmungen bzw. eines Verstoßes gegen den Inhalt dieser Einverständniserklärung durch mich, hafte ich gegenüber dem Betreiber der Sportstätte bzw. dem Veranstalter einer Sportausübung. Ich stimme ausdrücklich zu, diese im Falle derer
                Inanspruchnahme durch Dritte auf Grund eines Zuwiderhandelns gegen diese Einverständniserklärung durch mein Betreten, meinen Aufenthalt und/oder mein Verhalten auf der Sportstätte aus jeglichem Grund ausnahmslos schad- und klaglos zu halten.
            </p>
        </Container>
    </MyModal>)
        gelesen und verstanden habe, diese an alle von mir verwalteten Personen, die Einheiten/Kurse besuchen, weitergebe
        und sich alle an die Vorgaben halten. Alle handeln in Eigenverantwortung.
    </>,
    9: <div>Ich habe die <b>Hygieneregeln</b> des ÖTB Turnverein Linz (Link: <BlankLink>http://www.oetb-linz.at/images/data2020/TVLinz_Hygieneregeln.pdf</BlankLink>) gelesen <br/>und zur Kenntnis genommen.</div>,
    12: <div>Ich habe die <b>Hygieneregeln</b> des ATV Schwertbergs (Link: <BlankLink>https://atv-schwertberg.vereinfacht.at/nextcloud/index.php/s/NT4f8Sri8xsJnTT</BlankLink>) gelesen <br/>und zur Kenntnis genommen.</div>,
}

export function coronaMessageExists(club, child = false) {
    return null;
    const vereinMessageObject = coronaMessages[club?.verein_ID];
    if (!vereinMessageObject) {
        return null;
    }

    return vereinMessageObject[child] || vereinMessageObject
}

export function CoronaMessage({club, registration, child = false}) {
    return null;
    const message = coronaMessageExists(club, child);
    if (!message) {
        return null
    }
    return <>
        <p>
            <div style={{paddingBottom: "10px", float: "left", paddingRight: "10px"}}>
                <CheckboxInput name={"lsjdf"} tag={registration + "_covid19"} resetOnUnmount noLabel/>
            </div>
            <div className={"CheckboxParagraph"}>{message}</div>

        </p>
        <br/>
    </>
}

export function RegisterAusserordentlichesMitglied({club, subjectAndPraedicate, Kursbuchung}) {

    if ([12, 13].includes(club.verein_ID)) {
        return null;
    }

    return <div className={"CheckboxParagraph"}>{renderHtmlWithPlaceholders(club.registerAusserordentlichesMitgliedText, {club, subjectAndPraedicate, Kursbuchung}) || "Außerordentliches Mitglied werden"}</div>
}


export function RegisterOrdentlichesMitglied({club, subjectAndPraedicate, erzberDisclaimer=undefined, Kursbuchung}) {
    const seniorCutAge = {8: "65"}[club.verein_ID] || "60"
    const juniorCutAge = {10: "23"}[club.verein_ID] || "18"
    return <div className={"CheckboxParagraph"}>{renderHtmlWithPlaceholders(club.registerOrdentlichesMitgliedText, {club, subjectAndPraedicate, erzberDisclaimer, seniorCutAge, juniorCutAge, Kursbuchung}) || "Ordentliches Mitglied werden"}</div>
}

export function RegisterUnterstützendesMitglied({club, subjectAndPraedicate, erzberDisclaimer, Kursbuchung}) {
    return <div className={"CheckboxParagraph"}>{renderHtmlWithPlaceholders(club.registerUnterstuetzendesMitgliedText, {club, subjectAndPraedicate, erzberDisclaimer, Kursbuchung}) || "Unterstützendes Mitglied werden"}</div>
}
