import React, {CSSProperties, useState} from "react";
import {FaAngleDown, FaAngleRight} from "react-icons/fa";

export function makeIdFromHeading(name: string) {
    return name.toLowerCase().replace(/\s/g, "_");
}

interface ContainerProps {
    ID?: any
    name?: string
    addName?: string | JSX.Element
    style?: CSSProperties
    mutable?: boolean
    hideHeading?: boolean
    broad?: boolean
    visible?: boolean
    children: any
    class?: any
    props?: any
}

export function Container({ID, name, addName, style, mutable, hideHeading, broad, visible: defaultVisible = true, children, ...props}: ContainerProps) {
    const [visible, setVisible] = useState(defaultVisible);
    return (
        <div id={ID ? ID : (name !== undefined && name !== "" ? makeIdFromHeading(name) : "")}
             className={"reactContainer centercontent " + (broad ? "broad " : "") + props?.class}
             style={style}
        >
            {name !== undefined && name !== "" && !hideHeading &&
                <h2
                    onClick={() => mutable ? setVisible(!visible) : null}
                    className={mutable ? "headingToggleAble" : ""}
                >
                    {mutable && <>{visible ? <FaAngleDown/> : <FaAngleRight/>}&nbsp; </>}
                    {name}
                    {addName}
                </h2>
            }
            {visible && children}
            <br/>
        </div>
    )
}


export class LightContainer extends React.Component<ContainerProps> {
    render() {
        const props = this.props;
        return <>
            {props.name !== undefined && props.name !== "" &&
                <h2 style={{marginTop: "30px"}}>
                    {!props.hideHeading && props.name}
                    {props.addName}
                </h2>
            }
            {props.children}
            <br/>
        </>

    }

}
