import {CourseShiftPlannerShiftEntry, CourseShiftPlannerShiftLocation} from "../../core/interfaces/core"
import React, {useEffect, useState} from "react"
import {MyModal} from "../../core/components/modal"
import {FaEdit} from "react-icons/fa"
import {FormContextWrapper} from "../../core/form_context"
import {InputContainer, TextareaInput, TextfieldInput} from "../../core/input/basic"
import {MaxBtn} from "../../core/components/components"
import {DateTimeInput} from "../../core/input/date"


type EditModalPropsType<T extends object> = { trigger?: any, onFinish: (data: T) => any, data: T }


export function ShiftEntryChangerModal({onFinish, data, trigger}: EditModalPropsType<CourseShiftPlannerShiftEntry>) {
    const [state, setState] = useState<{ state: CourseShiftPlannerShiftEntry }>({state: data})

    useEffect(() => {
        setState({state: data})
    }, [data.ID, data.name, data.dateFrom, data.dateTo])

    return <MyModal trigger={<FaEdit/>} onClose={() => onFinish(state.state)} defaultOpen={trigger === undefined}>
        {
            (close) => <FormContextWrapper value={{state, setState}}>
                <InputContainer><TextfieldInput autoFocus name={"Name"} tag={"state_name"}/></InputContainer>
                <InputContainer><TextfieldInput name={"Anzahl an erforderlichen Personen"} tag={"state_count"}/></InputContainer>
                <InputContainer><DateTimeInput name={"Startzeitpunkt"} tag={"state_dateFrom"}/></InputContainer>
                <InputContainer> <DateTimeInput name={"Endzeitpunkt"} tag={"state_dateTo"}/></InputContainer>
                <InputContainer> <TextareaInput name={"Information"} tag={"state_info"}/></InputContainer>
                <div><MaxBtn onClick={() => {
                    close()
                }}><FaEdit/> {trigger? "Ändern":"Hinzufügen"}</MaxBtn></div>
            </FormContextWrapper>
        }
    </MyModal>
}

export function LocationNameChangerModal({trigger, onFinish, data}: EditModalPropsType<CourseShiftPlannerShiftLocation>) {
    const [state, setState] = useState<{ state: CourseShiftPlannerShiftLocation }>({state: data})

    useEffect(() => {
        setState({state: data})
    }, [data.ID, data.name])


    return <MyModal trigger={trigger} onClose={() => onFinish(state.state)} defaultOpen={trigger === undefined}>
        {
            (close) => <FormContextWrapper value={{state, setState}}>
                <InputContainer><TextfieldInput autoFocus={true} name={"Name"} tag={"state_name"}/></InputContainer>
                <MaxBtn onClick={() => close()}><FaEdit/> {trigger? "Ändern":"Hinzufügen"}</MaxBtn>
            </FormContextWrapper>
        }
    </MyModal>
}
