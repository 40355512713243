import React, {useContext, useState} from "react";
import {useMemo} from "react";
import {Container, MaxBtn} from "../core/components/components";
import {Link} from "react-router-dom";
import {FaBackward, FaFastBackward, FaPrint} from "react-icons/all";
import {UserContext} from "../user/UserContext";
import {FaQuestion} from "react-icons/fa";

export default function FAQ({match, hideNav=false}) {
    const topic = match.params.topic || "faq";
    const [Comp, setComp] = useState({});
    const {user} = useContext(UserContext)

    useMemo(async () => {
        /*console.log(user?.role, user?.role < 80, user, topic.startsWith("einführung"))
        if (!(user?.role >= 80) && !topic.startsWith("einführung")) {
            setComp(<>Diese Seite wurde leider nicht gefunden.</>)
            return
        }*/

        try {
            const t = await import(`!babel-loader!@mdx-js/loader!./${topic}.mdx`)
            setComp(t.default)
        } catch (e) {
            setComp(<>Diese Seite wurde leider nicht gefunden.</>)
        }
    }, [topic, user])

    const nav = !hideNav ? <p>
        {user?.role >= 80 && topic !== "faq" && <span className={"nav"}><FaFastBackward/> <Link to={"/faq/"}>Zurück zur Übersicht</Link></span>}
        {topic.startsWith("einführung-") && <span className={"nav"}><Link to={"/faq/einführung"}><FaBackward/> Zurück zur Einführung</Link></span>}
        <span className={"nav"}><a onClick={window.print}><FaPrint/> Drucken</a></span>
    </p> : null
    return !!Object.keys(Comp).length ?
        <div id={"faq"} className={"faq"}>
            <Container name={""}>
                <iframe id={"print_iframe"} style={{
                    height: '0px',
                    width: '0px',
                    position: 'absolute',
                    display: "none",
                }}/>
                {nav}
                {Comp}
                <br/>
                {nav}
            </Container>
        </div> : null;
}

export function FAQLink({to, text="Hilfe (FAQ)", style={}, mini=false}) {
    return <Link className={"no-underline"} to={"/faq/" + to}><MaxBtn style={{...style}} className={mini ? "mini" : ""}><FaQuestion/> {text}</MaxBtn></Link>
}