import React, {useContext, useEffect, useState} from "react";
import {FormContextWrapper} from "../core/form_context";
import {Container, InfoTooltip, MiniBtn} from "../core/components/components";
import {MyModal} from "../core/components/modal";
import {MyReactTable} from "../core/components/table";
import {CheckboxInput} from "../core/input/basic";
import {DateTimeInput} from "../core/input/date";
import {SelectfieldInput} from "../core/input/select";
import {TagsInput} from "../core/input/tags";
import {maxiGet, maxiPost} from "../core/maxios";
import {encodeGetParams} from "../core/helpers";
import {Link} from "react-router-dom";
import {dateFormat, dateFormatInline, dateFormatUnix} from "../core/dateFuncs";
import {downloadAsExcel} from "../core/download";
import {UserContext} from "./UserContext";
import {FaStickyNote} from "react-icons/all";
import {FaList} from "react-icons/fa";
import onFilterChangeForUsers, {TimeoutTextColumnFilter} from "./onFilterChangeForUsers";
import {CourseInput} from "../core/input/courseInput";

export default function CourseTagUsers(props) {
    const [option, setOption] = useState({data: {value: 1}})
    const [searchUser, setSearchUser] = useState({})
    const [date, setDate] = useState({from: {value: new Date().getTime() / 1000 - 86400 * 3}, to: {value: new Date().getTime() / 1000 + 86400 * 3}})
    const [memberStates, setMemberStates] = useState({trainers: 1, active: 1, waitlist: 1})
    const [specific, setSpecific] = useState({data: {value: []}})
    const [users, setUsers] = useState([])
    const [{loading, error, success}, setStatusVar] = useState({});
    const optionValue = parseInt(option.data.value) || 0;
    const updateMemberStates = setMemberStates
    const user = useContext(UserContext)

    const resetSearch = () => {
        setSpecific({data: {value: []}})
        setUsers([])
    }
    const loadUsers = ({value = specific.data.value, from = date.from.value, to = date.to.value, states = memberStates}) => {
        // console.log(value, timestamp, states)
        if (value === null || value === [] || value.length === 0) {
            resetSearch()
            return
        }
        maxiGet("/user/tags?" + encodeGetParams({...states, value: value?.map(x => x?.value || x) || [], from: Math.floor(from), to: Math.floor(to), type: (optionValue === 1 ? "course" : (optionValue === 2 ? "coursetag" : "room")), ...searchUser})).then(r => {
            setUsers(r.users || [])
        })
    }
    useEffect(() => loadUsers({}), [searchUser])

    const additionalTitles = () => {
        return "Gesetzte Filter >> " + (optionValue === 1 ? "Kurse: " : (optionValue === 2 ? "Kurstags: " : "Räume: ")) + (Array.isArray(specific.data?.value) ? specific.data?.value.map(x => x.label).join(", ") : "")
    }

    return <Container name={"Mitglieder nach Kursen, Kurstags oder Raumtags anzeigen"}>
        <FormContextWrapper value={{state: option, setState: setOption}} afterUpdate={() => {
            resetSearch()
        }}>
            <SelectfieldInput name={"Kategorie auswählen"} selectives={[[1, "Kurse"], [2, "Kurstags"], [3, "Raumtags"]]} tag={"data_value"}/>
        </FormContextWrapper>
        <FormContextWrapper value={{state: memberStates, setState: updateMemberStates}} afterUpdate={(a, b, val) => {
            loadUsers({states: {...memberStates, [a]: val}})
        }}>
            <CheckboxInput tag={"active"} name={"Aktive Teilnehmer"} style={{marginRight: "50px"}}/>
            <CheckboxInput tag={"trainers"} name={"Trainer"} style={{marginRight: "50px"}}/>
            <CheckboxInput tag={"waitlist"} name={"Teilnehmer auf der Warteliste"}/>
        </FormContextWrapper>
        {optionValue !== 1 && <FormContextWrapper value={{state: date, setState: b => setDate(a => ({...a, ...b}))}}>
            <DateTimeInput name={"Stichdatum von"} tag={"from_value"} afterUpdate={(a, b, val) => {
                loadUsers({from: val?.value})
            }}/>
            <DateTimeInput name={"Stichdatum bis"} tag={"to_value"} afterUpdate={(a, b, val) => {
                loadUsers({to: val?.value})
            }}/>
            <InfoTooltip children={<>
                Die Datumsauswahl für <b>Stichdatum von</b> und <b>Stichdatum bis</b> wird für Kurs- und Raumtags benötigt.<br/>
                <ul>
                    <li>Bei der Auswahl <b>Kurstags</b> werden alle Kursteilnehmer angezeigt, die an einem Kurs mit einem der ausgewählten Tags, wo mindestens ein Termin im Intervall "Stichdatum von" - "Stichdatum bis" liegt, teilnehmen.<br/>
                    </li>
                    <li>Bei der Auswahl <b>Raumtags</b> werden alle Kursteilnehmer angezeigt, die an einem Kurs in einem der ausgewählten Räume, wo mindestens ein Termin im Intervall "Stichdatum von" - "Stichdatum bis" liegt, teilnehmen.<br/>
                    </li>
                </ul>
            </>}/>
        </FormContextWrapper>}
        <FormContextWrapper value={{state: specific, setState: setSpecific}} afterUpdate={(a, b, val) => loadUsers({value: val?.value})}>
            {optionValue === 1 && <CourseInput name={"Kurse auswählen"} width={"100%"} tag={"data_value"} type={"reactselect"} multiple/>}
            {optionValue === 2 && <TagsInput entity={"course"} name={"Kurstags"} tag={"data_value"} multiple afterUpdate={(a, b, val) => loadUsers({value: val})}/>}
            {optionValue === 3 && <TagsInput entity={"event"} name={"Raumtags"} tag={"data_value"} multiple afterUpdate={(a, b, val) => loadUsers({value: val})}/>}
        </FormContextWrapper>
        <MyReactTable
            key={"stay"}
            data={users}
            title={"Personen, die Kursen mit bestimmten Eigenschaften beigetreten sind."}
            additionalTitles={additionalTitles()}
            exportData={true}
            defaultSorted={Object.keys(searchUser || {}).length === 0 ? [{id: "name"}] : []}
            columns={[
                {
                    Header: "Name",
                    filterable: true,
                    id: "name",
                    key: "name",
                    accessor: "name",
                    Cell: row => <Link to={"/benutzer/view/" + row.original.ID}>{row.value}</Link>,
                    Filter: TimeoutTextColumnFilter,
                            filterMethod: () => true,
                },
                {
                    Header: "E-Mail",
                    key: "email",
                    show: window.innerWidth > 1000,
                    filterable: true,
                    accessor: "email",
                    Filter: TimeoutTextColumnFilter,
                            filterMethod: () => true,
                    Cell: ({value}) => <a href={"mailto:" + value}>{value}</a>,
                },
                {
                    Header: "Geburtsdatum",
                    key: "birthdate",
                    filterable: true,
                    accessor: "birthdate",
                    maxWidth: 130,
                    Cell: row => dateFormatInline(row.value),
                    pdfCell: row => dateFormat(row.value),
                },
                {
                    Header: "Kurse",
                    accessor: "courses",
                    filterable: false,
                    width: 400,
                    Cell: ({value}) => {
                        const reference = value?.map(x => <span>{x.name} (Start: {dateFormatUnix(x.start)}, Ende: {dateFormatUnix(x.end)})</span>) || []
                        const modalData = value?.map(x => <li><Link to={"/kurs/buche/" + x.ID} target={"_blank"}>{x.name} (Start: {dateFormatUnix(x.start)}, Ende: {dateFormatUnix(x.end)})</Link></li>) || []
                        return <MyModal trigger={reference}>
                            <Container name={"Kurse:"}>
                                <ul>
                                    {modalData}
                                </ul>
                            </Container>
                        </MyModal>
                    }
                }
            ]}
            loading={loading}
            onFilteredChange={(a, old, updateTableFilterMethod) => onFilterChangeForUsers(a, old, (x) => setSearchUser(x), ["name", "email"], updateTableFilterMethod)}
            additionalButtonsRight={
                user?.user?.role > 50 ?
                    <>
                        <MiniBtn>
                            <Link to={{
                                pathname: "/newsletter/vorlagen", state: {
                                    emailAddresses: users.map(t => ([t.email, t.ID, t.name])),
                                }
                            }}>
                                <FaStickyNote/> Nachricht senden
                            </Link>
                        </MiniBtn>
                        <MiniBtn onClick={() => {
                            maxiPost("/user/download_excel", {user_ids: users.map(x => x.ID) || []}, {setStatusVar}).then(({content}) => {
                                downloadAsExcel(content, "TeilnehmerVonKursen.xlsx")
                            })
                        }
                        }><FaList/>Liste herunterladen</MiniBtn>
                    </> : null
            }
        />

    </Container>
}
