import React, {useMemo, useState} from "react";
import {maxiGetObj} from "../core/maxiosLeg";
import {Container} from "../core/components/components";
import {MyReactTable} from "../core/components/table";
import Status from "../core/status";
import {dateFormatTime} from "../core/dateFuncs";
import {Link} from "react-router-dom";


const NewsletterStats = ({match, news_ID}) => {
    const [messages, setMessages] = useState([]);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const messagePath = match !== undefined ? "newsletter/" + match.params.newsletter_ID : `news/${news_ID}`;

    const loadRoles = () => maxiGetObj({
        url: "/newsletter/newsletters/stats/" + messagePath, success: resp => setMessages(resp.messages), setError: e => setError(e.toString())
    });
    useMemo(() => {
        loadRoles()
    }, []);

    const numInitialisiert = messages.reduce((c, m) => m.status === "erfolgreich initialisiert" ? c + 1 : c, 0);
    const numReceived = messages.reduce((c, m) => m.status.startsWith("Empfang:") ? c + 1 : c, 0);
    const linkClicked = messages.reduce((c, m) => m.status === "Link geklickt" ? c + 1 : c, 0);
    const mailOpened = messages.reduce((c, m) => m.status === "E-Mail geöffnet" ? c + 1 : c, 0);
    return <Container name={"Empfangsstatistik"}>
        <Status type={"error"} text={error}/>
        <Status type={"success"} text={success}/>
        initialisiert: {numInitialisiert}<br/>
        empfangen: {numReceived}<br/>
        geöffnet: {mailOpened}<br/>
        geklickt: {linkClicked}<br/>

        <MyReactTable
            data={messages}
            defaultSorted={[{id: "time", desc: true}]}
            columns={[
                {
                    Header: "Person",
                    accessor: "childName",
                    Cell: ({value, original}) => original.personType === 0 ?
                        <Link to={"/benutzer/profil/" + original.childID}>{value}</Link> :
                        <Link to={"/kontakte/" + original.childID}>{value}</Link>,
                    filterable: true,
                },
                {
                    Header: "Art",
                    accessor: "msgType",
                    show: news_ID !== undefined,
                    Cell: ({value}) => value[0].toUpperCase() + value.slice(1),
                    maxWidth: 90,
                    filterable: true,
                },
                {
                    Header: "Status",
                    accessor: "status",
                    filterable: true,
                    //Cell: ({value, original}) => <Link to={"/newsletter/" + original.ID}>{value}</Link>,
                },
                {
                    Header: "Erstellt",
                    accessor: "time",
                    Cell: ({value}) => dateFormatTime(value),
                    maxWidth: 200,
                },
            ]}
        />


    </Container>
};
export default NewsletterStats
