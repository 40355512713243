import React, {SetStateAction} from 'react'

interface AbrechnungsContextProps {
    eingaben: number
    ausgaben: number
    userID: number
    setState: SetStateAction<any>
    state: any
    saldo: number
}

const AbrechnungsContext = React.createContext<AbrechnungsContextProps>({eingaben: 0, ausgaben: 0, userID: 0, state: {}, setState: () => {}, saldo: 0});

export {AbrechnungsContext};