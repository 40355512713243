import {ReactSortable} from "react-sortablejs"
import {FaBars} from "react-icons/fa"
import React, {CSSProperties} from "react"

type MySortableListProps<S> = {
    list: (S & { id: string })[]
    setList: (data: S[]) => void
    ulStyle: CSSProperties
    ulId: string
    children: JSX.Element[]
}

function MySortableList<T>({list, setList, children, ulStyle, ulId}: MySortableListProps<T>) {
    // @ts-ignore
    const SortableComponent = <ReactSortable list={list} setList={(data) => setList(data)} swap>
        {children.map((c, index) => <li key={index}><FaBars/> {c}</li>)}
    </ReactSortable>
    return <ul className={"draggableList"} style={ulStyle} id={ulId}>
        {SortableComponent}
    </ul>
}

export {MySortableList}
