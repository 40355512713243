import React from "react";
import "../style/modal.sass"
import {FormContextWrapper} from "../core/form_context";
import Consts from "../core/consts"
import {apiPost} from "../core/api";
import Status from "../core/status";
import {Container, InfoTooltip, Loader, MaxBtn} from "../core/components/components";
import {MyModal} from "../core/components/modal";
import {TextfieldInput} from "../core/input/basic";
import {DateInputUnix} from "../core/input/date";
import {FileUpload} from "../core/input/fileupload";
import {SelectfieldInput} from "../core/input/select";
import {formatAccount} from "./helper";
import {FaPlus, FaSave} from "react-icons/fa";
import {LightContainer} from "../core/components/container";

const newTemplate = {
    text: "",
    betrag: 0,
    belegnr: "",
    belegdatum: (new Date()).getTime() / 1000,
    buchsymbol: "",
    receipts: [{}],
}
export default class AddEinnahme extends React.Component {
    state = {
        new: newTemplate
    };

    constructor(props) {
        super(props);
        this.apiPost = apiPost.bind(this);
        this.setState = this.setState.bind(this);
    }

    render() {
        const accounts = this.props.accounts;
        //const {new: newLedgerRow} = this.state;
        return <MyModal trigger={<MaxBtn><FaPlus/> Neue Buchung</MaxBtn>} modal>
            {close =>
                <Container name={"Neue Buchung"}>
                    <Status type={"error"} text={this.state.error}/>
                    <FormContextWrapper
                        value={{state: this.state, setState: this.setState, updateState: (a, b, c) => this.setState({[a]: {...this.state[a], [b]: c}})}}
                        onSubmit={e => {
                            e.preventDefault();
                            this.setState({loading: true})
                            const receipts = Object.entries(this.state.receipts || {}).map(([key, value]) => ({name: value?.file_name_orig, file: value?.file_name}))
                            this.apiPost("/accounting/ledger/add", {...this.state.new, receipts}, resp => {
                                this.setState({new: newTemplate, receipt: undefined, receipts: [], error: "", loading: false})
                                this.props.reload()
                                close()
                            })
                        }}
                    >
                        <SelectfieldInput name={"Buch-Symbol / Art"} tag={"new_buchsymbol"} selectives={Object.keys(Consts.buchSymbole).map(s => ({value: s, label: Consts.buchSymbole[s]}))} type={"reactselect"}/>

                        <TextfieldInput name={"Text"} tag={"new_text"}/><br/>
                        <TextfieldInput name={
                            <>Betrag <InfoTooltip>
                                <>Negativer Betrag: Ausgabe<br/>Postiver Betrag: Einnahme</>
                            </InfoTooltip></>
                        } tag={"new_betrag"}/>

                        <br/>
                        <TextfieldInput name={"Belegnummer"} tag={"new_belegnr"}/>
                        <br/>
                        <DateInputUnix name={"Belegdatum"} tag={"new_belegdatum"}/>
                        <br/>
                        <SelectfieldInput name={<>Soll-Konto {this.state.new.buchsymbol === "AR" ? <><br/>z.B. Bankkonto</> : ""}</>} tag={"new_gkonto"} selectives={
                            accounts
                                .filter(a => a.kind !== 5)
                                .map(a => ({value: a.nr, label: formatAccount(a.nr) + " " + a.name}))} type={"reactselect"}
                        />
                        <SelectfieldInput name={<>Haben-Konto {this.state.new.buchsymbol === "ER" ? <><br/>z.B. Bankkonto</> : ""}</>} tag={"new_konto"} selectives={
                            accounts
                                .filter(a => a.kind !== 5)
                                .map(a => ({value: a.nr, label: formatAccount(a.nr) + " " + a.name}))} type={"reactselect"}
                        />
                        <SelectfieldInput name={"Kunden-Konto"} tag={"new_kundenkonto"} selectives={
                            accounts
                                .filter(a => a.kind === 5)
                                .map(a => ({value: a.nr, label: formatAccount(a.nr) + " " + a.name}))} type={"reactselect"}
                        />
                        {
                            this.state.new.receipts?.map((receipt, index) => {
                                return <LightContainer>
                                    <FileUpload saveFullFileObject={true} name={<>Beleg {index + 1}</>} tag={"receipts_" + index} prefix={"receipts"} setStateParent={this.setState}/>
                                </LightContainer>
                            })
                        }
                        <MaxBtn onClick={(e) => {
                            e.preventDefault()
                            this.setState(state => ({...state, new: {...state.new, receipts: [...(state.new?.receipts || []), {}]}}))
                        }}><FaPlus/> Neuen Beleg hinzufügen</MaxBtn><br/>
                        <MaxBtn><FaSave/> Buchung erstellen</MaxBtn> <Loader loading={this.state.loading}/>
                    </FormContextWrapper>
                </Container>
            }
        </MyModal>
    }
}
/*

 */
