import React from 'react';
import "../dashboard.sass"
import Consts from "../core/consts";
import Status from "../core/status";
import {Container, InfoTooltip, LightContainer, Loader, MaxBtn, TopButtonDiv} from "../core/components/components";
import {MyModal} from "../core/components/modal";
import {MyReactTable} from "../core/components/table";
import {DateTimeInput} from "../core/input/date";
import {SelectfieldInput} from "../core/input/select";
import {dateFormatDate, dateFormatUnix} from "../core/dateFuncs";
import "./buchhaltung.sass"
import {Link} from "react-router-dom";
import AddEinnahme from "./ledger_add";
import {apiGet, apiPost} from "../core/api";
import {FormContextWrapper} from "../core/form_context";
import {BusinessyearSearch, formatAccount, ShowAccountHolderImport} from "./helper";
import {FaDownload, FaEdit} from "react-icons/fa";
import UserHistory from "../user/user_history";
import {encodeGetParams} from "../core/helpers";
import {downloadAsPdf, downloadTextAsCsv} from "../core/download";
import DOMPurify from "dompurify"
import {FAQLink} from "../docs/faq";
import {UploadDownloadReceipt} from "./upload_download_receipt";

export default class LedgerView extends React.Component {

    constructor(props) {
        super(props);
        this.apiGet = apiGet.bind(this);
        this.apiPost = apiPost.bind(this);
        this.setState = this.setState.bind(this);
        //this.loadLedgerRows();
        this.state = {
            loading: true,
            ledgerRows: [],
            ledgerRowsOrig: [],
            tableData: [],
            tableDataSorted: [],
            show: {
                einn: true,
                ausg: true
            },
            sum: 0,
            attr: {},
            accounts: [],
            accountsMap: {},
            selected: {},
            selectAll: 0,
            last: 0,
            showingKontonr: -1,
            search: {timePeriod: "12", startDate: 0},
        };
    }

    componentDidMount() {
        this.loadAccounts();
        //this.loadBusinessYears();
    }

    accountNr = () => parseInt(this.props.match.params.kontonr || 0);
    loadLedgerRows = (search = this?.state?.search) => {
        this.setState({loading: true})
        let endDate;
        if (search.timePeriod == -1) {
            endDate = new Date(search.endDate * 1000);
        } else {
            endDate = new Date(search.startDate * 1000);
            endDate.setMonth(endDate.getMonth() + parseInt(search.timePeriod));
        }

        this.apiGet("/accounting/ledger/view/" + this.accountNr() + "?" + encodeGetParams({dateTo: endDate.getTime() / 1000, dateFrom: search.startDate}), resp => {
            let {ledgerRows} = resp;
            ledgerRows = ledgerRows.map(l => ({...l, belegdatumString: dateFormatUnix(l.belegdatum)}));
            this.setState({
                loading: false,
                ...resp,
                ledgerRowsOrig: ledgerRows,
                ledgerRows: ledgerRows.filter(({betrag}) => {
                    return (betrag < 0 || this.state.show.einn) && (betrag >= 0 || this.state.show.ausg)
                }),
                tableDataSorted: ledgerRows.filter(({betrag}) => {
                    return (betrag < 0 || this.state.show.einn) && (betrag >= 0 || this.state.show.ausg)
                })
            }, this.tableChange)
        });
    };
    loadAccounts = () => this.apiGet("/accounting/accounts/list", resp => this.setState({...resp, accountsMap: resp.accounts.reduce((obj, acc) => ({...obj, [acc.nr]: acc}), {})}), false, true);


    tableChange = (dataIn, show = this.state.show) => {
        const data = dataIn || this.state.ledgerRows;
        this.setState({
            tableSize: data.length,
            tableDataSorted: data,
            filteredIds: data.map(row => (row._original ? row._original.ID : row.ID)),
            sum: data.reduce((sum, row) => sum + (row._original ? row._original.betrag : row.betrag), 0.0)
        })
    };
    updateState = (a, b, c) => {
        //console.log(a, b, c)
        this.setState({[a]: {...this.state[a], [b]: c}})
    };

    accountViewer = ({nr, altText = "nicht zugeordnet", linkOnly = true}) => {
        const account = this.state.accountsMap[nr];
        if (account === undefined) {
            return altText
        }
        if (linkOnly) {
            return <Link to={"/buchhaltung/buchungen/" + account.nr}>{formatAccount(account.nr)} {account.name}</Link>;
        }
        return <MyModal trigger={<MaxBtn>Kontoinformationen</MaxBtn>}>
            <Container name={"Konto: " + account.name}>
                {
                    account.address !== "" &&
                    <>
                        Adresse: <br/>{account.address.split(", ").map(s => [s, <br/>])}<br/>
                    </>
                }
                {account.iban !== "" && <><b>IBAN</b>: {account.iban}<br/></>}
                {account.bic !== "" && <><b>BIC</b>: {account.bic}<br/></>}
                {account.saldo !== null && <><b>Saldo</b>: {Consts.moneyMax(account.saldo / 100)}<br/></>}
                <br/>
                {/*<Link to={"/buchhaltung/buchungen/" + account.nr}>
                    Konto anschauen
                </Link>*/}
            </Container>
        </MyModal>;
    };
    renderEditable = ({field, account = null, value, original}) => {
        return <div
            style={{backgroundColor: "#fafafa", color: field === "betrag" && account?.nr !== 0 && (account?.nr !== original.kundenkonto) ? ((original.gkonto === account?.nr ? 1 : -1) * (account?.inflow ? 1 : -1) * original.betrag > 0 ? "green" : "red") : undefined}}
            contentEditable
            suppressContentEditableWarning
            onBlur={e => {
                if (value !== e.target.innerHTML) {
                    // does not update if changing to original value again
                    this.apiPost("/accounting/ledger/modify", {[field]: e.target.innerHTML, "name": field, row: {ID: original.ID}}, data => {
                        this.loadLedgerRows()
                    })
                }
            }}
            dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(value)
            }}
        />;
    }

    getPDFReport = () => {
        this.setState({loading: true});
        this.apiPost("/accounting/ledger/pdf", this.state.tableDataSorted.map(x => {
            x = JSON.parse(JSON.stringify(x));
            x["buchdatum"] = dateFormatUnix(x["belegdatum"]);
            x["betrag"] = Consts.moneyMax(x["betrag"] / 100);
            return x;
        }), response => {
            downloadAsPdf(response.content, "buchungen.pdf");
            this.setState({loading: false});
        });
    }

    getCSVReport = () => {
        this.setState({loading: true});
        this.apiPost("/accounting/ledger/csv", this.state.tableDataSorted.map(x => {
            x = JSON.parse(JSON.stringify(x));
            x["buchdatum"] = dateFormatUnix(x["belegdatum"]);
            x["betrag"] = x["betrag"] / 100;
            return x;
        }), response => {
            downloadTextAsCsv(response.content, "buchungen.csv");
            this.setState({loading: false});
        });
    }

    render() {
        const accountNr = this.accountNr()
        const AccountViewer = this.accountViewer;
        const AccountEditor = ShowAccountHolderImport;
        const filterAccount = (filter, row) => this.state.accountsMap[row[filter.id]] !== undefined ? (row[filter.id].toString() + " " + this.state.accountsMap[row[filter.id]].name.toLowerCase()).includes(filter.value.toLowerCase()) : false;
        if (!this.state.loading && accountNr !== this.state.showingKontonr) {
            this.setState({showingKontonr: accountNr})
            this.loadLedgerRows()
        }
        const soll = this.state.ledgerRows
            .filter(l => this.state.ccAccounts.includes(l.gkonto) || (accountNr > 20000 && this.state.ccAccounts.includes(l.kundenkonto) && l.gkonto >= 2000 && l.gkonto < 3000))
            .reduce((s, l) => s + l.betrag, 0);

        const haben = this.state.ledgerRows
            .filter(l => this.state.ccAccounts.includes(l.konto) || (accountNr > 20000 && this.state.ccAccounts.includes(l.kundenkonto) && l.konto >= 2000 && l.konto < 3000))
            .reduce((s, l) => s + l.betrag, 0);
        const account = accountNr !== 0 && this.state.accounts.length > 0 && this.state.accountsMap[accountNr] !== undefined ? this.state.accountsMap[accountNr] : null;

        return <Container
            hideHeading
            broad
        >
            <h1>
                Buchungen
                {this.props.match.params.kontonr ? <> in <AccountViewer nr={this.props.match.params.kontonr}/></> : null}
            </h1>
            <TopButtonDiv>
                {
                    account !== null && <> <AccountEditor acct={account} value={""} reload={this.loadAccounts}><MaxBtn>Kontoinformationen</MaxBtn></AccountEditor></>
                }
                <AddEinnahme accounts={this.state.accounts} reload={this.loadLedgerRows}/>
            </TopButtonDiv>

            <Status text={this.state.status} type={"success"}/>
            <Status text={this.state.error} type={"error"}/>

            <BusinessyearSearch localStorageKeyName={"ledgerView"} justBusinessYearSearch={false} onChange={data => this.setState({search: data}, this.loadLedgerRows)}/>
            {
                accountNr !== 0 ?
                    <>
                        <>{this.state.tableSize}&nbsp;
                            Buchungen mit Soll:&nbsp;
                            <b>{Consts.moneyMax(soll / 100)}</b> EUR
                            Haben:&nbsp;
                            <b>{Consts.moneyMax(haben / 100)}</b> EUR
                            Saldo:&nbsp;
                            <b>{Consts.moneyMax((soll - haben) / 100 * (account?.inflow ? 1 : -1))}</b> EUR
                        </>
                    </> :
                    <>{this.state.tableSize} Buchungen im Wert von {Consts.moneyMax(this.state.sum / 100)} EUR</>
            }


            <br/>
            <Loader loading={this.state.loading}/>

            <MyReactTable
                data={this.state.ledgerRows}
                defaultSorted={[{id: "belegdatum", desc: true}]}
                loading={this.state.loading}
                exportData={true}
                title={"Buchungen"}
                additionalTitles={"Zeitraum: " + (this.state.search?.startDate !== "0" && this.state.search?.startDate !== 0 ? dateFormatUnix(this.state.search.startDate || 0) + " - " + dateFormatUnix(this.state.search.endDate || 0) : "Gesamte Zeitspanne")}
                additionalButtonsRight={<><FAQLink to={"buchhaltung"} mini={true}/></>}
                columns={[
                    {
                        Header: "Datum",
                        accessor: "belegdatum",
                        Cell: ({original}) => <EditLedgerDate row={original} name={original.text} reload={this.loadLedgerRows}>{original.belegdatumString}</EditLedgerDate>,
                        pdfCell: ({original}) => original.belegdatumString,
                        filterable: true,
                        filterMethod: ({value}, {_original}) => _original.belegdatumString.toLowerCase().includes(value.toLowerCase()),
                        maxWidth: 120,
                        pdfMinWidth: 150,
                        pdfMaxWidth: 150,
                    },
                    {
                        Header: "Betrag",
                        accessor: "betrag",
                        accountNr: account?.nr,
                        Cell: ({original, value}) => this.renderEditable({field: "betrag", account, original, value: Consts.moneyMax(value / 100)}),
                        pdfCell: ({value}) => value / 100,
                        filterable: true,
                        maxWidth: 100,
                        pdfAlign: "right",
                    },
                    {
                        Header: "Stand danach",
                        accessor: "balance_after",
                        show: this.accountNr() !== 0,
                        Cell: ({original, value}) => <div style={{color: accountNr < 10000 && (value >= 0 ? "green" : "red")}}>{Consts.moneyMax(value / 100)}</div>,
                        pdfCell: ({value}) => value / 100,
                        filterable: true,
                        maxWidth: 100,
                        pdfAlign: "right",
                    },
                    {
                        Header: "BN",
                        accessor: "belegnr",
                        filterable: true,
                        maxWidth: 100,
                        pdfMinWidth: 80,
                        Cell: row => this.renderEditable({field: "belegnr", account, ...row})
                    },
                    {
                        Header: "Text",
                        accessor: "text",
                        filterable: true,
                        sortable: true,
                        pdfMaxWidth: 500,
                        Cell: row => this.renderEditable({field: "text", account, ...row})
                    },
                    {
                        Header: "BS",
                        accessor: "buchsymbol",
                        filterable: true,
                        Cell: row => <span title={Consts.buchSymbole[row.value]}>{row.value}</span>,
                        maxWidth: 50,
                    },
                    {
                        Header: "Soll-Konto",
                        accessor: "gkonto",
                        sortable: false,
                        filterable: true,
                        filterMethod: filterAccount,
                        pdfCell: ({value}) => value || "",
                        Cell: row => <>
                            <EditLedgerAccount name={"gkonto"} row={row.original} accounts={this.state.accountsMap} reload={this.loadLedgerRows}/>
                            &nbsp;
                            <AccountViewer nr={row.value}/>
                        </>,
                    },
                    {
                        Header: "Haben-Konto",
                        accessor: "konto",
                        sortable: false,
                        filterable: true,
                        filterMethod: filterAccount,
                        //pdfMaxWidth: 70,
                        //pdfWidth: 70,
                        pdfCell: ({value}) => value || "",
                        Cell: row => <>
                            <EditLedgerAccount name={"konto"} row={row.original} accounts={this.state.accountsMap} reload={this.loadLedgerRows}/>
                            &nbsp;
                            <AccountViewer nr={row.value}/>
                        </>,
                    },
                    {
                        Header: "Kundenkonto",
                        accessor: "kundenkonto",
                        sortable: false,
                        filterable: true,
                        filterMethod: filterAccount,
                        pdfCell: ({value}) => value || "",
                        Cell: ({value, original}) => <>
                            <AccountViewer nr={value} altText={""}/>&nbsp;
                            {(original.child_ID > 0 ? <Link to={"/benutzer/profil/" + original.child_ID}>zum Benutzeraccount</Link> : "")}
                        </>,
                    },
                    {
                        Header: "",
                        accessor: "receiptData",
                        maxWidth: 100,
                        pdfShow: false,
                        Cell: ({original, value: receiptData}) => <>
                            <a><UploadDownloadReceipt row={original} reload={this.loadLedgerRows}/></a>
                            &nbsp;
                            <InfoTooltip>
                                <Container name={"Veränderungen"}>
                                    <UserHistory userID={original.ID} personType={"ledgerRow"}/>
                                </Container>
                            </InfoTooltip>
                            &nbsp;
                            {
                                !!receiptData?.length && <>{
                                    receiptData.length > 1 ? <a><UploadDownloadReceipt customPlaceholder={<FaDownload />} row={original} reload={this.loadLedgerRows}/></a>
                                        : receiptData?.map((datum, index) => !!datum?.file && <a title={`Datei ${index + 1} - ${datum?.name || ""}`} key={datum.file} href={Consts.API_PREFIX + "/file/download/" + datum?.file}>
                                            <FaDownload/>&nbsp;
                                        </a>)
                                }
                                </>
                            }
                        </>
                    }

                ]}
                onTableChange={this.tableChange}
            />


            <br/>

        </Container>
    }
}

const accountClassNameMap = {
    gkonto: "Soll-Konto",
    konto: "Haben-Konto",
    kundenkonto: "Kundenkonto",
};

class EditLedgerAccount extends React.Component {
    setState = this.setState.bind(this);
    updateState = (a, b, c) => this.setState({[a]: {...this.state[a], [b]: c}});
    state = {
        attr: {
            accountNr: this.props.row[this.props.name]
        }
    };
    apiPost = apiPost.bind(this)
    handleSubmit = (close) => {
        this.apiPost("/accounting/ledger/modify", {...this.props, ...this.state.attr}, () => {
            this.props.reload();
            close();
        })
    };

    render() {
        const {name, accounts} = this.props;
        return <MyModal trigger={<span style={{cursor: "pointer"}}><FaEdit/></span>}>{
            close =>
                <LightContainer name={`Buchung bearbeiten - ${accountClassNameMap[name]}`}>
                    <Status type={"error"} text={this.state.error}/>
                    <FormContextWrapper value={{state: this.state, setState: this.setState, updateState: this.updateState}} onSubmit={(e) => {
                        e.preventDefault();
                        this.handleSubmit(close)
                    }}>
                        <SelectfieldInput name={"neues Konto"} tag={"attr_accountNr"} selectives={
                            Object.keys(accounts)
                                .map(k => accounts[k])
                                .filter(a => a.kind < 2)
                                .map(a => ({value: a.nr, label: a.nr + " " + a.name}))} type={"reactselect"}/>
                        <MaxBtn>Speichern</MaxBtn>
                    </FormContextWrapper>
                </LightContainer>
        }
        </MyModal>
    }
}

class EditLedgerDate extends React.Component {
    setState = this.setState.bind(this);
    updateState = (a, b, c) => this.setState({[a]: {...this.state[a], [b]: c}});
    state = {
        ledgerRow: {
            belegdatum: this.props.row.belegdatum,
        }
    };
    apiPost = apiPost.bind(this)
    handleSubmit = (close) => {
        this.apiPost("/accounting/ledger/modify", {name: "belegdatum", row: this.props.row, ...this.state.ledgerRow}, () => {
            this.props.reload();
            close();
        })
    };

    render() {
        const {name, children} = this.props;
        return <MyModal trigger={<em style={{cursor: "pointer"}}>{children}</em>}>{
            close =>
                <Container name={`Buchung bearbeiten - ${name}`}>
                    <Status type={"error"} text={this.state.error}/>
                    <FormContextWrapper value={{state: this.state, setState: this.setState, updateState: this.updateState}} onSubmit={(e) => {
                        e.preventDefault();
                        this.handleSubmit(close)
                    }}>
                        <DateTimeInput name={"Belegdatum"} tag={"ledgerRow_belegdatum"}/>
                        <MaxBtn>Speichern</MaxBtn>
                    </FormContextWrapper>
                </Container>
        }
        </MyModal>
    }
}

// date: Date, timePeriod: Number of months between start and end
export function formatDateRange(date, timePeriod, startingMonth = 0) {
    timePeriod = parseInt(timePeriod);
    let prefix = "";
    const yearText = startingMonth === 0 ? date.getFullYear() : (startingMonth <= date.getMonth() ?
        date.getFullYear() + "-" + (date.getFullYear() + 1) : (date.getFullYear() - 1) + "." + (date.getFullYear()));

    switch (timePeriod) {
        case 1:
            //prefix = ("0" + parseInt(((date.getMonth()+12-startingMonth) % 12 + 1))).slice(-2) + " - " + yearText;
            prefix = ("0" + parseInt((date.getMonth() + 1))).slice(-2) + "." + date.getFullYear();
            break;
        case 3:
            prefix = "Q" + parseInt(((((date.getMonth() + 12 - startingMonth) % 12) / 3 + 1))) + " - " + yearText;
            break;
        case 6:
            prefix = "H" + parseInt(((((date.getMonth() + 12 - startingMonth) % 12) / 6 + 1))) + " - " + yearText;
            break;
        case 12:
            prefix = yearText;
    }

    let dateTo = new Date(date);
    dateTo.setMonth(dateTo.getMonth() + timePeriod);
    dateTo.setDate(dateTo.getDate() - 1);
    // return prefix;
    return prefix + " (" + dateFormatDate(date) + " - " + dateFormatDate(dateTo) + ")";

}


