import React, {useContext, useEffect, useMemo, useState} from "react";
import {Container, InfoTooltip, Loader, MaxBtn} from "../core/components/components";
import {CheckboxInput, TextfieldInput} from "../core/input/basic";
import {FormContextWrapper} from "../core/form_context";
import {maxiGet, maxiPost} from "../core/maxios";
import {encodeGetParams} from "../core/helpers";
import Status from "../core/status";
import {UserContext} from "./UserContext";
import {MyTabs} from "../core/components/tabs";

export default function NotifcationSettingsAll({child_ID}) {
    const [users, setUsers] = useState([])

    useMemo(() =>
        maxiGet(child_ID ? "/user/subusers?edit=" + child_ID : "/user/subusers?group=only_mine").then(resp => {
            setUsers(resp.users)
        }), [])

    return <Container name={""}><MyTabs tabs={Object.values(users).sort((aVal, bVal) => {
        return (aVal.ID === aVal.verwalter_ID ? 0 : aVal.birthdate) < (bVal.ID === aVal.verwalter_ID ? 0 : bVal.birthdate) ? -1 : 1
    })
        .map(user => ({
            name: user.prename,
            content: <div>
                <NotificationSettings child_ID={user.ID} phone={user.phone}/>
            </div>
        }))}/></Container>
}

export function NotificationSettings({child_ID, phone}) {
    const [{loading, error, success}, setStatusVar] = useState({})
    const [state, setState] = useState({newsletter: {}});

    useMemo(() => setStatusVar({loading: true}) || maxiGet("/user?" + encodeGetParams({child_ID}), {setStatusVar}).then(user => {
        setState({
            ...user,
            newsletter: {
                emails: [1, 3].includes(user.shouldReceivePost),
                post: [1, 2].includes(user.shouldReceivePost),
                whatsapp: user.whatsappPhone > 0,
            }
        })
    }), [child_ID])

    const toggleWhatsapp = (a, b, val) => {
        if (val.whatsapp === 0) {
            maxiPost("/whatsapp/code/unset", {ID: child_ID}, {setStatusVar}).then(resp => {
                setStatusVar({success: resp.message})
                setState(a => ({...a, whatsappPhone: 0}))
            })
        }
    }

    return <Container name={"Sendungs-Einstellungen"}>
        <Status type={"error"} text={error}/>
        <Status type={"success"} text={success}/>

        {
            state.newsletter.emails !== undefined && <><FormContextWrapper value={{state, setState}} afterUpdate={(a, b, c) => {
                const shouldReceivePost = {true: {true: 1, false: 3}, false: {true: 2, false: 0}}[!!c.emails][!!c.post]
                maxiPost("/user/notificationsettings", {child_ID, shouldReceivePost}, {setStatusVar}).then(() => setStatusVar({success: "Einstellung erfolgreich verändert"}))
            }}>
                <CheckboxInput tag={"newsletter_emails"} labelWidth={400} name={"Informations-E-Mails erhalten"} resetOnUnmount={false}/><br/>
                <CheckboxInput tag={"newsletter_post"} labelWidth={400} name={"Informations-Postsendungen erhalten"} resetOnUnmount={false}/><br/>
            </FormContextWrapper>
                <FormContextWrapper value={{state, setState}} afterUpdate={toggleWhatsapp}>
                    <CheckboxInput tag={"newsletter_whatsapp"} labelWidth={400} resetOnUnmount={false} name={<>Informationen als Whatsapp erhalten <InfoTooltip>Wenn diese Checkbox nicht angehakt wird, dann werden Informationen bevorzugt als SMS versandt.</InfoTooltip></>}/>
                    <br/>
                </FormContextWrapper>
                {!!state.newsletter.whatsapp && !(state.whatsappPhone > 0) && <ConfirmWhatsapp phone={phone} child_ID={child_ID}/>}
            </>
        }

        <Loader loading={loading}/>

        <p>
            Bitte beachte, dass unabhängig von oben getätigter Auswahl relevante Informationen zu Kursen, Kursbuchungen, Terminen, Zahlungen, Zahlungserinnerungen, sowie alle anderen vom Verein als wichtig erachtete E-Mail-, SMS- und Post-Sendungen
            unabhängig von der obigen Auswahl versandt werden.
        </p>
    </Container>
}

function ConfirmWhatsapp({phone, child_ID}) {
    const [clickConfirm, setClickConfirm] = useState(false)
    const [state, setState] = useState({})
    const [compareCode, setCompareCode] = useState(null)
    const [lastSent, setLastSent] = useState(0)
    const [{error, success}, setStatusVar] = useState({})
    const [confirmed, setConfirmed] = useState(false)

    const sendCode = () => {
        setClickConfirm(true)
        if (new Date().getTime() - 1000 * 60 > lastSent) {
            setLastSent(new Date().getTime())
            maxiGet("/whatsapp/code/send?" + encodeGetParams({ID: child_ID})).then(resp => setCompareCode(resp?.code))
        }
    }

    const verifyCode = () => {
        const code = state.code
        if (code !== state.code) {
            setStatusVar({error: "Der Code stimmt leider nicht."})
            return
        }
        maxiPost("/whatsapp/code/verify", {ID: child_ID, code: state.code}, {setStatusVar}).then(resp => {
            setConfirmed(true)
            setStatusVar({success: resp.message})
        })
    }

    return <FormContextWrapper value={{state, setState}}>
        <Status type={"error"} text={error}/>
        <Status type={"success"} text={success}/>
        {!confirmed && <>
            {!clickConfirm && <><b style={{color: "red"}}>Bitte Nummer bestätigen!</b> <MaxBtn onClick={sendCode}>WhatsApp bestätigen (Nachricht an {phone ? '+' + phone : ''} erhalten)</MaxBtn></>}
            {clickConfirm && <>
                <Status type={"success"} text={`Es wurde eine WhatsApp-Nachricht an deine im Benutzerbereich festgelegte Telefonnummer ${phone ? '+' + phone : ''} gesendet.`}/>
                <TextfieldInput name={"Code eingeben"} tag={"code"}/><MaxBtn onClick={verifyCode}>Bestätigen</MaxBtn> <a onClick={sendCode}>Erneut senden (bitte 1 Minute warten)</a>
            </>}
        </>}
    </FormContextWrapper>
}
