// tag, name, width, multiple, labelWidth: selbsterklärend
// Es wird standardmäßig eine Liste der Course-IDs die ausgewählt wurden rückgeliefert.
// Es ist aber auch möglich, eine Menge von key-value-pairs (ID - Name) in den ContextState zu setzen (askeyvalue=true).
// Das gewünschte Format zu Beginn ist [id1, id2, id3], es werden aber auch Kursdaten gelesen, die in der Form [{id: id1}, {value: id2}, id3] sind.
import React, {CSSProperties, useContext, useMemo, useState} from "react"
import {FormContext} from "../form_context"
import {maxiGet} from "../maxios"
import {encodeGetParams} from "../helpers"
import {MyModal} from "../components/modal"
import {MaxBtn} from "../components/components"
import {AllCoursesList} from "../../course/courses_for"
import {Course} from "../interfaces/core"
import {useForm, useFormContext} from "react-hook-form"

interface CourseInputProps {
    tag: string
    askeyvalue?: boolean
    name?: string | JSX.Element
    width?: any
    multiple?: boolean
    labelWidth?: any
}

export function CourseInput({tag, askeyvalue = false, name, width = "100%", multiple = false, labelWidth}: CourseInputProps) {
    const {state, updateState} = useContext(FormContext)
    const [selectedCourses, setSelectedCourses] = useState<Course[]>([])

    const tagSplit = tag.split("_")
    let dvTemp: any = null
    if (tagSplit.length === 1) {
        dvTemp = state[tagSplit[0]] || []
    } else {
        dvTemp = (state[tagSplit[0]] || {})[tagSplit[1]] || []
    }
    const dv: (string | number)[] = Array.isArray(dvTemp) ? dvTemp.map(x => x.value || x.ID || x) : [dvTemp]

    useMemo(() => {
        if ((dv || []).length > 0 && selectedCourses?.length === 0) {
            maxiGet("/courses?" + encodeGetParams({limitless: true, schoolyear: 2021, course_ids: dv.join(",")})).then((resp: { courses: Course[] }) => {
                setSelectedCourses(resp.courses.filter(x => dv.includes(x.ID)))
            })
        }
    }, [dv])

    // const [selectedValues, _, updateSelectedValues] = useMyState(dv)
    const updateCourses = (ids: number[], courses: Course[], close: Function) => {
        updateState && updateState(tagSplit[0], tagSplit.length > 1 ? tagSplit[1] : "", askeyvalue ? courses.map(x => ({label: x.name, value: x.ID})) : ids)
        if (!multiple) {
            close()
        }
        setSelectedCourses(courses)
        // afterUpdate && afterUpdate(tagSplit[0], tagSplit[1] || "", ids)
    }

    return <div style={{width: width}}><b style={{width: labelWidth}}>{name}</b>: <MyModal additionalPaperStyles={"WideDialogPaper"} trigger={<MaxBtn>Kurs{multiple && "e"} auswählen</MaxBtn>}>
        {
            (close: Function) => <>
                <AllCoursesList fullListViewer={true} match={{params: {date: new Date().getTime()}}} selectable={true} orderBySelection={true} selected={multiple ? dv : []}
                                onChange={(ids: number[], courses: Course[]) => updateCourses(ids, courses, close)}/>
                <div><MaxBtn onClick={close}>Schließen</MaxBtn> <MaxBtn onClick={() => updateCourses([], [], close)}>Auswahl löschen</MaxBtn></div>
            </>
        }
    </MyModal> {
        (dv?.length || 0) > 0 && <>
            <b>{dv.length} Kurs{dv.length !== 1 && "e"} ausgewählt</b>:
            {
                dv.length > 0 && <ul>
                    {selectedCourses.map(({name}) => <li key={name}>{name}</li>)}
                </ul>
            }
        </>
    }
    </div>
}

type CourseInputHookProps = ({ tag: string, onChange?: undefined } | { tag?: undefined, onChange: (courses: Course[]) => any }) & {
    name: string | JSX.Element
    multiple?: boolean
    style?: Partial<Record<"label" | "all", CSSProperties>>
}

export function CourseInputHook({tag, name, style, multiple, onChange}: CourseInputHookProps) {
    const localTag = tag || "tag"
    const formContext = useFormContext()
    const formLocal = useForm<{ tag: Course[] }>({defaultValues: {[localTag]: []}})
    const form = formContext || formLocal

    const tempValue = form.getValues(localTag)
    const courses: Course[] = !tempValue ? [] : (Array.isArray(tempValue) ? tempValue : [tempValue])

    const updateCourses = (courses: Course[], close: Function) => {
        form.setValue(localTag, courses)
        if (!multiple) {
            close()
        }
        onChange && onChange(courses)
    }

    return <div style={style?.all}><b style={style?.label}>{name}</b>: <MyModal additionalPaperStyles={"WideDialogPaper"} trigger={<MaxBtn>Kurs{multiple && "e"} auswählen</MaxBtn>}>
        {
            (close: Function) => <>
                <AllCoursesList fullListViewer={true} match={{params: {date: new Date().getTime()}}} selectable={true} orderBySelection={true} selected={multiple ? courses : []}
                                onChange={(ids: number[], courses: Course[]) => updateCourses(courses, close)}/>
                <div><MaxBtn onClick={close}>Schließen</MaxBtn> <MaxBtn onClick={() => updateCourses([], close)}>Auswahl löschen</MaxBtn></div>
            </>
        }
    </MyModal> {
        (courses?.length || 0) > 0 && <>
            <b>{courses.length} Kurs{courses.length !== 1 && "e"} ausgewählt</b>:
            {
                courses.length > 0 && <ul>
                    {courses.map(({name}) => <li key={name}>{name}</li>)}
                </ul>
            }
        </>
    }
    </div>
}
