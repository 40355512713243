import {MyModal} from "../core/components/modal"
import {FaDownload, FaFileUpload, FaPlus, FaTrashAlt, FaUpload} from "react-icons/fa"
import {Container, LightContainer, Loader, MaxBtn, MiniBtn} from "../core/components/components"
import Status from "../core/status"
import {FormContextWrapper} from "../core/form_context"
import {FileUpload} from "../core/input/fileupload"
import React, {useEffect, useState} from "react"
import {StatusVar} from "../club/club_inputs"
import {maxiPost} from "../core/maxios"
import Consts from "../core/consts"
import {objGetEntries} from "../core/helpers"

type UploadDownloadReceiptProps = { customPlaceholder?: JSX.Element, row: { ID: number, receiptData: { file: string, name: string, createdAt?: number, deletedAt?: number }[] }, reload: Function }

export function UploadDownloadReceipt(props: UploadDownloadReceiptProps) {
    const [receipts, setReceipts] = useState<{ error?: string, receipts?: Record<string, { file_name: string, file_name_orig: string }> }>({receipts: {}})
    const [{error, loading, success}, setStatusVar] = useState<StatusVar>({})
    const [localDeletedIndices, setLocalDeletedIndices] = useState<number[]>([])
    const [uploadedEntries, setUploadedEntries] = useState(props.row.receiptData || [])

    const customSetState = (local: any) => setReceipts((original) => {
        const realLocal = typeof local === "function" ? local() : local
        return ({error: local?.error, receipts: {...original.receipts, ...realLocal?.receipts}})
    })

    const submit = (close?: Function) => {
        setStatusVar({loading: true})
        const localReceipts = Object.entries(receipts.receipts || {}).map(([key, value]) => ({name: value.file_name_orig, file: value.file_name}))
        maxiPost("/accounting/ledger/modify", {name: "receiptData", row: props.row, receipts: localReceipts}, {setStatusVar}).then(resp => {
            setStatusVar({success: "Hochgeladene wurden folgende Dateien: " + localReceipts.map(receipt => receipt.name).join(", ")})
            setUploadedEntries(arr => ([...arr, ...localReceipts]))
            close && close()
        })
    }
    useEffect(() => {
        if (objGetEntries(receipts.receipts).length > 0) {
            submit()
            setReceipts({receipts: {}})
        }
    }, [receipts.receipts])

    const deleteFile = (index: number, filename: string, name?: string) => {
        if (!window.confirm("Willst du diese Datei wirklich löschen?")) {
            return
        }

        maxiPost("/accounting/ledger/deleteReceipt", {ID: props.row.ID, filename}).then(() => {
            setStatusVar({success: `Datei ${(name || "") === "" ? filename : name} erfolgreich gelöscht`})
            setLocalDeletedIndices(deleted => ([...deleted, index]))
        })
    }

    return <MyModal trigger={props.customPlaceholder ? props.customPlaceholder : <FaFileUpload/>} modal onClose={() => props.reload()}>
        {close =>
            <Container name={"Belege hochladen / herunterladen"}>
                <FormContextWrapper
                    value={{
                        state: receipts, setState: customSetState
                    }}
                    onSubmit={(e: any) => {
                        e.preventDefault()
                        submit(close)
                    }}>
                    <LightContainer>
                        <FileUpload saveFullFileObject={true} name={<>Beleg auswählen</>} tag={"receipts_0"} prefix={"receipts"} setStateParent={customSetState}/>
                    </LightContainer>
                    <Status type={"error"} text={error}/>
                    <Status type={"error"} text={receipts.error}/>
                    <Status type={"success"} text={success}/>
                    <Loader loading={loading}/>
                </FormContextWrapper>

                <h3>Belege herunterladen ({uploadedEntries.length || 0})</h3>
                {
                    (uploadedEntries.length || 0) === 0 ? <p>Es gibt noch keine Belege zu dieser Buchungszeile</p> : <>
                        {
                            uploadedEntries.filter(datum => !datum.deletedAt).map((datum, index) => {
                                const {name, file, createdAt} = datum
                                return <p key={datum?.file || index}>
                                    <a href={Consts.API_PREFIX + "/file/download/" + file}><FaDownload/>&nbsp;&nbsp;
                                        <b>Datei {index + 1}:</b> {name || "-"}</a> {createdAt && <>(hochgeladen am {new Date(createdAt * 1000).toLocaleString()})</>}&nbsp;
                                    {localDeletedIndices.includes(index) ? <MiniBtn>Gelöscht</MiniBtn> : <MiniBtn onClick={() => deleteFile(index, file, name)}><FaTrashAlt/> Löschen</MiniBtn>}
                                </p>
                            })
                        }
                    </>
                }
            </Container>
        }
    </MyModal>
}
