import React, {CSSProperties, useContext, useEffect, useMemo, useState} from "react"
import {LightContainer, Loader, MiniBtn} from "../core/components/components"
import {ColumnType, MyReactTable} from "../core/components/table"
import {TextfieldInput} from "../core/input/basic"
import Status from "../core/status"
import {User, UserContext} from "./UserContext"
import {dateFormat} from "../core/dateFuncs"
import {Link} from "react-router-dom"
import {maxiGet} from "../core/maxios"
import {FormContextWrapper} from "../core/form_context"
import {FaSyncAlt, FaTimes} from "react-icons/all"
import {FaPlus} from "react-icons/fa"
import {StatusVar} from "../club/club_inputs"

export type UserLocal = User & { birthdate: number }

type UserSearchSelectOpenerProps = {
    userIDField: number[]
    multiple?: boolean
    furtherFields?: ColumnType<UserLocal>[]
    onChange: (userID: number[], user: UserLocal[]) => void
    style?: CSSProperties
    labelStyle?: CSSProperties
    heading?: string
    filter?: (u: UserLocal) => boolean
    newPerson?: string
    label?: string
    textNotSelected?: string
    usersHide?: number[]
    preclaimer?: string
}

type DetailUserSearchSelectProps = UserSearchSelectOpenerProps & {
    userData: UserLocal[]
    storeID?: boolean
    searchNameLength?: number
    selectButton?: Function
    includedUsers?: UserLocal[]
    includeSearch?: boolean
    hideSelected?: boolean
    showSelectedUsers?: boolean
    showHeader?: boolean
    id?: any
}

function DetailUserSearchSelectTS({
                                      userIDField = [], userData = [], storeID, searchNameLength = 3, multiple = false, furtherFields = [], onChange, usersHide = [], style = {}, preclaimer, selectButton = () => {
    }, heading = "", filter, includedUsers = [], includeSearch = true, hideSelected = false, showSelectedUsers = true, showHeader = true,
                                  }: DetailUserSearchSelectProps) {
    const [users, setUsers] = useState<UserLocal[]>([])
    const [state, setState] = useState({searchName: ""})
    const [userIDs, setUserIDs] = useState({ids: userIDField, users: userData})
    const [{loading, error,}, setStatusVar] = useState<StatusVar>({})
    const {} = useContext(UserContext)
    const [timer, setTimer] = useState<number | null>(null)

    const loadUsers = (force = false) => {
        if (timer) {
            clearTimeout(timer)
        }
        setTimer(window.setTimeout(() => {
            if (state.searchName?.length >= searchNameLength || force) {
                const url = "/user/search?"
                maxiGet(encodeURI(url + "name=" + state.searchName) + (userIDs?.ids?.length > 0 ? userIDs.ids.map(x => ("&ids=" + x)).join("") : "")
                    + (furtherFields ? "&furtherFields=" + Object.keys(furtherFields).join(",") : ""), {setStatusVar}).then(
                    resp => setUsers(resp.users))
            } else {
                setUsers([])
            }
        }, force ? 0 : 500))
    }

    useMemo(() => loadUsers(userIDField !== null && userIDField.length !== 0), [])
    useEffect(loadUsers, [state])
    useEffect(() => {
        onChange && onChange(userIDs.ids, userIDs.users)
    }, [userIDs])

    const onChangeLocal = (newID: number, user: UserLocal, add = true) => {
        if (multiple) {
            setUserIDs(a => {
                const contained = a.ids.some(x => x === newID)
                if (contained && !add) {
                    return {ids: a.ids.filter(x => x !== newID), users: a.users.filter(x => x.ID !== newID)}
                }
                if (!contained && add) {
                    return {ids: [...a.ids, newID], users: [...a.users, user]}
                }
                return a
            })
        } else {
            setUserIDs(add ? {ids: [newID], users: [user]} : {ids: [], users: []})
        }


        if (storeID !== undefined && !multiple && add) {
            setState({searchName: ""})
        }
    }

    const allUsers = [...(users || []), ...(includedUsers || []), ...(userIDs.users || [])].filter((val, indexOuter, array) => {
        return array.filter((x, indexInner) => x.ID === val.ID && indexInner < indexOuter).length === 0
    }).sort((a, b) => (a.fullname || "").toLowerCase() < (b.fullname || "").toLowerCase() ? -1 : 1)
    const shownUsers = allUsers.filter(filter || (() => true)).filter(y => !hideSelected || !(userIDs.ids.some(x => x === y.ID)))

    return <LightContainer name={""} style={style}>
        {showHeader && <h2>{heading || "Wähle Benutzer"}</h2>}
        {preclaimer && <span>{preclaimer}</span>}
        {includeSearch && <FormContextWrapper value={{state: state, setState: setState}}>
            <TextfieldInput name={"Name"} tag={"searchName"} ph={"Max Mustermann"}/>
            {showSelectedUsers && <>{storeID !== undefined && (allUsers.filter(u => (userIDs.ids || []).some(x => x === u.ID)).map(u => u.fullname).join(", ") || "kein Benutzer ausgewählt")}</>}
        </FormContextWrapper>}
        <Loader loading={loading}/>
        <Status type={"error"} text={error}/>
        {
            (shownUsers.length > 0) && <><MyReactTable
                data={shownUsers}
                columns={[
                    {
                        Header: "Name",
                        accessor: "lastname",
                        Cell: ({original: u}) => <Link to={"/benutzer/profil/" + u.ID}>{u.lastname && u.prename ? u.lastname + ", " + u.prename : u.fullname}</Link>,
                        //
                    },
                    {
                        Header: "Aktion",
                        id: "aktion",
                        Cell: ({original: u}) => usersHide.indexOf(u.ID) > -1 ? <em>nicht auswählbar</em> : (
                            storeID === undefined ? selectButton(u.ID, u) : (userIDs.ids.some(x => x === u.ID) ? <em><b onClick={() => onChangeLocal(u.ID, u, false)}>ausgewählt</b></em> :
                                <em onClick={() => onChangeLocal(u.ID, u)}>auswählen</em>)
                        )
                    },
                    {
                        Header: "Geburtstag",
                        accessor: "birthdate",
                        filterable: true,
                        Cell: ({original: u}) => dateFormat(u.birthdate),
                    },
                    ...Object.entries(furtherFields || {}).map(([key, field]) => ({
                        Header: field.Header,
                        accessor: key,
                        Cell: field.Cell,
                    })),

                ]}
            /></>
        }
        {
            shownUsers.length === 0 && state.searchName !== "" && <p>Keine solchen Benutzer.</p>
        }
    </LightContainer>
}

function UserSearchSelectOpenerTS({
                                      userIDField, multiple = false, furtherFields = [], onChange, style = {}, heading = "Person auswählen",
                                      filter, newPerson = "Neue Person", label = "Ausgewählt", textNotSelected = "Es wurde noch keine Person ausgewählt.", labelStyle = {}
                                  }: UserSearchSelectOpenerProps) {
    const [users, setUsers] = useState<UserLocal[]>([])
    const [showSelection, setShowSelection] = useState(false)
    const [{}, setStatusVar] = useState<StatusVar>({})

    const loadUsers = (ids: number[]) => {
        const data = ids || []
        const url = "/user/search?"
        maxiGet(encodeURI(url + "name=") + data.map(x => ("&ids=" + x)).join("") + (furtherFields ? "&furtherFields=" + Object.keys(furtherFields).join(",") : ""), {setStatusVar}).then(
            resp => {
                //console.log(data, resp.users)
                setUsers(resp.users.filter((u: User) => data.includes(u.ID)))
            })
    }
    const removeUser = (x: User) => {
        setUsers(a => a.filter(y => y.ID !== x.ID))
    }
    const onChangeLocal = (ids: number[], newUsers: UserLocal[]) => {
        if (multiple ? users.length !== newUsers.length : users[0]?.ID !== newUsers[0]?.ID) {
            setShowSelection(false)
            setUsers(newUsers)
        }

    }

    useMemo(() => loadUsers(userIDField), [])
    useEffect(() => onChange(users.map(x => x.ID), users), [users])

    return <><label><span style={labelStyle}><b>{label}</b></span>
        {users.length > 0 ? users.map(x => <MiniBtn key={x.ID} style={{marginRight: 2}}><FaTimes onClick={() => removeUser(x)}/> {x.fullname} </MiniBtn>) : <p style={{marginRight: 10, display: "inline"}}>{textNotSelected}</p>}
        {!showSelection && <MiniBtn onClick={() => setShowSelection(true)}>{multiple ? <><FaPlus/> {newPerson}</> : <><FaSyncAlt/> {newPerson || "Ändere Person"}</>}</MiniBtn>}
        {showSelection && <DetailUserSearchSelectTS storeID heading={heading} onChange={onChangeLocal} id={users} filter={filter} userIDField={users.map(x => x.ID)} userData={users}
                                                    searchNameLength={1} multiple={multiple} style={style} hideSelected={true} showSelectedUsers={false}/>}
    </label><br/></>
}

export {UserSearchSelectOpenerTS, DetailUserSearchSelectTS}
