import React, {useMemo, useState} from 'react';
import "../dashboard.sass"
import Status from "../core/status";
import "./courses.sass"
import {apiGet} from "../core/api";
import {Container} from "../core/components/components";
import {MyTabs} from "../core/components/tabs";
import ViewCoursesOf from "./courses_of";
import Consts from "../core/consts";
import {Link} from "react-router-dom";
import {maxiGet} from "../core/maxios";

class Courses extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: 2,

        };
        this.pn = props.location?.pathname || "";
        this.apiGet = apiGet.bind(this);
        this.apiGet("/user/subusers?group=only_mine", resp => {
            this.setState({"users": resp.users})
        });
    }

    render() {
        return (
            <>
                <Container name={"Meine " + ({"/kurs/trainer": "Teilnehmer", "/kurs/meine_online": "TurnvereinLive@Home Buchungen"}[window.location.pathname] || "Buchungen")} visible>
                    <Status type="error" text={this.state.error}/>
                    <Status type="success" text={this.state.status}/>
                    <SaldoInfo childID={0}/>
                    {
                        this.state.users !== undefined && Object.keys(this.state.users).length > 1 && <>
                            von: &nbsp;
                            <MyTabs tabs={Object.values(this.state.users)
                                .sort((aVal, bVal) => {
                                    return (aVal.ID === aVal.verwalter_ID ? 0 : aVal.birthdate) < (bVal.ID === aVal.verwalter_ID ? 0 : bVal.birthdate) ? -1 : 1
                                })
                                .map(user => ({
                                        name: user.prename,
                                        content: <ViewCoursesOf id={user.ID}/>
                                    })
                                )}/>
                        </>
                    }
                    {
                        this.state.users !== undefined && Object.keys(this.state.users).length === 1 &&
                        <ViewCoursesOf id={this.state.users[Object.keys(this.state.users)[0]].ID}/>
                    }
                </Container>
            </>
        )
    }
}

function SaldoInfo({childID}) {
    //console.log({childID})

    const [saldo, setSaldo] = useState(0);
    useMemo(() => maxiGet("/user/payments/saldo/" + childID).then(resp => setSaldo(resp.saldo)), []);

    if (saldo < 0) {
        return <Status type={"info"}>
            Du hast noch {Consts.moneyMax(-saldo / 100)} offen. Bitte gehe zu <Link to={"/abrechnung"}>Abrechnung</Link> um dies zu begleichen.
        </Status>
    }
    return null;

}

export default Courses;
