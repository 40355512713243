import {CoursePaymentFormModel, CoursePaymentModel} from "../../core/interfaces/core"
import React, {useContext, useMemo, useState} from "react"
import {Container, LightContainer} from "../../core/components/container"
import {MaxBtn, MiniBtn} from "../../core/components/components"
import {CellParams, MyReactTable} from "../../core/components/table"
import {MyModal} from "../../core/components/modal"
import {FaPencilAlt} from "react-icons/all"
import {CoursePlanEdit} from "./course_plan_edit"
import {FaClone, FaPlus} from "react-icons/fa"
import {usePaymentModels} from "./usePaymentModels"
import {useStatusVar} from "../../club/club_inputs"
import {StatusGroup} from "../../core/status"
import {Link} from "react-router-dom"
import {UpdateContext} from "../../core/context/updateContext"


export function CoursePaymentModelList() {
    const [statusVar, setStatusVar] = useStatusVar()
    const [paymentModels, reloadPaymentModels] = usePaymentModels({setStatusVar})
    const [showModelId, setShowModelId] = useState<number>()
    const [duplicable, setDuplicable] = useState<CoursePaymentModel>()
    const [lastButtonClickedAt, setLastButtonClickedAt] = useState<Date | null>(null)

    const reload = (close?: Function) => {
        close && close()
        reloadPaymentModels()
    }

    return <Container name={"Kursübergreifende Zahlungsweisen verwalten"}>
        <UpdateContext.Provider value={{lastUpdate: lastButtonClickedAt, setLastUpdate: setLastButtonClickedAt}}>
            <CoursePaymentModelCreateModal onChange={reload}/>
            {showModelId && <CoursePaymentModelEditModal onChange={() => reload()} model_ID={showModelId}/>}
            {duplicable && <CoursePaymentModelDuplicateModal onChange={(close) => {reload(close); setDuplicable(undefined)}} initialData={duplicable}/>}
        </UpdateContext.Provider>
        <StatusGroup {...statusVar} />
        {
            paymentModels && <MyReactTable data={paymentModels} columns={[
                {
                    Header: "Name",
                    accessor: "name",
                },
                {
                    Header: "Aktiv",
                    id: "active",
                    accessor: (row) => row.active ? "ja" : "nein",

                },
                {
                    Header: "Anzahl an definierten Zahlungsweisen",
                    id: "paymentGroupNumber",
                    accessor: (data) => data.paymentGroups.length,
                },
                {
                    Header: "Namen der Zahlungsweisen",
                    id: "paymentGroupNames",
                    accessor: (data) => data.paymentGroups.map(group => group.name).join(", "),
                },
                {
                    Header: "Bearbeiten",
                    id: "edit",
                    Cell: ({original}: CellParams<CoursePaymentModel, any>) => <MiniBtn onClick={() => {
                        setLastButtonClickedAt(new Date())
                        setShowModelId(original.ID)
                    }}><FaPencilAlt/> Bearbeiten</MiniBtn>,
                },
                {
                    Header: "Duplizieren",
                    id: "edit",
                    Cell: ({original}: CellParams<CoursePaymentModel, any>) => <MiniBtn onClick={() => {
                        setLastButtonClickedAt(new Date())
                        setDuplicable(original)
                    }}><FaClone/> Duplizieren</MiniBtn>,
                },
                {
                    Header: "Zugewiesene Kurse",
                    id: "courses",
                    Cell: ({original}: CellParams<CoursePaymentModel, any>) => (original.courses?.length || 0) > 0 ? <MyModal trigger={`${original.courses.length} Kurse`}>
                        <h3>Folgenden Kursen wurde diese Gruppe von Zahlungsweisen zugeordnet:</h3>
                        <ul>
                            {
                                original.courses.map(course => <li key={course.ID}><Link to={`kurs/buche/${course.ID}`}>{course.name}</Link></li>)
                            }
                        </ul>
                    </MyModal> : `${original.courses.length} Kurse`
                }
            ]}/>
        }
    </Container>
}


export function CoursePaymentModelDuplicateModal({onChange, initialData}: { onChange: (close: Function) => any, initialData: CoursePaymentModel }) {
    const {lastUpdate} = useContext(UpdateContext)

    return <MyModal key={`${initialData.ID}.${lastUpdate}`} additionalPaperStyles={"WideDialogPaper"} defaultOpen={true}>
        {(close) => <>
            <h1>Neue Zahlungsweise als Duplikat erstellen</h1>
            <CoursePaymentModelEdit model_ID={undefined} onChange={() => onChange(close)} initialData={initialData}/>
        </>}
    </MyModal>
}

function useHasBeenUpdated() {
    const {lastUpdate} = useContext(UpdateContext)
    const [localUpdate, setLocalUpdate] = useState<Date>()
    return [localUpdate, localUpdate !== lastUpdate, setLocalUpdate] as const
}

function CoursePaymentModelEditModal({model_ID, onChange}: { model_ID: number, onChange: Function }) {
    const {lastUpdate} = useContext(UpdateContext)
    const [modelId, setModelId] = useState<number |null>(model_ID)
    useMemo(() => setModelId(model_ID), [model_ID])

    if(!modelId) {
        return null
    }

    return <MyModal key={`${modelId}.${lastUpdate}`} additionalPaperStyles={"WideDialogPaper"} defaultOpen={true} onClose={() => {}}>
        <>
            <h1>Gruppe von Zahlungsweisen editieren</h1>
            <CoursePaymentModelEdit onChange={onChange} model_ID={model_ID}/>
        </>
    </MyModal>
}

function CoursePaymentModelCreateModal({onChange}: { onChange: (close: Function) => any }) {
    return <MyModal key={Math.random()} additionalPaperStyles={"WideDialogPaper"} trigger={<MaxBtn><FaPlus/> Neu erstellen</MaxBtn>}>
        {(close) => <>
            <h1>Neues Zahlungsmodell (Sammlung von Zahlungsgruppen) erstellen</h1>
            <CoursePaymentModelEdit model_ID={undefined} onChange={() => onChange(close)} initialData={{ID: undefined, active: true, name: "", paymentGroups: [], courses: []}}/>
        </>}
    </MyModal>
}

function CoursePaymentModelEdit(props: { model_ID?: number, onChange?: Function, initialData?: CoursePaymentFormModel }) {
    return <LightContainer>
        <CoursePlanEdit {...props} />
    </LightContainer>
}
