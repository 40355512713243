import React, {CSSProperties, Dispatch, SetStateAction} from 'react'
import {Course} from "./interfaces/core"


const FormContext = React.createContext<FormContextProps<any>>({state: {}})
export type CourseFormContextProps = FormContextProps<{course: Partial<Course>}>
export const CourseFormContext = React.createContext<CourseFormContextProps>( {state: {course: {}}})

export type UpdateType<T> = (a: keyof T & string, b: string | null, val: T) => void

interface FormContextWrapperProps<T = any> {
    value: FormContextValue<T>
    afterUpdate?: UpdateType<T>
    onSubmit?: Function
    style?: CSSProperties
    children?: any
}

interface FormContextValue<T> {
    state: T
    setState: SetStateAction<T>
    updateState?: UpdateType<T>
}

export interface FormContextProps<T> {
    state: T
    setState?: SetStateAction<T>
    updateState?: UpdateType<T>
}

class FormContextWrapper<T = Record<string, any>> extends React.Component<FormContextWrapperProps> {
    constructor(props: FormContextWrapperProps) {
        super(props)
        if (!this.props.value || !this.props.value.state || !this.props.value.setState) {
            throw new Error("<FormContextWrapper /> needs state and setState in the value attribute")
        }
    }

    handleChange = (e: any) => {
        if (!e.target.name) {
            return
        }
        const s = e.target.name.split("_")
        const val = e.target.type === 'checkbox' ? (e.target.checked ? 1 : 0) : e.target.value
        const valueContainer = s.length > 1 ? {...this.props.value.state[s[0]], [s[1]]: val} : val
        this.props.value.setState((old: T) => ({...old, [s[0]]: valueContainer}))
        if (this.props.afterUpdate !== undefined) {
            this.props.afterUpdate(s[0], s[1], valueContainer)
        }
    }

    updateState: UpdateType<T> = (a: keyof T & string, b, c) => {
        try {
            if (b) {
                !!this.props.value?.setState && this.props.value.setState((old: T) => ({...old, [a]: {...(typeof(old[a]) === "object" ? old[a] : {}), [b]: c}}))
                !!this.props.afterUpdate && this.props.afterUpdate(a, b, {[b]: c})
            } else {
                !!this.props.value?.setState && this.props.value.setState((old: any) => ({...old, [a]: c}))
                !!this.props.afterUpdate && this.props.afterUpdate(a, null, c)
            }
        } catch (e) {
            console.log(e)
        }
    }

    render() {

        return <FormContext.Provider value={{updateState: this.updateState, ...(this.props.value || {})}}>
            <form onSubmit={
                !!this.props.onSubmit ? e => {
                        e.preventDefault()
                        !!this.props.onSubmit && this.props.onSubmit(e)
                    } :
                    (e) => e.preventDefault()
            }
                  onChange={this.handleChange}
                  style={this.props.style || {}}
            >
                {this.props.children}
            </form>
        </FormContext.Provider>
    }
}

export {FormContext, FormContextWrapper}
