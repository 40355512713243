import React, {useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import {FaTimesCircle} from "react-icons/fa";

export interface MyModalProps {
    trigger?: any
    defaultOpen?: boolean
    hoverEnabled?: boolean
    onOpen?: Function | null
    onClose?: ((trigger: any) => any) | Function | null
    additionalPaperStyles?: string | "WideDialogPaper"
    children: ((close: Function) => any) | JSX.Element |JSX.Element[] | string
    modal?: boolean
}

export const MyModal = ({trigger, defaultOpen = false, children, hoverEnabled = false, onOpen = null, onClose = null, additionalPaperStyles = "", modal}: MyModalProps) => {
    const [open, setOpenRaw] = useState(!!defaultOpen);
    const setOpen = (open: boolean) => {
        if (open && onOpen !== null) {
            onOpen()
        }
        setOpenRaw(open)
    };
    const close = (trigger: any) => () => {
        if (onClose !== null) {
            const returnFromClose = onClose(trigger);
            if (returnFromClose !== false) {
                setOpen(false);
            }
        } else {
            setOpen(false);
        }
    };
    return <>
        <i
            style={{cursor: "pointer"}}
            onClick={(e) => {
                e.preventDefault();
                setOpen(!open)
                return false
            }}
            onMouseOver={() => hoverEnabled && setOpen(!open)}
        >
            {trigger}
        </i>
        <Dialog
            open={open}
            onClose={close("eventHandler")}
            classes={{root: 'MyDialogRoot', paper: additionalPaperStyles + ' MyDialogPaper'}}
            maxWidth={"lg"}
        >
            <div className={"closeButton"} onClick={close("closeButton")}><FaTimesCircle/></div>
            {typeof children === "function" ? children(close("child")) : children}
        </Dialog>
    </>
};
