/*eslint no-restricted-globals: ["error", "event", "fdescribe"]*/
import {
    FaAlignJustify,
    FaBars,
    FaBirthdayCake, FaBox,
    FaBuilding, FaCalculator,
    FaCalendarAlt,
    FaCashRegister,
    FaChartBar,
    FaChartPie,
    FaCheck,
    FaCode,
    FaCogs, FaCross,
    FaDatabase,
    FaDownload,
    FaEnvelope,
    FaExchangeAlt, FaEye,
    FaFileAlt,
    FaFileExport,
    FaFileInvoice, FaFileInvoiceDollar,
    FaFileSignature,
    FaGlobe,
    FaGripHorizontal,
    FaHistory,
    FaHome, FaHourglass, FaHourglassEnd,
    FaLayerGroup,
    FaList,
    FaListAlt,
    FaLock,
    FaLockOpen, FaMailBulk,
    FaMoneyBill,
    FaNetworkWired,
    FaNewspaper,
    FaPercent,
    FaPlusCircle,
    FaQrcode, FaQuestion,
    FaSignOutAlt,
    FaTable,
    FaTableTennis,
    FaTags,
    FaUser,
    FaUserAlt,
    FaUserCheck,
    FaUsers,
    FaUsersCog,
    FaVideo,
    FaVolleyballBall,
    FaWind
} from "react-icons/fa";
import React, {useContext, useEffect, useMemo, useState} from 'react';
import {Link} from "react-router-dom"
import "./general.sass"
import {UserContext} from "./user/UserContext"
import {Redirect} from "react-router";
import {checkPermissions} from "./club/permissionsHelpers";
import {MyModal} from "./core/components/modal";
import VereinID from "./user/vereinid";
import Consts from "./core/consts";

const NavContext = React.createContext();

export default class Nav extends React.Component {
    static contextType = UserContext;


    constructor(props) {
        super(props);
        this.state = {open: ""};
        this.pn = this.props.location.pathname;
        if (this.pn.indexOf("buchhaltung") > -1) {
            this.state.open = "Buchhaltung"
        }
        if (this.pn.indexOf("newsletter") > -1) {
            this.state.open = "Newsletter"
        }
        this.setState = this.setState.bind(this);
    }


    render() {
        let {user, club} = this.context;
        const pn = this.pn;
        if (pn === "/benutzer/logout") {
            return null
        }
        if (!user?.ID) {
            return null;
        }
        return <NavContext.Provider value={{state: this.state, location: this.props.location, toggleMenuItem: this.toggleMenuItem}}>

            {/*pn.split("/").length > 1 && ["course", "my", "admin", "user"].indexOf(pn.split("/")[1]) < 0
            && (pn.split("/").length < 2 || pn !== "/benutzer/select")*/
            }

            <header className={"MobileHeader"}>
                <FaBars id={"menuexpander"} onClick={this.context.navToggle}/>
                <span>{club.shortName}</span>
            </header>
            <header className={"MobileHeaderBehind"}/>
            <header className={"App-header " + (this.context.navOpen ? "open" : "closed")}>

                <a>
                    <FaBars id={"menuexpander"} onClick={this.context.navToggle}/>
                    <FaGlobe className={"icon"}/>
                    <span>{club.shortName}</span>
                </a>
                <>
                    <NavItem child={{
                        link: "/",
                        icon: <FaHome className={"icon"}/>,
                        text: "Home"
                    }}/>
                </>

                {
                    club?.showMembershipCard && <NavItem child={{
                        link: "/meineMitgliedskarte",
                        icon: <FaQrcode className={"icon"}/>,
                        text: "Meine Mitgliedskarte"
                    }}/>
                }


                {
                    checkPermissions(this.context, "club_read") &&
                    <>
                        <SubNav name={"Verein"}
                                icon={<FaBuilding className={"icon"}/>}
                                sort
                                subs={[
                                    {
                                        link: "/verein",
                                        icon: <FaDatabase className={"icon"}/>,
                                        text: "Stammdaten",
                                    },
                                    {
                                        link: "/verein/vorlagen",
                                        icon: <FaLayerGroup className={"icon"}/>,
                                        text: "Vorlagen",
                                    },
                                    {
                                        link: "/verein/vorstand",
                                        icon: <FaUsers className={"icon"}/>,
                                        text: "Vorstand",
                                    },
                                    {
                                        link: "/mitgliedsbeiträge",
                                        icon: <FaMoneyBill className={"icon"}/>,
                                        text: "Mitgliedsbeiträge",
                                    },
                                    /*{
                                        link: "/rollen",
                                        icon: <FaUsersCog className={"icon"}/>,
                                        text: "Rollen",
                                    },*/
                                    {
                                        link: "/standesmeldung",
                                        icon: <FaChartPie className={"icon"}/>,
                                        text: "Standesmeldung",
                                    },
                                ]}
                        />
                    </>
                }

                <SubNav name={"Einheiten / Kurse"} icon={<FaGripHorizontal className={"icon"}/>} subs={[
                    {
                        link: "/kurs/meine",
                        icon: <FaListAlt className={"icon"}/>,
                        text: "Meine Buchungen",
                    },
                    user?.trainer ? {
                        link: "/kurs/trainer",
                        icon: <FaListAlt className={"icon"}/>,
                        text: "Meine Teilnehmer"
                    } : null,
                    {
                        link: "/kurs/buchung",
                        icon: <FaCheck className={"icon"}/>,
                        text: "Angebot"
                    },
                    user?.role >= 80 ?
                        {
                            link: "/kurse/nachrichten",
                            icon: <FaMailBulk className={"icon"}/>,
                            text: "Kursnachrichten"
                        } : null,
                    user?.role >= 80 ?
                        {
                            link: "/kurs/buchungen",
                            icon: <FaLayerGroup className={"icon"}/>,
                            text: "Letzte Buchungen"
                        } : null,
                    user?.role >= 80 && club.showDiscounts ?
                        {
                            link: "/rabatte",
                            icon: <FaPercent className={"icon"}/>,
                            text: "Rabatte"
                        } : null,
                    user?.role >= 50 ?
                        {
                            link: "/trainer/anwesenheitprokurs",
                            icon: <FaHourglassEnd className={"icon"}/>,
                            text: "Traineranwesenheiten"
                        } : null,
                    user?.role >= 50 ?
                        {
                            link: "/kurse/umsatz",
                            icon: <FaLayerGroup className={"icon"}/>,
                            text: "Umsätze"
                        } : null,
                    checkPermissions(this.context, "course/data") ?
                        {
                            link: "/zahlungsmodelle",
                            icon: <FaCashRegister className={"icon"}/>,
                            text: "Zahlungsweisen",
                        } : null,


                ]}/>
                {
                    club?.otherClubs?.length > 0 && <NavItem child={{

                        link: "/meineVereine",
                        text: "Meine Vereine",
                        icon: <FaBuilding className={"icon"}/>,
                    }}
                    />
                }
                {
                    checkPermissions(this.context, "user") ?
                        <SubNav name={"Personen"} icon={<FaUsers className={"icon"}/>} subs={[
                            {
                                link: "/benutzer/liste",
                                icon: <FaUsersCog className={"icon"}/>,
                                text: "Alle Personen"
                            },
                            user?.verein_ID === Consts.CLUB_ATV_ST_VALENTIN ? {
                                link: "/benutzer/schnell",
                                icon: <FaWind className={"icon"}/>,
                                text: "Schnellsuche"
                            } : null,


                            {
                                link: "/benutzer/aktiv",
                                icon: <FaVolleyballBall className={"icon"}/>,
                                text: "Aktive"
                            },
                            {
                                link: "/benutzer/admins",
                                icon: <FaUsers className={"icon"}/>,
                                text: "Aktive Verwalter"
                            }, {
                                link: "/benutzer/aktivOderVerwalter",
                                icon: <FaUsers className={"icon"}/>,
                                text: "Aktiv oder Verwalter"
                            },
                            {
                                link: "/benutzer/trainer",
                                icon: <FaVolleyballBall className={"icon"}/>,
                                text: "Trainer"
                            },
                            {
                                link: "/benutzer/nachRolle",
                                icon: <FaUsers className={"icon"}/>,
                                text: "nach Rolle"
                            },
                            {
                                link: "/kurstag/benutzer",
                                icon: <FaTags className={"icon"}/>,
                                text: "nach Tags"
                            },
                            {
                                link: "/benutzer/verwalterliste",
                                icon: <FaUsersCog className={"icon"}/>,
                                text: "nach Verwalter"
                            },

                            {
                                link: "/benutzer/registrieren",
                                icon: <FaPlusCircle className={"icon"}/>,
                                text: "neu registrieren"
                            },
                            {
                                link: "/benutzer/registrierungen",
                                icon: <FaList className={"icon"}/>,
                                text: "Registrierungen"
                            },
                            user?.role >= 80 ? {
                                link: "/benutzer/saldo",
                                icon: <FaUserCheck className={"icon"}/>,
                                text: "Offenstände"
                            } : null,
                            {
                                link: "/benutzer/jubilare",
                                icon: <FaBirthdayCake className={"icon"}/>,
                                text: "Jubilare"
                            },

                            {
                                link: "/benutzer/history",
                                icon: <FaHistory className={"icon"}/>,
                                text: "Geschichte"
                            },
                            {
                                link: "/benutzer/tote",
                                icon: <FaCross className={"icon"}/>,
                                text: "Verstorbene"
                            },
                            {
                                link: "/dash",
                                icon: <FaChartPie className={"icon"}/>,
                                text: "Demografie"
                            },


                        ]}/> :
                        <NavItem child={{
                            link: "/benutzer/liste",
                            icon: <FaUsers className={"icon"}/>,
                            text: "Benutzerdaten"
                        }}/>

                }

                {
                    user?.trainer && user?.verein_ID === Consts.CLUB_ATV_ST_VALENTIN &&
                    <>
                        <NavItem child={{
                            link: "/profil/" + user?.ID,
                            icon: <FaUser className={"icon"}/>,
                            text: "Mein Profil"
                        }}/>

                    </>
                }
                {
                    ((user?.trainer && club?.showMediaMode === 1) || club?.showMediaMode === 2 || checkPermissions(this.context, "pageblocks_read")) && <NavItem child={{
                        link: "/medien",
                        icon: <FaVideo className={"icon"}/>,
                        text: [Consts.CLUB_ARTANDDANCE_CENTER, Consts.CLUB_ARTANDDANCE_CONNECTION].includes(club.verein_ID) ? "Preise" : "Medien"
                    }}/>
                }
                {
                    !club?.noMembership && <NavItem child={{
                        link: "/trainer",
                        icon: <FaUsers className={"icon"}/>,
                        text: "Trainer"
                    }}/>
                }
                {
                    !club?.noMembership  && <NavItem child={{
                        link: "/vorstand",
                        icon: <FaUsersCog className={"icon"}/>,
                        text: "Vorstand"
                    }}/>
                }
                {
                    user?.verein_ID === -10 &&
                    <SubNav name={"Live@Home"} icon={<FaVideo className={"icon"}/>}
                            subs={[
                                {
                                    link: "/testraum",
                                    icon: <FaVideo className={"icon"}/>,
                                    text: "Test Raum"
                                },
                                {
                                    link: "/kurs/meine_online",
                                    icon: <FaListAlt className={"icon"}/>,
                                    text: "Meine",
                                },
                            ]
                            }/>
                }
                {
                    user?.verwalter &&
                    <NavItem child={{
                        link: "/abrechnung",
                        icon: <FaMoneyBill className={"icon"}/>,
                        text: "Abrechnung"
                    }}/>
                }

                {
                    /*
                    user !== undefined && user?.verein_ID === 0 &&
                    <SubNav name={"Turnverein@Home"} icon={<FaVideo className={"icon"}/>}
                            subs={[
                                {
                                    link: "/athome/fitness",
                                    icon: <FaHeart className={"icon"}/>,
                                    text: "Fitness"
                                },
                                {
                                    link: "/athome/challenges",
                                    icon: <FaDumbbell className={"icon"}/>,
                                    text: "Turnverein Challenges"
                                },
                                {
                                    link: "/athome/jumpy",
                                    icon: <FaHome className={"icon"}/>,
                                    text: "Jumpy@Home"
                                }
                                ,
                                {
                                    link: "/athome/upload",
                                    icon: <FaUpload className={"icon"}/>,
                                    text: "Eigenes Video uploaden"
                                }
                            ]
                            }/>*/
                }
                {
                    club?.facebook !== "" && <NavItem reloadRedir child={{
                        link: "/neuigkeiten",
                        icon: <FaNewspaper className={"icon"}/>,
                        text: "Neuigkeiten",
                    }}/>
                }
                {
                    ![Consts.CLUB_OETB_LINZ].includes(user?.verein_ID) && <NavItem reloadRedir child={{
                        link: "/sendungseinstellungen",
                        icon: <FaEnvelope className={"icon"}/>,
                        text: "Sendungs-Einstellungen",
                    }}/>
                }

                {
                    user?.role < 50 &&
                    <>
                        {
                            ![Consts.CLUB_TV_SALZBURG].includes(user?.verein_ID) && (club?.paymentMethods || []).includes("voucher") && <NavItem child={{
                                link: "/gutscheine/kaufen",
                                icon: <FaTags className={"icon"}/>,
                                text: "Gutschein kaufen"
                            }}/>
                        }
                        {
                            !club?.noMembership && <NavItem child={{
                                link: "/mitgliedschaft",
                                icon: <FaLockOpen className={"icon"}/>,
                                text: "Mitgliedschaft"
                            }}/>
                        }


                        <NavItem child={{
                            link: "/kontakt",
                            icon: <FaFileInvoice className={"icon"}/>,
                            text: "Kontakt / Anfrage"
                        }}/>

                    </>
                }

                <SubNav name={"Kalender"} icon={<FaCalendarAlt className={"icon"}/>}
                        subs={[
                            {
                                link: "/kalender/monat",
                                icon: <FaCalendarAlt className={"icon"}/>,
                                text: "Kalender"
                            },
                            ...(checkPermissions(this.context, "events_read") || (user?.trainer && [Consts.CLUB_TV_STEYR].includes(user?.verein_ID))) ? [
                                {
                                    link: "/kalender/raum",
                                    icon: <FaBuilding className={"icon"}/>,
                                    text: "Raumplan"
                                }] : [],
                            {
                                link: "/kalender/export",
                                icon: <FaFileExport className={"icon"}/>,
                                text: "Exportieren"
                            }
                        ]
                        }/>

                {
                    (([1,3].includes(club.roomBookingViewMode) && user?.role >= 50) || club.roomBookingViewMode === 2|| (club.roomBookingViewMode === 3 && (user?.memberStatus===220 || user?.trainer))) && <SubNav
                        name={"Raumbuchungen"}
                        icon={<FaTableTennis className={"icon"}/>}
                        subs={[
                            user?.role >= 50 ? {
                                link: "/raum/preise",
                                icon: <FaCashRegister className={"icon"}/>,
                                text: "Raumpreise festlegen"
                            } : null,
                            {
                                link: "/raum/buchungen",
                                icon: <FaTable className={"icon"}/>,
                                text: "Raum buchen"
                            },
                        ]
                        }/>
                }

                {
                    checkPermissions(this.context, "accounting_read") &&
                    <>
                        <SubNav name={"Buchhaltung"}
                                icon={<FaMoneyBill className={"icon"}/>}
                                subs={[
                                    {
                                        link: "/buchhaltung/dashboard",
                                        icon: <FaChartBar className={"icon"}/>,
                                        text: "Dashboard"
                                    },
                                    {
                                        link: "/buchhaltung/geschaeftsjahr",
                                        icon: <FaCalendarAlt className={"icon"}/>,
                                        text: "Geschäftsjahre"
                                    },
                                    {
                                        link: "/buchhaltung/buchungen",
                                        icon: <FaList className={"icon"}/>,
                                        text: "Buchungen"
                                    },
                                    {
                                        link: "/buchhaltung/konten",
                                        icon: <FaListAlt className={"icon"}/>,
                                        text: "Konten"
                                    },
                                    {
                                        link: "/buchhaltung/berichte",
                                        icon: <FaFileAlt className={"icon"}/>,
                                        text: "Berichte"
                                    },
                                    {
                                        link: "/buchhaltung/bankimport",
                                        icon: <FaExchangeAlt className={"icon"}/>,
                                        text: "Bank Import"
                                    },
                                    (club?.paymentMethods || []).includes("sepm") ? {
                                        link: "/buchhaltung/bankexport",
                                        icon: <FaFileExport className={"icon"}/>,
                                        text: "Bank Export"
                                    } : null,
                                    {
                                        link: "/buchhaltung/zuordnung",
                                        icon: <FaList className={"icon"}/>,
                                        text: "Kontenzuordnung"
                                    },
                                    {
                                        link: "/buchhaltung/mitgliederzuordnung",
                                        icon: <FaNetworkWired className={"icon"}/>,
                                        text: "Personenzuordnung"
                                    },
                                    {
                                        link: "/zahlungen",
                                        icon: <FaCashRegister className={"icon"}/>,
                                        text: "Zahlungen"
                                    },
                                    /*
                                                                    {
                                                                        link: "/buchhaltung/export",
                                                                        icon: <FaFileInvoice className={"icon"}/>,
                                                                        text: "Steuerberater-Export"
                                                                    },
                                    */
                                ]}
                        />
                    </>
                }

                {
                    (checkPermissions(this.context, "accounting_read") || checkPermissions(this.context, "invoice_read")) &&
                    <SubNav
                        name={"Rechnungen"}
                        icon={<FaFileInvoice className={"icon"}/>}
                        subs={[
                            {
                                link: "/rechnungen",
                                icon: <FaFileInvoiceDollar className={"icon"}/>,
                                text: "Rechnungen"

                            },
                            {
                                link: "/rechnung/vorlagen",
                                icon: <FaFileInvoice className={"icon"}/>,
                                text: "Rechnungsvorlagen",
                            },
                        ]}/>
                }
                {
                    checkPermissions(this.context, "newsletter_read") &&
                    <>
                        <SubNav name={"Newsletter"}
                                icon={<FaEnvelope className={"icon"}/>}
                                subs={[

                                    {
                                        link: "/newsletter/vorlagen",
                                        icon: <FaEnvelope className={"icon"}/>,
                                        text: "Vorlagen",
                                    },
                                    {
                                        link: "/newsletter/sendungen",
                                        icon: <FaEnvelope className={"icon"}/>,
                                        text: "Sendungen",
                                    }
                                ]}/>
                    </>
                }
                {
                    checkPermissions(this.context, "page_blocks") &&
                    <NavItem child={{
                        link: "/formular/uebersicht",
                        icon: <FaAlignJustify className={"icon"}/>,
                        text: "Formulare"
                    }}/>
                }
                {
                    checkPermissions(this.context, "contacts_read") &&
                    <NavItem child={{
                        link: "/kontakte",
                        icon: <FaUserAlt className={"icon"}/>,
                        text: "Kontakte"
                    }}/>
                }
                {
                    checkPermissions(this.context, "vouchers_read") && ![Consts.CLUB_TV_SALZBURG].includes(user?.verein_ID) && (club?.paymentMethods || []).includes("voucher") &&
                    <SubNav name={"Gutscheine"}
                            icon={<FaTags className={"icon"}/>}
                            subs={[
                                {
                                    link: "/gutscheine",
                                    icon: <FaTable className={"icon"}/>,
                                    text: "Anzeigen",
                                },
                                {
                                    link: "/gutscheine/verkaufen",
                                    icon: <FaCashRegister className={"icon"}/>,
                                    text: "Gutschein verkaufen",
                                }
                            ]}/>
                }

                {
                    (user?.memberStatus > 4 || !!user?.trainer || user?.role > 20 || checkPermissions(this.context, "cloudstorage")) &&
                    <SubNav
                        name={"Cloudstorage"}
                        icon={<FaFileAlt className={"icon"}/>}
                        subs={[
                            {
                                link: "/cloudstorage/login",
                                icon: <FaFileAlt className={"icon"}/>,
                                text: "Cloudstorage"
                            },
                            {
                                link: "/cloudstorage/anleitung",
                                icon: <FaFileAlt className={"icon"}/>,
                                text: "App installieren"
                            }
                        ]}/>
                }
                {
                    checkPermissions(this.context, "faq") &&
                    <NavItem child={{
                        link: "/faq",
                        icon: <FaQuestion className={"icon"}/>,
                        text: "FAQ"
                    }}/>
                }
                <SubNav name={"System"}
                        icon={<FaCogs className={"icon"}/>}
                        subs={[
                            checkPermissions(this.context, "export_read") && {
                                link: "/export",
                                icon: <FaDownload className={"icon"}/>,
                                text: "Datenexport",
                            },
                            user?.role >= 20 && {
                                link: "/embed/konfigurator",
                                icon: <FaCode className={"icon"}/>,
                                text: "HTML Snippet Konfigurator"
                            },
                            user?.role >= 80 && {
                                link: "/migrieren/person",
                                icon: <FaBox className={"icon"}/>,
                                text: "Personendaten importieren",
                            },
                            checkPermissions(this.context, "") && ({
                                link: "/migrieren/kontenrahmen",
                                icon: <FaCalculator className={"icon"}/>,
                                text: "Kontenrahmen importieren",
                            }),
                            user?.role >= 20 && {
                                link: "/verein/konfiguration",
                                icon: <FaCogs className={"icon"}/>,
                                text: "Konfiguration",
                            },
                            user?.role >= 20 && {
                                link: "/berechtigungen",
                                icon: <FaLock className={"icon"}/>,
                                text: "Berechtigungen",
                            },
                            user?.role >= 20 && {
                                link: "/tags",
                                icon: <FaTags className={"icon"}/>,
                                text: "Tags verwalten",
                            },
                        ]}/>
                {/*
                <SubNav name={"Einstellungen"}
                        icon={<FaCogs className={"icon"}/>}
                        subs={[
                            {
                                link: "/mitgliedschaft/beitrag",
                                icon: <FaInfinity className={"icon"}/>,
                                text: "Mitgliedsbeitrag"
                            },
                            {
                                link: "/einstellungen/design",
                                icon: <FaCog className={"icon"}/>,
                                text: "Design"
                            }
                        ]}
                />
                */}


                <a href={"/benutzer/logout"}>
                    <FaSignOutAlt className={"icon"}/>
                    <span>Logout <span
                        style={{display: 'inline-block'}}>{user?.email?.substr(0, 40)}</span></span>
                </a>
                <MyModal trigger={<a>Meine VEREINfacht-Konten</a>}>
                    <VereinID/>
                </MyModal>
                <br/>
                <br/>


            </header>

            {/*pn.split("/").length > 1 && ["course", "my"].indexOf(pn.split("/")[1]) < 0 &&
            <div id={"behindnav"}/>
            */}
        </NavContext.Provider>
    }
}


const SubNav = ({name: name, subs, icon, sort = false}) => {
    const mySubs = useMemo(() => (sort ? subs.sort((a, b) => a.text < b.text ? -1 : 1) : subs).filter(a => !!a));
    const visitingSubchild = useMemo(() => mySubs.reduce((obj, curr) => obj || curr.link === window.location.pathname, false));

    const [navOpen, setOpen] = useState(localStorage.getItem("navOpen" + name) === "true" || visitingSubchild);
    const [redirectContainer, setRedirectContainer] = useState(null);
    useEffect(_ => {
        localStorage.setItem("navOpen" + name, navOpen)
    });

    if (mySubs.length === 0) {
        return null
    }

    let linkFirstSplit = mySubs[0].link.split("/");
    const pathnameSplit = window.location.pathname.split("/");


    let isCurrent = false;

    if (pathnameSplit.length > 1) {
        isCurrent = pathnameSplit[1] === linkFirstSplit[1]
    }

    const onClick = () => {
        let isCurrent = false;
        if (pathnameSplit.length > 1) {
            isCurrent = pathnameSplit[1] === linkFirstSplit[1]
        }
        setOpen(((!isCurrent && !navOpen) || isCurrent) ? true : !navOpen);
        setRedirectContainer(!isCurrent && !navOpen ? <Redirect to={mySubs.link}/> : null)
    };


    return <>
        <a onClick={onClick} className={isCurrent ? "current" : "noncurrent"}>
            {icon}
            <span>{name}</span>
        </a>
        {redirectContainer}
        {navOpen &&
            <em>
                {
                    mySubs.map((child) => {
                        return <NavItem key={"child" + child.text} parentOfSubnav child={child}/>
                    })
                }
            </em>
        }
    </>

};

const NavItem = ({child, parentOfSubnav, reloadRedir}) => {
    //const context = useContext(NavContext);
    let isCurrent = false;
    const {club} = useContext(UserContext);
    const pathnameSplit = decodeURI(window.location.pathname).split("/");
    const location = pathnameSplit.slice(0, 2 + 1).join("/");

    if (pathnameSplit.length > 1) {
        isCurrent = pathnameSplit[1] === child.link.split("/")[1]
    }
    if (parentOfSubnav && child.link !== location) {
        isCurrent = false;
    }
    useEffect(() => {
        if (isCurrent) {
            document.title = `${child.text} - ` + club.title
        }
    });
    const userContext = useContext(UserContext);
    //console.log(child.link,location)
    const onClick = () => {
        if (window.innerWidth < 1000 && userContext.navOpen) {
            userContext.navToggle()
        }
    };
    const NormalAnchor = ({children, to, ...props}) => <a href={to} {...props}>{children}</a>;
    const LinkNode = !!reloadRedir ? NormalAnchor : Link;
    return <LinkNode to={child.link !== undefined && child.link !== "" ? child.link : "#"} onClick={onClick}
                     className={isCurrent ? "current" : "noncurrent"}>
        {child.icon}
        <span>{child.text}</span>
    </LinkNode>

};
