import DatePicker, {ReactDatePicker} from "react-datepicker"
import React, {createRef, CSSProperties} from "react"
import {useFormContext} from "react-hook-form"
import {ErrorTooltip} from "../components/components"

interface DateInputParams {
    tag: string,
    name?: string | JSX.Element,
    disabled?: boolean
    error?: any
    style?: Partial<Record<"label" | "all", CSSProperties>>
    onChange?: (date: Date | null) => any
    justDate?: boolean
}

export function DateTimepickerUnixHook(props: DateInputParams) {
    return <DateTimepickerHookCore isUnix={true} {...props} />
}

export function DateTimepickerHook(props: DateInputParams) {
    return <DateTimepickerHookCore isUnix={false} {...props} />
}

function DateTimepickerHookCore({tag, name, disabled, error, style, onChange, isUnix, justDate}: DateInputParams & { isUnix: boolean }) {
    const form = useFormContext()

    const value = form.getValues(tag) ? (isUnix ? new Date(form.getValues(tag) * 1000) : new Date(form.getValues(tag))) : null

    const datepickerRef = createRef<ReactDatePicker>()

    const onChangeLocal = (date: Date | null) => {
        form.setValue(tag, isUnix ? (date ? date.getTime() / 1000 : null) : date)
        onChange && onChange(date)
    }

    return <>
        <label className={"dateTimeInput"} style={style?.all}>
            {name && <span style={style?.label}>{name}</span>}
            <DatePicker selected={value} className={error ? "error" : undefined} disabled={disabled} ref={datepickerRef}
                        locale="de" showTimeSelect timeFormat="HH:mm" timeIntervals={5} dateFormat={justDate ? "dd.MM.y" : "dd.MM.y HH:mm"}
                        timeCaption="Zeit" placeholderText={"Zeitpunkt auswählen"} portalId="react-datepicker-portal" onChange={onChangeLocal}
                        onKeyDown={(e: any) => {
                            if (e.key == 'Tab') {
                                setTimeout(() => datepickerRef.current?.setOpen(false), 100)
                            }
                        }}
            />
            {
                error && <ErrorTooltip>{error}</ErrorTooltip>
            }
        </label>
    </>
}

export type DateLike = Date | string | null

export function datelikeCompare(d1: DateLike, d2: DateLike) {
    return d1 && d2 ? (typeof d1 === "string" ? d1 : d1.toISOString()).localeCompare(typeof d2 === "string" ? d2 : d2.toISOString()) : (d2 ? -1 : -1)
}
