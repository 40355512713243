import React, {useContext, useEffect, useState} from "react"
import {FormContext, FormContextWrapper} from "../form_context"
import {maxiGet} from "../maxios"
import {encodeGetParams} from "../helpers"
import {TextfieldInput} from "./basic"
import {MaxBtn} from "../components/components"
import Status from "../status"
import consts from "../consts"
import {FaTrashAlt} from "react-icons/fa"
import {useStatusVar} from "../../club/club_inputs"
import {CoursePaymentGroup} from "../interfaces/core"
import {UserContext} from "../../user/UserContext"
import Consts from "../consts"

type DiscountProps = {
    course_ID?: number
    child_ID: number
    isMember?: boolean
    course_parts_ID?: number | null
    payment_group?: CoursePaymentGroup
    initialDiscountCode?: string
    readonly?: boolean
    hideCode?: boolean
    changeArray?: any[]
    onChange?: (amount: number) => any
}

type Discount = {
    absoluteAmount?: number
    amountRelative?: number
    appliesToMembershipFee: boolean
}

export function DiscountInput(props: DiscountProps) {
    const {course_ID, child_ID, isMember = false, readonly = false, hideCode = false, changeArray = [], course_parts_ID = null, payment_group, initialDiscountCode, onChange} = props
    const {club} = useContext(UserContext)
    const initialLocalDiscountCode = initialDiscountCode || ([Consts.CLUB_ARTANDDANCE_CENTER, Consts.CLUB_ARTANDDANCE_CONNECTION].includes(club.ID) ? "RABATT" : undefined)
    const [state, setState] = useState<{ discount?: Discount, discountCode?: string, discountAmount?: number, discountCodeSaved?: string, discountOnMembershipfee?: boolean, firstLoad?: boolean }>({discountCode: initialLocalDiscountCode, discountCodeSaved: initialLocalDiscountCode})
    const [{loading, error, success}, setStatusVar] = useStatusVar()
    const context = useContext(FormContext)

    useEffect(() => {
        if (typeof context.setState === "function") {
            context.setState(state)
        }
    }, [state])

    const verifyDiscountCode = () => {
        if ((state.discountCode?.length || 0) > 0) {
            setState(a => ({...a, discountAmount: undefined}))
            maxiGet("/discount/verify?" + encodeGetParams({course_ID, child_ID, price: payment_group?.plan?.map(plan => plan.price).reduce((a, b) => a + b, 0), code: state.discountCode}), {setStatusVar}).then(resp => {
                setState(a => ({...a, discount: resp, discountAmount: resp.amount, discountOnMembershipfee: resp.course_parts_ID !== null, discountCodeSaved: a.discountCode, firstLoad: true,}))
                onChange && onChange(parseInt(""+resp.amount))
            })
        }
    }

    useEffect(() => {
        if (!error) {
            verifyDiscountCode()
        }
    }, changeArray)

    const isCalculatedDiscount = (""+state.discountCode).toLowerCase() === "rabatt"
    const codeString = isCalculatedDiscount ? "Ein Rabatt" : <>Der Code "{state.discountCode}"</>

    if(isCalculatedDiscount && (state.discountAmount || -1) <= 0) {
        return null
    }

    return <>
        <p>
            <FormContextWrapper value={{state: state, setState: setState}}>
                {
                    !hideCode && <>
                        <TextfieldInput readOnly={readonly} tag={"discountCode"} name={"Rabattcode"} ph={"rabatt123"}/>
                        <MaxBtn onClick={verifyDiscountCode}>Prüfen</MaxBtn>
                    </>
                }
                <Status type={"error"} text={error}/>
                {state.firstLoad && (state.discountAmount === undefined || !isNaN(state.discountAmount)) && state.discountCode === state.discountCodeSaved && <>{(state.discountAmount || -1) >= 0 ?
                    <p><span
                        style={{color: "green"}}>{codeString} wird bei Anmeldung auf {state.discountOnMembershipfee && state.discount?.appliesToMembershipFee ? "den letzten vorgeschriebenen Mitgliedsbeitrag" : "den aktuellen Kurs"} eingelöst und hat einen Wert von {consts.moneyMax((state.discountAmount || 0) / 100)}.</span>
                    </p> :
                    <p><i>{codeString} wird bei Anmeldung bei diesem Kurs und <b>dabei abgeschlossener Mitgliedschaft beim Verein</b> auf den Mitgliedsbeitrag gutgeschrieben und hat einen Wert
                        von {state.discount?.absoluteAmount ? consts.moneyMax((state.discount?.amountRelative || 0) / 100) : (state.discount?.amountRelative || 0) / 100 + " %"}.</i>
                    </p>}
                    {!hideCode && <a style={{marginLeft: 10}} onClick={(() => setState(a => ({...a, discountCode: "", discountCodeSaved: "", discountAmount: undefined})))}><FaTrashAlt/> Löschen</a>}
                </>}
            </FormContextWrapper>
        </p>
    </>
}
