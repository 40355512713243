import React, {PropsWithChildren, useContext, useEffect, useMemo, useState} from "react"
import {Club, UserContext} from "./UserContext"
import {StatusVar} from "../club/club_inputs"
import {encodeGetParams, objGetEntries, pick, updateState} from "../core/helpers"
import {maxiGet, maxiPost} from "../core/maxios"
import {Redirect} from "react-router/index"
import UserView from "./user_view"
import {Container, InfoTooltip, Loader, MaxBtn, MiniBtn} from "../core/components/components"
import {FormContext} from "../core/form_context"
import Status from "../core/status"
import {CellParams, ColumnType, MyReactTable} from "../core/components/table"
import {Link} from "react-router-dom"
import onFilterChangeForUsers from "./onFilterChangeForUsers"
import {dateFormatFromYMD, dateFormatInline, dateFormatUnix, dateFormatUnixInline} from "../core/dateFuncs"
import {FaStickyNote, FaUndo} from "react-icons/all"
import AddNewPerson from "./user_add"
import {TextfieldInput} from "../core/input/basic"
import {DerivedDataObject} from "react-table"

import {LocalUser, SearchType, TableMappingType, UserGeneralListProps, UserTableState} from "./user_general_list.type"
import myDeepQual from "../core/myDeepEqual"
import useLocalStorage from "../core/hooks/useLocalStorageWithTimeout"
import {checkPermissions} from "../club/permissionsHelpers";


type UserGeneralListUserKeys = keyof LocalUser | ("erzber.lastname" | "erzber.prename" | "erzber.email" | "erzber.phone" | "lastConfirmed" | "lastConfirmedGroup")
type UserGeneralListUser = LocalUser & Record<UserGeneralListUserKeys, any>

const tableHeaderAccessorMapping: Partial<Record<UserGeneralListUserKeys, string>> = {
    phone: "Telefon",
    phoneAdd: "Telefon 2",
    locality: "Ort",
    route: "Straße",
    street_number: "Haus-Nr.",
    street_number_add: "Haus-Nr. Zusatz",
    address: "Adresse",
    country: "Land",
    titlesAnte: "Titel vorangestellt",
    titlesPost: "Titel nachgestellt",
    prename: "Vorname",
    lastname: "Nachname",
    memberSince: "Mitglied seit",
    note: "Notiz",
    internalnote: "Interne Notiz",
    saldo: "Saldo",
    "erzber.lastname": "EB Nachname",
    "erzber.prename": "EB Vorname",
    "erzber.email": "EB E-Mail",
    "erzber.phone": "EB Telefon",
    lastLogin: "Letzter Login",
    lastConfirmed: "Letzte Bestätigung der Daten",
    lastConfirmedGroup: "Letzte Bestätigung der Daten (Gruppe)",
}

export const defaultTableMapping: TableMappingType<keyof LocalUser> = {
    fullname: ({user}) => ({
        Header: "Name",
        filterable: true,
        key: "fullname",
        id: "fullname",
        accessor: (row) => row.fullname || row.name,
        Cell: row => <Link to={`/benutzer/${user.role > 20 ? "liste" : "view"}/` + row.original.ID}>{row.value}</Link>,
        filterMethod: () => true,
        timeout: 500,
    }),
    email: {
        Header: "E-Mail",
        key: "email",
        show: window.innerWidth > 1000,
        filterable: true,
        accessor: "email",
        filterMethod: () => true,
        Cell: ({value}) => <a href={"mailto:" + value}>{value}</a>,
        timeout: 500,
    },
    birthdate: {
        Header: "Geburtsdatum",
        key: "birthdate",
        filterable: true,
        accessor: "birthdate",
        maxWidth: 130,
        Cell: row => dateFormatFromYMD(row.value),
        pdfCell: row => dateFormatFromYMD(row.value),
    },
    postal_code: {
        Header: "PLZ",
        key: "plz",
        filterable: true,
        accessor: "postal_code",
        maxWidth: 90,
    },
    isVerwalter: {
        Header: "Verwalter",
        key: "isVerwalter",
        id: "isVerwalter",
        accessor: (row) => row.isVerwalter,
        show: window.innerWidth > 1000,
        filterable: true,
        filterType: "select",
        selectables: [{label: "ja", value: true}, {label: "nein", value: false}],
        Cell: ({original}) => original.isVerwalter ? "ja" : "nein",
        maxWidth: 130,
    },
    sex: {
        Header: "m/w",
        filterable: true,
        id: "sex",
        accessor: (row) => {
            return ({male: "m", fem: "w"} as Record<string, string>)[row.sex || ""] || row.sex
        },
        maxWidth: 60,
    },
    memberStatus: ({club}) => ({
        Header: "Status",
        accessor: r => {
            return (club?.memberStatesShort || {})[r.memberStatus || ""] || ""
        },
        id: "memberStatus",
        show: window.innerWidth > 1000,
        maxWidth: 90,
        filterable: true,
    }),
    ...(objGetEntries(tableHeaderAccessorMapping).reduce((prev, current) => {
        let Cell: (x: CellParams<UserGeneralListUser, any>) => any = (row) => row.value || null
        let pdfCell: (x: CellParams<UserGeneralListUser, any>) => any = (row) => row.value || null
        let accessor: string | ((x: UserGeneralListUser) => any) = current.key
        if (["memberSince", "lastLogin"].includes(current.key)) {
            Cell = (row) => dateFormatUnixInline(row.value)
            pdfCell = (row) => dateFormatUnix(row.value)
        } else if (["lastConfirmed", "lastConfirmedGroup"].includes(current.key)) {
            accessor = (u) => {
                const value = u[current.key]
                const date = new Date(value * 1000)
                /*if(isNaN(date.getTime()) || date.getTime() < new Date().getTime() - 1000 * 86400 * 1000) {
                    return "noch nie"
                }*/

                return isNaN(date.getTime()) ? 10000 : Math.floor((new Date().getTime() - date.getTime()) / 1000 / 86400)
            }
            Cell = (row) => {
                if (!Number.isInteger(row.value)) {
                    return row.value
                }
                if(row.value > 1000) {
                    return "noch nie"
                }
                return row.value + " Tage her"
            }
        }

        return {
            ...prev,
            [current.key]: {
                Header: current.value,
                key: current.key,
                id: current.key,
                filterable: true,
                maxWidth: 150,
                accessor,
                Cell,
                pdfCell,
            } as ColumnType<LocalUser>,
        }
    }, {})),
}

const serversideFilteredColumns = ["fullname", "email"]

export const UserGeneralList = (props: PropsWithChildren<UserGeneralListProps<keyof LocalUser>>) => {
    const {apiRoute, location, match, defaultShownTableColumns, customTableMapping, FormComponents, title, defaultLocalSearch, reloadOnSubmit, BeforeComponent} = props
    const userContext = useContext(UserContext)
    const {user, club} = userContext
    const [open, setOpen] = useState<number[]>([match?.params?.userID !== undefined ? parseInt(match?.params.userID) : 0],)
    const [{loading, error}, setStatusVar] = useState<StatusVar>({})

    // Search fields / state
    const [additionalFieldsLoadingCount, setAdditionalFieldsLoadingCount] = useState(0)
    const [search, setSearch] = useLocalStorage<SearchType>({search: {}}, props.tableName + "-search", 180, 0)
    const [localSearch, setLocalSearch] = useLocalStorage<SearchType>({search: defaultLocalSearch || {}}, props.tableName + "-localSearch", 180, 0)
    const [isLockedSearch, setIsLockedSearch] = useState(false)
    const [fieldsAlreadyLookedUp, setFieldsAlreadyLookedUp] = useState<string[]>([])
    const [lastSearchUrl, setLastSearchUrl] = useState("")

    const [shownColumns, setShownColumns] = useState<string[]>([])
    const [userIDs, setUserIDs] = useState<number[]>([])
    const [state, setState] = useState<UserTableState>({
        lastUpdate: 0,
        lastAdditionalFieldsUpdate: 30,
        tableData: [],
        tableDataRaw: [],
        loadedBigData: false,
        userMap: {},
        tableSize: 0
    })

    const isEditMode = () => (location?.pathname || "").substring(0, 14) === "/benutzer/profil"

    const getAdditionalFields = () => {
        let fields = shownColumns
        if (isLockedSearch) {
            return
        }
        fields = shownColumns.filter(x => !fieldsAlreadyLookedUp.includes(x))
        if (fields.length === 0) {
            return
        }
        setFieldsAlreadyLookedUp(al => ([...al, ...fields]))
        setAdditionalFieldsLoadingCount(a => a + 1)
        maxiPost<{ users: Record<number, LocalUser> }>("/user/filterFields", {fields: fields, user_ids: userIDs}, {setStatusVar}).then(resp => {
            setState(a => {
                const tableData = a.tableDataRaw.map(row => ({
                    ...row,
                    ...(resp.users[row.ID] || {})
                }))
                return {
                    ...a,
                    tableDataRaw: tableData,
                    tableData: tableData,
                }
            })

        }).finally(() => setAdditionalFieldsLoadingCount(a => a - 1))
    }

    const loadData = () => {
        //#"ID", "name","email", "birthdate"", "plz", "verwalter", "memberStatus"
        const s = {...pick(search.search || {}, props.frontendSearchAll ? [] : serversideFilteredColumns), ...(localSearch.search || {})}
        const url = apiRoute + (apiRoute.includes("?") ? (apiRoute.endsWith("&") ? "" : "&") : "?") + (isEditMode() ? "edit=" + open.join("") + "&" : "") + ("view=userstable&") + encodeGetParams(s)
        if (url === lastSearchUrl) {
            return
        }
        setIsLockedSearch(true)
        setFieldsAlreadyLookedUp([])
        setStatusVar({loading: true})
        setLastSearchUrl(url)
        maxiGet<{ users: LocalUser[] }>(url.endsWith("&") ? url.substring(0, url.length - 1) : url, {setStatusVar}).then((resp) => {
            const tableData = resp.users
            setUserIDs(resp.users.map(r => r.ID))
            if (resp.users.length > 0) {
                setFieldsAlreadyLookedUp(fields => ([
                    ...fields,
                    ...Object.keys(resp.users[0])
                ]))
            }
            setState(a => ({
                ...a, ...{
                    userIDs: userIDs,
                    userMap: tableData.reduce<Record<number, LocalUser>>((all, curr) => {
                        all[curr.ID] = curr
                        return all
                    }, {}),
                    tableSize: tableData.length,
                    tableData: tableData,
                    tableDataRaw: tableData,
                    loadedBigData: true,
                    lastUpdate: new Date().getTime(),
                    lastAdditionalFieldsUpdate: new Date().getTime(),
                }
            }))

            if (userIDs.length < 6) {
                setOpen(userIDs)
            }
            setIsLockedSearch(false)
        })
    }

    // Initialer Load der Benutzerdaten.
    useMemo(() => ((reloadOnSubmit !== true && props.initialLoad === undefined) || props.initialLoad) && loadData(), [])

    // Bei Änderung der Tabellensuchfilter
    useEffect(() => {
        loadData()
    }, [search])

    // Wenn die lokalen Suchfelder (also alle außerhalb der ReactTable) verändert werden
    useEffect(() => {
        if ((reloadOnSubmit !== true && props.initialLoad === undefined) && (objGetEntries(search.search).length > 0 || objGetEntries(localSearch.search).length > 0 || lastSearchUrl !== "")) {
            const timeout = setTimeout(loadData, 500)
            return () => clearTimeout(timeout)
        }
    }, [localSearch])


    // Wenn sich die angezeigten Spalten, die angezeigten userIDs oder die Suchsperre ändern, muss potenziell nachgeladen werden
    useEffect(() => {
        if (shownColumns.length > 0 && userIDs.length > 0) {
            getAdditionalFields()
        }
    }, [shownColumns, userIDs, isLockedSearch])

    // Wenn sich die angezeigten Spalten ändern, muss vielleicht auch die Suche reduziert werden (versteckte Spalten nicht mehr inkludieren).
    useEffect(() => {
        if (shownColumns.length > 0 && objGetEntries(search.search).filter(x => !shownColumns.includes(x.key)).length > 0) {
            setSearch(s => {
                return {search: objGetEntries(s.search).filter(x => shownColumns.includes(x.key)).reduce((prev, curr) => ({...prev, ...curr}), {})}
            })
        }
    }, [shownColumns])


    const handleChange = (e: any) => {
        if (!e?.target) {
            return
        }
        const s = e.target.name.split("_")
        if (s[1] === "olderthan" || s[1] === "youngerthan") {
            const boundLower = s[1] === "olderthan" ? parseInt(e.target.value) || 0 : localSearch.search.olderthan || 0
            const boundUpper = s[1] === "youngerthan" ? parseInt(e.target.value) || 150 : localSearch.search.youngerthan || 150

            const filtered = state.tableDataRaw.filter(u => u.age && boundLower <= u.age && u.age <= boundUpper)
            setLocalSearch(se => ({search: {...se.search, [s[1]]: e.target.type === 'checkbox' ? (e.target.checked ? 1 : 0) : e.target.value}}))
            setState(a => ({
                ...a,
                tableData: filtered,
                tableSize: filtered.length,
                tableIDsFiltered: [],
            }))
        } else {
            let value: any
            if (e.target?.type === "checkbox") {
                value = e.target.checked ? 1 : 0
            } else {
                value = e.target.value
            }
            setLocalSearch(se => ({search: {...se.search, [s[1]]: value}}))
        }
    }

    if (user === undefined) {
        return null
    }
    if (!user.verwalter && !checkPermissions(userContext, "user_read")) {
        return <Redirect to={"/benutzer/profil/" + user.ID}/>
    }

    const defaultShownColumns = (defaultShownTableColumns || ["fullname", "address", "email", "birthdate"])
    const usableColumns = {...defaultTableMapping, ...(customTableMapping || {})}
    const columns: ColumnType<LocalUser>[] = objGetEntries(usableColumns)
        .map(x => ({value: usableColumns[x.key], key: x.key}))
        .filter(x => !!x.value)
        .map(x => ({key: x.key, value: typeof x.value === "function" ? x.value(userContext) : x.value}))
        .map(x => ({...x.value, defaultHidden: !defaultShownColumns.includes(x.key)})) as ColumnType<LocalUser> []
    return <>
        {
            match?.params?.userID !== undefined && checkPermissions(userContext, "user_read") && <UserView {...props}/>
        }
        <Container name={title} visible className={checkPermissions(userContext, "user_read") ? "mobileBroad" : ""}>
            {state.tableSize !== undefined && <>{state.tableSize} Benutzer gefunden.<br/><br/></>}
            {
                checkPermissions(userContext, "user_read") &&
                <>
                    <FormContext.Provider value={{state: localSearch, setState: setLocalSearch, updateState: updateState<SearchType>(localSearch, setLocalSearch)}}>
                        <form onChange={handleChange} onSubmit={(e) => {
                            e.preventDefault()
                            if (reloadOnSubmit) {
                                loadData()
                            }
                        }}>
                            {BeforeComponent && <BeforeComponent/>}
                            {FormComponents && <FormComponents state={localSearch} setState={setLocalSearch} club={club} user={user} load={loadData}/>}
                        </form>
                    </FormContext.Provider>
                </>
            }

            <Status type="error" text={error}/>
            <Loader loading={loading || additionalFieldsLoadingCount > 0}/>
            {
                 checkPermissions(userContext, "user_read") ?
                    <MyReactTable
                        ignoreLocalStorageColumns={["lastConfirmedGroup"]}
                        key={"stay"}
                        data={state.tableData}
                        title={props.tableName}
                        exportData={true}
                        columns={columns}
                        loading={loading}
                        search={search.search}
                        columnsChange={(columns) => setShownColumns(columns.filter(x => !!x) as unknown as string[])}
                        getTrProps={props.getTrProps ? props.getTrProps({state, setState}) : undefined}
                        onFilteredChange={
                            (a, old, updateTableFilterMethod) =>
                                (onFilterChangeForUsers(a, old, function <T extends object>(newData: T) {
                                    if (!myDeepQual(
                                        objGetEntries(search.search).filter(x => !!x.value).sort((a, b) => a.key.localeCompare(b.key)),
                                        objGetEntries(newData).filter(x => !!x.value).sort((a, b) => (a.key as string).localeCompare(b.key as string))
                                    )) {
                                        setSearch((s) => ({
                                            search: {
                                                ...newData
                                            }
                                        }))
                                    }
                                }), props.frontendSearchAll ? ["email"] : serversideFilteredColumns, updateTableFilterMethod)
                        }
                        onTableChange={(data: DerivedDataObject[]) => {
                            setState(a => ({...a, tableSize: data.length}))
                            setUserIDs(data.map(u => u._original.ID))
                        }}
                        additionalButtonsRight={checkPermissions(userContext, "newsletter") ? <><MiniBtn>
                            <Link to={{
                                pathname: "/newsletter/vorlagen", state: {
                                    emailAddresses: state.tableData.filter(t => userIDs.indexOf(t.ID) > -1).map(t => {
                                        return ([t.email, t.ID, t.fullname || t.name])
                                    }),
                                }
                            }}>
                                <FaStickyNote/> Nachricht senden
                            </Link>
                        </MiniBtn>
                            <MiniBtn onClick={() => {
                                setSearch({search: {}})
                                setLocalSearch({search: defaultLocalSearch || {}})
                            }
                            }><FaUndo/> Suche zurücksetzen</MiniBtn>
                        </> : null}
                    /> :
                    <>
                        <PrintChildList allChilds={state.tableData} inactive={false} club={club}/>
                        <PrintChildList allChilds={state.tableData} inactive={true} club={club}/>
                    </>

            }

        </Container>

        {
            user.verwalter && !checkPermissions(userContext, "user_read") &&
            <>
                {
                    club.svnRequired && <VerwaltendenHinzu updateParent={loadData}/>
                }
                <AddNewPerson updateParent={loadData} childs={state.tableDataRaw}/>
            </>
        }
    </>
}

interface PrintChildListProps {
    allChilds: LocalUser[]
    club: Club
    inactive: boolean
}

function PrintChildList({allChilds, club, inactive = false}: PrintChildListProps) {
    const childs = allChilds
        .filter(a => inactive ? a.memberStatus === 0 : (a.memberStatus || 0) > 0)
        .sort((a, b) => (a.fullname || a.name || a.ID) < (b.fullname || b.name || b.ID) ? -1 : 1)

    if (childs.length === 0) {
        return null
    }

    return <>
        <h2>{club.noMembership ? "Personen" : (inactive ? "Personen ohne Mitgliedschaft" : "Mitglieder des " + club.fullNameDeclined)} in deiner Admingruppe</h2>
        <ul style={{lineHeight: "40px"}}>
            {
                childs.map(u => <PrintChild child={u} club={club}/>)
            }
        </ul>

    </>
}

interface PrintChildProps {
    child: LocalUser
    club: Club
}

function PrintChild({child, club}: PrintChildProps) {
    const memberState = club.memberStates[child.memberStatus || -1]
    return <li><Link to={`/benutzer/profil/` + child.ID}>{child.fullname}{memberState && ` (${memberState})`}, {dateFormatInline(child.birthdate)}</Link></li>
}

const VerwaltendenHinzu = ({updateParent}: { updateParent?: Function }) => {
    const [token, setToken] = useState("")
    const [{loading, error}, setStatusVar] = useState<StatusVar>({})
    const onChange = (e: any) => {
        setToken(e.target.value)
    }
    const onSubmit = (e: any) => {
        e.preventDefault()
        setStatusVar({loading: true})
        maxiPost("/user/add_allocation", {token}).then(() => {
            setToken("")
            updateParent && updateParent()
        })
    }

    return <Container name={"Benutzer hinzufügen"}>
        <Status type={"error"} text={error}/>
        <form onChange={onChange} onSubmit={onSubmit}>
            <TextfieldInput name={<span>Code oder SVN &nbsp;<InfoTooltip>Bitte den Code von dem Aktivierungs-E-Mail / Brief oder die Sozialversicherungs-Nummer der zu verwaltenden Person eingeben.</InfoTooltip></span>} tag={"token"}
                            state={{token}} ph={"4321010199"}/>
            <MaxBtn>Hinzufügen</MaxBtn>
            <Loader loading={loading}/>
        </form>
    </Container>
}
