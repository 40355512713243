import React, {CSSProperties, MouseEventHandler} from "react";

type BtnProps = {
    style?: CSSProperties
    children: any
    className?: string
    onClick?: MouseEventHandler<HTMLButtonElement>
}

type LoaderProps = {
    loading: boolean
}

const MaxBtn = ({
                    style = {}, children, className = "", onClick = () => {
    }
                }: BtnProps) => <button style={style} onClick={onClick} className={"maxbtn " + (className || "")}>{children}</button>;
const MiniBtn = ({
                     style = {}, children, className = "", onClick = () => {
    }
                 }: BtnProps) => <MaxBtn style={style} onClick={onClick} className={"mini " + (className || "")}>{children}</MaxBtn>;
const Loader = ({loading}: LoaderProps) => loading ? <img alt={"loader"} src="https://uid-suche.eu/src/img/ajax-loader-fff.gif"/> : null;

export {
    MaxBtn,
    MiniBtn,
    Loader,
};
