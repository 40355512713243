import React, {useCallback, useContext, useMemo, useState} from "react"
import {StatusVar} from "../../club/club_inputs"
import {UpdateContext} from "../../core/context/updateContext"
import {CoursePaymentGroup} from "../../core/interfaces/core"
import {FormProvider, useForm, useWatch} from "react-hook-form"
import {maxiGet} from "../../core/maxios"
import {LightContainer} from "../../core/components/container"
import {DateTimepickerHook} from "../../core/input/datehooks"
import {MaxBtn} from "../../core/components/components"
import {FaRecycle} from "react-icons/fa"
import {StatusGroup} from "../../core/status"
import {dateFormatTime} from "../../core/dateFuncs"
import {CoursePaymentPlanSelect} from "./CoursePaymentPlanSelect"
import {CoursePlanProps} from "./course_plan_edit"

export function CoursePaymentPlanSelectPreview({course_ID, model_ID}: CoursePlanProps) {
    const [statusVar, setStatusVar] = useState<StatusVar>({})
    const {lastUpdate} = useContext(UpdateContext)
    const [paymentGroups, setPaymentGroups] = useState<CoursePaymentGroup[]>([])
    const form = useForm({defaultValues: {timeViewed: new Date()}})
    const {timeViewed} = useWatch(form)
    const [currentLoadedTime, setCurrentLoadedTime] = useState<Date>()

    const reload = useCallback(() => {
        const time = Math.floor((timeViewed ? timeViewed.getTime() : new Date().getTime()) / 1000)
        maxiGet<{ data: CoursePaymentGroup[] }>(course_ID ? `/course/${course_ID}/paymentplan/active?time=${time}` : `/course/paymentmodels/${model_ID}/active?time=${time}`, {setStatusVar}).then(resp => {
            setPaymentGroups(resp.data)
            setCurrentLoadedTime(new Date(time))
        })
    }, [course_ID, timeViewed])
    useMemo(reload, [lastUpdate, course_ID, timeViewed])

    return <LightContainer name={"Vorschau (bitte zuerst speichern, um aktuelle Vorschau sehen zu können)"}>
        <form>
            <FormProvider {...form} >
                <DateTimepickerHook style={{label: {width: 300}}} tag={"timeViewed"} name={"Betrachtungszeitpunkt auswählen"}/>
            </FormProvider>
        </form>
        <StatusGroup {...statusVar} />
        {currentLoadedTime && <p>Vorschau für Zeitpunkt {dateFormatTime(currentLoadedTime)}</p>}
        {
            paymentGroups.length > 0 ?
                <CoursePaymentPlanSelect paymentGroups={paymentGroups}/> :
                <p>Es wurden keine Zahlungsweisen für diesen Zeitpunkt gefunden. Das bedeutet, dass im Produktivbetrieb keine zusätzlichen Zahlungsweisen angezeigt werden.</p>
        }
    </LightContainer>

}
