import {useAuth, User} from "./UserContext"
import {useClubTemplates} from "../core/hooks/useClubTemplates"
import React, {useEffect, useMemo, useState} from "react"
import {maxiGet, maxiPost} from "../core/maxios"
import {MyModal} from "../core/components/modal"
import {Container, InfoTooltip, Loader, MaxBtn} from "../core/components/components"
import Status from "../core/status"
import {FormContextWrapper} from "../core/form_context"
import {SelectfieldInput} from "../core/input/select"
import {dateFormatUnix} from "../core/dateFuncs"
import {RoleInput, useRoles} from "../core/input/selectSpecial"
import {CheckboxInput, InputContainer, TextareaInput} from "../core/input/basic"
import {DateInputUnix} from "../core/input/date"
import {PredefinedInsertButtonsEditorInput} from "../core/input/texteditor"
import {Link} from "react-router-dom"
import {PartsEdit} from "../course/course_parts_edit"
import {useStatusVar} from "../club/club_inputs"
import {renderHtmlWithPlaceholders} from "../core/helpers"

export const AdaptMembershipFee = ({roles, child, onClose}: { roles: { ID: number, role: number, timeEnd: number, timeStart: number }[], onClose: Function, child: User }) => {
    const {club} = useAuth()
    const clubRoles = useRoles()
    const templates = useClubTemplates()
    const [state, setState] = useState({data: {oldRoleID: undefined, newRole: undefined, sendEmail: false, emailText: club.ChildRoleChangedEmailText}})
    const [emailPreviewText, setEmailPreviewText] = useState("")
    const [{loading, error}, setStatusVar] = useStatusVar()
    const childID = child?.ID

    const isCancellation = !state.data.newRole || state.data.newRole === -1
    const emailPreviewData = {
        changeRole: !isCancellation,
        deleteRole: isCancellation,
        oldRole: clubRoles.filter(role => role.value === roles.filter(role => role.ID === state.data.oldRoleID)[0]?.role)[0]?.label || "BITTE ANGEBEN",
        newRole: clubRoles.filter(role => role.value === state.data.newRole)[0]?.label || "BITTE ANGEBEN",
        child: {fullname: child?.fullname || "-"},
    }

    useEffect(() => {
        setState((d) => ({data: {...d.data, emailText: renderHtmlWithPlaceholders(club.ChildRoleChangedEmailText || templates.defaultChildRoleChangedEmailText || "", emailPreviewData, false, [])}}))
    }, [templates.defaultChildRoleChangedEmailText, club.ChildRoleChangedEmailText, isCancellation, state.data.newRole, state.data.oldRoleID, child.fullname])

    const [participations, setParticipations] = useState<{ course: { ID: number, name: string }, part: { timeStart: number } }[]>([])
    const submitData = (close?: Function) => {
        if (!state.data.oldRoleID) {
            setStatusVar({error: "Eine alte Rolle muss ausgewählt werden (bitte sonst ''keine'' wählen)."})
        }
        setStatusVar({loading: true})
        maxiPost("/user/membershipfee/adapt", {...state.data, child_ID: childID, emailText: emailPreviewText}, {setStatusVar}).then(() => {
            window.location.reload()
            if (close) {
                close()
                onClose()
            }
        })
    }

    const loadVisitedCourses = () => {
        if (!childID) {
            return
        }
        maxiGet("/child/parts/" + childID, {setStatusVar}).then(resp => setParticipations(resp.parts))
    }

    useMemo(loadVisitedCourses, [childID])
    useMemo(() => setState((d) => ({data: {...d.data, oldRoleID: undefined, newRole: undefined}})), [roles])


    return <MyModal trigger={<MaxBtn className={"floatRight"}>Änderung der Mitgliedschaft</MaxBtn>}>
        {(close) =>
            <Container name={"Änderung der Mitgliedschaft"}>
                <Status type={"error"} text={error}/>

                <FormContextWrapper value={{state, setState}}>
                    <SelectfieldInput type={"reactselect"} tag={"data_oldRoleID"}
                                      selectives={[{label: "keine", value: -1}, ...roles.filter(x => x.timeEnd === 0).map(x => ({label: club.memberStates[x.role] + " - ab " + dateFormatUnix(x.timeStart), value: x.ID}))]}
                                      name={"Rolle, die beendet wird (optional)"} width={"100%"} labelWidth={500}/>
                    <RoleInput additionalRoles={[{label: "keine (Mitgliedschaft beenden)", value: -1}]} tag={"data_newRole"} name={"Rolle, die neu vergeben wird (optional)"} width={"100%"} labelWidth={400}/>
                    <InputContainer>
                        <DateInputUnix name={"Startdatum der neuen Rolle"} tag={"data_timeStart"} labelWidth={550} width={"100%"}/><br/>
                    </InputContainer>
                    <InputContainer>
                        <CheckboxInput name={<>
                            Mitgliedsbeitrag {isCancellation ? "stornieren" : "auf geänderte Rolle anpassen"} <InfoTooltip>Dadurch wird der Mitgliedsbeitrag der alten Rolle entfernt und neu berechnet, ob nun ein weiterer Mitgliedsbeitrag fällig ist / bzw. ob er storniert wird.</InfoTooltip>
                        </>} tag={"data_cancelMembershipfee"} labelWidth={550}/>
                    </InputContainer>
                    <TextareaInput name={"Bemerkung"} tag={"data_note"} width={"100%"}/>
                    <InputContainer>
                        <CheckboxInput tag={"data_sendEmail"} name={"E-Mail an Verwalter senden"}/>
                    </InputContainer>
                    <br/>
                    {
                        state.data.sendEmail && <>
                            <PredefinedInsertButtonsEditorInput showPreview buttonClasses={""} tag={"data_emailText"}
                                                                name={<b>E-Mail-Text</b>} previewData={emailPreviewData} onPreviewChange={(text: string) => setEmailPreviewText(text)}/>

                        </>
                    }
                    <br/>
                    <MaxBtn onClick={() => submitData(close)}>Anwenden</MaxBtn> <Loader loading={loading}/>
                </FormContextWrapper>

                {
                    participations.length > 0 && <>
                        <b>Noch nicht beendete Kursbuchungen</b>
                        <ul>
                            {
                                participations.map(({course, part}) => <li>
                                    <Link to={"/kurs/" + course.ID}>{course.name} (seit {dateFormatUnix(part.timeStart)})</Link>
                                    <PartsEdit original={{child: {ID: childID}, registration: part}} course={course} course_ID={course.ID} reload={loadVisitedCourses}/>
                                </li>)
                            }
                        </ul>
                    </>
                }
            </Container>
        }
    </MyModal>
}
