import {CourseShiftPlannerShiftEntry, CourseShiftPlannerShiftLocation} from "../../core/interfaces/core"
import React, {useContext, useMemo, useState} from "react"
import LocationStyle from "./CourseShiftPlannerLocation.module.sass"
import {LocationNameChangerModal, ShiftEntryChangerModal} from "./modals"
import {FaArrowCircleDown, FaArrowCircleUp, FaEdit, FaPlus} from "react-icons/fa"
import {dateFormatUnix} from "../../core/dateFuncs"
import {CourseShiftPlannerContext, LocalDispatcher} from "./CourseShiftPlanner"
import {CourseShiftPlannerEntry} from "./CourseShiftPlannerEntry"
import {v4 as uuidv4} from "uuid";
import {FaTrashAlt} from "react-icons/all";

export function CourseShiftPlannerLocation({location, onChange, onLocationChange, index, previous, next, onlyShowUnmetShifts}:
                                               { location: CourseShiftPlannerShiftLocation, onChange: LocalDispatcher<CourseShiftPlannerShiftLocation>, onLocationChange: Function, index: number, previous: CourseShiftPlannerShiftLocation, next: CourseShiftPlannerShiftLocation ,onlyShowUnmetShifts: boolean}) {
    const {isEditable} = useContext(CourseShiftPlannerContext)

    const [appendedEntry, setAppendedEntry] = useState<CourseShiftPlannerShiftEntry | null>(null)

    const allOnSameDay = useMemo(() => {
        const beginningsOfDay = location.shifts.map(shift => {
            const date = new Date(shift.dateFrom * 1000)
            date.setHours(0, 0, 0, 0)
            return date.getTime()
        })
        return Math.max(...beginningsOfDay) === Math.min(...beginningsOfDay)
    }, [location.shifts.map(shift => shift.dateFrom)])


    const preferredStartTime = new Date()
    preferredStartTime.setHours(8, 0, 0, 0)
    preferredStartTime.setDate(preferredStartTime.getDate() + 7)

    const onShiftEntryChange = (index: number) => (shift: CourseShiftPlannerShiftEntry) => onChange({
        ...location,
        shifts: [...location.shifts.slice(0, index), shift, ...location.shifts.slice(index + 1)]
    })
    const appendEntry = () => {
        const entry = {ID: uuidv4(), name: "", dateFrom: preferredStartTime.getTime() / 1000, dateTo: preferredStartTime.getTime() / 1000 + 3600 * 4, deleted: false}
        /*onChange(
            {
                ...location,
                shifts: [...location.shifts, entry]
            })*/
        setAppendedEntry(entry)
    }
    const updateAppendedEntry = (edited: CourseShiftPlannerShiftEntry) => {
        if (!!edited.name) {
            onShiftEntryChange(location.shifts.length)(edited)
        }
        setAppendedEntry(null)
    }

    const sortableShifts = location.shifts.map(shift => ({...shift, id: shift.ID}))

    if (location.deleted) {
        return null
    }

    return <div className={LocationStyle.location + " " + (location.deleted ? (isEditable ? LocationStyle.deletedadmin : LocationStyle.deleted) : "")}>
        <div className={LocationStyle.header}>
            <div className={LocationStyle.headerText}>
                <b>{location.name}</b>
                {allOnSameDay && location.shifts.length > 0 && <>, {dateFormatUnix(location.shifts[0].dateFrom)}</>}
            </div>
            &nbsp;
            {isEditable && <div className={LocationStyle.options}>
                {
                    previous && <FaArrowCircleUp style={{cursor: "pointer"}} onClick={() => {
                        onLocationChange(index - 1)(location)
                        onLocationChange(index)(previous)
                    }}/>
                }&nbsp;
                {
                    next && <FaArrowCircleDown style={{cursor: "pointer"}} onClick={() => {
                        onLocationChange(index + 1)(location)
                        onLocationChange(index)(next)
                    }}/>
                }&nbsp;
                {
                    isEditable && <><FaPlus style={{cursor: "pointer"}} onClick={appendEntry}/>&nbsp;</>
                }
                <LocationNameChangerModal trigger={<FaEdit title={"Bearbeiten"}/>} onFinish={(edited: CourseShiftPlannerShiftLocation) => onChange({...location, name: edited.name})} data={location}/>
                <div className={LocationStyle.inline + " " + LocationStyle.pointer} onClick={() => {
                    //@ts-ignore
                    window.confirm("Wirklich löschen?") && onChange(null)
                }}>{<FaTrashAlt/>}</div>
            </div>}
        </div>
        {appendedEntry && <ShiftEntryChangerModal key={appendedEntry.ID} onFinish={updateAppendedEntry} data={appendedEntry}/>}
        <div className={LocationStyle.body}>
            {
                location.shifts.length > 0 ? <>
                        <div className={LocationStyle.entries}>
                            {sortableShifts.map((shift, index) => <CourseShiftPlannerEntry key={shift.ID} showDate={!allOnSameDay} {...{onlyShowUnmetShifts}} onChange={onShiftEntryChange(index)} entry={shift}/>)}
                        </div>
                    </> :
                    <div>Bisher keine Schichten erfasst.</div>
            }
        </div>
    </div>
}
