import React, {useContext} from "react";
import {UserContext} from "./UserContext";
import {CheckboxInput, InputContainer, TextareaInput, TextfieldInput} from "../core/input/basic";
import {DateInput} from "../core/input/date";
import {FileUpload} from "../core/input/fileupload";
import {SelectfieldInput} from "../core/input/select";
import {checkPermissions} from "../club/permissionsHelpers";


// t shirt size: [{"type": "select", "label": "T-Shirt-Gr\u00f6\u00dfe", "editable": "nonRegisterWrite", "props": {"selectives": ["S","M","L","XL","XXL"]}, "showIfNonExistent": 1, "tag": "T-Shirt-Gr\u00f6\u00dfe", "touched": true}]

function Field({context, field, registerMode}) {
    const admin = checkPermissions(context, "user/data")
    const editable = admin || (registerMode && field.editable === "registerWrite") || (field.editable === "write" || field.editable==="nonRegisterWrite");
    if (!editable && !["registerWrite", "read"].includes(field.editable) && !field.showInAMS) {
        return null;
    }

    const myProps = {...(field.props || {}), ...{name: `${field.label || ""} ${field.info?.props?.children || field.info?.props || field.info || ""}`, tag: "data_" + field.tag, readOnly: !editable}}

    switch (field.type) {
        case "select":
            return <InputContainer><SelectfieldInput {...myProps}/></InputContainer>;
        case "file":
            return <FileUpload {...{...myProps, prefix: "data_uploads"}}/>;
        case "textarea":
            return <InputContainer><TextareaInput {...myProps}/></InputContainer>;
        case "checkbox":
            return <InputContainer><CheckboxInput {...myProps}/></InputContainer>;
        case "date":
            return <InputContainer><DateInput {...myProps}/></InputContainer>;
        default:
            return <InputContainer><TextfieldInput {...myProps}/></InputContainer>;
    }


}

export function AdditionalFields({registerMode}) {
    const context = useContext(UserContext);

    return <div>

        {
            ((context?.club || {}).additionalFields || [])?.map(field => <Field {...{context, field, registerMode}}/>)
        }

    </div>
}
