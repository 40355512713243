import React, {useContext, useEffect, useMemo, useState} from "react";
import {FormContext} from "../form_context";
import {Editor} from "@tinymce/tinymce-react";
import {UserContext} from "../../user/UserContext";
import {renderHtmlWithPlaceholders} from "../helpers";

export class EditorInput extends React.Component {
    tinymcKey = "5yqoi0ysxzwd3182o8lhp0kuoaixer0cvelp8elcvg3v1wz3";
    static contextType = FormContext;

    editorChange = (content) => {
        const s = this.props.tag.split("_");

        if (s.length >= 2) {
            this.context.setState(a => ({
                ...a,
                [s[0]]: Object.assign({}, this.context.state[s[0]], {
                    [s[1]]: content
                })
            }))
        } else {
            this.context.setState(a => ({
                ...a,
                [s[0]]: content,
            }))
        }
    };

    render() {
        const s = this.props.tag.split("_");
        return <div style={{marginBottom: "20px"}}>
            <div style={{marginBottom: "10px"}}>{this.props.name}</div>
            <Editor tinymceScriptSrc={"/static/tinymce/tinymce.js"} style={this.props.style} value={(this.context.state[s[0]] || {})[s[1]] ?? ""} onEditorChange={this.editorChange} init={{
                language: "de",
                language_url: '/static/tinymce_de.js',
                plugins: 'lists table image media link autoresize fullscreen code',
                mediaembed_max_width: 450,
                images_upload_url: "/api/page_blocks/photo/upload",
                toolbar_mode: 'floating',
                removed_menuitems: "newdocument",
                toolbar: "undo redo | styleselect | bold italic underline | colorpicker | link image | numlist bullist | code | fullscreen ",
                ...(this.props.inits || []),
                setup: this.props.setup
            }} disabled={this.props.disabled}/>

        </div>
    }
}

// toolbarButtons = [{text: "...", name: "...", content: "...", icon: ..., }, {...}, ...]
const ConfigurableEditorInput = ({toolbarButtons, showPreview, previewData = {}, partials, tag, onPreviewChange, defaultText, ...props}) => {
    const [previewOn, setPreviewOn] = useState(false)
    const form = useContext(FormContext)
    const {club, user} = useContext(UserContext)
    const s = tag.split("_")
    const value = s.length === 1 ? form.state[s[0]] : (form.state[s[0]] || {})[s[1]]

    const preview = useMemo(() => {
        return {
            plain: renderHtmlWithPlaceholders(value || "", {club: club, user: user, ...previewData}, false, partials),
            div: renderHtmlWithPlaceholders(value || "", {club: club, user: user, ...previewData}, partials),
        }
    }, [club, value, user, JSON.stringify(previewData)])
    useEffect(() => {
        if (preview) {
            onPreviewChange && onPreviewChange(preview.plain)
        }
    }, [preview]);

    const setup = (editor) => {
        [...(toolbarButtons || [])].forEach(x => {
            editor.ui.registry.addButton(x.name, {
                icon: x.icon,
                tooltip: x.text,
                text: x.text,
                onAction: () => {
                    editor.insertContent(x.content)
                }
            })
        })
        if (showPreview) {
            editor.ui.registry.addButton("preview", {
                icon: "search",
                tooltip: "Vorschau anzeigen",
                text: "Vorschau",
                onAction: () => {
                    setPreviewOn(x => !x)
                }
            })
        }
        if (!!defaultText) {
            editor.ui.registry.addButton("defaultText", {
                icon: "restore-draft",
                tooltip: "Standardtext einfügen",
                text: "Standardtext",
                onAction: () => {
                    editor.setContent(defaultText || "")
                }
            })
        }
    }

    return <div style={{display: "grid", gridTemplateColumns: previewOn ? "1fr 1fr" : "1fr", gap: "20px"}}>
        <div>
            <EditorInput {...props} setup={setup} tag={tag}
                         inits={{toolbar1: "undo redo | styleselect | bold italic underline | colorpicker | link image | numlist bullist | fullscreen | " + (toolbarButtons || []).map(x => x.name).join(" | ") + " | defaultText | preview |"}}/>
        </div>
        {previewOn && <>
            <div><h2>Vorschau mit Platzhaltern</h2>
                {preview.div}
            </div>
        </>}
    </div>
}

const PredefinedInsertButtonsEditorInput = ({buttonClasses = "", toolbarButtons = [], ...props}) => {
    const toolbarButtonsNew = (Array.isArray(buttonClasses) ? buttonClasses : [buttonClasses]).map(x => {
        switch (x) {
            case "club.msFees":
                return [
                    {text: "Mitgliedsbeitrag normal", name: "club.msFees.normal", content: "{{club.msFees.normal}}"},
                    {text: "MB Jugend", name: "club.msFees.junior", content: "{{club.msFees.junior}}"},
                    {text: "MB Senioren", name: "club.msFees.senior", content: "{{club.msFees.senior}}"},
                    {text: "MB unterstützend", name: "club.msFees.supporter", content: "{{club.msFees.supporter}}"},
                ]
            case "club":
                return [
                    {text: "Vereinsname (lang)", name: "club.name", content: "{{club.name}}"},
                    {text: "Vereinsname (kurz)", name: "club.shortname", content: "{{club.shortname}}"},
                ]
            case "child":
                return [
                    {text: "Anrede + Vorname", name: "child.anrede", content: "{{Anrede}}"},
                    ...["Kreditkarte", "Sofort", "Sepa"].map(x => ({
                        text: x, name: x, content: `{{#if ${x}}}{{else}}{{/if}}`
                    })),
                    {text: "Bank", name: "NurBank", content: `{{#if NurBank}}{{else}}{{/if}}`}
                ]
            default:
                return []
        }
    })
    return <ConfigurableEditorInput toolbarButtons={[...(toolbarButtons || []), ...toolbarButtonsNew.reduce((arr, next) => [...arr, ...next], [])]} {...props} />
}

export {ConfigurableEditorInput, PredefinedInsertButtonsEditorInput}
