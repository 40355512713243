export default function myDeepQual(a, b, depth = 0, print = false) {
    if (depth > 5) {
        return true;
    }
    try {

        if (typeof a !== typeof b) {
            //console.log("different types")
            return false
        }

        if (typeof a === "function") {
            //console.log("same function")
            return a.toString() === b.toString();
        }
        if (a === null && b === null) {
            return true
        }
        if (a === null || b === null) {
            return false;
        }
        if (typeof a === "object") {
            for (let i = 0; i < Object.keys({...a, ...(b || {})}).length; i++) {
                if (Object.keys(a)[i] !== Object.keys(b)[i]) {
                    //console.log("differnt keys")
                    return false
                }
                if (!myDeepQual(Object.values(a)[i], Object.values(b)[i], depth + 1)) {
                    return false
                }
            }
            //console.log("same", a, b)
            return true
        }
        if (a !== b) {
            //console.log("differnt values", a, b, typeof a === "object")
            return false
        }
    } catch (e) {
        console.log(e)
        console.trace()
        return false;
    }
    return true;
}
