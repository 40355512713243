import {useEffect, useState} from "react"
import {maxiGet} from "../maxios"

export function usePaymentPlan(course_ID: number) {
    const [paymentPlan, setPaymentPlan] = useState([])

    useEffect(() => {
        maxiGet("/course/participate/" + course_ID).then(resp => {
            setPaymentPlan(resp.course?.paymentPlan || [])
        })
    }, [course_ID])
    return paymentPlan
}
