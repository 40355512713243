import React from 'react';
//import {CheckboxInput, Container, InputContainer, Loader, MaxBtn, MyModal, SelectfieldInput, TextareaInput, TextfieldInput} from "../core/primitives";
import {Container, Loader, MaxBtn} from "../core/components/components";
import {MyModal} from "../core/components/modal";
import {CheckboxInput, InputContainer, TextareaInput, TextfieldInput} from "../core/input/basic";
import {SelectfieldInput} from "../core/input/select";

import {apiGet, apiPost} from "../core/api";
import Login from "./login";
import {FormContextWrapper} from "../core/form_context";
import Consts from "../core/consts";
import "./register.sass"
import Status from "../core/status";
import ChangeAddressExt from "./address_input";

import "./login.sass"
import {FaCheckCircle, FaInfoCircle} from "react-icons/fa";
import {coronaMessageExists, RegisterAusserordentlichesMitglied, RegisterDisclaimer, RegisterOrdentlichesMitglied, RegisterUnterstützendesMitglied} from "./registerDisclaimers";
import DatePicker from "react-datepicker";
import {AdditionalFields} from "./user_additionalFields";
import {UserContext} from "./UserContext";
import {getAge} from "../buchhaltung/helper";

/*function get_param(search, param) {
    const t = search.substr(1);
    const re = new RegExp(param + "=(.*?)(&|$)");
    const m = t.match(re);
    if (m != null && m.length === 3) {
        return m[1]
    }
    return null
}*/
Number.prototype.pad = function (size) {
    var s = String(this);
    while (s.length < (size || 2)) {
        s = "0" + s;
    }
    return s;
}
const today = new Date();
export default class Register extends React.Component {
    static contextType = UserContext

    constructor(props) {
        super(props)
        this.apiGet = apiGet.bind(this)
        this.apiPost = apiPost.bind(this)
        this.setState = this.setState.bind(this)
        this.apiGet("/user/status", resp => this.setState(resp));
        this.course_ID = props.match ? parseInt(props.match.params.course_ID) : NaN;
        this.redirUrl = "/kurs" + (!isNaN(this.course_ID) ? "e/" + this.course_ID : "/buchung");
        this.state = {
            action: this.props.location.pathname === "/aktivieren" ? "activate" : "register",
            user_ids: [],
            new: {
                token: Math.round(Math.random() * 1e8),
                member: 0,
                country: Consts.f("AT", "AT"),
                ...props.location.search.replace("?", "").split("&").filter(a => !!a).map(a => a.split("=")).reduce((obj, [key, val]) => ({...obj, [key]: decodeURIComponent(val)}), {}),
            },
            activate: {},
            parent: {
                country: "AT",
            },
        };
        if (localStorage.getItem("prefill_form_data")) {
            console.log("prefill_form", tmp)
            let tmp = JSON.parse(localStorage.getItem("prefill_form_data"))
            if (!tmp.parent) {
                tmp.parent = {}
            }
            this.state = tmp
        }
    }

    checkPhone = (e) => {
        const s = e.target.name.split("_");
        const val = this.state[s[0]][s[1]];
        if (s[1].startsWith("phone")) {
            const newVal = Consts.phoneNormalise(val)
            if (newVal !== val) {
                this.setState({[s[0]]: {...this.state[s[0]], [s[1]]: newVal}})
                //console.log(val, newVal)
            }
        }
        if (s[1] === "email") {
            this.apiPost("/user/address/email", {email: val}, resp => {
                if (resp.email === (this.state[s[0]] || {}).email) {
                    this.setState({[s[0]]: {...this.state[s[0]], emailValid: resp.valid}})
                }
            })
        }
    };

    handleSubmit = (e) => {
        e.preventDefault();
        if (coronaMessageExists(this.context.club) && this.state.new.covid19 !== 1) {
            this.setState({error: "Bitte zuerst die Kenntnisnahme der Covid 19 Hinweise bestätigen!"});
            return;
        }
        if (this.state.new.approved !== 1) {
            this.setState({error: "Bitte zuerst die Datenschutzerklärung akzeptieren!"})
            return;
        }
        if (this.state.parent.acceptResp !== 1 && (getAge(this.state.new) || 18) < 18) {
            this.setState({error: "Bitte zuerst akzeptieren, dass Sie verantwortlich sind."})
            return;
        }
        if (this.state.parent.supportingMember === 1 && this.state.parent.member === 1) {
            this.setState({error: "Du kannst nicht gleichzeitig ordentliches und unterstützendes Mitglied werden!"})
            return;
        }
        if (this.state.new.supportingMember === 1 && this.state.new.member === 1) {
            this.setState({error: "Du kannst nicht gleichzeitig ordentliches und unterstützendes Mitglied werden!"})
            return;
        }
        if (this.state.loading) {
            return;
        }
        this.setState({loading: true});
        if (this.state.action === "register") {
            this.apiPost("/user/register", this.state, resp => this.setState({loading: false, action: "finish"}));
        } else if (this.state.action === "activate_register") {
            this.apiPost("/user/register?activate", this.state, resp => this.setState({loading: false, action: "finish"}));
        } else if (this.state.action === "activate") {
            this.apiPost("/user/activate", this.state.activate, resp => this.setState({loading: false, action: "finish"}), false, error => {
                if (error.includes("Ein gesetzlicher Vertreter muss")) {
                    alert(error)
                    this.setState({action: "activate_register", error: ""})
                    window.setTimeout(() => this.setState({error: ""}), 200)
                }
            });
        }
    };

    render() {
        const {user, club} = this.context
        if (club.ID === undefined || club.ID === null) {
            return <Loader loading/>
        }

        if (this.state.status === "logged_in") {
            window.location.href = this.redirUrl
        }
        if (club?.domain === "mein.vereinfacht.at") {
            return <Container name={"Fehler aufgetreten"}>
                Leider konnte dein Verein nicht ermittelt werden. Bitte überprüfe Tippfehler im eingebenen Link oder wende dich mit dieser Nachricht an die Vereinsverwaltung!
            </Container>
        }
        //console.log(this.state)
        const child = this.state.new;
        const parent = this.state.parent;
        const id = "new";
        const idParent = "parent";
        const age = getAge(child, child.country === "AT" && club.svnRequired)
        //console.log("age", age, birthdate)

        return <>
            {
                this.state.action !== "finish" &&
                <Container name={club?.fullName} ID={"registerHeader"}>

                    <table>
                        <tr>
                            <td className={this.state.action === "register" ? "active" : ""} onClick={() => this.setState({action: "register"})}>
                                <MaxBtn>
                                    <h2>Neu registrieren</h2>
                                    Möchtest du dich neu registrieren, da du keinen Zugang zur online Verwaltungs-Software, also kein E-Mail und keinen Brief mit Hinweisen zur Aktivierung des Accounts hast?
                                </MaxBtn>
                            </td>
                            {
                                ![Consts.CLUB_ITV_INNSBRUCK].includes(club?.verein_ID) && <td className={this.state.action === "activate" ? "active" : ""} onClick={() => this.setState({action: "activate"})}>
                                    <MaxBtn>
                                        <h2>Aktivieren</h2>
                                        Du hast keinen Benutzeraccount, jedoch einen Code zur Aktivierung zugeschickt bekommen?
                                    </MaxBtn>
                                </td>
                            }
                            <td className={this.state.action === "login" ? "active" : ""} onClick={() => this.setState({action: "login"})}>
                                <MaxBtn>
                                    <h2>Anmelden</h2>
                                    Du hast bereits einen Benutzeraccount?
                                </MaxBtn>
                            </td>
                        </tr>
                    </table>
                </Container>
            }


            <FormContextWrapper value={{state: this.state, setState: this.setState}} onSubmit={this.handleSubmit}>
                {
                    (this.state.action === "register" || this.state.action === "add") &&
                    <>
                        <Container name={"Neu registrieren" + (age !== null && age < 18 ? " - Daten des Kindes" : "")}>
                            {
                                !club?.noMembership && <div style={([Consts.CLUB_TV_MONDSEE, Consts.CLUB_ITV_INNSBRUCK, Consts.CLUB_OETB_LINZ].includes(club?.verein_ID) ? {color: "red"} : {})}>
                                    Wenn du DEIN KIND neu bei uns anmelden möchtest, dann gib hier die <b>Daten deines Kindes</b> ein.<br/>
                                    Wenn DU neu bei uns Mitglied werden möchtest, dann gib hier <b>deine Daten</b> ein!<br/>
                                </div>
                            }
                            <br/>
                            <InputContainer style={{display: "block"}}>
                                <SelectfieldInput name={"Geschlecht"} tag={id + "_sex"} selectives={[["fem", "Weiblich"], ["male", "Männlich"],]} demandSelect/>
                            </InputContainer>
                            {
                                ["titlesAnte", "titlesPost", "prename", "lastname", "email", "phone", "Notfallnummer", "address", "svn", "birthdate", "note",]
                                    .map((k, i) => {
                                        if (k === "address") {
                                            return <div>
                                                <ChangeAddressExt key={"child"} child={child} changeAddress={a => this.setState({[id]: {...child, ...a}})}/>
                                                {i % 2 === 1 && <br/>}
                                            </div>
                                        }

                                        if (k === "Notfallnummer") {
                                            if (age === null || age >= 18) {
                                                return null
                                            }
                                            return <InputContainer>
                                                <TextfieldInput name={"Notfallnummer"} tag={id + "_phoneAdd"}/>
                                                {(i % 2 === 1) && <br/>}
                                            </InputContainer>
                                        }
                                        if (k === "note" || k === "internalnote") {
                                            return <InputContainer className={"textarea"}>
                                                <TextareaInput name={<>Gesundheitliche Einschränkungen und Hinweise (optional)
                                                    &nbsp;
                                                    <MyModal trigger={<FaInfoCircle/>} on={"hover"}>
                                                        <h2>Gesundheitliche Einschränkungen und Hinweise (optional)</h2>
                                                        Um noch besser auf die Bedürfnisse der Kinder eingehen zu können bzw. im Notfall richtig gehandelt wird, ist es von großem Vorteil, wenn der
                                                        Trainer über Gesundheitliche Einschränkungen Bescheid
                                                        weiß bzw. Hinweise dazu angegeben werden. Weiters werden zu besonderen Anlässen (Weihnachten, Fasching, letzte Einheit usw.) oftmals eine
                                                        Kleinigkeit zu essen ausgegeben, deshalb bitte Allergien
                                                        und Unverträglichkeiten angeben.<br/>
                                                        Mit der Bekanntgabe erklärt sich der gesetzliche Vertreter mit der Verarbeitung und Weitergabe an die Trainer einverstanden. Art. 6 Abs. 1 lit.
                                                        D DSVGO: „Schutz lebenswichtiger Interessen“
                                                    </MyModal>
                                                </>
                                                } tag={id + "_" + k}/>
                                            </InputContainer>
                                        }

                                        if (typeof child[k] === "boolean") {
                                            return <InputContainer>
                                                <CheckboxInput name={k[0].toUpperCase() + k.substr(1)} tag={id + "_" + k}/>
                                                {i % 2 === 1 && <br/>}
                                            </InputContainer>
                                        }
                                        if (k === "svn") {
                                            if (child.country !== "AT" || !club.svnRequired) {
                                                return;
                                            }
                                            return [<InputContainer>
                                                <TextfieldInput key={"textfieldinput" + id} name={<>
                                                    {Consts.translate(k)}
                                                    &nbsp;
                                                    <MyModal trigger={<FaInfoCircle/>} on={"hover"}>
                                                        <h2>Sozialversicherungsnummer</h2>
                                                        Die Angabe der Sozialversicherungsnummer dient lediglich zur eindeutigen Identifikation von Personen und wird in unserem System verschlüsselt
                                                        abgespeichert, somit hat darauf niemand Zugriff.
                                                    </MyModal>
                                                </>} tag={id + "_" + k} ph={Consts.placeHolders[k]}/>

                                            </InputContainer>,
                                                <br/>]
                                        }

                                        if (k === "birthdate") {
                                            if (child.country === "AT" && club.svnRequired) {
                                                return;
                                            }
                                            let d;
                                            if (child.birthdate > 0) {
                                                let date = `${child[k]}`;
                                                let date_string = `${date.substr(0, 4)}-${date.substr(4, 2)}-${date.substr(6, 2)}`;
                                                d = new Date(date_string);
                                            } else {
                                                //d = new Date()
                                            }

                                            return [<InputContainer>
                                                <label key={"gbrtst" + id}><span>Geburtsdatum</span>
                                                    <DatePicker selected={d}
                                                                locale={"de"}
                                                                dateFormat={"dd.MM.yyyy"}
                                                                placeholderText={"00.00.0000"}
                                                                showYearDropdown
                                                                peekNextMonth
                                                                showMonthDropdown
                                                                maxDate={new Date()}
                                                                dropdownMode="select"
                                                                onChange={(date) => {
                                                                    if (date) {
                                                                        let datemy = date.getFullYear() * 10000 + (date.getMonth() + 1) * 100 + date.getDate()
                                                                        this.setState({[id]: Object.assign({}, this.state[id], {birthdate: datemy})})
                                                                    }
                                                                }
                                                                }/></label>
                                            </InputContainer>, <br/>];
                                        }
                                        if (k === "email") {
                                            return <InputContainer>
                                                <TextfieldInput key={"textfieldinput" + id}
                                                                onBlur={this.checkPhone}
                                                                inputBorderColor={child.emailValid === false ? "red" : null}
                                                                name={<>{Consts.translate(k)} {child.emailValid === true && <FaCheckCircle/>}{child.emailValid === false && "(falsch)"}</>}
                                                                tag={id + "_" + k}
                                                                ph={Consts.placeHolders[k]}
                                                />
                                                {(i % 2 === 1) && <br/>}
                                            </InputContainer>
                                        }
                                        return <InputContainer>
                                            <TextfieldInput key={"textfieldinput" + id} onBlur={this.checkPhone} name={Consts.translate(k)} tag={id + "_" + k} ph={Consts.placeHolders[k]}/>
                                            {(i % 2 === 1) && <br/>}
                                        </InputContainer>
                                    })

                            }
                            <AdditionalFields registerMode/>

                            {
                                (age === null || age > 18 || ![Consts.CLUB_ITV_INNSBRUCK].includes(club?.verein_ID)) && ![Consts.CLUB_ATV_ANDORF, Consts.CLUB_GUN_CLUB_LUSTENAU, Consts.CLUB_ARTANDDANCE_CONNECTION, Consts.CLUB_ARTANDDANCE_CENTER, Consts.CLUB_OETB_LINZ, Consts.CLUB_TV_STEYR].includes(club?.verein_ID) && !club?.noMembership && <>
                                    <br/>
                                    <br/>
                                    <div style={{paddingBottom: "30px", float: "left", paddingRight: "10px"}}>
                                        <CheckboxInput name={"akzeptiere"} tag={"new_member"} resetOnUnmount noLabel/>
                                    </div>
                                    {
                                        age !== null && age < 18 ?
                                            <RegisterAusserordentlichesMitglied club={club} subjectAndPraedicate={"Diese Person soll"}/> :
                                            <RegisterOrdentlichesMitglied club={club} subjectAndPraedicate={"Ich will"}/>
                                    }
                                </>
                            }

                            {
                                club?.canRegisterUnterstuetzendesMitglied && !club?.noMembership && !!club?.msFees?.supporter && <>
                                    <br/>
                                    <br/>
                                    <div style={{paddingBottom: "30px", float: "left", paddingRight: "10px"}}>
                                        <CheckboxInput name={"akzeptiere"} tag={"new_supportingMember"} resetOnUnmount noLabel/>
                                    </div>
                                    <RegisterUnterstützendesMitglied club={club} subjectAndPraedicate={age !== null && age < 18 ? "Diese Person soll" : "Ich will"}/>
                                    <br/>
                                </>
                            }
                        </Container>
                        {
                            age !== null && age < 18 && this.state.action !== "add" &&
                            <Container name={"Gesetzlicher Vertreter"}>
                                <InputContainer style={{display: "block"}}>
                                    <SelectfieldInput name={"Geschlecht"} tag={idParent + "_sex"} selectives={[["fem", "Weiblich"], ["male", "Männlich"],]} demandSelect/>
                                </InputContainer>
                                {
                                    ["titlesAnte", "titlesPost", "prename", "lastname", "email", "phone", "address", "svn", "birthdate"]
                                        .map((k, i) => {
                                            if (k === "address") {
                                                return <div>
                                                    <ChangeAddressExt child={parent} changeAddress={a => this.setState({[idParent]: {...parent, ...a}})}/>
                                                    {i % 2 === 1 && <br/>}
                                                </div>
                                            }


                                            if (k === "note" || k === "internalnote") {
                                                return <InputContainer className={"textarea"}>
                                                    <TextareaInput name={<>Gesundheitliche Einschränkungen und Hinweise (optional)</>}
                                                                   tag={idParent + "_" + k}/>
                                                </InputContainer>
                                            }

                                            if (typeof parent[k] === "boolean") {
                                                return <InputContainer>
                                                    <CheckboxInput name={k[0].toUpperCase() + k.substr(1)} tag={idParent + "_" + k}/>
                                                    {i % 2 === 1 && <br/>}
                                                </InputContainer>
                                            }
                                            if (k === "email") {
                                                return <InputContainer>
                                                    <TextfieldInput key={"textfieldinput" + idParent}
                                                                    onBlur={this.checkPhone}
                                                                    inputBorderColor={parent.emailValid === false ? "red" : null}
                                                                    name={<>{Consts.translate(k)} {parent.emailValid === true && <FaCheckCircle/>}{parent.emailValid === false && "(falsch)"}</>}
                                                                    tag={idParent + "_" + k}
                                                                    ph={Consts.placeHolders[k]}
                                                    />
                                                    {(i % 2 === 1) && <br/>}
                                                </InputContainer>
                                            }
                                            if (k === "svn") {
                                                if (parent.country !== "AT" || !club?.svnRequired) {
                                                    return;
                                                }
                                                return [<InputContainer>
                                                    <TextfieldInput key={"textfieldinput" + id} name={<>
                                                        {Consts.translate(k)}
                                                        &nbsp;
                                                        <MyModal trigger={<FaInfoCircle/>} on={"hover"}>
                                                            <h2>Sozialversicherungsnummer</h2>
                                                            Die Angabe der Sozialversicherungsnummer dient lediglich zur eindeutigen Identifikation von Personen und wird in unserem System
                                                            verschlüsselt abgespeichert, somit hat darauf niemand Zugriff.
                                                        </MyModal>
                                                    </>} tag={idParent + "_" + k} ph={Consts.placeHolders[k]}/>

                                                </InputContainer>,
                                                    <br/>]
                                            }

                                            if (k === "birthdate") {
                                                if (parent.country === "AT" && club?.svnRequired) {
                                                    return;
                                                }
                                                let d;
                                                if (parent.birthdate > 0) {
                                                    let date = `${parent[k]}`;
                                                    let date_string = `${date.substr(0, 4)}-${date.substr(4, 2)}-${date.substr(6, 2)}`;
                                                    d = new Date(date_string);
                                                } else {
                                                    //d = new Date()
                                                }

                                                return [<InputContainer>
                                                    <label key={"gbrtst" + id}><span>Geburtsdatum</span>
                                                        <DatePicker selected={d}
                                                                    locale={"de"}
                                                                    dateFormat={"dd.MM.yyyy"}
                                                                    placeholderText={"00.00.0000"}
                                                                    showYearDropdown
                                                                    peekNextMonth
                                                                    showMonthDropdown
                                                                    maxDate={new Date()}
                                                                    dropdownMode="select"
                                                                    onChange={(date) => {
                                                                        if (date) {
                                                                            let datemy = date.getFullYear() * 10000 + (date.getMonth() + 1) * 100 + date.getDate()
                                                                            this.setState({[idParent]: Object.assign({}, this.state[idParent], {birthdate: datemy})})
                                                                        }
                                                                    }
                                                                    }/></label>
                                                </InputContainer>, <br/>];
                                            }
                                            return <InputContainer>
                                                <TextfieldInput key={"textfieldinput" + idParent} onBlur={this.checkPhone} name={Consts.translate(k)} tag={idParent + "_" + k}/>
                                                {(i % 2 === 1) && <br/>}
                                            </InputContainer>
                                        })
                                }

                                {
                                    ![Consts.CLUB_ATV_ANDORF, Consts.CLUB_GUN_CLUB_LUSTENAU, Consts.CLUB_ARTANDDANCE_CENTER, Consts.CLUB_ARTANDDANCE_CONNECTION, Consts.CLUB_OETB_LINZ, Consts.CLUB_TV_STEYR].includes(club?.verein_ID) && !club?.noMembership && <>
                                        <br/>
                                        <div style={{paddingBottom: "30px", float: "left", paddingRight: "10px"}}>
                                            <CheckboxInput name={"akzeptiere"} tag={"parent_member"} resetOnUnmount noLabel/>
                                        </div>
                                        <RegisterOrdentlichesMitglied club={club} erzberDisclaimer={"Z.B. als Erziehungsberechtigter musst du nicht Mitglied werden."} subjectAndPraedicate={"Ich selbst will"}/>
                                        <br/>
                                    </>
                                }
                                {
                                    [Consts.CLUB_ATV_ANDORF, Consts.CLUB_TV_TAUFKIRCHEN, Consts.CLUB_TV_SCHWANENSTADT, Consts.CLUB_TV_MONDSEE, Consts.CLUB_ATV_SCHWERTBERG, Consts.CLUB_VBC_STEYR].includes(club?.verein_ID) && !club?.noMembership && !!club?.msFees?.supporter && <>
                                        <br/>
                                        <div style={{paddingBottom: "30px", float: "left", paddingRight: "10px"}}>
                                            <CheckboxInput name={"akzeptiere"} tag={"parent_supportingMember"} resetOnUnmount noLabel/>
                                        </div>
                                        <RegisterUnterstützendesMitglied club={club} erzberDisclaimer={"Z.B. als Erziehungsberechtigter musst du nicht Mitglied werden."} subjectAndPraedicate={"Ich selbst will"}/>
                                        <br/>
                                    </>
                                }


                            </Container>
                        }

                        <Container name={"Abschließen"}>
                            <RegisterDisclaimer club={club} registration={"new"} registrationAdmin={"parent"} age={age}/>

                            <br/>
                            <br/>
                            <Status type={"error"} text={this.state.error}/>
                            <Status type={"success"} text={this.state.success}/>

                            <MaxBtn>Registrieren</MaxBtn><Loader loading={this.state.loading}/>
                        </Container>
                    </>
                }

                {
                    this.state.action === "activate" &&
                    <Container name={"Account aktivieren"}>
                        <TextfieldInput tag={"activate_token"} name={"Aktivierungscode"} ph={"1a2b3c4d5e6f"}/><br/>
                        <TextfieldInput tag={"activate_email"} name={"E-Mail-Adresse"} ph={"max@mustermann.at"}/>
                        <br/>
                        <RegisterDisclaimer club={club} registration={"new"} registrationAdmin={"parent"} age={age}/>
                        <br/>
                        <Status type={"error"} text={this.state.error}/>
                        <Status type={"success"} text={this.state.success}/>
                        <MaxBtn>Aktivierungs-E-Mail anfordern</MaxBtn><Loader loading={this.state.loading}/>

                    </Container>
                }
                {
                    this.state.action === "activate_register" && <>

                        <Container name={"Account aktivieren"}>
                            <TextfieldInput tag={"activate_token"} name={"Aktivierungscode"} ph={"1a2b3c4d5e6f"}/><br/>
                        </Container>
                        <Container name={"Registrieren des Erziehungsberechtigten"}>
                            <InputContainer style={{display: "block"}}>
                                <SelectfieldInput name={"Geschlecht"} tag={id + "_sex"} selectives={[["fem", "Weiblich"], ["male", "Männlich"],]} demandSelect/>
                            </InputContainer>
                            {
                                ["titlesAnte", "titlesPost", "prename", "lastname", "email", "phone", "Notfallnummer", "address", "svn", "birthdate", "note"]
                                    .map((k, i) => {
                                        if (k === "address") {
                                            return <div>
                                                <ChangeAddressExt child={child} changeAddress={a => this.setState({[id]: {...child, ...a}})}/>
                                                {i % 2 === 1 && <br/>}
                                            </div>
                                        }

                                        if (k === "Notfallnummer") {
                                            if (age === null || age >= 18) {
                                                return null
                                            }
                                            return <InputContainer>
                                                <TextfieldInput name={"Notfallnummer"} tag={id + "_phoneAdd"}/>
                                                {(i % 2 === 1) && <br/>}
                                            </InputContainer>
                                        }
                                        if (k === "note" || k === "internalnote") {
                                            return <InputContainer className={"textarea"}>
                                                <TextareaInput name={<>Gesundheitliche Einschränkungen und Hinweise (optional)
                                                    &nbsp;
                                                    <MyModal trigger={<FaInfoCircle/>} on={"hover"}>
                                                        <h2>Gesundheitliche Einschränkungen und Hinweise (optional)</h2>
                                                        Um noch besser auf die Bedürfnisse der Kinder eingehen zu können bzw. im Notfall richtig gehandelt wird, ist es von großem Vorteil, wenn der
                                                        Trainer über Gesundheitliche Einschränkungen Bescheid
                                                        weiß bzw. Hinweise dazu angegeben werden. Weiters werden zu besonderen Anlässen (Weihnachten, Fasching, letzte Einheit usw.) oftmals eine
                                                        Kleinigkeit zu essen ausgegeben, deshalb bitte Allergien
                                                        und Unverträglichkeiten angeben.<br/>
                                                        Mit der Bekanntgabe erklärt sich der gesetzliche Vertreter mit der Verarbeitung und Weitergabe an die Trainer einverstanden. Art. 6 Abs. 1 lit.
                                                        D DSVGO: „Schutz lebenswichtiger Interessen“
                                                    </MyModal>
                                                </>
                                                } tag={id + "_" + k}/>
                                            </InputContainer>
                                        }

                                        if (typeof child[k] === "boolean") {
                                            return <InputContainer>
                                                <CheckboxInput name={k[0].toUpperCase() + k.substr(1)} tag={id + "_" + k}/>
                                                {i % 2 === 1 && <br/>}
                                            </InputContainer>
                                        }
                                        if (k === "svn") {
                                            return [<InputContainer>
                                                <TextfieldInput key={"textfieldinput" + id} name={<>
                                                    {Consts.translate(k)}
                                                    &nbsp;
                                                    <MyModal trigger={<FaInfoCircle/>} on={"hover"}>
                                                        <h2>Sozialversicherungsnummer</h2>
                                                        Die Angabe der Sozialversicherungsnummer dient lediglich zur eindeutigen Identifikation von Personen und wird in unserem System verschlüsselt
                                                        abgespeichert, somit hat darauf niemand Zugriff.
                                                    </MyModal>
                                                </>} tag={id + "_" + k} ph={Consts.placeHolders[k]}/>
                                            </InputContainer>, <br/>];
                                        }
                                        if (k === "birthdate") {
                                            if (child.country === "AT" && club.svnRequired) {
                                                return;
                                            }
                                            let d;
                                            if (child.birthdate > 0) {
                                                let date = `${child[k]}`;
                                                let date_string = `${date.substr(0, 4)}-${date.substr(4, 2)}-${date.substr(6, 2)}`;
                                                d = new Date(date_string);
                                            } else {
                                                //d = new Date()
                                            }

                                            return [<InputContainer>
                                                <label key={"gbrtst" + id}><span>Geburtsdatum</span>
                                                    <DatePicker selected={d}
                                                                locale={"de"}
                                                                dateFormat={"dd.MM.yyyy"}
                                                                placeholderText={"00.00.0000"}
                                                                showYearDropdown
                                                                peekNextMonth
                                                                showMonthDropdown
                                                                maxDate={new Date()}
                                                                dropdownMode="select"
                                                                onChange={(date) => {
                                                                    if (date) {
                                                                        let datemy = date.getFullYear() * 10000 + (date.getMonth() + 1) * 100 + date.getDate()
                                                                        this.setState({[id]: Object.assign({}, this.state[id], {birthdate: datemy})})
                                                                    }
                                                                }
                                                                }/></label>
                                            </InputContainer>, <br/>];
                                        }
                                        if (k === "email") {
                                            return <InputContainer>
                                                <TextfieldInput key={"textfieldinput" + id}
                                                                onBlur={this.checkPhone}
                                                                inputBorderColor={child.emailValid === false ? "red" : null}
                                                                name={<>{Consts.translate(k)} {child.emailValid === true && <FaCheckCircle/>}{child.emailValid === false && "(falsch)"}</>}
                                                                tag={id + "_" + k}
                                                                ph={Consts.placeHolders[k]}
                                                />
                                                {(i % 2 === 1) && <br/>}
                                            </InputContainer>
                                        }
                                        return <InputContainer>
                                            <TextfieldInput key={"textfieldinput" + id} onBlur={this.checkPhone} name={Consts.translate(k)} tag={id + "_" + k} ph={Consts.placeHolders[k]}/>
                                            {(i % 2 === 1) && <br/>}
                                        </InputContainer>
                                    })

                            }
                            <br/>
                            <br/>


                        </Container>
                        <Container name={"Abschließen"}>
                            <RegisterDisclaimer club={club} registration={"new"} registrationAdmin={"parent"} age={age}/>

                            <br/>
                            <br/>
                            <Status type={"error"} text={this.state.error}/>
                            <Status type={"success"} text={this.state.success}/>

                            <MaxBtn>Registrieren</MaxBtn><Loader loading={this.state.loading}/>
                        </Container>
                    </>
                }

                {
                    this.state.action === "finish" &&
                    <Container name={"Registration abgeschlossen"}>
                        <Status type={"success"} text={"Erfolgreich registriert, du solltest ein E-Mail mit einem Link zum einrichten deines Passwortes erhalten haben!"}/>
                    </Container>
                }
            </FormContextWrapper>


            {
                this.state.action === "login" &&

                <Login {...this.props} redir={this.redirUrl}/>

            }
        </>
    }
}
