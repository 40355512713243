import React, {Dispatch, SetStateAction, useState} from "react"
import {Container, InfoTooltip, MaxBtn} from "../core/components/components"
import {CellParams, ColumnType} from "../core/components/table"
import {Link} from "react-router-dom"
import {dateFormat, dateFormatDate, dateFormatTimeShort, dateFormatUnix} from "../core/dateFuncs"
import {FaAngleDown, FaAngleRight, FaExchangeAlt, FaEye} from "react-icons/fa"
import {CheckboxInput, InputContainer} from "../core/input/basic"
import {SelectfieldInput} from "../core/input/select"
import {DateInputUnix, DateTimeInput} from "../core/input/date"
import SelectFieldInput2 from "../core/input/select2"
import {MyModal} from "../core/components/modal"
import {TagsInput} from "../core/input/tags"

import {LocalUser, UserRouteComponent, UserTableState} from "./user_general_list.type"
import {defaultTableMapping, UserGeneralList} from "./user_general_list"
import Consts from "../core/consts"
import {objGetEntries} from "../core/helpers"
import {CourseInput} from "../core/input/courseInput"
import {User} from "./UserContext"


export function UsersList(props: UserRouteComponent) {
    return <UserGeneralList
        title={"Alle Personen"}
        apiRoute={"/user/subusersAsObject"}
        defaultShownTableColumns={["fullname", "email", "birthdate", "postal_code", "isVerwalter", "memberStatus"]}
        tableName={"Alle Personen"} {...props} />
}

export function BoardList(props: UserRouteComponent) {
    return <UserGeneralList
        title={"Vorstand"}
        FormComponents={({}) => <>
            <DateInputUnix name={"Zum Zeitpunkt"} tag={"search_date"}/>
        </>}
        apiRoute={"/club/board/v2"}
        defaultShownTableColumns={["roleDescriptionLong", "fullname", "roleTimeStart", "roleTimeEnd"]}
        customTableMapping={{
            roleDescriptionLong: {
                Header: "Position",
                accessor: "roleDescriptionLong",
                filterable: true,
            },
            roleTimeStart: {
                Header: "Start",
                accessor: "roleTimeStart",
                Cell: ({value}: any) => dateFormatUnix(value)
            },
            roleTimeEnd: {
                Header: "Ende",
                accessor: "roleTimeEnd",
                Cell: ({value}: any) => value === 0 ? "nicht beendet" : dateFormatUnix(value)
            },
        }}
        tableName={"Alle Personen"} {...props} />
}

export function UsersListFast(props: UserRouteComponent) {
    return <UserGeneralList
        title={"Alle Personen"}
        apiRoute={"/user/search?size=100"}
        defaultShownTableColumns={["fullname", "email", "birthdate", "postal_code", "isVerwalter", "memberStatus"]}
        tableName={"Alle Personen (schnell)"}
        {...props} />
}

export function ActiveAdminsList(props: UserRouteComponent) {
    return <UserGeneralList
        title={"Aktive Verwalter"}
        apiRoute={"/courses/list/activeAdmins"}
        tableName={"Aktive Verwalter"}
        defaultShownTableColumns={["fullname", "email", "birthdate", "subuser_count", "memberStatus", "memberStatuses"]}
        reloadOnSubmit={true}
        initialLoad={true}
        customTableMapping={{
            subuser_count: {
                Header: "Aktive Personen",
                accessor: "subuser_count",
                key: "subuser_count",
                filterable: true,
                maxWidth: 100,
            },
            memberStatuses: ({club}) => ({
                Header: "Status in Familie",
                accessor: r => r.memberStatuses?.split(",").filter(a => a !== (r.memberStatus || "").toString()).map(a => club.memberStatesShort[a]).join(", "),
                id: "memberStates",
                show: window.innerWidth > 1000,
                maxWidth: 90,
                Cell: ({original}) => original.memberStatuses?.split(",")
                    .filter(a => a !== (original.memberStatus || "").toString())
                    .map((a, i) => [i > 0 ? ", " : null, <span title={club.memberStates[a]}>{club.memberStatesShort[a]}</span>]) || "",
                filterable: true,
            }),
        }}
        FormComponents={() => <>
            <DateInputUnix name={"Zum Zeitpunkt"} tag={"search_date"}/>
            <br/>
            <CheckboxInput labelWidth={300} name={"Wartelisten-Buchungen berücksichtigen?"} tag={"search_includeWaitlistBookings"}/>
            &nbsp;&nbsp;
            <MaxBtn>aktualisieren</MaxBtn>
        </>}
        BeforeComponent={() => <h3>verwalten Personen, die zu diesem Datum einen Kurs besuchen oder aktive Mitglieder sind</h3>} {...props} />
}

export function ActiveList(props: UserRouteComponent) {
    return <UserGeneralList
        title={"Aktive Personen"}
        apiRoute={"/courses/list/active"}
        tableName={"Alle aktiven Personen"}
        reloadOnSubmit={true}
        initialLoad={true}
        defaultShownTableColumns={["fullname", "email", "sex", "birthdate", "isVerwalter", "course_num", "memberStatus"]}
        customTableMapping={{
            course_num: {
                Header: "Kursanzahl",
                accessor: "courses_num",
                filterable: true,
                maxWidth: 100,
            },
        }}
        FormComponents={() => <>
            <DateInputUnix name={"Zum Zeitpunkt"} tag={"search_date"}/><br/>
            <CheckboxInput labelWidth={300} name={"Wartelisten-Buchungen berücksichtigen?"} tag={"search_includeWaitlistBookings"}/>
            &nbsp;&nbsp;
            <MaxBtn>aktualisieren</MaxBtn>
        </>}
        BeforeComponent={() => <h3>besuchen zu diesem Datum einen Kurs oder sind aktive Mitglieder</h3>} {...props} />
}

const getDate = () => Math.round(new Date().getTime() / 1000)

export function ChildsByRole(props: UserRouteComponent) {
    return <UserGeneralList
        title={"Personen nach Rolle filtern"}
        apiRoute={"/courses/list/byRole"}
        reloadOnSubmit={true}
        initialLoad={true}
        tableName={"Personen nach Rollen filtern"}
        defaultLocalSearch={{
            date: getDate(),
            roles: [],
            operator: "AND",
            mode: "date",
            ended: 0,
        }}
        defaultShownTableColumns={["fullname", "email", "sex", "birthdate", "isVerwalter", "course_num", "course_num", "memberStatus"]}
        customTableMapping={{
            course_num: {
                Header: "Kursanzahl",
                accessor: "courses_num",
                filterable: true,
                maxWidth: 100,
            },
        }}
        FormComponents={({club, setState, state}) => <>
            <SelectFieldInput2
                name={<>Rolle
                    (<em onClick={() => setState((search) => ({search: {...search.search, operator: search.search.operator === "AND" ? "OR" : "AND"}}))}>{state.search.operator === "AND" ? "UND" : "ODER"}-Verknüpfung</em>
                    <InfoTooltip>Bei der UND Verknüpfung werden nur alle Personen angezeigt, welche alle der ausgewählten Rollen haben. Bei der ODER hingegen nur jene, die mindestens eine der Rollen haben.</InfoTooltip>)
                </>}
                labelWidth={250}
                tag={"search_roles"}
                multiple={true}
                width={"500px"}
                selectives={[{value: -1, label: "alle"}, ...Object.keys(club.memberStates || {}).filter(s => parseInt(s) > 0).map(s => ({value: s.toString(), label: club.memberStates[s]}))]}
            />
            {
                state.search.mode === "date" ? <>
                        <DateInputUnix name={<>Zum Zeitpunkt <em onClick={(e) => {
                            e.preventDefault()
                            setState(se => ({search: {...se.search, mode: "daterange"}}))
                        }}><FaExchangeAlt/></em></>} tag={"search_date"}/>
                    </> :
                    <>
                        <DateInputUnix labelWidth={100} name={<>von <em onClick={(e) => {
                            e.preventDefault()
                            setState(se => ({search: {...se.search, mode: "date"}}))
                        }}><FaExchangeAlt/></em></>} tag={"search_dateStart"}/>
                        <DateInputUnix labelWidth={100} name={<>bis</>} tag={"search_dateEnd"}/><br/>
                        <CheckboxInput name={"Rolle beendet?"} tag={"search_ended"}/>&nbsp;&nbsp;
                    </>
            }
            <MaxBtn>aktualisieren</MaxBtn>
        </>} {...props} />
}

export function LatestBookings(props: UserRouteComponent) {
    let d = new Date()
    d.setHours(0)
    d.setMinutes(0)
    d.setSeconds(0)
    return <UserGeneralList
        title={"Letzte Buchungen"}
        apiRoute={"/courses/bookings/list"}
        reloadOnSubmit={true}
        initialLoad={true}
        tableName={"Letzte Buchungen"}
        defaultLocalSearch={{
            showStart: d.getTime() / 1000,
            role: "all",
            showMSFees: false,
        }}
        defaultShownTableColumns={["course", "personname", "registration_role", "registration_time"]}
        customTableMapping={{
            course: {
                Header: "Kursname",
                accessor: "course.name",
                filterable: true,
                Cell: ({value, original}) => <Link to={"/kurse/" + original.course?.ID}>{value} </Link>,
            },
            personname: {
                Header: "Person",
                id: "personname",
                accessor: row => row.fullname,
                filterable: true,
                Cell: ({value, original}) => <Link to={"/benutzer/profil/" + original.ID}>{value}</Link>,
            },
            registration_role: {
                Header: "Status",
                maxWidth: 100,
                show: window.innerWidth > 800,
                accessor: "registration.role",
                Cell: ({value, original}) => (value === 0 ? "normal" : "Warteliste") + (original.registration.timeLeave > 0 ? " (storniert)" : "")
            },
            registration_time: {
                Header: "Zeit",
                maxWidth: 150,
                id: "time",
                accessor: row => dateFormatTimeShort(row.registration.timeCreation),
                filterable: true,
            },
        }}
        FormComponents={({load,}) => <>
            <CheckboxInput labelWidth={200} name={"Mitgliedsbeiträge anzeigen"} tag={"search_showMSFees"}/>&nbsp;&nbsp;&nbsp;&nbsp;
            <SelectfieldInput name={"Teilnahmeart"} tag={"search_role"} selectives={[["0", "Teilnehmer"], ["all", "Teilnehmer und Warteliste"], ["2", "Warteliste"]]}/>
            <DateTimeInput name={"Anzeigen ab"} tag={"search_showStart"}/>
            <MaxBtn onClick={load}>aktualisieren</MaxBtn>
        </>} {...props} />
}

const FullNameRenderer = ({user}: { user: User }) => {
    return {
        Header: "Name",
        filterable: true,
        key: "fullname",
        accessor: "fullname",
        timeout: 500,
        Cell: row => {
            const manageesLength = row.original.managees?.length || 0
            const isManager = manageesLength > 0
            return <span style={{marginLeft: (!row.original.isVerwalter ? 1.5 : 0) + "em"}}>
            <span style={{opacity: isManager ? 1 : 0.4, cursor: isManager ? "pointer" : "inherit"}}>
        {
            row.original.isVerwalter && (row.original.visible ? <FaAngleDown/> : <FaAngleRight/>)
        }
            </span>
            <Link to={`/benutzer/${user.role > 20 ? "liste" : "view"}/` + row.original.ID}>{row.value}</Link>
                {
                    isManager && <em>({manageesLength} verwaltete Person{manageesLength > 1 && "en"})</em>
                }
            </span>
        },
        filterMethod: () => true,
    } as ColumnType<LocalUser>
}

export function UsersManagerList(props: UserRouteComponent) {
    const onVerwalterRowClick = (state: UserTableState, setState: Dispatch<SetStateAction<UserTableState>>, e: any, t: any, rowInfo: CellParams<LocalUser, any>) => {
        const managees = rowInfo.original?.managees
        if ((managees?.length || 0) <= 0) {
            return
        }

        let data = [...state.tableData]
        const newData = data.map(d => ({...d, visible: managees?.includes(d.ID) || rowInfo.row.ID === d.ID ? !d.visible : d.visible}))
        setState(s => ({...s, tableData: newData}))
    }

    return <UserGeneralList
        title={"Verwaltende Personen"}
        apiRoute={"/user/bymanager"}
        tableName={"Alle Verwalter + Verwalteten"}
        defaultShownTableColumns={["fullname", "email", "birthdate", "address", "isVerwalter", "memberStatus"]}
        getTrProps={({state, setState}) => (s: any, rowInfo: CellParams<LocalUser, any>) => {
            const verwalter = rowInfo && rowInfo.original.isVerwalter
            return {
                onClick: (e: any, t: any) => {
                    if (verwalter) onVerwalterRowClick(state, setState, e, t, rowInfo)
                },
                style: {
                    display: verwalter || rowInfo && rowInfo.original.visible ? "inherit" : "none"
                }
            }
        }}
        customTableMapping={{
            fullname: FullNameRenderer,
        }} {...props} />
}

export function ActiveUserActiveManagerList(props: UserRouteComponent) {
    return <UserGeneralList
        title={"Aktive Personen und aktive Verwalter"}
        apiRoute={"/courses/list/activeUsersActiveManagers"}
        tableName={"Alle aktiven Personen und aktive Verwalter"}
        reloadOnSubmit={true}
        initialLoad={true}
        defaultShownTableColumns={["fullname", "email", "sex", "birthdate", "isVerwalter", "course_num", "memberStatus", "isActive"]}
        customTableMapping={{
            /*course_num: {
                Header: "Kursanzahl",
                accessor: "courses_num",
                filterable: true,
                maxWidth: 100,
            },
            activeNames: {
                Header: "Aktive Personen der Verwaltergruppe",
                accessor: "activeNames",
                filterable: true,
            }*/
            isActive: {
                Header: "Ist aktiv",
                id: "isActive",
                accessor: "isActive",
                Cell: ({original}) => original.isActive ? "ja" : "nein",
                maxWidth: 100,
                filterable: true,
                filterType: "select",
                selectables: [{label: "ja", value: true}, {label: "nein", value: false}],
            },
        }}
        FormComponents={() => <>
            <DateInputUnix name={"Zum Zeitpunkt"} tag={"search_date"}/><br/>
            <CheckboxInput labelWidth={300} name={"Wartelisten-Buchungen berücksichtigen?"} tag={"search_includeWaitlistBookings"}/>
            &nbsp;&nbsp;
            <MaxBtn>aktualisieren</MaxBtn>
        </>}
        BeforeComponent={() => <h3>besuchen zu diesem Datum einen Kurs oder sind aktive Mitglieder</h3>} {...props} />
}


export function CourseTagUsers(props: UserRouteComponent) {
    return <UserGeneralList
        title={"Mitglieder nach Kursen, Kurstags oder Raumtags anazeigen"}
        apiRoute={"/user/tags"}
        tableName={"Mitglieder nach Kursen, Kurstags oder Raumtags"}
        defaultShownTableColumns={["fullname", "email", "birthdate", "courses"]}
        initialLoad={false}
        defaultLocalSearch={{value: "course"}}
        FormComponents={({state}) => {
            const optionValue = state.search.value
            return <>
                <div>
                    <SelectfieldInput name={"Kategorie auswählen"} selectives={[["course", "Kurse"], ["coursetag", "Kurstags"], ["room", "Raumtags"]]} tag={"search_type"}/>
                </div>
                <div>
                    <CheckboxInput tag={"search_active"} name={"Aktive Teilnehmer"} style={{marginRight: "50px"}}/>
                    <CheckboxInput tag={"search_trainers"} name={"Trainer"} style={{marginRight: "50px"}}/>
                    <CheckboxInput tag={"search_waitlist"} name={"Teilnehmer auf der Warteliste"}/>
                </div>
                {optionValue !== "course" &&
                    <>
                        <DateTimeInput name={"Stichdatum von"} tag={"search_from"}/>
                        <DateTimeInput name={"Stichdatum bis"} tag={"search_to"}/>
                        <InfoTooltip children={<>
                            Die Datumsauswahl für <b>Stichdatum von</b> und <b>Stichdatum bis</b> wird für Kurs- und Raumtags benötigt.<br/>
                            <ul>
                                <li>Bei der Auswahl <b>Kurstags</b> werden alle Kursteilnehmer angezeigt, die an einem Kurs mit einem der ausgewählten Tags, wo mindestens ein Termin im Intervall "Stichdatum von" - "Stichdatum bis" liegt,
                                    teilnehmen.<br/>
                                </li>
                                <li>Bei der Auswahl <b>Raumtags</b> werden alle Kursteilnehmer angezeigt, die an einem Kurs in einem der ausgewählten Räume, wo mindestens ein Termin im Intervall "Stichdatum von" - "Stichdatum bis" liegt,
                                    teilnehmen.<br/>
                                </li>
                            </ul>
                        </>}/></>
                }
                {optionValue === "course" && <CourseInput name={"Kurse auswählen"} width={"100%"} tag={"search_value"} multiple/>}
                {optionValue === "coursetag" && <TagsInput entity={"course"} name={"Kurstags"} tag={"search_value"} multiple/>}
                {optionValue === "room" && <TagsInput entity={"event"} name={"Raumtags"} tag={"search_value"} multiple/>}
            </>
        }}
        customTableMapping={{
            courses: {
                Header: "Kurse",
                accessor: "courses",
                filterable: false,
                width: 400,
                Cell: ({original}) => {
                    const value = original.courses
                    const reference = value?.map(x => <span>{x.name} (Start: {dateFormatUnix(x.start)}, Ende: {dateFormatUnix(x.end)})</span>) || []
                    const modalData = value?.map(x => <li><Link to={"/kurs/buche/" + x.ID} target={"_blank"}>{x.name} (Start: {dateFormatUnix(x.start)}, Ende: {dateFormatUnix(x.end)})</Link></li>) || []
                    return <MyModal trigger={reference}>
                        <Container name={"Kurse:"}>
                            <ul>
                                {modalData}
                            </ul>
                        </Container>
                    </MyModal>
                }
            }
        }} {...props} />
}

const yearNow = (new Date()).getFullYear()
const yearsLast = [0, 1, 2, 3, 4, 5, 6, 7, 8].map(i => {
    return [yearNow - i, `${yearNow - i}/${yearNow - i + 1}`]
})

export function TrainersList(props: UserRouteComponent) {
    const [yearMode, setYearMode] = useState(true)
    const ModeChanger = ({children}: { children?: any }) => <b
        style={{cursor: "pointer"}}
        onClick={() => {
            setYearMode(ym => !ym)
        }}>
        {children} &nbsp;
        <FaExchangeAlt/>
    </b>

    return <UserGeneralList
        title={"Trainerliste"}
        tableName={"Alle Trainer"}
        apiRoute={"/courses/list/trainers"}
        defaultShownTableColumns={["fullname", "email", "birthdate", "course_num", "isVerwalter", "trainerInfo"]}
        customTableMapping={{
            course_num: {
                Header: "Kursanzahl",
                accessor: "courses_num",
                filterable: true,
                maxWidth: 100,
            },
            trainerInfo: {
                Header: "Trainer",
                Cell: row => <Link to={"/profil/" + row.original.ID}><FaEye/></Link>,
                maxWidth: 70,
                pdfShow: false,
            },
            trainerSince: {
                Header: "Trainer Seit",
                accessor: "trainerSince",
                Cell: ({value})=>value && dateFormatDate(new Date(value)),
                maxWidth: 120,
                pdfShow: false,
            },
        }}
        FormComponents={() => <>{
            yearMode ?
                <SelectfieldInput name={<ModeChanger>Schuljahr</ModeChanger>} tag={"search_year"} selectives={yearsLast}/> :
                <DateInputUnix name={<ModeChanger>Zum Zeitpunkt </ModeChanger>} tag={"search_date"}/>
        }</>}
        {...props} />
}

export function UsersWithMembershipFees(props: any) {
    return <UserGeneralList
        title={"Mitglieder mit Mitgliedsbeiträgen"}
        apiRoute={"/club/membership_fees/userlist"}
        tableName={"Mitglieder mit Mitgliedsbeiträgen"}
        defaultShownTableColumns={["fullname", "birthdate", "courses", "roles", "course_price", "course_name", "course_start", "saldo"]}
        FormComponents={() => {
            return <>
                <div>
                    <InputContainer>
                        <DateInputUnix name={"Stichtag auswählen"} tag={"search_timestamp"}/>
                    </InputContainer>
                </div>
            </>
        }}
        customTableMapping={{
            fullname: (params) => ({
                ...(typeof defaultTableMapping.fullname === "function" ? defaultTableMapping.fullname(params) : {}),
                width: 200,
                pdfWidth: 100,
            }),
            email: {
                ...(defaultTableMapping.email),
                width: 300,
                pdfWidth: 100,
            },
            roles: ({club}) => ({
                Header: "Rollen",
                id: "roles",
                filterable: true,
                width: 300,
                pdfWidth: 100,
                filterType: "select",
                selectables: objGetEntries(club.memberStates).map(({key, value}) => ({label: value, value: key})),
                selectCompareFunction: (localUser, selected) => !selected || selected.length === 0 || !localUser || (localUser.roles || []).filter(role => selected.filter(s => "" + s.value === "" + role.key).length > 0).length > 0,
                accessor: (original) => {
                    return original.roles?.map(role => role.value).join(", ")
                }
            }),
            course_price: {
                Header: "Preis",
                accessor: "course.price",
                width: 80,
                pdfWidth: 40,
                Cell: ({original}: CellParams<LocalUser, number>) => {
                    return original.course?.price ? Consts.moneyMax(original.course.price / 100) : "-"
                }
            },
            course_name: {
                Header: "Art MB",
                id: "course.name",
                width: 300,
                pdfWidth: 100,
                filterable: true,
                filterType: "select",
                accessor: (row: LocalUser) => {
                    return row.course?.name || "-"
                }
            },
            course_start: {
                Header: "Verrechnungsdatum",
                accessor: "course.start",
                width: 120,
                pdfWidth: 80,
                Cell: ({original}: CellParams<LocalUser, number>) => {
                    return dateFormatUnix(original.course?.start)
                }
            },
            saldo: {
                Header: "Saldo",
                accessor: "saldo",
                width: 90,
                pdfWidth: 40,
                pdfAlign: "right",
                Cell: ({original}) => {
                    return original.saldo ? Consts.moneyMax(original.saldo / 100) : "-"
                }
            }
        }} {...props} />
}
