import React from 'react';
import {UserContext} from "./UserContext";
import {TextfieldInput} from "../core/input/basic";
import {SelectfieldInput} from "../core/input/select";
import {apiGet} from "../core/api";
import {FormContextWrapper} from "../core/form_context";
import Consts from "../core/consts";
import {countriesConverter} from "../core/helpers";

//const GOOGLE_API_KEY = "AIzaSyAnEIs2Ydmfv1gR9DfqP2OksO9Nytk7n-8";

export default class ChangeAddressExt extends React.Component {
    static contextType = UserContext;


    constructor(props) {
        super(props);
        const {child} = this.props;
        this.state = {
            search: {
                country: child.country || "AT",
                postalCode: !!child.postal_code ? child.postal_code.toString() : "",
                route: child.route || "",
                locality: child.locality || "",
                houseNumber: child.street_number || "",
                houseNumberAdd: child.street_number_add || "",
            },
            loading: false,
            results: [],
            localities: !!child.locality ? [child.locality] : [],
            houseNumbers: !!child.street_number ? [child.street_number] : [],
            streets: !!child.route ? [child.route] : [],
            open: false,
            houseNumbersInitiallyLoaded: false,
        };
        this.apiGet = apiGet.bind(this);
        this.setState = this.setState.bind(this);
        //this.afterUpdate("search", "initial");
    }

    handleChange = (e) => {
        const s = e.target.name.split("_");
        const val = e.target.type === 'checkbox' ? (e.target.checked ? 1 : 0) : e.target.value;
        this.setState({[s[0]]: {...this.state[s[0]], [s[1]]: val}});
    };
    /*search = () => {
        //e.preventDefault();
        //this.apiGet(`https://maps.google.com/maps/api/geocode/json?address=${this.state.search.name}&key=${GOOGLE_API_KEY}&language=de&region=at`, resp => {
        this.apiGet("/user/address/search?q=" + this.state.search.name, resp => {
            //console.log(resp)
            this.setState({results: resp.hits.hits, maxScore: resp.hits.max_score})
        })

    };*/
    getAddressComponents = (a) => {
        return {
            ...a,
            postal_code: a.postalCode,
            street_number: a.houseNumber,
            street_number_add: a.houseNumberAdd,
        }
        /*return {
            place_ID: r.place_id,
            address: r.formatted_address,
            ...Object.assign({}, ...r.address_components.map(c => {
                return c.types.reduce((b, a) => {
                    if (fields.indexOf(a) > -1) {
                        return {[a]: c.long_name}
                    }
                    return b
                }, null);

            }))
        }*/
    };
    afterUpdate = (p, c, val) => {
        if (val === undefined) {
            val = this.state.search
        }
        if (val.country === "AT") {
            if (c === "postalCode" || c === "initial") {
                if (val.postalCode !== undefined && val.postalCode.length === 4 && this.state.streets.length <= 1) {
                    this.apiGet("/user/address/search_plz?plz=" + val.postalCode+"&verein_ID="+this.context.club?.verein_ID, resp => {
                        const {plzs, streets} = resp.aggregations;
                        this.setState({localities: plzs.buckets.map(a => a.key), search: {...this.state.search, locality: plzs.buckets.length > 0 ? plzs.buckets[0].key : ""}, streets: streets.buckets.map(a => a.key)})
                    })
                    //*/
                } else if ((!!val.locality || !!val.route) && this.state.streets.length > 1 && p !== "menuOpen") {
                    window.setTimeout(() => {
                        this.setState({search: {...this.state.search, locality: "", route: "", houseNumber: ""}, localities: [], houseNumbers: [], streets: []})
                    }, 100)
                }
            }
            if (c === "initial" || (c === "route" && val.route !== undefined && val.route.length > 0) || (c === "houseNumberPreSelect" && !this.state.houseNumbersInitiallyLoaded)) {
                if (!this.state.houseNumbersInitiallyLoaded) {
                    this.setState({houseNumbersInitiallyLoaded: true})
                }
                this.apiGet("/user/address/search_number?plz=" + val.postalCode + "&street=" + val.route, resp => this.setState({houseNumbers: resp.aggregations.hnrs.buckets.map(a => a.key)}))
            }
            if (c === "route" && !!val.houseNumber && p !== "menuOpen") {
                window.setTimeout(() => this.setState({search: {...this.state.search, houseNumber: ""}}), 100)
            }
            if (((c === "houseNumber" || c === "houseNumberAdd") && !!val.houseNumber) || c === "country") {
                this.props.changeAddress(this.getAddressComponents(val))
            }
        } else {
            this.props.changeAddress(this.getAddressComponents(val))
        }

    };

    render() {
        const {search} = this.state;
        return <div>
            <FormContextWrapper
                value={{state: this.state, setState: this.setState, updateState: (a, b, c) => this.setState({[a]: {...this.state[a], [b]: c}}, () => this.afterUpdate(a, b))}}
                onSubmit={e => {
                    e.preventDefault();
                    this.search()
                }}

                afterUpdate={this.afterUpdate}
            >
                <label>
                    <span>Adresse</span>
                </label>
                <div style={{display: "inline-block", verticalAlign: "top"}}>
                    <SelectfieldInput name={""} inline ph={"Land"} type={"reactselect"} width={"150px"} noLabel={true} tag={"search_country"} className={"country-select"}
                                      selectives={Consts.countriesToSelect}/>
                    &nbsp;
                    &nbsp;
                    <TextfieldInput name={"postalCode"} tag={"search_postalCode"} noLabel ph={"PLZ"} style={{width: "80px", marginRight: "12px"}}/>

                    {search?.country === "AT" ? <>
                        {
                            (this.state.localities.length > 0 || this.context?.club?.verein_ID !== 9) && <SelectfieldInput name={""} noLabel={true} tag={"search_locality"} width={"223px"} inline ph={"Ort"} type={"reactselect"}
                                                                                                                           onMenuOpen={() => this.afterUpdate("menuOpen", "postalCode")}
                                                                                                                           noOptionsMessage={() => this.state.localities.length > 0 ? "nicht gefunden" : "zuerst PLZ"}
                                                                                                                           selectives={this.state.localities.map(s => ({label: s, value: s}))}/>
                        }
                        <br/>
                        {
                            (this.state.localities.length > 0 || this.context?.club?.verein_ID !== 9) &&
                            <SelectfieldInput name={""} noLabel={true} tag={"search_route"} width={search?.country === "AT" ? "277px" : "243px"} type={"reactselect"} inline ph={"Straße"}
                                              onMenuOpen={() => this.afterUpdate("menuOpen", "postalCode")} noOptionsMessage={() => this.state.streets.length > 0 ? "nicht gefunden" : "zuerst PLZ"}
                                              selectives={this.state.streets.map(s => ({label: s, value: s}))}/>
                        }

                        &nbsp;
                        &nbsp;
                        {
                            (this.state.houseNumbers.length > 0 || this.context?.club?.verein_ID !== 9) &&
                            <SelectfieldInput name={""} noLabel={true} tag={"search_houseNumber"} type={"reactselect"} inline ph={"HNr"} width={"100px"}
                                              onMenuOpen={() => this.afterUpdate("menuOpen", "houseNumberPreSelect")} noOptionsMessage={() => "zuerst Straße"}
                                              selectives={this.state.houseNumbers.sort((a, b) => parseInt(a) - parseInt(b)).map(s => ({label: s, value: s}))}/>
                        }
                    </> : <>
                        <TextfieldInput name={""} noLabel={true} tag={"search_locality"} style={{width: "223px", marginRight: 0}} inline ph={"Ort"} type={"reactselect"}
                                        selectives={this.state.localities.map(s => ({label: s, value: s}))}/>
                        <br/>
                        <TextfieldInput name={""} noLabel={true} tag={"search_route"} style={{width: "243px", marginRight: 0}} type={"reactselect"} inline ph={"Straße"}
                                        selectives={this.state.streets.sort((a, b) => a < b ? -1 : 1).map(s => ({label: s, value: s}))}/>

                        &nbsp;
                        &nbsp;

                        <TextfieldInput name={""} noLabel={true} tag={"search_houseNumber"} type={"reactselect"} inline ph={"HNr"} style={{width: "100px", marginRight: 0}}
                                        onMenuOpen={() => this.afterUpdate("menuOpen", "houseNumberPreSelect")} noOptionsMessage={() => "zuerst Straße"}
                                        selectives={this.state.houseNumbers.sort((a, b) => parseInt(a) - parseInt(b)).map(s => ({label: s, value: s}))}/>
                    </>
                    }
                    &nbsp;
                    &nbsp;
                    {
                        (!!search?.houseNumber || this.context?.club?.verein_ID !== 9 || search?.country !== "AT") &&
                        <TextfieldInput name={"Tür / Stiege"} noLabel tag={"search_houseNumberAdd"} style={{width: "76px"}} ph={"Tür / Stiege"}/>
                    }
                </div>
                <br/>


            </FormContextWrapper>
        </div>

    }
}
