import React from "react";
import {apiGet, apiPost} from "../core/api";
import {FormContextWrapper} from "../core/form_context";
import {Container, LightContainer, Loader, MaxBtn} from "../core/components/components";
import {MyModal} from "../core/components/modal";
import {CheckboxInput, InputContainer, TextareaInput, TextfieldInput} from "../core/input/basic";
import {SelectfieldInput} from "../core/input/select";
import ChangeAddressExt from "./address_input";
import Consts from "../core/consts";
import Status from "../core/status";
import {FaInfoCircle} from "react-icons/fa";
import {UserContext} from "./UserContext";
import {coronaMessageExists, RegisterAusserordentlichesMitglied, RegisterDisclaimer, RegisterOrdentlichesMitglied} from "./registerDisclaimers";
import {AdditionalFields} from "./user_additionalFields";
import {getAge} from "../buchhaltung/helper";
import DatePicker from "react-datepicker";
import {checkPermissions} from "../club/permissionsHelpers";

export default class AddNewPerson extends React.Component {
    setState = this.setState.bind(this);
    state = {
        new: {
            token: Math.round(Math.random() * 1e8),
            member: 0,
            shouldGetEmail: 0,
            country: "AT",
        },
        action: "add"
    };

    constructor(props) {
        super(props);
        this.apiGet = apiGet.bind(this);
        this.apiPost = apiPost.bind(this)

    }

    handleSubmit = (e) => {
        e.preventDefault();
        const {user, club} = this.context;
        if (this.state.action === "add") {
            if (this.state.new.approved !== 1) {
                this.setState({error: "Bitte zuerst die Datenschutzerklärung akzeptieren!"});
                return;
            }
            if (coronaMessageExists(club) && this.state.new.covid19 !== 1) {
                this.setState({error: "Bitte zuerst die Kenntnisnahme der Covid 19 Hinweise bestätigen!"});
                return;
            }
            if (this.state.new.acceptResp !== 1 && (getAge(this.state.new) || 18) < 18) {
                this.setState({error: "Bitte zuerst akzeptieren, dass du als Verwalter verantwortlich bist!"});
                return;
            }
            this.setState({loading: true});
            this.apiPost("/user/register", {...this.state, verwalter_ID: this.props.verwalter_ID || null}, resp => this.setState({loading: false, action: "finish"}));

        }
    };


    date = new Date();
    static contextType = UserContext;

    render() {
        const child = this.state.new;
        const id = "new";
        const {user, club} = this.context;


        const age = getAge(child);


        let childsSelect = this.props.childs || [];

        return <FormContextWrapper
            value={{state: this.state, setState: this.setState}}
            onSubmit={this.handleSubmit}
        >

            <Container name={(!this.props.name || (!!this.props.match && !!this.props.match.params.userID)) ? "Neu registrieren " : this.props.name}
                       visible={!!this.props.defaultOpen}
                       style={{marginTop: "-40px"}}>
                {this.state.action !== "finish" &&
                <>
                    <InputContainer style={{display: "block"}}>
                        <SelectfieldInput name={"Geschlecht"} tag={id + "_sex"} selectives={[["fem", "Weiblich"], ["male", "Männlich"],]} demandSelect/>
                    </InputContainer>
                    {
                        [...["titlesAnte", "titlesPost", "prename", "lastname", "email", "phone", "svn", "birthdate", "Gesetzlicher Vertreter", "Notfallnummer", "address", "note"], ...(checkPermissions(this.context, "user") ? ["internalnote"] : [])]
                            .map((k, i) => {
                                if (k === "address") {
                                    return <div>
                                        <ChangeAddressExt child={child} changeAddress={a => this.setState({[id]: {...child, ...a}})}/>
                                        {i % 2 === 1 && <br/>}
                                    </div>
                                }
                                if (k === "Gesetzlicher Vertreter" || k === "Notfallnummer") {
                                    if (age === null || age >= 18 || user.role > 20) {
                                        return null
                                    } else {
                                        return k === "Gesetzlicher Vertreter" ?
                                            <InputContainer>
                                                <SelectfieldInput name={"Gesetzlicher Vertreter"} tag={id + "_erzberID"}
                                                                  demandSelect
                                                                  selectives={
                                                                      childsSelect
                                                                          .filter(c => (this.date.getFullYear() - c.birthdate / 10000) >= 18)
                                                                          .map(a => [a.ID, a.name || a.fullname])
                                                                  }/>
                                                {(i % 2 === 1) && <br/>}
                                            </InputContainer> :
                                            <InputContainer>
                                                <TextfieldInput name={"Notfallnummer"} tag={id + "_phoneAdd"}/>
                                                {(i % 2 === 1) && <br/>}
                                            </InputContainer>

                                    }
                                }

                                if (k === "note" || k === "internalnote") {
                                    return <InputContainer className={"textarea"}>
                                        <TextareaInput name={<>{k === "note" ? "Gesundheitliche Einschränkungen und Hinweise (optional)" : "Interne Notizen"}
                                            &nbsp;
                                            <MyModal trigger={<FaInfoCircle/>} on={"hover"}>
                                                <h2>Gesundheitliche Einschränkungen und Hinweise (optional)</h2>
                                                Um noch besser auf die Bedürfnisse der Kinder eingehen zu können bzw. im Notfall richtig gehandelt wird, ist es von großem Vorteil, wenn der Trainer über gesundheitliche Einschränkungen Bescheid
                                                weiß bzw. Hinweise dazu angegeben werden. Weiters werden zu besonderen Anlässen (Weihnachten, Fasching, letzte Einheit usw.) oftmals eine Kleinigkeit zu essen ausgegeben, deshalb bitte Allergien
                                                und Unverträglichkeiten angeben.<br/>
                                                Mit der Bekanntgabe erklärt sich der gesetzliche Vertreter mit der Verarbeitung und Weitergabe an die Trainer einverstanden. Art. 6 Abs. 1 lit. D DSVGO: „Schutz lebenswichtiger Interessen“
                                            </MyModal>
                                        </>
                                        } tag={id + "_" + k}/>
                                    </InputContainer>
                                }

                                if (typeof child[k] === "boolean") {
                                    return <InputContainer>
                                        <CheckboxInput name={k[0].toUpperCase() + k.substr(1)} tag={id + "_" + k}/>
                                        {i % 2 === 1 && <br/>}
                                    </InputContainer>
                                }
                                if (k === "svn") {
                                    if (child.country !== "AT" || !club.svnRequired) {
                                        return;
                                    }
                                    return <InputContainer>
                                        <TextfieldInput key={"textfieldinput" + id} name={<>
                                            {Consts.translate(k)}
                                            &nbsp;
                                            <MyModal trigger={<FaInfoCircle/>} on={"hover"}>
                                                <h2>Sozialversicherungsnummer</h2>
                                                Die Angabe der Sozialversicherungsnummer dient lediglich zur eindeutigen Identifikation von Personen und wird in unserem System nur verschlüsselt abgespeichert, somit hat darauf niemand Zugriff.
                                            </MyModal>
                                            <UserContext.Consumer>
                                                {context => context?.user?.role > 80 && <MyModal trigger={<FaInfoCircle/>} on={"hover"}>
                                                    <h2>Person ohne SVN hinzufügen</h2>
                                                    Bitte im Feld Sozialversicherungsnummer das Geburtsdatum ohne führende Ziffern im Format TTMMJJ angeben.
                                                </MyModal>}
                                            </UserContext.Consumer>
                                        </>} tag={id + "_" + k} ph={Consts.placeHolders[k]}/>
                                        {(i % 2 === 1) && <br/>}
                                    </InputContainer>
                                }
                                if (k === "birthdate") {
                                    if (child.country === "AT" && club.svnRequired) {
                                        return;
                                    }
                                    let d;
                                    if (child.birthdate > 0) {
                                        let date = `${child[k]}`;
                                        let date_string = `${date.substr(0, 4)}-${date.substr(4, 2)}-${date.substr(6, 2)}`;
                                        d = new Date(date_string);
                                    }

                                    return [<InputContainer>
                                        <label key={"gbrtst" + id}><span>Geburtsdatum</span>
                                            <DatePicker selected={d}
                                                        locale={"de"}
                                                        dateFormat={"dd.MM.yyyy"}
                                                        placeholderText={"00.00.0000"}
                                                        showYearDropdown
                                                        peekNextMonth
                                                        showMonthDropdown
                                                        maxDate={new Date()}
                                                        dropdownMode="select"
                                                        onChange={(date) => {
                                                            if (date) {
                                                                let datemy = date.getFullYear() * 10000 + (date.getMonth() + 1) * 100 + date.getDate()
                                                                this.setState({[id]: Object.assign({}, this.state[id], {birthdate: datemy})})
                                                            }
                                                        }
                                                        }/></label>
                                    </InputContainer>, <br/>];
                                }

                                return <InputContainer>
                                    <TextfieldInput key={"textfieldinput" + id} name={Consts.translate(k)} tag={id + "_" + k} ph={Consts.placeHolders[k]}/>
                                    {(i % 2 === 1) && <br/>}
                                </InputContainer>
                            })

                    }
                    <AdditionalFields registerMode={true}/>
                    <br/>
                    <LightContainer name={"Abschließen"}>
                        {
                            !!user && user.role >= 80 && age >= 18 && false &&
                            <>
                                <CheckboxInput name={"shouldGetEmail"} tag={"new_shouldGetEmail"} resetOnUnmount noLabel/> Diese Person soll Zugang zu dieser online Verwaltungssoftware bekommen (ein Link zum Einrichten des Passwortes wird versendet).
                                <br/>
                                <br/>
                            </>
                        }
                        {
                            (age === null || age > 18 || club.verein_ID !== Consts.CLUB_ITV_INNSBRUCK) && ![Consts.CLUB_ATV_ANDORF, Consts.CLUB_GUN_CLUB_LUSTENAU, Consts.CLUB_ARTANDDANCE_CENTER, Consts.CLUB_ARTANDDANCE_CONNECTION, Consts.CLUB_OETB_LINZ, Consts.CLUB_TV_STEYR].includes(club?.verein_ID) && !club?.noMembership && <>
                                <p>
                                    <div style={{paddingBottom: "30px", float: "left", paddingRight: "10px"}}>
                                        <CheckboxInput name={"akzeptiere"} tag={"new_member"} resetOnUnmount noLabel/>
                                    </div>
                                    {
                                        age !== null && age < 18 ?
                                            <RegisterAusserordentlichesMitglied club={club} subjectAndPraedicate={"Diese Person soll"}/> :
                                            <RegisterOrdentlichesMitglied club={club} subjectAndPraedicate={"Diese Person soll "}/>
                                    }
                                </p>
                            </>
                        }
                        <br/>

                        <RegisterDisclaimer club={club} registration={"new"} age={age}/>
                        <br/>
                        <Status type={"error"} text={this.state.error}/>
                        <Status type={"success"} text={this.state.success}/>

                        <MaxBtn>Registrieren</MaxBtn>
                        <Loader loading={this.state.loading}/>

                    </LightContainer>
                </>
                }
                {this.state.action === "finish" &&
                <LightContainer name={"Registration abgeschlossen"}>
                    <Status type={"success"} text={"Erfolgreich registriert, du solltest ein E-Mail mit einem Link zum einrichten deines Passwortes erhalten haben!"}/>
                </LightContainer>
                }
            </Container>
        </FormContextWrapper>
    }
}
