import React, {useMemo, useState} from 'react';
import "../dashboard.sass"
import Consts from "../core/consts";
import Status from "../core/status";
import "./abrechnung.sass"
import {Container, InfoTooltip, LightContainer, Loader, MaxBtn} from "../core/components/components";
import {MyModal} from "../core/components/modal";
import {TextfieldInput} from "../core/input/basic";
import {FaBalanceScale, FaCreditCard, FaList, FaMoneyBill, FaTags, FaUser, FaWind} from "react-icons/fa";
import {AbrechnungsContext} from "./context";
import {dateFormatUnix, dateFormatUnixInline} from "../core/dateFuncs";
import {apiGet, apiPost} from "../core/api";
import "../style/modal.sass"
import {FormContextWrapper} from "../core/form_context";
import {UserContext} from "../user/UserContext";
import {Link} from "react-router-dom";

import {maxiGet, maxiPost} from "../core/maxios";
import {TiArrowBackOutline} from "react-icons/all";
import {SelectfieldInput} from "../core/input/select";

const showLimit = 4;

export default class Zahlungen extends React.Component {
    static contextType = AbrechnungsContext;

    constructor(props) {
        super(props);
        this.state = {
            open: 2,
            eingaben: 0,
            payments: [],
            verwalter: true,
            showAll: false,
        };
        this.apiGet = apiGet.bind(this)
    }

    componentDidMount = () => this.loadPayments()


    loadPayments = () => {
        this.apiGet("/user/payments/list/" + this.props.userID, resp => {
            this.setState({...resp});
            /*console.log("setting saldo",resp.saldo)
            const eingaben = resp.payments.reduce((s, z) => {
                return s + z.amount
            }, 0);
            this.state.eingaben = eingaben;*/
            this.context.setState({eingaben: resp.saldo, saldo: resp.saldo, saldos: resp.saldos, reloadPayments: this.loadPayments});
        });
    }

    render() {
        //console.log(this.context)
        if (this.state.payments.length === 0 && !this.state.verwalter) {
            return null
        }

        const paymentsSortedAndSliced = this.state.payments
            .sort((a, b) => b.time - a.time)
            .slice(0, this.state.showAll ? 10000000 : showLimit)

        return (
            <LightContainer name={"Meine Zahlungen"} visible class={"centercontent"}>
                <UserContext.Consumer>
                    {
                        userContext => <>
                            {
                                userContext.user !== undefined && userContext.user.role > 80 &&
                                <>
                                    {
                                        this.state.verwalter ?
                                            <AddGuthaben child_ID={this.props.userID} reload={this.loadPayments}/> :
                                            this.state.payments.length > 0 && "Diese Zahlungen sind dieser Person zugeordnet, da sie in der Vergangenheit einmal Verwalter war."
                                    }
                                </>
                            }
                            <Status type="error" text={this.state.error}/>
                            <Status type="success" text={this.state.status}/>
                            <ul>
                                <li>
                                    <FaBalanceScale/> Saldo jetzt: {Consts.moneyMax(Math.abs(this.state.saldo) / 100)} {this.state.saldo > 0 && "Guthaben"} {this.state.saldo < 0 && "Offenstand"}
                                </li>
                                <PrintSaldosInbetween saldos={this.state.saldos} after={null} before={paymentsSortedAndSliced[0]?.time}/>
                                {

                                    paymentsSortedAndSliced.map((z, index) => {
                                        let sourceDescription
                                        try {
                                            const creatorID = parseInt(z.source);
                                            if (!isNaN(creatorID)) {
                                                sourceDescription = JSON.parse(z.sourceDescription)
                                            }
                                        } catch (e) {
                                        }
                                        return <>

                                            <li>
                                                <PaymentIcon style={{verticalAlign: "bottom"}} type={z.kind}/> &nbsp;
                                                <span style={{width: "120px", display: "inline-block"}}>{dateFormatUnixInline(z.time)}:</span>
                                                {z.kind === "manual" ? "manuelle Guthabenbuchung" : Consts.translate(z.kind)} iHv. {Consts.moneyMax(z.amount / 100, z.currency)},
                                                {(z.kind !== "card" && z.kind !== "sofort" && z.kind !== "sepa_debit" && z.text !== "") && " Referenz: " + z.text.replace(" ,", ", ")}
                                                {z.status === 4 ? <><b> (Fehlgeschlagen)</b></> : ""}
                                                {z.status === 3 && <b> (noch nicht bestätigt)</b>}
                                                {z.status === 6 && <b> (storniert)</b>}
                                                <PrintCardDetails z={z}/>
                                                <PrintSepaDetails z={z}/>
                                                {userContext.user !== undefined && userContext.user.role > 80 && <>

                                                    {
                                                        !!sourceDescription && <>
                                                            {
                                                                !!sourceDescription.name &&
                                                                <>, von <Link to={"/benutzer/profil/" + z.source}>{sourceDescription.name}</Link> gebucht</>
                                                            }
                                                            {
                                                                !!sourceDescription.konto &&
                                                                <>, <Link to={"/buchhaltung/buchungen/" + sourceDescription.konto}>zum Konto</Link></>
                                                            }
                                                        </>
                                                    }
                                                    {
                                                        z.kind === "manual" && <>, von {z?.sourceDescription?.name} gebucht</>
                                                    }
                                                    {
                                                        z.amount < 0 && z.kind !== "manual" && <>, Refundierung {z.text.split(",").map(a => ({duplicate: "weil Duplikat"}[a] || a))}</>
                                                    }
                                                    {
                                                        !!["card", "sofort", "sepa_debit"].includes(z.kind) && !!z.ID_ext && z.ID_ext.startsWith("py_") && <>
                                                            &nbsp;<InfoTooltip><ShowPayoutStatus {...{z, sourceDescription}}/></InfoTooltip>

                                                        </>
                                                    }
                                                    {
                                                        ["card", "sofort", "sepa_debit", "sepm_debit"].includes(z.kind) && (!!z.ID_ext || (z.kind === "sepm_debit" && z.status === 3)) && <>
                                                            <RefundModal payment={z} reload={this.loadPayments}/>
                                                        </>
                                                    }
                                                </>
                                                }

                                            </li>
                                            <PrintSaldosInbetween after={z.time} saldos={this.state.saldos} before={paymentsSortedAndSliced[index + 1]?.time}/>
                                        </>
                                    })
                                }
                                {!this.state.showAll && showLimit <= this.state.payments.length && <em style={{marginLeft: 0}} onClick={() => this.setState({showAll: true})}>alle anzeigen</em>}
                            </ul>
                        </>
                    }

                </UserContext.Consumer>
                {
                    this.state.payments.length === 0 && this.state.verwalter && "Noch keine vorhanden."
                }
            </LightContainer>
        )
    }
}
const prettify = s => s.split("_").map(w => w[0].toUpperCase() + w.substr(1)).join(" ");

function PrintSaldosInbetween({saldos, before, after}) {
    if (!saldos?.length) {
        return null
    }
    return saldos
        ?.filter(({t}) => (before ?? 0) < t && (after === null || t < after))
        .slice(0, before ? 10 : 1)
        .map(({saldo, t}) => <li>
            <FaBalanceScale/> Saldo per {dateFormatUnix(t)}: {Consts.moneyMax(Math.abs(saldo) / 100)} {saldo > 0 && "Guthaben"} {saldo < 0 && "Offenstand"}
        </li>)
}


function PrintCardDetails({z}) {
    if (z.kind !== "card") {
        return null
    }
    const {brand, last4} = z.sourceDescription;
    return <>
        &nbsp; {!!brand && prettify(brand)} <span>****</span>{last4}
    </>
}

function PrintSepaDetails({z}) {
    if (z.kind !== "sepa_debit" && z.kind !== "sofort" && z.kind !== "sepm_debit") {
        return null
    }
    const {country, last4, iban_last4, bank_name} = z.sourceDescription;
    return <>
        &nbsp; Konto {country} <span>****</span>{last4 || iban_last4}{!!bank_name ? ` (${bank_name})` : ""}
    </>
}

class AddGuthaben extends React.Component {
    state = {
        new: {
            amount: 0,
            note: "",
        }
    };

    constructor(props) {
        super(props);
        this.apiPost = apiPost.bind(this);
        this.setState = this.setState.bind(this);
    }

    render() {
        return <MyModal trigger={<MaxBtn style={{float: "right", marginTop: "-40px"}}>Guthaben/Vorschreibung hinzufügen</MaxBtn>} modal>
            {close =>
                <Container name={"Guthaben/Vorschreibung hinzufügen"}>
                    <Status type={"error"} text={this.state.error}/>
                    <FormContextWrapper
                        value={{state: this.state, setState: this.setState, updateState: (a, b, c) => this.setState({[a]: {...this.state[a], [b]: c}})}}
                        onSubmit={e => {
                            e.preventDefault();
                            this.setState({loading: true})
                            this.apiPost("/user/payments/add_manual", {child_ID: this.props.child_ID, ...this.state.new}, resp => {
                                this.setState({new: {}, error: "", loading: false})
                                this.props.reload()
                                close()
                            })
                        }}
                    >
                        <TextfieldInput name={<>Betrag (in EUR)</>} tag={"new_amount"}/> (positiv falls Guthaben, negativ falls Vorschreibung)<br/>
                        <TextfieldInput name={"Bemerkung"} tag={"new_text"} ph={"z.B. Mahnspesen"}/><br/>

                        <MaxBtn>Hinzufügen</MaxBtn> <Loader loading={this.state.loading}/>
                    </FormContextWrapper>
                </Container>
            }
        </MyModal>
    }
}

const PaymentIcon = ({type}) => {
    if (type === "card") {
        return <FaCreditCard/>
    }
    if (type === "sofort") {
        return <FaWind/>
    }
    if (type === "manual") {
        return <FaUser/>
    }
    if (type === "voucher") {
        return <FaTags/>
    }
    return <FaMoneyBill/>
};


function ShowPayoutStatus({z, sourceDescription}) {
    const [data, setData] = useState({})
    const [{error, loading, success}, setStatusVar] = useState({loading: true});

    useMemo(() => !!z.ID_ext && maxiGet("/user/payments/stripe_info/" + z.ID_ext, {setStatusVar}).then(({balance_transaction, charge, payout}) => {
        setData({
            "Status": charge.outcome?.seller_message,
            "Verfügbar am": balance_transaction.available_on > 0 ? dateFormatUnix(balance_transaction.available_on) : null,
            "Betrag": Consts.moneyMax(balance_transaction.amount / 100),
            "Gebühr": Consts.moneyMax(balance_transaction.fee / 100),
            "Netto": Consts.moneyMax(balance_transaction.net / 100),
            "Auszahlung am": payout.arrival_date > 0 ? dateFormatUnix(payout.arrival_date) + " inkludiert in Zahlung über " + Consts.moneyMax(payout.amount / 100) : null,
            "Mehr Informationen": <a target="_blank" href={"https://dashboard.stripe.com/payments/" + charge.id}>dashboard.stripe.com</a>,
        })
    }), [z.ID])
    return <Container name={"Information von Stripe"}>
        <Loader loading={loading}/>
        <table>
            {
                Object.entries(data).map(([key, val]) => <tr>
                    <td>{key}</td>
                    <td>{val}</td>
                </tr>)}
        </table>
    </Container>
}

function RefundModal({payment, reload}) {
    const [refunded, setRefunded] = useState(payment.refundType === 1)
    const [state, setState] = useState({refund: {amount: parseInt(payment.amount) / 100, text: "", reason: "other"}})
    const [{error, loading}, setStatusVar] = useState({})

    const refund = () => {
        const amount = parseFloat(state.refund.amount)
        if (amount <= 0 || amount > parseInt(payment.amount) / 100) {
            setStatusVar({error: `Der Betrag muss zwischen € 0,01 und € ${parseInt(payment.amount) / 100} liegen.`})
            return
        }
        maxiPost("/user/payments/refund/" + payment.ID, {...state.refund}, {setStatusVar}).then(resp => {
            setRefunded(true)
            reload && reload()
        })
    }

    const title = payment.kind === "sepm_debit" ? "Zahlung stornieren" : "Zahlung refundieren"

    return payment.refundType === 1 ? null : <MyModal trigger={<span> <TiArrowBackOutline/></span>}>
        <Container name={title}>
            <Status type={"error"} text={error}/>
            {
                refunded && <>Die Zahlung wurde bereits refundiert.</>
            }

            {
                !refunded && <>
                    {
                        payment.paymentMethod === "sepa_debit" && <>SEPA-Refundierungen können bis zu 8 Wochen nach Zahlung angefordert werden. Sollte der Kunde einen Disput anmelden, dann kann es nach einer angemeldeten Refundierung von Seiten von Stripe passieren, dass die Zahlung 2x erstattet
                            wird.</>
                    }
                    <FormContextWrapper value={{state, setState}} onSubmit={refund}>
                        {
                            payment.kind !== "sepm_debit" && <>
                                <TextfieldInput tag={"refund_amount"} name={"Refundierungsbetrag"}/><br/>
                                <SelectfieldInput tag={"refund_reason"} name={"Grund"} type={"reactselect"} selectives={[
                                    {label: "Duplikat", value: "duplicate"},
                                    {label: "Vom Mitglied angefragt", value: "requested_by_customer"},
                                    {label: "Betrugsabsicht", value: "fraudulent"},
                                    {label: "Anderer Grund", value: "other"},
                                ]}/>
                            </>
                        }
                        {
                            state.refund?.reason === "other" &&
                            <>
                                <TextfieldInput tag={"refund_text"} name={"Bemerkung"}/><br/>
                            </>
                        }
                        <MaxBtn>{title}</MaxBtn>
                    </FormContextWrapper>
                </>
            }
        </Container>
    </MyModal>
}
