import {UserContext} from "../user/UserContext";
import React from "react";

const directAndNonRead = (a, c) => {

    return a.includes(c) || a.includes(c.replace("_read", "")) || false
}

export function checkPermissions(context, permission, entity_ID) {
    if (!context?.user?.permissions) {
        return false
    }

    if (context?.user?.role >= 10) {
        if (permission.includes("_read") && context.user.role >= 50) {
            return true
        } else if (context.user.role >= 80) {
            return true
        }
    }
    return (entity_ID && (directAndNonRead(context.user.permissions, permission + "|" + entity_ID) || directAndNonRead(context.user.permissions, permission.split("/")[0] + "|" + entity_ID))) || directAndNonRead(context.user.permissions, permission) || directAndNonRead(context.user.permissions, permission.split("/")[0]) || false
}

export function ShowOnlyIfAdmin({children, orOtherCond = false}) {
    return <UserContext.Consumer>
        {
            context => context.user !== undefined && (context.user.role > 30 || orOtherCond) &&
                children
        }
    </UserContext.Consumer>
}
