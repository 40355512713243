import React from "react";
import Courses from "./courses";
import CourseOverview from "./course_view";
import CoursesAvailable from "./courses_available";

export default class BookingRouter extends React.Component {
    componentDidMount() {
        console.log("mounted router")
    }

    componentWillUnmount() {
    }

    render() {
        const {location} = this.props
        return <>
            <div style={{display: location.pathname !== "/kurs/buchung" ? "none" : "block"}}>
                <CoursesAvailable key={"buchung"} {...this.props}/>
            </div>
            {location.pathname !== "/kurs/buchung" && <CourseOverview key={this.props.match.params.courseID} {...this.props}/>}
        </>
    }
}