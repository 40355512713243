import React, {useContext, useMemo, useState} from 'react';
import {InfoTooltip, Loader, MaxBtn, MiniBtn} from "../core/components/components";
import {MyModal} from "../core/components/modal";
import {CheckboxInput, InputContainer, TextfieldInput} from "../core/input/basic";
import {SelectfieldInput} from "../core/input/select";
import {EditorInput} from "../core/input/texteditor";
import "./course_form.css"
import Status from "../core/status";
import {apiGet, apiPost} from "../core/api";
import {UserContext} from "../user/UserContext";


import {FormContextWrapper} from "../core/form_context";
import {dateFormatTime} from "../core/dateFuncs";
import {maxiGetObj} from "../core/maxiosLeg";
import {FaChartBar, FaEye, FaEyeSlash} from "react-icons/fa";
import NewsletterStats from "../newsletter/newsletter_stats";
import {checkPermissions} from "../club/permissionsHelpers";
import DOMPurify from "dompurify"
import {Container, LightContainer} from "../core/components/container";
import myDeepQual from "../core/myDeepEqual";
import {Link} from "react-router-dom";
import {maxiPost} from "../core/maxios";

class CourseNews extends React.Component {
    newFormTemplate = { // just a object template for below
        email: true,
        sms: true,
        textLong: "",
        textShort: "",
        subject: "",
        towhom: "all",
        showInNews: true
    };

    state = {
        error: "",
        news: [],
        loading: true,
        new: this.newFormTemplate
    };
    static contextType = UserContext;
    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({loading: true})
        this.apiPost("/course/news/add/" + this.id, {...this.state.new, sendto: this.state.sendto}, resp => {
            this.setState({new: this.newFormTemplate, ...resp, loading: false, success: "Erfolgreich versandt!"}, this.loadRecipients);
            //this.loadNews()
        });
    };

    constructor(props) {
        super(props);
        this.id = props.id;
        this.apiGet = apiGet.bind(this);
        this.apiPost = apiPost.bind(this);
        this.setState = this.setState.bind(this);
        this.loadNews()
    }

    loadNews = () => this.apiGet("/course/news/list/" + this.id, resp => {
        // console.log("news", this.props)
        if (resp.news.length === 0 && this.context.user !== undefined && this.context.user.role < 80) {
            this.props.hide(false)
            //document.getElementById("neuigkeiten").style.display="none"
        }
        this.setState({...resp})
    })

    loadRecipients = (force = false) => {
        const {email, sendTrainers, sendVerwalters, sms, towhom} = this.state.new

        const recipientCacheState = {email, sendTrainers, sendVerwalters, sms, towhom, sendto: this.state.sendto,}

        if (!myDeepQual(this.state.lastData, recipientCacheState) || force) {
            this.setState({lastData: recipientCacheState})
            this.apiPost("/course/news/get/recipients/" + this.id, {...this.state.new, sendto: this.state.sendto}, (resp) => {
                this.setState({recipients: resp})
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // console.log(this.props, prevProps)
        if (prevProps.id !== this.props.id) {
            this.id = this.props.id
            this.loadRecipients(true)
        }
    }

    componentDidMount = () => {
        if (this.props.trainerIsAndAllowed || checkPermissions(this.context, "course/news", this.id)) {
            this.loadRecipients()
        }
    }

    render() {
        if (this.state.news.length === 0 && this.context.user !== undefined && !this.props.trainerIsAndAllowed && !checkPermissions(this.context, "course/news", this.id)) {
            return null
        }
        const {user} = this.context;
        const sendMethodMap = {whatsapp: "WhatsApp", email: "E-Mail", sms: "SMS"}
        const sms = ` informiert zu : \n \nhttps://${this.context?.club?.domain}/kurs/${this.id}`
        const smsLimit = 140 - sms.length - Math.min(this.context.user?.prename?.length || 20, 20) - Math.min(this.props.name?.length || 40, 40)

        return <>
            {!this.props.hideExistingNews &&
                this.state.news.map(n => (
                    <div className={"courselist"} style={{opacity: n.content.showInNews ? 1 : 0.5}}>
                        {
                            (this.props.trainerIsAndAllowed || checkPermissions(this.context, "course/news", this.id)) && <StatsViewer news={n} loadNews={this.loadNews}/>
                        }
                        <h3 className={"sms"}>{n.content.subject || "-"} </h3>
                        {
                            n.content?.textShort && <div><b>SMS- / WhatsApp-Nachricht</b>: {n.content?.textShort}
                                <hr/>
                            </div>
                        }
                        <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(n.content.textLong)}}/>
                        <b>{n.child.fullname + ", "} am {dateFormatTime(n.content.time)}</b>
                    </div>
                ))
            }
            {
                //this.state.news.length === 0 && "Derzeit sind noch keine Neuigkeiten verfügbar."
            }
            <Status type="error" text={this.state.error}/>
            <Loader loading={this.state.loading}/>

            {
                (this.props.trainerIsAndAllowed || checkPermissions(this.context, "course/news", this.id)) && <>

                    <hr/>
                    <h2>Neue Neuigkeit</h2>
                    <FormContextWrapper value={{
                        state: this.state, setState: (a) => {
                            this.setState(a, this.loadRecipients)
                        }
                    }} onSubmit={this.handleSubmit}>

                        <b>Nachricht</b><br/>
                        <TextfieldInput name={"Betreff (für E-Mails)"} tag={"new_subject"} style={{width: "600px"}}/><br/>
                        <TextfieldInput name={<span style={{color: this.state.new.textShort.length > smsLimit ? "red" : "inherit"}}>Text für SMS ({this.state.new.textShort.length} / {smsLimit})</span>} style={{width: "600px"}} tag={"new_textShort"}/>
                        <br/>
                        <EditorInput tag={"new_textLong"} name={<b>Langtext für E-Mails (unbegrenzt) und WhatsApps (1400 Zeichen, Zeilenumbruch wird in Leerzeichen verwandelt)</b>}/>
                        <InputContainer>
                            <CheckboxInput tag={"new_email"} name={"Teilnehmer per E-Mail informieren"}/>
                        </InputContainer>
                        <InputContainer>
                            <CheckboxInput tag={"new_sms"} name={"Teilnehmer per SMS oder WhatsApp informieren"}/>
                        </InputContainer>
                        <br/>
                        <InputContainer>
                            <SelectfieldInput tag={"new_towhom"} name={"Senden an"} selectives={[
                                ["registered", `angemeldete Personen` + (!!this.props.partsCount || this.props.partsCount === 0 ? ` (${this.props.partsCount})` : "")],
                                ["waitlist", `Personen auf Warteliste` + (!!this.props.waitlistCount || this.props.waitlistCount === 0 ? ` (${this.props.waitlistCount})` : "")],
                                ["all", `beide Personengruppen` + ((!!this.props.partsCount || this.props.partsCount === 0) && (!!this.props.waitlistCount || this.props.waitlistCount === 0) ? ` (${this.props.partsCount + this.props.waitlistCount})` : "")],
                                ["individual", `individuell auswählen`],
                            ]}/>
                        </InputContainer>
                        <InputContainer>
                            <CheckboxInput tag={"new_showInNews"} name={<>Hier Anzeigen <InfoTooltip>Wenn dies ausgewählt ist, sieht jeder diese Text hier in VEREINfacht unter Neuigkeiten.</InfoTooltip></>}/>
                        </InputContainer>
                        <InputContainer>
                            <CheckboxInput tag={"new_sendTrainers"} name={<>Trainern auch senden</>}/>
                        </InputContainer>
                        <InputContainer>
                            <CheckboxInput tag={"new_sendVerwalters"} name={<>Verwaltern auch senden</>}/>
                        </InputContainer>
                        {
                            this.state.new.towhom === "individual" &&
                            <RecipientSelector sendto={this.state.sendto} course_ID={this.id}/>
                        }
                        <br/>

                        <LightContainer>
                            {!!this.state.recipients && <>
                                <h3>Empfänger:</h3>
                                <ul>
                                    {
                                        Object.entries(sendMethodMap)
                                            .map(([key, label]) => {
                                                const enabled = !!this.state.new[{"whatsapp": "sms"}[key] || key];
                                                return <li>
                                                    <b>{label}{enabled && " (" + this.state.recipients.filter(x => x[key]).length + ")"}:</b>&nbsp;
                                                    {
                                                        enabled ? this.state.recipients
                                                                .sort((a, b) => a.fullname < b.fullname ? -1 : 1)
                                                                .filter(x => x[key])
                                                                .map(x => <><Link to={"/benutzer/liste/" + x.child_ID}>{x.fullname}</Link>, </>) :
                                                            "nicht ausgewählt"
                                                    }
                                                </li>
                                            })
                                    }
                                </ul>
                            </>
                            }
                        </LightContainer>

                        <Status type="error" text={this.state.error}/>
                        <Status type="success" text={this.state.success}/>
                        <MaxBtn>Senden</MaxBtn> <WhatsappInfoMessage/>

                    </FormContextWrapper>

                </>
            }

        </>

    }
}

function WhatsappInfoMessage() {
    return <InfoTooltip>
        <Container name={"Was wird versandt?"}>
            <ul>
                <li><b>E-Mails:</b> Eine E-Mail-Nachricht mit dem Betreff (min. 5 Zeichen) und dem Langtext (wie im Editor).</li>
                <li><b>SMS:</b> Eine SMS-Nachricht, deren individueller Text höchstens so viele Zeichen haben darf, damit sie gesamt maximal 140 Zeichen hat. Dieser Wert wird aus der Länge des Namens des Autors und des Kursnamens errechnet.</li>
                <li><b>WhatsApp:</b> Eine Whatsapp-Nachricht, deren Text der oben eingegebene Langtext ist. Zeilenumbrüche werden allerdings in Leerzeichen umgewandelt, es ist also nicht möglich, Absätze zu machen. Dies ist leider eine Vorgabe von
                    WhatsApp, die man für automatisch generierte Nachrichten nicht umgehen kann.
                </li>
            </ul>
        </Container>
    </InfoTooltip>
}

function RecipientSelector({course_ID, sendto}) {
    const [parts, setParts] = useState([]);
    useMemo(() => {
        maxiGetObj({
            url: "/course/parts/" + course_ID, success: resp => {
                setParts(resp.parts.filter(({child, registration}) => registration.role === 0 && registration.timeLeave === 0))
            }
        });
    }, [course_ID])
    return <>
        <h3>Empfänger auswählen</h3>
        <div className={"selectColumns"}>
            {
                parts
                    .sort((a, b) => a.child?.lastname + a.child?.prename < b.child?.lastname + b.child?.prename ? -1 : 1)
                    .map(({child}) => (
                        <label style={{paddingRight: "10px"}}>
                            <input checked={(sendto || {})["c" + child.ID] || false} name={"sendto_c" + child.ID} type={"checkbox"}/>
                            {child.fullname}
                        </label>
                    ))
            }
        </div>
    </>
}


function StatsViewer({news, loadNews}) {
    return <div className={"icon-group"}>
        <ShowHereEditor news={news} loadNews={loadNews}/>
        <MyModal trigger={<div className={"statsmodal"}><MiniBtn><FaChartBar/></MiniBtn></div>}>
            <NewsletterStats news_ID={news.content.ID}/>
        </MyModal>
    </div>
}

function ShowHereEditor({news, loadNews}) {
    // for news: type NewsUser = {content: {ID: number, showInNews: boolean}, child: Partial<User>}
    const context = useContext(UserContext)
    const [showInNews, setShowInNews] = useState(news.content.showInNews);
    if (!checkPermissions(context, "course/news", news.content.course_ID)) {
        return null
    }

    const toggleShowHere = () => {
        maxiPost("/course/news/toggle/showInCourse/" + news.content.ID, {}).then(resp => {
            setShowInNews(resp)
            loadNews()
        })
    }

    return <MiniBtn style={{cursor: "pointer", float: "right"}} onClick={() => toggleShowHere()}>
        {showInNews ? <><FaEye/> Angezeigt</> : <><FaEyeSlash/> Versteckt</>}
    </MiniBtn>
}


export default CourseNews;
