
const n = 6;

function PaymentReferenceCheck(id) {
    let str = id.toString();
    let dig;
    let sum = -parseInt(str[str.length - 1]);
    while (str.length <= n) str = '0' + str;
    for (let i = 0; i < n; i++) {
        dig = parseInt(str[i])
        if (i % 2 === 0) {
            dig = dig * 2
        }
        if (dig > 9) {
            dig = dig - 9
        }
        sum += dig
    }
    return (sum % 10) === 0;
}

function PaymentReference(id) {
    let str = id.toString();
    let dig;
    let sum = 0;
    while (str.length < n) str = '0' + str;
    for (let i = 0; i < n; i++) {
        dig = parseInt(str[i])
        if (i % 2 === 0) {
            dig = dig * 2
        }
        if (dig > 9) {
            dig = dig - 9
        }
        sum += dig
    }
    let ref = str + "" + (sum % 10)
    //console.log(ref, PaymentReferenceCheck(ref))
    if (ref[0] === "0") {
        return ref.slice(1)
    }
    return ref
}


export {PaymentReferenceCheck, PaymentReference}