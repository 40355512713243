// Hook
import {useForm, useWatch} from "react-hook-form"
import {useMemo} from "react"

// secondsSinceLastUpdated - Invalidierung bestimmter Daten.
export default function useLocalStorageForm<T extends object>(initialValue: T, key: string, secondsSinceLastUpdated?: number) {
    const form = useForm<T>({
        defaultValues: async () => {
            const local = localStorage.getItem(key)
            return local ? JSON.parse(local) : initialValue
        },
        mode: "onChange",
    })
    const watch = useWatch(form)

    const onChange = () => {
        localStorage.setItem(key, JSON.stringify(form.getValues()))
    }
    useMemo(onChange, [watch])

    return form
}

