import {usePaymentModels} from "./usePaymentModels"
import {AlwaysLoader, MiniBtn} from "../../core/components/components"
import React, {useContext} from "react"
import SelectFieldInput2, {getAccessors, SelectfieldInputProps} from "../../core/input/select2"
import {FaEdit} from "react-icons/all"
import {FormContext} from "../../core/form_context"
import {MyModal} from "../../core/components/modal"
import {CoursePlanEdit} from "./course_plan_edit"
import { Link } from "react-router-dom"

export function CoursePaymentModelSelect({...props}: Omit<SelectfieldInputProps, "selectives"> & { onSubmit: Function }) {
    const [paymentModels] = usePaymentModels({apiUrl: "/course/paymentmodels/active"})

    if (!paymentModels) {
        return <AlwaysLoader/>
    }

    const Component = ({selected}: { selected: any }) => {
        const {state, setState} = useContext(FormContext)
        const courseId = state?.course?.ID
        const parsedSelected = parseInt("" + selected)
        if (isNaN(parsedSelected)) {
            return null
        }

        const updateState = (value: any) => {
            const accessors = getAccessors(props.tag)
            setState((state: any) => ({...state, [accessors[0]]: {...state[accessors[0]], [accessors[1]]: value}}), () => {
                props.onSubmit()
            })
        }

        const editCoursePlan = parsedSelected === 0
        const initialData = editCoursePlan ? undefined : paymentModels.filter(model => model.ID === selected)[0]
        return <>
            <MyModal key={parsedSelected} additionalPaperStyles={"WideDialogPaper"} trigger={<MiniBtn style={{margin: 5}}><FaEdit/></MiniBtn>}>
                {
                    (close) => <>
                        {editCoursePlan ? <h2>Zahlungsweisen des Kurses bearbeiten</h2> : <>
                            <h2>Zahlungsweise importiert von Vorlage bearbeiten - in der Vorlage deaktivierte Zahlungsweisen werden NICHT importiert.</h2>
                            <p>ACHTUNG: Die Änderungen, die hier getätigt werden, werden NICHT an der Vorlage vorgenommen, sondern stattdessen nur für diesen Kurs verwendet und gespeichert.
                                Solltest du Änderungen an der Vorlage machen wollen, mach das bitte unter dem Menüpunkt <Link to={"/zahlungsmodelle"}>Zahlungsweisen</Link></p>
                        </>}
                        <CoursePlanEdit course_ID={courseId} initialData={initialData} onChange={() => {
                            close()
                            updateState("0")
                        }}/>
                    </>
                }
            </MyModal>
        </>
    }

    return <SelectFieldInput2 {...props} multiple={false} RightComponent={Component} labelWidth={300}
                              selectives={[{label: "-", value: null}, {label: "benutzerdefiniert", value: "0"}, ...paymentModels.map(model => ({label: model.name, value: model.ID}))]}/>
}
