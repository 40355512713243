import React from "react"
import {Container, Loader, MaxBtn} from "../core/components/components";
import {MyReactTable} from "../core/components/table";
import {CheckboxInput} from "../core/input/basic";
import {DateTimeInput} from "../core/input/date";
import {SelectfieldInput} from "../core/input/select";

import {apiGet, apiPost} from "../core/api";
import {dateFormatTimeShort} from "../core/dateFuncs";
import {Link} from "react-router-dom";
import {FormContextWrapper} from "../core/form_context";
import UserView from "../user/user_view";
import CourseOverview from "./course_view";
import Consts from "../core/consts";
import {UserContext} from "../user/UserContext";

export default class CourseLastBookings extends React.Component {
    constructor(props) {
        super(props);
        let d = new Date()
        d.setHours(0)
        d.setMinutes(0)
        d.setSeconds(0)
        this.state = {
            bookings: [],
            loading: true,

            search: {
                showMSFees: false,
                showStart: d.getTime() / 1000,
                role: "all",
            }
        };

    }

    setState = this.setState.bind(this)
    apiGet = apiGet.bind(this);
    apiPost = apiPost.bind(this);
    static contextType = UserContext;

    componentDidMount() {
        this.loadData(this.state.search)
    }

    loadData = (search) => {
        this.setState({loading: true})
        this.apiPost("/courses/bookings/list", search, resp => this.setState({...resp, loading: false}))
    };


    render() {
        const {bookings, search, tableData} = this.state;
        const bookingsFilteredRich = bookings.map(b => {
            b["timeString"] = dateFormatTimeShort(b.registration.timeCreation)
            return b
        });
        const bookingsFilteredTable = tableData || bookingsFilteredRich;
        if (!!this.props.match.params.userID) {
            return <UserView {...this.props}/>
        }
        if (!!this.props.match.params.courseID) {
            return <CourseOverview {...this.props}/>
        }

        return <Container name={"Letzte Buchungen"} class={"mobileBroad"} broad>
            {bookingsFilteredTable.length} gefunden
            <FormContextWrapper
                value={{state: this.state, setState: this.setState}}
                afterUpdate={(a, b, val) => this.loadData(val)}
            >
                <CheckboxInput labelWidth={200} name={"Mitgliedsbeiträge anzeigen"} tag={"search_showMSFees"}/>&nbsp;&nbsp;&nbsp;&nbsp;
                <SelectfieldInput name={"Teilnahmeart"} tag={"search_role"} selectives={[["0", "Teilnehmer"], ["all", "Teilnehmer und Warteliste"], ["2", "Warteliste"]]}/>
                <DateTimeInput name={"Anzeigen ab"} tag={"search_showStart"} afterUpdate={(a, b, val) => this.loadData(val)}/>
                <MaxBtn onClick={() => this.loadData(search)}>Neu laden?</MaxBtn>
                <Loader loading={this.state.loading}/>
            </FormContextWrapper>
            <MyReactTable
                data={bookingsFilteredRich}
                loading={this.state.loading}
                exportData={true}
                title={"Letzte Buchungen"}
                columns={[
                    {
                        Header: "Kursname",
                        accessor: "course.name",
                        Cell: ({value, original}) => <Link to={"/kurs/buchungen/kurs/" + original.course.ID}>{value} </Link>,
                        filterable: true,
                    },
                    {
                        Header: "Person",
                        accessor: "child.lastname",
                        pdfCell: ({value, original}) => value + ", " + original.child.prename,
                        Cell: ({value, original}) => <Link to={"/kurs/buchungen/user/" + original.child.ID}>{value}, {original.child.prename}</Link>,
                        filterable: true,
                    },
                    ...["prename", "lastname", "memberStatus"].map(k => ({
                        Header: Consts.translate(k),
                        id: k,
                        defaultHidden: true,
                        filterable: true,
                        accessor: k === "memberStatus" ? (row => this.context.club?.memberStates[row.child.memberStatus] || row.child.memberStatus) : "child." + k,
                    })),
                    {
                        Header: "Status",
                        show: window.innerWidth > 800,
                        accessor: "registration.role",
                        Cell: ({value, original}) => (value === 0 ? "normal" : "Warteliste") + (original.registration.timeLeave > 0 ? " (storniert)" : "")
                    },
                    {
                        Header: "Zeit",
                        accessor: "registration.timeCreation",
                        filterable: true,
                        filterMethod: ({value}, {_original: b}) => b.timeString.indexOf(value) > -1,
                        Cell: ({value, original}) => original.timeString
                    },
                ]}
                onTableChange={data => {
                    this.setState({tableData: data})
                }}
                // manualUpdate={f => this.refreshTable = f}

            />

        </Container>
    }
}
