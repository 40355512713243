import React from 'react';
import "../dashboard.sass"
import Consts from "../core/consts";

import {Container, MiniBtn} from "../core/components/components";
import {MyReactTable} from "../core/components/table";
import {CheckboxInput} from "../core/input/basic";
import Status from "../core/status";
import {dateFormat} from "../core/dateFuncs"
import {apiGet, apiPost} from "../core/api";


import {FormContextWrapper} from "../core/form_context";
import {Link} from "react-router-dom";
import {downloadAsExcel} from "../core/download";
import {UserContext} from "./UserContext";
import {Redirect} from "react-router";
import UserView from "./user_view";
import {FaStickyNote} from "react-icons/all";
import {FaList} from "react-icons/fa";
import {encodeGetParams} from "../core/helpers";
import onFilterChangeForUsers, {TimeoutTextColumnFilter} from "./onFilterChangeForUsers";


export default class OffenständeList extends React.Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);
        this.pn = props.location.pathname || "";
        this.state = {
            open: [props.match.params !== undefined && props.match.params.userID !== undefined ? parseInt(props.match.params.userID) : 0],
            loading: true,
            user_ids: [],
            search: {
                showNullSaldo: false,
                considerPending: true,
                showCredits: false,
            },
            tableData: [],
            showPagination: true,
            filter: {},
            tableIDsFiltered: [],
        };

        this.tableRef = React.createRef();
        this.apiGet = apiGet.bind(this);
        this.apiPost = apiPost.bind(this);
        this.setState = this.setState.bind(this)

    }

    loadData = (considerPending = true) => {
        this.apiGet("/courses/list/offen?showNullSaldo=" + (this.state.search.showNullSaldo ? 1 : 0) + "&considerPending=" + (considerPending ? 1 : 0) + "&" + encodeGetParams(this.state.searchUser), (resp) => {
            const tableData = Object.keys(resp.users).map(id => ({
                ...resp.users[id],
                name: resp.users[id].lastname.trim() + ", " + resp.users[id].prename,
                verwalter: resp.users[id].verwalter_ID === resp.users[id].ID ? "ja" : "nein",
                birthdateString: dateFormat(resp.users[id].birthdate),
                sex: (Consts.sexMap[resp.users[id].sex] || "u")[0],
            }));
            this.setState({
                loading: false, ...resp.users,
                user_ids: Object.keys(resp.users),
                tableSize: tableData.filter(u => u.saldoall < 0).length,
                tableData: tableData,
                tableIDsFiltered: tableData.filter(a => (a.saldoall < 0 || this.state.search.showCredits)).map(u => u.ID),
                tableDataRaw: tableData,
                loadedBigData: true,
            });


            if (Object.keys(resp.users).length < 6) {
                this.setState({open: Object.keys(resp.users).map(id => id * 1)})
            }
        });
    };

    componentDidMount = () => this.loadData(true);
    componentWillReceiveProps = (nextProps, nextContext) => {
        if (nextProps.match.params.userID === undefined && !this.state.loadedBigData) {
            this.loadData(true);
        }
    }

    render() {
        const {user, club} = this.context;
        if (user === undefined) {
            return null;
        }
        if (!user.verwalter && user.role < 80) {
            return <Redirect to={"/benutzer/profil/" + user.ID}/>
        }
        return <>
            {
                this.props.match.params.userID !== undefined && <UserView {...this.props}/>
            }
            <Container visible broad class={"mobileBroad"}>

                <h2>Offenstände</h2>
                <Status type="error" text={this.state.error}/>
                {
                    this.state.tableSize !== undefined && <>{this.state.tableSize} Verwalter gefunden mit insgesamt {Consts.moneyMax(this.state.tableData.filter(a => a.saldoall < 0).reduce((s, curr) => s + curr.saldoall, 0) / 100)} offen
                        und {Consts.moneyMax(this.state.tableData.filter(a => a.saldoall > 0).reduce((s, curr) => s + curr.saldoall, 0) / 100)} als Guthaben. Ein positiver Saldo bedeutet, eine Person hat ein Guthaben.<br/><br/></>
                }
                <FormContextWrapper value={{state: this.state, setState: this.setState}} afterUpdate={(a, b, val) => {
                    if (b !== "showCredits") {
                        this.loadData(val.considerPending)
                    }
                    this.setState({
                        tableSize: this.state.tableData.filter(a => (a.saldoall < 0 || val.showCredits)).length,
                        tableIDsFiltered: this.state.tableData.filter(a => (a.saldoall < 0 || val.showCredits)).map(u => u.ID)

                    })
                }}>
                    <CheckboxInput name={""} tag={"search_considerPending"} noLabel/> Unbestätigte Zahlungen berücksichtigen &nbsp; &nbsp;
                    <CheckboxInput name={""} tag={"search_showCredits"} noLabel/> Guthaben anzeigen
                </FormContextWrapper>

                <MyReactTable
                    data={this.state.tableData.filter(a => (a.saldoall < 0 || this.state.search.showCredits))}
                    columns={[
                        {
                            Header: "Name",
                            filterable: true,
                            accessor: "name",
                            Cell: row => <Link to={"/benutzer/sprofil/" + row.original.ID}>{row.value}</Link>,
                            Filter: TimeoutTextColumnFilter,
                            filterMethod: () => true,
                        },
                        {
                            Header: "E-Mail",
                            filterable: true,
                            accessor: "email",
                            Cell: ({value}) => <a href={"mailto:" + value}>{value}</a>,
                            Filter: TimeoutTextColumnFilter,
                            filterMethod: () => true,
                        },
                        {
                            Header: "Kurse gebucht",
                            filterable: true,
                            accessor: "cp",
                            maxWidth: 90,
                        },
                        {
                            Header: "Status in Familie",

                            accessor: r => r.memberStatuses?.split(",").map(a => club.memberStatesShort[a]).join(", "),
                            id: "memberStatuses",
                            show: window.innerWidth > 1000,
                            maxWidth: 90,
                            Cell: ({value, original}) => original.memberStatuses
                                .split(",")
                                .map((a, i) => [i > 0 ? ", " : null, <span title={club.memberStates[a]}>{club.memberStatesShort[a]}</span>]),
                            filterable: true,
                        },
                        {
                            Header: "ZE Level",
                            filterable: true,
                            accessor: "reminderlevel",
                            maxWidth: 90,
                        },
                        {
                            Header: "Saldo",
                            id: "saldoall",
                            accessor: r => r.saldoall / 100,
                            filterable: true,
                            maxWidth: 100,
                            Cell: row => row.value === null ? "" : Consts.moneyMax(row.value),
                            sortMethod: (a, b) => (b || -Infinity) - (a || -Infinity),
                        },


                    ]}
                    defaultSorted={Object.keys(this.state.searchUser || {}).length === 0 ?[
                        {
                            id: "saldoall",
                            desc: true
                        }
                    ] : []}
                    onTableChange={(data) => {
                        this.setState({
                            tableSize: data.length,
                            tableIDsFiltered: data.map(u => u._original.ID)
                        })
                    }
                    }
                    loading={this.state.loading}
                    onFilteredChange={(a, old, updateTableFilterMethod) => onFilterChangeForUsers(a, old, (x) => this.setState({searchUser: x}, this.loadData), ["name", "email"], updateTableFilterMethod)}
                    additionalButtonsRight={
                        user?.role > 50 ?
                            <>
                                <MiniBtn>
                                    <Link to={{
                                        pathname: "/newsletter/vorlagen", state: {
                                            emailAddresses: this.state.tableData.filter(t => this.state.tableIDsFiltered.includes(t.ID)).map(t => ([t.email, t.ID, t.name])),
                                        }
                                    }}>
                                        <FaStickyNote/> Nachricht senden
                                    </Link>
                                </MiniBtn>
                                <MiniBtn onClick={() => {
                                    this.apiPost("/user/download_excel", {
                                        user_ids: this.state.tableIDsFiltered,
                                        additional: [
                                            {
                                                name: "Saldo", ...this.state.tableData.reduce((obj, t) => ({...obj, [t.ID]: Consts.moneyMax(t.saldoall / 100)}), {}),
                                            }, {
                                                name: "Zahlungsreferenz", ...this.state.tableData.reduce((obj, t) => ({...obj, [t.ID]: t.paymentReference}), {}),
                                            },
                                        ]
                                    }, ({content}) => {
                                        downloadAsExcel(content, "Verwalter.xlsx")
                                    })
                                }
                                }><FaList/> Liste herunterladen</MiniBtn>
                            </> : null
                    }
                />

            </Container>
        </>

    }
}

