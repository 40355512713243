import React, {useCallback, useContext, useEffect, useMemo, useState} from "react"
import {FormProvider, useFieldArray, useForm, useFormContext, useWatch} from "react-hook-form"
import {maxiGet, maxiPost} from "../../core/maxios"
import {objGetEntries} from "../../core/helpers"
import {LightContainer} from "../../core/components/container"
import {CheckboxInputHook, InputContainer, PriceInputHook, TextInputHook} from "../../core/input/basic"
import {datelikeCompare, DateTimepickerHook} from "../../core/input/datehooks"
import {InfoTooltip, MaxBtn, MiniBtn} from "../../core/components/components"
import {FaClone, FaMinus, FaPlus, FaSave} from "react-icons/fa"
import {useStatusVar} from "../../club/club_inputs"
import {StatusGroup} from "../../core/status"
import UserHistory from "../../user/user_history"
import {CoursePaymentFormGroup, CoursePaymentFormModel, CoursePaymentGroup, CoursePaymentPlan} from "../../core/interfaces/core"
import {UpdateContext} from "../../core/context/updateContext"
import {MyModal} from "../../core/components/modal"
import {ImportPaymentPlanFromOtherCourse} from "./ImportPaymentPlanFromOtherCourse"
import {CoursePaymentPlanSelectPreview} from "./course_payment_plan_select_preview"

export type CoursePlanProps = { readonly?: boolean, onChange?: Function, initialData?: CoursePaymentFormModel } & ({ course_ID: number, model_ID?: undefined } | { model_ID?: number, course_ID?: undefined })

interface CoursePlanEditFormType {
    data: CoursePaymentFormGroup[]
    name?: string,
    active?: boolean
}

const initialCoursePaymentPlan = {dueDate: null, description: "", active: true, bookingStart: null, bookingEnd: null, price: 0, pricenm: 0}

function moveAllDatesBy(data: CoursePaymentFormGroup[], yearCount: number) {
    return data.map(datum => ({
            ...datum, plan: datum.plan.map(({bookingEnd, bookingStart, dueDate, ...planEntry}) => {
                    let modified: Pick<CoursePaymentPlan, "bookingEnd" | "bookingStart" | "dueDate"> = {bookingEnd, bookingStart, dueDate}
                    objGetEntries({bookingEnd, bookingStart, dueDate}).forEach(({key, value}) => {
                        if (value) {
                            value = new Date(value)
                            value.setFullYear(value.getFullYear() + yearCount)
                        }
                        modified[key] = value
                    })
                    return {
                        ...planEntry,
                        ...modified,
                    }
                }
            )
        }
    ))
}

export function CoursePlanEdit(props: CoursePlanProps) {
    const {course_ID, model_ID, readonly, initialData} = props
    const {paymentGroups} = initialData || {paymentGroups: undefined}
    const form = useForm<CoursePlanEditFormType>({defaultValues: {data: paymentGroups, name: initialData?.name, active: initialData?.active}})
    const watch = useWatch(form)
    const isCompletelyNewModelEntry = !course_ID && !model_ID
    const [statusVar, setStatusVar] = useStatusVar()
    const [updatedAt, setUpdatedAt] = useState<Date | null>(null)

    const reloadData = useCallback(() => {
        if ((course_ID !== undefined && isNaN(course_ID)) || isCompletelyNewModelEntry || !!initialData) {
            return
        }
        maxiGet<CoursePlanEditFormType>(course_ID ? `/course/${course_ID}/paymentplan` : `/course/paymentmodels/${model_ID}`, {setStatusVar}).then(resp => {
            form.setValue("data", resp.data)
            form.setValue("name", resp.name)
            form.setValue("active", resp.active)
        })
    }, [course_ID, model_ID, initialData])
    useEffect(() => {
        form.reset({data: paymentGroups, name: initialData?.name, active: initialData?.active})
    }, [initialData])

    useMemo(reloadData, [])

    return <LightContainer>
        <UpdateContext.Provider value={{lastUpdate: updatedAt, setLastUpdate: setUpdatedAt}}>
            <StatusGroup {...statusVar} />
            {watch.data &&
                <form onSubmit={(e) => e.preventDefault()}>
                    <FormProvider {...form}>
                        <CoursePlanEditForm reloadData={reloadData} {...props} />
                    </FormProvider>
                </form>

            }
            {
                !readonly && !isCompletelyNewModelEntry && <>
                    {course_ID && <CoursePaymentPlanSelectPreview course_ID={course_ID}/>}
                    {model_ID && <CoursePaymentPlanSelectPreview model_ID={model_ID}/>}

                    {
                        (course_ID || model_ID) && <>
                            <h2>History</h2>
                            {course_ID && <UserHistory userID={course_ID} personType={"coursePaymentPlan"}/>}
                            {model_ID && <UserHistory userID={model_ID} personType={"modelPaymentPlan"}/>}
                        </>
                    }
                </>
            }
        </UpdateContext.Provider>
    </LightContainer>
}

export function CoursePlanEditForm({course_ID, model_ID, readonly, reloadData, onChange}: { reloadData: Function } & CoursePlanProps) {
    const form = useFormContext<CoursePlanEditFormType>()
    const paymentGroups = useFieldArray({
        name: "data",
        control: form.control,
    })
    const [errorGroups, setErrorGroups] = useState<number[]>([])
    const watch = useWatch(form)
    const {fields, append, remove} = paymentGroups
    const [statusVar, setStatusVar] = useStatusVar()
    const {setLastUpdate} = useContext(UpdateContext)

    const validateData = () => {
        const data = watch.data || []
        const distinctFieldNames = data.map(field => field.name).filter((name, index, names) => name && name !== "" && names.indexOf(name) === index)

        if (distinctFieldNames.length !== data.length) {
            setStatusVar({error: "Alle Namen der Zahlungsgruppen müssen unterschiedlich sein und dürfen nicht leer sein."})
            return
        }
        const groupPlanLengthArray = data.map((field, index) => ({
            distinctLength: (field.plan || []).map(planEntry => planEntry.description).filter((description, index, descriptions) => !!description && descriptions.indexOf(description) === index).length,
            length: (field.plan || []).length,
            index
        }))
        if (groupPlanLengthArray.length > 0 && groupPlanLengthArray.filter(entry => entry.length > entry.distinctLength).length > 0) {
            const errorGroups = groupPlanLengthArray.filter(entry => entry.distinctLength < entry.length).map(entry => entry.index)
            setErrorGroups(errorGroups)
            setStatusVar({
                error: "In jeder Zahlungsgruppe muss es zumindest einen Eintrag geben und jeder Eintrag muss eine Bezeichnung haben. Das betrifft folgende Zahlungsgruppen: " + errorGroups.map(x => x + 1).join(", ")
            })
            return
        }
        setErrorGroups([])
        setStatusVar({})
        return true
    }

    const submitData = () => {
        if (readonly || !validateData()) {
            return
        }
        const {name, active} = watch
        maxiPost(course_ID ? `/course/${course_ID}/paymentplan` : "/course/paymentmodels", {paymentplan: watch.data, model_ID, name, active,}, {setStatusVar}).then(() => {
            reloadData()
            setLastUpdate(new Date())
            setStatusVar({success: "Erfolgreich gespeichert."})
            onChange && onChange()
        })
    }
    const moveAllDatesByYears = (yearCount: number) => {
        const data = form.getValues("data")
        form.setValue("data", moveAllDatesBy(data, yearCount))
    }

    const count = watch.data?.length || 0

    return <LightContainer>
        <span style={{marginRight: 20}}>{count} Zahlungsweise{count !== 1 && "n"} gefunden.</span>
        {
            !readonly && <>
                <MaxBtn onClick={(e: any) => {
                    e.preventDefault()
                    append({name: "", plan: [initialCoursePaymentPlan], active: true})
                }}><FaPlus/> Neue Zahlungsgruppe</MaxBtn>
                <MyModal trigger={<MaxBtn>Zahlungsweise von anderem Kurs importieren</MaxBtn>}>
                    {(close) =>
                        <ImportPaymentPlanFromOtherCourse onImport={(paymentPlan: CoursePaymentGroup[]) => {
                            form.setValue("data", paymentPlan)
                            setStatusVar({success: "Zahlungsweisen wurden erfolgreich importiert. Bitte speichere noch unten, damit die Änderungen übernommen werden."})
                            close()
                        }}/>
                    }
                </MyModal>
                {
                    (watch.data?.length || 0) > 0 && <>
                        <MaxBtn onClick={(e: any) => {
                            e.preventDefault()
                            moveAllDatesByYears(-1)
                        }}><FaMinus/> 1 Jahr</MaxBtn>
                        <MaxBtn onClick={(e: any) => {
                            e.preventDefault()
                            moveAllDatesByYears(1)
                        }}><FaPlus/> 1 Jahr</MaxBtn>
                    </>
                }
            </>
        }
        <StatusGroup {...statusVar} />
        {!course_ID &&
            <div>
                <TextInputHook tag={"name"} labelStyle={{width: 300}} name={"Name des Zahlungsmodells"} placeholder={"z.B. Kurszahlungsweisen 2023/24"}/>
                <CheckboxInputHook tag={"active"} name={"Aktiv"}/>
            </div>
        }
        {
            fields.map((entry, index) =>
                <div key={entry.ID || index} className={"bordered" + (errorGroups.includes(index) ? " error" : "")} style={{overflowX: "scroll"}}>
                    <InputContainer><TextInputHook key={entry.name} tag={`data.${index}.name`} name={"Zahlungsgruppe " + (index + 1)} placeholder={"Name der Zahlungsgruppe (z.B. jährlich)"}/></InputContainer>
                    <InputContainer><CheckboxInputHook tag={`data.${index}.active`} name={"Aktiv"}/></InputContainer>
                    {
                        !entry.ID && <InputContainer style={{width: 200}}><MaxBtn onClick={() => remove(index)}><FaMinus/> Eintrag entfernen</MaxBtn></InputContainer>
                    }
                    <PaymentPlanInputTable prefix={`data.${index}.plan`} readonly={readonly}/>
                </div>)
        }
        {
            !readonly && <MaxBtn onClick={submitData}><FaSave/> Speichern</MaxBtn>
        }
        <StatusGroup {...statusVar} />
    </LightContainer>
}

function PaymentPlanInputTable({prefix, readonly}: { prefix: `data.${number}.plan`, readonly?: boolean }) {
    const form = useFormContext<{ data: CoursePaymentFormGroup[] }>()
    const watch = useWatch(form)
    const fieldArray = useFieldArray({name: prefix, control: form.control})

    return <>
        {
            !readonly && <MaxBtn onClick={() => fieldArray.append(initialCoursePaymentPlan)}><FaPlus/> Neue Zeile hinzufügen</MaxBtn>
        }
        <table className={"course-plan-table"}>
            <thead>
            <tr>
                <td> Bezeichnung</td>
                <td>Preis (€) / Preis kein Mitglied</td>
                <td>Wirksam von <WirksamTooltip/></td>
                <td>Wirksam bis <WirksamTooltip/></td>
                <td>Fällig am <InfoTooltip>Das Datum, an dem die Zahlung dieses Teilbetrags fällig ist. Wird keines gesetzt, sind die Zahlungen zum Buchungsdatum fällig.</InfoTooltip></td>
                <td>Aktiv</td>
                <td>Löschen</td>
                <td>Duplizieren</td>
            </tr>
            </thead>
            {
                form.getValues(prefix).map((plan, planIndex) => {
                    const tagPrefix: `data.${number}.plan.${number}` = `${prefix}.${planIndex}`
                    const bookingStart = form.getValues(`${tagPrefix}.bookingStart`), bookingEnd = form.getValues(`${tagPrefix}.bookingEnd`), price = form.getValues(`${tagPrefix}.price`)
                    const bookingError = datelikeCompare(bookingStart, bookingEnd) >= 0 ? "Endzeitpunkt muss nach Startzeitpunkt liegen." : undefined
                    const priceError = price && parseFloat("" + price) < 0 ? "Preis muss größer gleich 0 sein." : undefined
                    return <tr>
                        <td><TextInputHook tag={`${tagPrefix}.description`} placeholder={"z.B. 1. Semester"} required={true}/></td>
                        <td><PriceInputHook tag={`${tagPrefix}.price`} error={priceError} inputStyle={{width: 70}} required={true} min={0}/><PriceInputHook tag={`${tagPrefix}.pricenm`} error={priceError} inputStyle={{width: 70}} required={true} min={0}/></td>
                        <td><DateTimepickerHook tag={`${tagPrefix}.bookingStart`} error={bookingError}/></td>
                        <td><DateTimepickerHook tag={`${tagPrefix}.bookingEnd`} error={bookingError}/></td>
                        <td><DateTimepickerHook tag={`${tagPrefix}.dueDate`}/></td>
                        <td><CheckboxInputHook tag={`${tagPrefix}.active`}/></td>
                        <td><MiniBtn preventDefault={true} onClick={() => fieldArray.remove(planIndex)}><FaMinus/></MiniBtn></td>
                        <td><MiniBtn preventDefault={true} onClick={(e: MouseEvent) => {
                            const copyRow = form.getValues(tagPrefix)
                            if (copyRow) {
                                const {ID, ...copyable} = copyRow
                                fieldArray.append(copyable)
                            }
                        }}><FaClone/></MiniBtn></td>
                    </tr>
                })
            }
        </table>
    </>
}

function WirksamTooltip() {
    return <InfoTooltip>
        <h3>Erklärung:</h3>
        <p>"Wirksam von" und "Wirksam bis" haben folgende Bedeutung:</p>
        <p>Wenn der Zeitpunkt der Buchung zwischen diesen beiden Zeitpunkten liegt und die zu diesen Zeitpunkten zugehörige Zahlungsweise vom buchenden Benutzer ausgewählt wird, dann zählt diese Zeile der Zahlungsweise zum Buchungspreis,
            ansonsten nicht.</p>
        <h3>Zur Veranschaulichung ein kleines Beispiel:</h3>
        <ul>
            <li>Du hast 2 Zeilen, weil du eine Zahlungsgruppe "Semesterweise" erstellt hast.</li>
            <li>Die erste Zeile, das erste Semester, hat "Wirksam von" auf 01.08.2022 und "Wirksam bis" auf 01.02.2023 gesetzt.</li>
            <li>Die zweite Zeile, das zweite Semester, hat "Wirksam von" auf 01.08.2022 und "Wirksam bis" auf 01.08.2023 gesetzt.</li>
            <li>Beide Zeilen haben einen Preis von 50,-.</li>
            <li>Wenn der Benutzer Max M. jetzt am 31.01.2023 bucht, liegt das Buchungsdatum in beiden Wirksamkeitsspannen der Zeilen. Ihm wird die Zahlungsweise "Semesterweise" mit beiden Zeilen und einem Gesamtpreis von 100,- angezeigt.
            </li>
            <li>Wenn der Benutzer Max M. allerdings am 01.02.2023 bucht, liegt das Buchungsdatum nur in der zweiten Wirksamkeitsspanne. Ihm wird die Zahlungsweise "Semesterweise" mit der zweiten Zeile und einem Gesamtpreis von 50,-
                angezeigt.
            </li>
        </ul>
        <h3>Was bringt das?</h3>
        <p>Damit kannst du deine Kurse semesterweise oder quartalsweise strukturieren, ohne zusätzliche Kurse dafür erstellen zu müssen. Wenn jemand im ersten Semester bucht, zahlt er den vollen Betrag, im zweiten Semester ist aber nur die
            Hälfte fällig.</p>
        <p>Es lassen sich auch andere Modelle realisieren. Zum Beispiel: Das ganze Jahr kostet 100,-, das zweite Semester allein aber 70,-. Dafür musst du nur die Preise auf 30,- fürs erste und 70,- fürs zweite Semester stellen.</p>
    </InfoTooltip>
}
